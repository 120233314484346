import React from "react";

// Customizable Area Start
import ReactHtmlParser from 'react-html-parser';
import DOMPurify from 'dompurify';
import {
  Box,
  IconButton,
  Typography,
  styled,
  Drawer,
  Container,
  Hidden,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { image_chevron_gray } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import ContactUs from "./ContactUs.web";
const dummyProps = {
  navigation: {},
  id: "FAQ"
};
// Customizable Area End

import FAQController, { Props } from "./FAQController.web";

class FAQ extends FAQController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  FAQsForWeb() {
    return (
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
            <a href="/HelpCentre"
            style={{color: "inherit", textDecoration: "none"}}
            >
              <ArrowBack />
            </a>
          </Box>
          <Box className="heading">FAQs</Box>
          <Box className="mainBox">
            <Box className="items">
              <Typography className="using-kella-heading">
                Using Kella
              </Typography>
            </Box>
            {this.state.questions_arr.map((item: any) => {
              return (
                <Box className="items" style={{position:"relative", cursor: "pointer"}}>
                  <Box className="icon-content" onClick={this.toggleFAQsDrawer(true,item.attributes.id)}>
                    <Typography
                      className="content"
                      onClick={this.toggleFAQsDrawer(true,item.attributes.id)}
                    >
                      {item.attributes.question}
                    </Typography>
                    <img
                      src={image_chevron_gray}
                      alt="image_chevron_gray"
                      onClick={this.toggleFAQsDrawer(true,item.attributes.id)}
                      style={{ position: "absolute", right: 0, cursor: "pointer" }}
                    />
                  </Box>
                  {this.FAQsDrawerWeb()}
                </Box>
              );
            })}
            <ContactUs {...dummyProps} />
          </Box>
        </Box>
      </Setting>
    );
  }

  FAQsForMobile() {
    return (
      <Box style={{ minHeight: "100vh", overflow:"hidden" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width:  "100%",
            paddingLeft: 0,
            paddingRight: 0,
            position: "relative",            
            borderBottom: "2px solid #E2E8F0", }} >
          <IconButton style={{ position: "absolute", left: 0 }}>
            <a style={{ color: "inherit", textDecoration: "none" }}
              href="/HelpCentre" >
              <ArrowBack />
            </a>
          </IconButton>
          <Typography variant="h6" style={webStyle.mobileHeading}>
            FAQs
          </Typography>
          <div style={{ width: 48 }}></div>{" "}
        </Box>

        <Box
          style={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "13px",
          }}
        >
          <Box style={webStyle.mobileMainBox}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Typography style={webStyle.mobileAccount}>
                Using Kella
              </Typography>
            </Box>
            {this.state.questions_arr.map((item: any) => {
              return (
            <Box style={{ ...webStyle.mobileItems}}>
              <Box style={webStyle.mobileIconContent} onClick={this.toggleFAQsDrawer(true,item.attributes.id)}>
                <Typography style={webStyle.mobileContent}
                  onClick={this.toggleFAQsDrawer(true,item.attributes.id)}
                >
                  {item.attributes.question}
                </Typography>
                <IconButton
                  style={{ position: "absolute", right: 0, cursor: "pointer" }}
                  onClick={this.toggleFAQsDrawer(true,item.attributes.id)}
                >
                  <img src={image_chevron_gray} alt="image_chevron_gray" />
                </IconButton>
              </Box>
              {this.FAQsDrawerMobile()}
            </Box>
              );
            })}
            <ContactUs {...dummyProps} />
          </Box>
        </Box>
      </Box>
    );
  }

  FAQsDrawerWeb = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.faqDrawerOpen}
        onClose={this.FAQsDrawerClose}
        PaperProps={{
          style: {
            borderTopRightRadius: "20px",
            width: "40%",
            overflowX:"hidden",
          },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", padding: "10px" }}>
            <Typography
              style={{
                fontWeight: "bolder",
                color: "#14362E",
                fontSize: "24px",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              {this.state.question}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.FAQsDrawerClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box
          style={{
            marginTop: "10px",
            fontFamily: "MyFont",
            letterSpacing: "1.4",
          }}
        >
          <Box style={{ marginTop: "10px", marginRight: "3%", wordBreak: 'break-word', paddingLeft: "10px" }}>
            <Typography component='div'>
              {ReactHtmlParser(DOMPurify.sanitize(this.state.answer))}
            </Typography>
          </Box>
        </Box>
        <Box style={{margin:"20px 0"}}>
          <ContactUs {...dummyProps} />
        </Box>
      </Drawer>
    );
  };

  FAQsDrawerMobile = () => {
    return(
      <Drawer
      anchor="right"
      open={this.state.faqDrawerOpen}
      onClose={this.FAQsDrawerClose}
        PaperProps={{
          style: {
            width: "100%",
            overflowX: "hidden"
          }
        }}
      >
       <Box style={{ 
            width: "100%", 
            marginTop:"30px", 
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",                    
            borderBottom: "1px solid #E2E8F0",          
          }} >
          <Box>
            <IconButton 
            style={{ 
              left: 0 
            }}
              onClick={this.FAQsDrawerClose}>
                  <ArrowBack />
              </IconButton>
          </Box>
          <Box> <IconButton style={{ right: "10px" }}
                onClick={this.FAQsDrawerClose} >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",         
        }} >
        <Box 
          style={{ 
            padding:"15px" 
            }}
          >
          <Typography style={{ color: "#14362E",
              fontSize: "24px", fontFamily: "MyFont",
              fontWeight: "bolder", letterSpacing: "1.4", }}
          >
            {this.state.question}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{ fontFamily: "MyFont",
          marginTop: "10px",        
          letterSpacing: "1.4", }}
        >
        <Box style={{ marginTop: "10px", paddingLeft: "10px" }}>
          <Typography component='div'>
            {ReactHtmlParser(DOMPurify.sanitize(this.state.answer))}
          </Typography>
        </Box>
      </Box>
      <Box style={{paddingLeft: "10px", margin: "10px" }}>
        <ContactUs {...dummyProps} />
      </Box>
    </Drawer>
    );
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Container maxWidth={false}>
        <Hidden smDown>{this.FAQsForWeb()}</Hidden>
        <Hidden mdUp>{this.FAQsForMobile()}</Hidden>
      </Container>
      // Customizable Area End
    );
  }
}
// Customizable Area Start

export default FAQ;

const Setting = styled(Box)({
  "& .outerBox": {
    height: "100%",
    width: "100%",
    backgroundColor: "#F8FAFC",
  },
  "& .arrow": {
    display: "flex",
    alignItems: "center",
    height: "48px",
    padding: "0px 2%",
    backgroundColor: "#FFFFFF",   
  },
  "& .heading": {
    display: "flex",
    alignItems: "center",
    color: "#14362E",
    height: "56px",
    fontSize: "24px",
    paddingLeft: "6%",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",  
  },
  "& .mainBox": {
    width: "816px",
    // height: "929vh",
    margin: "2% auto",
    borderRadius: "16px",
    padding: "24px 40px",
    backgroundColor: "#FFFFFF", 
  },
  "& .items": {
    display: "flex",
    alignItems: "center",
    position: "relative",
    height: "42px",
    borderBottom: "1px solid #E2E8F0",
    padding: "12px 0px",  
  },
  "& .account": {
    fontFamily: "MyFont",
    color: "#64748B",
    fontSize: "16px",
    fontWeight: 800,  
  },
  "& .icon-content": {
    display: "flex",
    alignItems: "center",
    gap: "10px", 
  },
  "& .content": {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "MyFont",
  },
  "& .contact": {
    height: "120px",
    padding: "28px 0px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  "& .using-kella-heading": {
    fontSize: "18px",
    fontWeight: "bold",
    fontFamily: "MyFont",
    color: "#334155",
  },
});

const webStyle = {
  mobileHeading: {
    color: "#14362E",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    fontWeight: 700,
    height: "56px",
    fontSize: "24px",  
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",    
  },
  mobileMainBox: {
    margin: "1% auto",
    borderRadius: "16px",
    backgroundColor: "#FFFFFF",  
  },
  mobileItems: {
    marginRight: "40px",
    marginTop: "18px",
    display: "flex",
    alignItems: "center",
    height: "42px",
    borderBottom: "1px solid #E2E8F0", 
  },
  mobileAccount: {
    fontFamily: "MyFont",
    color: "#334155",
    fontSize: "18px",
    fontWeight: 900,  
    marginBottom: "18px",
    letterSpacing: "0.05em",
  },
  mobileIconContent: {
    display: "flex",
    alignItems: "center",
    gap: "10px",   
    marginBottom: "18px",
    curser: "pointer,"
  },
  mobileContent: {
    color: "#0F172A",
    fontFamily: "MyFont",
    fontSize: "16px",
    fontWeight: 700,   
    marginButtom: "10px",
  },
  mobileContact: {
    display: "flex",
    alignItems: "center",
    height: "120px",
    paddingTop: "28px",  
  },
  mobileIconContentContact: {   
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
    gap: "10px",
    // marginRight: "-200px"
  },
  contactUsDrawerTxt: {
    color: "#1E1E1E",
    fontSize: "14px",
    fontFamily: "MyFont",
    letterSpacing: "1.4",   
    marginBottom: "20px",
  },
  contactUsDrawerInputLabel: {
    color: "#334155",
    fontSize: "16px",
    fontWeight: 700,  
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "1px",
    bottom: "10px",
  },
};
// Customizable Area End
