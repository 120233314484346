//@ts-nocheck
import React from "react";

// Customizable Area Start
import { CardHeader, Avatar, CardContent,Card, Typography, Box, MenuItem, Menu, IconButton, styled, Drawer, Container, Button, Modal, Divider, InputAdornment, List, ListItem, Collapse, TextField, StyledComponentProps } from "@material-ui/core";
import TrendingProductDetailsController, { Props } from "./TrendingProductDetailsController.web";
import ShareDrawer from "../../../components/src/ShareDrawer.web";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { LinkImg } from "./assets";
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Toaster } from 'react-hot-toast';
import moment from "moment";
import Carousel from "react-multi-carousel";
import {
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ReportIcon from '@material-ui/icons/Report';
import BlockIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { formSubmittedSuccessfully, blockedSuccessfully } from "../../../components/src/CommonForms";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

import EmojiPicker from "emoji-picker-react";
import { AppFooter } from "../../../components/src/AppFooter.web";
  //istanbul ignore next
// Customizable Area End

export default class TrendingProductDetails extends TrendingProductDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.redirectprevios = this.redirectprevios.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  commentCounts = (element:any)=>{
    return element.attributes.comment_counts == "0" ? "Comment" : element.attributes.comment_counts
  }
  
  repliesCount = (element: any) => {
    return element.attributes.replies_counts == "0" ? "Reply": element.attributes.replies_counts;
  }

  categoryElement(element) {
    return (
      <>
        {
          element.attributes && element.attributes.profiles.accounts?.map((secondelement: object, index: number) => {
                if (index < 4) {
                  return (
                    <>
                    <img
                      key={index}
                      src={secondelement.profile_picture.url}
                      alt=""
                      style={{width: this.state.isScreenSmall ? "40px" : "50px", height: this.state.isScreenSmall ? "40px" : "50px", borderRadius: "50%"}}
                    />
                   {index === 3 && <div
                      key={index}
                      style={{...webStyle.roundBox, height: this.state.isScreenSmall ? "40px" : "50px",
                      width: this.state.isScreenSmall ? "40px" : "50px",}}
                    >
                      +{element.attributes.profiles.counts}
                    </div>}
                    </>
                  );
                }  else {
                  return null;
                }
          })
        }
      </>
    )
  }
  categoryAlllist() {
    return (
      <>
        {
          this.state.categoryDataset && this.state.categoryDataset.attributes?.sub_categories?.map((element: object, index: number) => {
            return (
              <CardBox key={index} >
                <Box style={webStyle.innercontent}>
                  <Box style={webStyle.inercontentheader}>
                    <Typography style={webStyle.headercontent}>{element.attributes?.name}</Typography>
                    <Typography data-test-id="viewGroupId" style={webStyle.headersecondcontent} onClick={this.handleviewgroup.bind(this, this.state.categoryDataset.id, element.id, element.attributes?.name)}>View Group</Typography>
                  </Box>
                  <Box style={webStyle.innermaincontent}>
                    <Typography style={webStyle.innermaincontenttext}>{element.attributes?.description}</Typography>
                  <Box style={ webStyle.imgflexbox}>
                    {this.categoryElement(element)}
                  </Box>
                  </Box>
                </Box>
              </CardBox >)
          })
        }
      </>
    )
  }

  getSendIcon = (comment: string) => {
    return {
        padding: '6px 17px',
        margin: '6px',
        borderRadius: '50px',
        background: comment.trim() ? '#73A58E' : '#E0E0E0',
        color: comment.trim() ? '#FFFFFF' : '#9E9E9E',
    };
};
  
  replyRender = (index: number, element: any ) => {
    return (<Collapse data-test-id="collapse" in={this.state.commentReplyOpen[index] && this.state.isOpenElememt === element?.id} timeout="auto" unmountOnExit>
      <Box style={{
        padding: "10px", margin: "auto", display: "flex", alignItems: "baseline",
        gap: "15px", justifyContent: "end"
      }}>
        <TextField
          data-test-id="replytext"
          fullWidth
          placeholder="Write a Reply...."
          value={this.state.replyComment}
          onChange={this.handleReplyCommentChange}
          maxRows={4}
          variant="outlined"
          style={{ marginBottom: "10px", width: "50%", height: "48px" }}
          InputProps={{
            style: {
              fontFamily: "MyFont",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "50px"
            },
            endAdornment: (
              <InputAdornment position="end">
                <i className="fa-regular fa-face-smile custom-icon" 
                 onClick={this.onClickReplyedEmoji.bind(this)}
                style={{ cursor: "pointer", right: "auto"}} 
               >
                </i>
                {this.state.repliedShowEmoji && <EmojiPicker
                onEmojiClick={this.RepliedEmojiClick.bind(this)}
                
                className="reply-emoji-picker"
                />}
                <IconButton
                  aria-label="Submit"
                  edge="end"
                  style={{
                    borderRadius: "8px",
                    padding: "0",
                  }}
                  onClick={this.handleReplycomment}
                  disabled={!this.state.replyComment?.trim()}
                >
                  <ArrowUpwardIcon
                    style={{
                      borderRadius: "50px",
                      color: this.state.replyComment?.trim() ? '#FFFFFF' : '#9E9E9E',
                      background: this.state.replyComment?.trim() ? '#73A58E' : '#E0E0E0', padding: "6px 17px", margin: "6px"}} /></IconButton> </InputAdornment>),}}/></Box></Collapse>)}

renderReplycomment = (element: any, index: number) => {
    return (
        element.attributes.replies.slice().reverse().map((replys: any, replyIndexs: number) => {
            const commentUser = replys.attributes.comment_user;

            if (!commentUser || !commentUser.first_name || !commentUser.last_name) {

                return null;
            }
            return (
              <Collapse data-test-id="collapse-reply" in={this.state.forReplyOpen && this.state.isOpenElememt === element?.id} timeout="auto" unmountOnExit>
                <Box key={replyIndexs} sx={{ padding: '0px 10px 0px 32px', display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: "flex", fontFamily: "MyFont", justifyContent: "end",  }}>
                        <Avatar style={{ margin: "16px" }} src={replys.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                        <Box style={{ fontSize: "14px",padding: "12px 16px", backgroundColor: "#F8FAFC",  width: "50%", wordBreak: 'break-word' }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont", }}>
                                    {`${commentUser.first_name} ${commentUser.last_name}`}
                                </Typography>
                                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                    {this.calculateTimeGap(replys.attributes.created_at)}
                                </Typography>
                            </Box>
                            {replys.attributes.comment}
                        </Box>
                    </Box>
                    <CardContent>
                        <Box style={{ display: "flex",alignItems: "center", justifyContent: "end",  fontFamily: "MyFont", gap: "50px", marginRight: "50px", color: '#64748B', fontSize: '14px', }}>
                            <Box>
                                {replys.attributes.is_liked ? (
                                    <>
                                        <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, replys)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>
                                        <span style={{color: '#64748B', fontSize: '14px', fontFamily: 'MyFont', fontWeight: 600}}>{replys.attributes.like_counts}</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="fa-regular fa-heart" style={{  cursor: "pointer", marginRight: "5px", }} onClick={this.likeComment.bind(this, replys)}></i>
                                       <span style={{color: '#64748B', fontSize: '14px', fontFamily: 'MyFont', fontWeight: 600}}>{replys.attributes.like_counts === 0 ? "Like" : replys.attributes.like_counts}</span>
                                    </>
                                )}
                                &nbsp;&nbsp;
                            </Box>
                            <Box style={{color: '#64748B', fontSize: '14px', fontFamily: 'MyFont', fontWeight: 600}}>
                            <i data-test-id="likeReply" className="fa-regular fa-message" style={{cursor: 'pointer'}} onClick={() => this.handleReplySection(index, element.attributes.id)}></i>&nbsp;&nbsp;
                               Reply
                              &nbsp;&nbsp;
                            </Box>
                        </Box>
                    </CardContent>
                </Box>
              </Collapse>
            );
        })
    );
}

renderDraweredfunction() {
    return (
        <CustomDrawer
            anchor="right"
            open={this.state.isCommentDrawerOpen}
            onClose={this.drawerClosed}
            data-test-id="Drawer-closed"
        >
            <Box
                style={{backgroundColor: '#F8FAFC', height: '100vh'}}
                role="presentation"
            >
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography style={{ fontSize: "24px", fontWeight: "900", color: "#14362E", paddingLeft: "25px" }}>
                        Post
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                        <IconButton onClick={this.handleClose2}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box style={{backgroundColor: '#F8FAFC', height: '100vh'}}>
                    {
                        this.state.selectedPostId !== null && (
                            <Box style={{padding: '20px 22px 10px 10px'}}>
                                <Typography>
                                    { this.state.groupDataset.length > 0 && this.state.groupDataset.filter((element: any) => element.id === this.state.selectedPostId)
                                        .map((element: any, index: number) => (
                                            <Card
                                                key={element.id}
                                                style={{
                                                    maxWidth: 750,
                                                    width: "100%",
                                                    fontFamily: "MyFont",
                                                    boxShadow: 'none',
                                                }}
                                            >
                                                <CardHeader
                                                    data-test-id="commentsCard"
                                                    style={{ fontFamily: "MyFont" }}
                                                    avatar={
                                                        <Avatar src={element.attributes.posted_user.profile_picture.url} aria-label="recipe">
                                                        </Avatar>
                                                    }
                                                    action={
                                                      <>
                                                        {element.attributes.is_favorite && (
                                                            <IconButton
                                                              data-test-id="icon-button"
                                                              edge="start"
                                                              aria-haspopup="true"
                                                              aria-controls={`menu-${index}`}
                                                              aria-label="more"
                                                              onClick={() => this.getToFavrioutedData(element.id)}
                                                            >
                                                              <BookmarkIcon style={{ color: "#14362E" }} />
                                                            </IconButton>
                                                          )}
                                                        <i className="fa-solid fa-ellipsis-vertical" style={{ marginTop: "12px", cursor: 'pointer',
                                                        marginRight: '3px' 
                                                        }} onClick={(event) => this.handleOpenMenu(event, element.id)}></i>
                                                        </>
                                                    }
                                                    title={
                                                        <div style={{  fontSize: "16px", fontWeight: "700",fontFamily: "MyFont", letterSpacing: "1px" }}>
                                                            {element.attributes.posted_user.first_name + ' ' + element.attributes.posted_user.last_name} .
                                                            <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>
                                                                {element.attributes.posted_user.role}
                                                            </span>
                                                        </div>
                                                    }
                                                    subheader={
                                                        <div style={{  fontSize: "14px", fontWeight: "400",fontFamily: "MyFont", letterSpacing: "1px" }}>
                                                            {moment(element.attributes.updated_at).format('dddd, hh:mm A')}
                                                            <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>
                                                                {element.attributes.category}
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                                <Typography
                                                    style={{
                                                        fontWeight: 500,
                                                        fontSize: "14px",
                                                        paddingLeft: "17px",
                                                        fontFamily: "MyFont",
                                                        letterSpacing: "1px",
                                                        fontStyle: "normal"
                                                    }}
                                                >
                                                    {element.attributes.body}
                                                </Typography>
                                                {
                                                    (element.attributes.link_title && element.attributes.external_link) && (
                                                        <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", padding: "4px", justifyContent: "space-between", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                                                            <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                                                <img src={LinkImg} alt="" />
                                                                <Box style={{ marginLeft: "10px" }}>
                                                                    <p>{element.attributes.link_title}</p>
                                                                </Box>
                                                            </Box>
                                                            <span style={{ fontSize: "20px", cursor: 'pointer' }}><i className="fa-regular fa-copy"></i></span>
                                                        </Box>
                                                    )
                                                }
                                                <CardContent>
                                                    <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont", color: '#64748B', fontSize: '14px' }}>
                                                        <Box>
                                                            {element.attributes.is_liked ? (
                                                                <>
                                                                    <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red",  cursor: "pointer", marginRight: "5px"}}></i><span style={{fontWeight: 600, fontSize: '14px', color: '#64748B'}}>{element.attributes.like_counts}</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
                                                                    <span style={{fontWeight: 600, fontSize: '14px', color: '#64748B',}}>{element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}</span>
                                                                </>
                                                  )}
                                                  &nbsp;&nbsp;
                                                </Box>
                                                <Box onClick={this.handleClick.bind(this, element.id)} style={{fontWeight: 600, fontSize: '14px', color: '#64748B',}}>
                                                  <i className="fa-regular fa-message" style={{cursor: 'pointer'}}></i>&nbsp;&nbsp;
                                                  {this.commentCounts(element)}
                                                </Box>
                                                <Box onClick={this.drawerSidebaar.bind(this, element.id)} style={{fontWeight: 600, fontSize: '14px', color: '#64748B',}}>
                                                  <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                                  Share
                                                </Box>
                                              </Box>
                                            </CardContent>
                                            </Card>
                                        ))}

                                </Typography>
                            </Box>
                        )
            }
            
            <Box style={{padding: '10px'}}>
              <Box style={{ display: 'flex', alignItems: 'center', gap: "1", justifyContent: "start", backgroundColor: 'white', padding: '10px' }}>
                <Avatar
                  src={this.state.profileImage}
                  aria-label="recipe"
                />
                <StyledMentionedInput style={{ width: "85%"}}  onChange={this.handleCommentChange} placeholder="Write a comment..." value={this.state.comment}>
                  <Mention
                    trigger="@"
                    data={this.state.users}
                    renderSuggestion={(suggestion: SuggestionDataItem) => (<>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Avatar style={{ margin: "8px" }} src={(suggestion as UserSuggestion).profile_picture} />
                        <Box style={{ display: "flex", alignItems: "center" }}>{suggestion.display}</Box></Box>
                    </>)}/>
                </StyledMentionedInput>
                <InputAdornment position="end" style={{position: "absolute", marginLeft: "75%"}}>
                  <i  className="fa-regular fa-face-smile custom-icon "
                    style={{ paddingRight: "0px", cursor: "pointer"}}
                    onClick={this.onClickedEmoji.bind(this)}>
                  </i>
                  {this.state.showEmoji && <EmojiPicker
                    onEmojiClick={this.EmojiClick.bind(this)}
                    className="emoji-picker"
                  />}
                  <IconButton
                    data-test-id="commentSubmit"
                    aria-label="send comment"
                    edge="end"
                    onClick={this.handleCommentSubmit}
                    className="button-icon custom-icon"
                    disabled={!this.state.comment?.trim()}
                  >
                    <ArrowUpwardIcon
                      style={this.getSendIcon(this.state.comment)}
                    />
                  </IconButton>
                </InputAdornment>
              </Box>
            </Box>
              <List style={{padding: '5px 10px'}}>
                <Box style={{backgroundColor: 'white'}}>
                  <ListItem button onClick={this.toggleComments} disableRipple
                    style={{
                      display: "flex",
                      fontSize: "16px",
                      fontWeight: "500",
                      alignItems: "center",
                      justifyContent: "end",
                      fontFamily: "MyFont"
                    }}
                            >
                      <Box style={{fontFamily:"MyFont", color:' #334155', fontWeight: "bold", marginRight: '6px', marginTop: '5px'}}>All comments</Box>
                      {this.state.isCommentsOpen ? <ExpandMoreIcon /> : < ExpandLessIcon />}
                  </ListItem>
                  <Collapse in={this.state.isCommentsOpen} timeout="auto" unmountOnExit>
                      <Box style={{ display: "flex", flexDirection: "column", marginTop: '10px' }}>
                          {this.state.commentData.filter((comment: { attributes: { parent_comment_id: null; comment: string; }; }) => comment.attributes.parent_comment_id === null).map((element: {
                              attributes: {
                                  comment_user: { first_name: string, last_name: string, profile_picture: { url: string } };
                                  is_liked: boolean;
                                  created_at: string;
                                  like_counts: number;
                                  replies: any;
                                  id: number; comment: string | null | undefined; parent_comment_id: number;
                              };
                          }, index: number) => (
                              <Box key={index}>
                                  <Box style={{ display: "flex", fontFamily: "MyFont", }}>
                                      <Avatar style={{ margin: "0px 16px", width: '44px', height: '44px' }} src={element.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                                      <Box style={{  backgroundColor: "#F8FAFC",fontSize: "14px", padding: "12px 16px", width: "75%", wordBreak: 'break-word', borderRadius: '0px 16px 16px 16px' }}>
                                          <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                              <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>{`${element.attributes.comment_user.first_name} ${element.attributes.comment_user.last_name}`}</Typography>
                                              <Typography style={{ fontSize: "12px", fontWeight: "500" }}>{this.calculateTimeGap(element.attributes.created_at)}</Typography>
                                          </Box>
                                          {element.attributes.comment}
                                      </Box>
                                  </Box>
                                  <CardContent>
                                      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", fontSize: '14px', color: '#64748B', fontFamily: "MyFont" }}>
                                          <Box>
                                              {element.attributes.is_liked ? (
                                                  <>
                                                      <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                                                  </>
                                              ) : (
                                                  <>
                                                      <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, element)}></i>
                                                      <span style={{color: '#64748B',fontWeight: 600, fontSize: '14px'}}>{element.attributes.like_counts === 0 ? "Like" : element.attributes.like_counts}</span>
                                                  </>
                                              )}
                                              &nbsp;&nbsp;

                                          </Box>
                                          <Box style={{color: '#64748B',fontWeight: 600, fontSize: '14px'}}>
                                              <i data-test-id="likeReply" className="fa-regular fa-message" style={{cursor: 'pointer', }} onClick={() => this.handleReplySection(index, element.attributes.id)}></i>&nbsp;&nbsp;
                                              {this.repliesCount(element)}
                                          </Box>
                                          <Box style={{color: '#64748B',fontWeight: 600, fontSize: '14px'}}>
                                              <i className="fa-solid fa-arrow-up-from-bracket" style={{cursor: 'pointer'}} onClick={this.handleShareCommentClick.bind(this, element.attributes.id)}></i>&nbsp;&nbsp;
                                              Share
                                          </Box>
                                      </Box>
                                      <List>
                                      <ListItem data-test-id="toggleListFun" button onClick={() => this.toggleReplyFun(element)} disableRipple
                                        style={webStyle.toggleStyle}
                                      >
                                        <Box>All Replies</Box>
                                        {this.state.forReplyOpen && this.state.isOpenElememt === element?.id ? < ExpandLessIcon style={{cursor: "pointer"}} /> : <ExpandMoreIcon style={{cursor: "pointer"}}/> }
                                      </ListItem>
                                    </List>
                                  </CardContent>
                                  {this.renderReplycomment(element, index)}
                                  {this.replyRender(index, element)}
                              </Box>
                          ))}
                      </Box>
                  </Collapse>
                </Box>
              </List>
            </Box>

          </Box>
        </CustomDrawer>)
}

renderSharedfunction = () => {
      const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, onClose }) => (
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
            <Typography className="selectedtype">{title}</Typography>
            <span onClick={onClose}>
                <i className="fa-solid fa-xmark" style={{cusror: 'pointer'}}></i>
            </span>
        </Box>
      );
    
  const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => (
    <> <Box style={{ display: "flex", justifyContent: "center", width: '100%', alignItems: 'center', position: 'absolute', bottom: '0px', padding: '10px 0px' }}>
        <Button variant="contained" style={{width: "90%", background: "#14362E",textTransform: "none", fontFamily: "myfont", color: "white",margin: "auto"}} onClick={onClick}>Send</Button>
    </Box> </>
);
   
const SelectedMembers: React.FC<SelectedMembersProps> = ({ members, onDelete }) => (
  <>
    <Box style={{ display: 'flex', flexWrap: 'wrap' }}>{members.map((member, index) => (
       <><Box key={index} style={{ borderRadius: '26px', width: 'auto', display: 'flex', alignItems: 'center', background: '#F1F5F9', margin: '0px 10px 10px',
            padding: '6px 8px 6px 12px', height: '32px' }}><Typography style={{ fontSize: '14px', fontFamily: "MyFont", marginRight: '9px', lineHeight: '22px', color: '#475569' }}>{member.value}</Typography>
            <span onClick={() => onDelete(index)} style={{padding: '0px 15px'}}><i className="fa-solid fa-xmark" style={{ fontSize: '15px' }}></i></span>
          </Box>
       </>
        ))}
    </Box>
  </>
);

return (
      <>

        <CustomDrawer
         anchor="right" onClose={this.drawerSidebaarClose3} open={this.state.open3} >
          <BoxCustomtwo >
            
            <DrawerHeader title="Share comment" onClose={this.drawerSidebaarClose3} 
              title="Share comment" />
            
            <Box style={{ borderBottom: '1px solid #CBD5E1' }}></Box>

            <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px", marginLeft: "5px" }}>
                      <Box style={{ width: "100%", marginBottom: "9px", border: "1px solid #CBD5E1", marginTop: "5px", display: "flex", alignItems: "center", borderRadius: "8px" }}>
                        <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: "9px", color:"#DADADA" }}></i>
                        <input data-test-id="connectionSearch" placeholder="Search By Name" type="text" style={{ width: "100%", padding: "11px 4px", borderRadius: "5px", border: "none", outline: "none", lineHeight: '24px', }} onChange={this.handlesearchTag} value={this.state.setSearchvalue} name="exlink" id="" />
                        {
                          this.state.setSearchvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer", marginRight: '10px' }} data-test-id="removesearchdata" onClick={this.removesearchdata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8", marginRight: '10px', cursor: 'pointer' }}></i>
                       }
                </Box>
              </Box>
              
              <SelectedMembers members={this.state.selectedmember} onDelete={this.handleselectmemberDelete} />

              <Box data-test-id="selectedMemberchip" style={{padding: '5px 15px', maxHeight: this.state.selectedmember.length > 3 ? "120px" : "180px", overflowY: 'scroll'}}>
                { this.state.shareconnectiondata2.length > 0 && this.state.shareconnectiondata2.map((element1: any, index: any) => {
                    return(
                      <>
                         <Box key={index} 
                              style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 0px', display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px", height: "48px", marginTop: '11px'}}
                              onClick={(elementvalue) => this.getvalueconnectioncheck(elementvalue, element1)}
                              >
                              <Box style={{ display: "flex", borderRadius: "9px", alignItems: "center" }}>
                                <img src={element1.profile_picture.url} style={{ height: "44px", width: "44px", borderRadius: '9pc' }} alt="" />
                                <Box style={{ marginLeft: "18px", display: 'flex', flexDirection: 'column' }} >
                                  <span style={{fontFamily: 'MyFont', fontSize: '16px', fontWeight: '700', color: '#0F172A', lineHeight: '24px'}}> {element1.name}</span>
                                  <span style={{fontFamily: 'MyFont', fontSize: '16px', fontWeight: '500', color: '#64748B', lineHeight: '18px'}}> {element1.rol} </span></Box >
                              </Box >
                              <span className="inputSelectedMember">
                                <input data-test-id = "connectionInputId" checked={this.state.selectedmember.some((sl) => sl.id === element1?.id)} onClick={(elementvalue) => this.getvalueconnectioncheck(elementvalue, element1)} type="checkbox" name="" id="" />
                                </span></Box > 
                      </>
                    )
                  })
                }
              </Box >
              <Box className="box7" style={webStyle.inputspace}>
                  <Box className="box8" style={{width: '99%', border: '1px solid #CBD5E1', display: 'flex', alignItems: 'center', borderRadius: '8px'}}><input className="input2" style={webStyle.searchinputspace} type="text" onChange={this.handlewritemessage} name="exlink" placeholder="Write Message" id="" /></Box>
              </Box>
              <ContinueButton data-test-id="continued" onClick={this.handlePostshare} />
          </BoxCustomtwo>
          <Toaster />
        </CustomDrawer >
      </>
    )
}

   mapMediaItems(mediaItems: { content_type: string; url: string; }[]) {
    return mediaItems.map((item: { content_type: string; url: string; }) => ({
      type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
      url: item?.url,
    }));
  }

  getCards = () => {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 0 },
        items: 1,
        slidesToSlide: 1
      },
    };
    return (
      <Box key={1} style={{ display: "flex", flexDirection: "column", background: "#F8FAFC", alignItems: "center" }}>

      {
       this.state.groupDataset.map((element: object, index: number) => {

    
  
        const imagesAndVideos = element.attributes.images_and_videos ? this.mapMediaItems(element.attributes.images_and_videos) : [];
        const media = element.attributes.media ? this.mapMediaItems(element.attributes.media) : [];
  
        const combinedMedia = [...imagesAndVideos, ...media];
        const images = combinedMedia.filter(media => media.type === 'image');
        const videos = combinedMedia.filter(media => media.type === 'video');
        const isFavoritePost = element.attributes.is_favorite;
          return (
  <Card key={element.id} style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: index === 0 ? "6px" : "0px", fontFamily: "MyFont" }}>
    <Box style={webStyle.cardflexbox}>
      <CardHeader
        style={{ fontFamily: "MyFont" }}

        avatar={
          <a href={`UserProfileBasicBlock/${element?.attributes?.account_id}`}>
            <Avatar src={element.attributes?.posted_user?.profile_picture?.url} aria-label="recipe">

            </Avatar>
          </a>

        }

        title={<div style={{ fontFamily: "MyFont", fontSize: "18px", fontWeight: "700", letterSpacing: "1px" }}>{element.attributes.posted_user.first_name + element.attributes.posted_user.last_name} . <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>{element.attributes.posted_user?.role}</span></div>}
        subheader={<div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>{moment(element.attributes.updated_at).format('dddd, hh:mm A')} <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes?.category}</span>
        <span style={webStyle.citytest}>{element.attributes?.city}</span></div>}
      />

      <Box key={element.id} style={{ cursor: "pointer", textAlign: "right", display: 'flex', height: '50px' }}>
        {isFavoritePost && (
          <IconButton
            data-test-id="icon-button"
            edge="start"
            aria-haspopup="true"
            aria-controls={`menu-${index}`}
            aria-label="more"
            onClick={() => this.getToFavrioutedData(element.id)}
          >
            <BookmarkIcon style={{ color: "#14362E" }} />
          </IconButton>
        )}

        <IconButton
          data-test-id="handleOpenMenu" edge="start" aria-haspopup="true" aria-controls={`menu-${index}`} aria-label="more"
          onClick={(event) => this.handleOpenMenu(event, element.id)}
        ><MoreVertIcon /></IconButton>
        {/* menu box start */}
        <MenuBox style={{ position: "relative" }}>
          <Menu
            key={index} anchorEl={this.state.openMenu} id={`menu-${index}`} open={this.state.openMenuId === element.id} onClick={this.handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }} anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            style={{
              marginTop: "34px"
            }}
          >
            <MenuItem
              data-test-id="addtofavBtn"
              onClick={() => this.getToFavrioutedData(element.id)}
              style={{
                fontFamily: "MyFont",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                letterSpacing: "0.08em",
                textAlign: "left",
              }}
            >
              {isFavoritePost ? (
                <>
                  <BookmarkBorderIcon
                    style={{
                      height: "20px",
                      fontWeight: "500",
                    }}
                  />
                  Remove from favourites
                </>
              ) : (
                <>
                  <BookmarkBorderIcon
                    style={{
                      height: "20px",
                      fontWeight: "500",
                    }}
                  />
                  Add to favourites
                </>
              )}
            </MenuItem>
         { element?.attributes?.account_id != this.state.accountId &&  (<><MenuItem
              data-test-id="addtoReportBtn"
              onClick={(event) => this.handlePostReport(element.id)}
              style={{
                color: "#D1584C",
                fontFamily: "MyFont",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                letterSpacing: "0.08em",
                textAlign: "left",
              }}
            >
              <ReportIcon
                style={{
                  height: "20px",
                  fontWeight: "500",
                }} />
              &nbsp; Report
            </MenuItem><MenuItem
              data-test-id="addtoBlockBtn"
              onClick={() => this.handleBlockUser(element.attributes?.account_id)}
              style={{
                color: "#D1584C",
                fontFamily: "MyFont",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "22px",
                letterSpacing: "0.08em",
                textAlign: "left",
              }}
            >
                <BlockIcon
                  style={{
                    height: "20px",
                    fontWeight: "500",
                  }} />
                &nbsp; Block user
              </MenuItem></>)}
          </Menu>
        </MenuBox>
      </Box>
    </Box>
    {element.attributes.is_recommendation ? <Box style={webStyle.recommendationBox}>Recommendation</Box> : ""}

    {element.attributes.is_recommendation ? <Box style={{ marginTop: "20px"}}>
      <Box style={webStyle.recommendationBoxtitle}>{element.attributes.name}</Box>

      <Box style={{ display: 'flex', alignItems: 'center', marginLeft: "10px" }}>
        <Box style={webStyle.recommendationBoxType}>
          {element.attributes.recommendation_type.name}
        </Box>
        <Typography
          variant="body1"
          style={{ margin: '0 8px', alignSelf: 'flex-end', position: 'relative', bottom: '-5px' }}
        >
          &bull;
        </Typography>
        <Box style={webStyle.recommendationBoxCity}>
          {element.attributes.city}
        </Box>
      </Box>

    </Box> : ""}

    <Typography style={{
      fontSize: "14px", padding: "11px", fontFamily: "MyFont", fontWeight: 500, letterSpacing: "1px",
      fontStyle: "normal", marginTop: "10px"
    }}>{element.attributes.body}</Typography>
    {
      (element.attributes.link_title && element.attributes.external_link) && (
        <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
          <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
            <img data-test-id="handleRedirect" src={LinkImg} onClick={() => this.handleRedirect(element.attributes.external_link)} alt="" />
            <Box style={{ marginLeft: "10px" }}>
              <p>{element.attributes.link_title}</p>
            </Box>
          </Box>
          <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
            <span style={{ fontSize: "20px", cursor: 'pointer' }}><i className="fa-regular fa-copy"></i></span>

          </Button>
        </Box>
      )
    }
    <Carousel
      responsive={responsive}
      autoPlay={false}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={false}
      partialVisible={false}
      dotListClass="custom-dot-list-style"
    >
      {images.map((media, index) => (
        <div key={`image-${index}`} style={{display:"flex",alignItems:"center",height:"200px"}}>
          <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
        </div>
      ))}
               {videos.map((media, index) => (
                 <div key={`video-${index}`}>
                   <video style={{ width: "100%", height: "250px" }} controls>
                     <source src={media.url} type="video/mp4" />
                     Your browser does not support the video tag.
                   </video>
                 </div>
               ))}
             </Carousel>
             <CardContent>
               <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                 <Box>
                   {element.attributes.is_liked ? (
                     <>
                       <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", cursor: "pointer", marginRight: "5px", }}></i>{element.attributes.like_counts}
                     </>
                   ) : (
                     <>
                       <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
                       <span>{element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}</span>
                     </>
                   )}
                   &nbsp;&nbsp;
                 </Box>
                 <Box onClick={this.handleClick.bind(this, element.id)}>
                   <i className="fa-regular fa-message" style={{cursor: 'pointer'}}></i>&nbsp;&nbsp;
                   {this.commentCounts(element)}
                 </Box>
                 <Box onClick={this.drawerSidebaar.bind(this, element.id)} >
                   <i className="fa-solid fa-arrow-up-from-bracket" style={{cursor: 'pointer'}}></i>&nbsp;&nbsp;
                   Share
                 </Box>
               </Box>
             </CardContent>
           </Card>
         )
       })
      }
      </Box>
    )
  }

  subcategoryList() {
    return (
      <>
        <Box>
        <Box>
              {  this.state.groupFollowed?.followed  ? 
                 <Button data-test-id="followedId" onClick={() => this.followGroup()} style={webStyle.followedBtn}>
                     Followed
                 </Button>
                 :  <Button data-test-id="followedId" onClick={() => this.followGroup()} style={webStyle.followBtn}>
                     Follow Group
            </Button>
              }
              </Box>
            { this.getCards()}
          <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.open1} onClose={this.toggleDrawer1.bind(this, false, "")} >
            <BoxesMain>
              <Box style={webStyle.categoryDropdown}>
                <Typography className="selectedtype">Block User</Typography>
                <span onClick={this.drawerClose}>
                  <i className="fa-solid fa-xmark" style={{cursor: 'pointer'}}></i>
                </span>
              </Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Box className="flaggingcontent">Why are you blocking this user?</Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Container className="content-container">
              {
                this.state.deleteReasonData.map((item: { id: number, title: string, description: string }, index: number) => {
                  return <Box
                    data-test-id="selectBlockMsg"
                    className="typebox"
                    key={index}
                    onClick={() => this.handleSelect1s(item, index)}
                    style={{ backgroundColor: this.state.indexBlockList === index ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box>
                      <Typography className="titlemyfont" >{item.title}</Typography>
                      <Typography className="titleReason">{item.description}</Typography>
                    </Box>
                    {this.state.indexBlockList === index && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />

            </Container>
            <Container className="button-containers">
            <Box style={{width: '100%', display: 'flex', justifyContent: 'center'}}> <Button data-test-id="addedToBlock" variant="contained" className="selectedbtn" onClick={this.addedToBlock}>Continue</Button></Box>
            </Container>
          </BoxesMain>
        </Drawer>
        {formSubmittedSuccessfully(this.state.successModalOpen, this.handleCloseSuccessModal)}
        {blockedSuccessfully(this.state.openBlockModal, this.handleCloseSuccessModal)}
        </Box>
        <Drawer  anchor="right" open={this.state.open} style={webStyle.drawer as any} onClose={this.toggleDrawer.bind(this, false, "")} >
          <BoxesMain>
            <Box style={webStyle.categoryDropdown}>

              <Typography className="selectedtype">Report Content</Typography>
              <span onClick={this.drawerClose} data-test-id='report-close'>
                <i className="fa-solid fa-xmark"></i>

              </span>
            </Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Box className="flaggingcontent">Why are you flagging this content?</Box>
            <Container className="content-container">
              {
                this.state.reportListData.map((item: { reason: string, id: number, description: string }, index: number) => {
                  return <Box className="typebox"  onClick={() => this.handleSelect(item, index)} key={index} data-test-id="reportSelectedId"
                    style={{ backgroundColor: this.state.selected === `${index}` ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box><Typography className="titlemyfont">{item.reason}</Typography> <Typography className="titleReason">{item.description}</Typography></Box>
                    {this.state.selected === `${index}` && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />
            </Container>
            <Container className="button-containers">
            <Box style={{width: '100%', display: 'flex', justifyContent: 'center'}}> <Button  disabled={this.state.reportId == 0} variant="contained" className="selectedbtn" onClick={this.addedReport} data-test-id="continueBtn">Continue</Button> </Box>
            </Container>
          </BoxesMain>
        </Drawer>
        <ShareDrawer open={this.state.sidebaarOpen} handleClose={this.drawerSidebaarClose} 
          handleChangeMessageValue={this.handlewritemessage} disabled={this.state.postShareProductDetailsLoading} handleSave={this.handlePostshare} 
          clearSerchValue={this.removesearchdata} selectedElements={this.state.selectedmember} handleDeleteSelected={this.handleselectmemberDelete}
          searchValue={this.state.setSearchvalue} hadnleChangeSearchValue={this.handlesearchTag}
          serachData={this.state.shareconnectiondata} handleCheckItem={this.getvalueconnectioncheck} messageValue={this.state.sharemessage}
        />
        {this.renderDraweredfunction()}
         {this.renderSharedfunction()}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
     //istanbul ignore next
    return (
      <>
        <div style={{opacity: this.state.isCommentDrawerOpen ? '30%' : '100%',}}>
          <AppHeader component={"explore"} />
          <Box key={1} style={{ display: "flex", justifyContent: "center", alignItems: "center", background: "#F8FAFC" }}>
            <Card style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: "20px", fontFamily: "MyFont" }}>
              <CardContent>
                <Box>
                  <Typography>
                    <a data-test-id="redirectTest" onClick={this.redirecttoExplore} style={{ textDecoration: "none", color: "#fff" }}>
                      <span style={{ marginRight: "5px", color: "black" , cursor:"pointer" }}><i className="fa-solid fa-arrow-left"></i></span>
                    </a>
                    <span style={{marginLeft:"10px",fontFamily:"MyFont", fontWeight: 600, letterSpacing: "0.05em", color: "#14362E", fontSize: "21px"}}>{this.state.categoryDataset?.attributes?.name}</span>
                  </Typography>
                </Box>
                <Box style={{marginTop:"10px"}}>
                  <span data-test-id="redirectTest2" style={{fontFamily:"MyFont", fontWeight: 500, letterSpacing: "0.05em", color:"#475569", fontSize: "14px" , cursor:"pointer"}} onClick={this.redirecttoExplore}>For you</span> &nbsp; 
                  <span style={{color:"#475569", marginLeft:"10px"}}><i style={{fontSize:"14px", cursor: 'pointer'}} className="fa-solid fa-greater-than"></i></span>&nbsp;
                  <a data-test-id="prevredirect" style={{fontFamily:"MyFont", fontWeight: 500, letterSpacing: "0.05em", color:"#475569", cursor: 'pointer', fontSize: "14px",marginLeft:"10px", textDecoration:"none"}}  onClick={this.redirectprevios}>
                    {this.state.categoryDataset?.attributes?.name}
                  </a>&nbsp;
                  {this.state.groupinfo && <i  style={{color:"#475569", marginLeft:"10px", fontSize: "14px", cursor: 'pointer'}} className="fa-solid fa-greater-than"></i>}&nbsp;
                  <span style={{fontFamily:"MyFont", fontWeight: 500, letterSpacing: "0.05em", color:"#0F172A", fontSize: "14px",marginLeft:"10px", cursor: 'pointer',}}> {this.state.groupinfo ? this.state.groupinfoName : ""} </span>
                </Box>
            
                {
                !this.state.openPosts && (!this.state.groupinfo) ? (
                    <>
                      <Box style={webStyle.innercontentParent}>
                        {this.categoryAlllist()}
                      </Box>
                    </>
                  ) : (
                    <>
                      {this.subcategoryList()}
                    </>
                  )
                }
              </CardContent>

            </Card>
          </Box>
          <AppFooter component={"explore"} />
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledMentionedInput = styled(MentionsInput)<MentionsInputProps & StyledComponentProps>(() => ({
  backgroundColor: '#F1F5F9',
  border: '1px solid #E2E8F0',
  marginLeft: "8px",
  width: '385px',
  height: '48px',
  borderRadius: "50px",

  "&:focus": {
      outline: 'none',
      border: '1px solid #E2E8F0',
  },

  "&:hover": {
    border: '1px solid #E2E8F0',
  },


  '& [class*="__suggestions__list"]': {
      border: "none",
  },


  '& [class*="__suggestions"]': {
    top: "25px !important",
    minWidth: "333px",
    fontFamily: "MyFont",
    fontSize: "16px",
    fontWeight: "500",
    borderRadius: "16px",
    border: "1px solid #E2E8F0",
    padding: "8px 12px",
},

"& textarea": {
  padding: '12px 16px',
  width: '100%',
  height: '100%',
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "MyFont",
  border: "none",
  borderRadius: "50px",
  "&:focus": {
      outline: 'none',
      border: 'none',
  },
  "&:hover": {
      border: 'none',
  },
},

'& [class*="__suggestions__item"]': {
  border: "none",
  borderBottom: "1px solid #E2E8F0",
  borderRadius: "0",
  padding: "8px"
},
  "@media(max-width: 600px)": {
      "& textarea": {
          fontSize: "13px !important"
      },
      '& [class*="__suggestions"]': {
          minWidth: "100%",
          fontSize: "14px",
      },
  }

}));

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  '& .flexBoxCus': {
      position: "relative",
      textAlign: "right" as 'right',
      flexWrap: "wrap",
      width: "30px",
      cursor: "pointer",
 
  },
});



const CustomDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '540px',
    height: '100vh',
    borderRadius: '24px 0px 0px 0px',
  },
  '& .button-icon': {
    borderRadius: "8px",
    padding: "0",
    position: "absolute",
    right: "17%"
  },
  '& .custom-icon': {
    borderRadius: "8px",
    position: "relative",
    padding: "0",
    right: "44%",
    display: 'block'
  },
  '& .emoji-picker': {
    height:"400px !important",
    width: "400px !important",
    position: "absolute",
    right: "32% ",
    top :"26px",
    zIndex : 1000
  },
  '& .reply-emoji-picker': {
    height:"400px !important",
    width: "450px !important",
    position: "absolute",
    top :'103% ',
    right : '0%',
    zIndex : 1000
  },
  '& .display_arrow':{
    display : 'none'
  },
  '@media (max-width: 601px)': {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
    '& .emoji-picker':{
      right : '5%',
      width: "370px !important",
    },
    '& .button-icon': {
      right: "7%"
    },

    '& .reply-emoji-picker':{
      top: "103%",
      right : '0%',
      height: '400px !important',
      width: '415px !important'
    },
    '& .display_arrow':{
      display:'flex'
    },
}})
const CardBox = styled(Box)({
  borderRadius: "10px",
  border: "1px solid #73A58E",
  padding: "10px",
  flexBasis: 'calc(50% - 20px)',
  marginBottom: "10px",
  boxSizing: 'border-box',

  "@media (max-width: 601px)": {
    flexBasis: '100%', 
    padding: '10px',
  },
})

const BoxesMain = styled(Box)({
  "& .mediatitle": {
    display: "flex",
    width: "370px",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontFamily: "MyFont",
    margin: "15px",
    paddingLeft: "12px",
  },
  '& .titlemyfont': {
    fontFamily: "MyFont"
  },
  "& .mainBox": {
    padding: '4px',
    fontWeight: "400",
    height: '30px',
    fontFamily: "Halyard Display",
    justifyContent: 'space-between',
    marginBottom: '5px',
    paddingLeft: "10px",
    display: 'flex',
    fontSize: "12px"
  },
  width: "540px",
  fontFamily: "MyFont",
  "@media(max-width: 600px)": {
    width: '100%',
  },
  '& .titleReason': {
    fontSize: "14px",
    fontFamily: "MyFont"
  },
  "& .mainBoxDivider": {
    width: "95%",
    marginLeft: "10px"
  },
  "& .button-containers": {
    "@media (max-width: 600px)": {
      width: '100%',
    },
    width: "540px",
    position: "fixed",
    background: "white",
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    bottom: 0,
    padding: "10px 0", // Adjust as needed
  },
  '@media (max-width: 601px)': {
    '& .selectedbtn': {
      marginTop: "0", // No need for extra margin
    },
  },
  '& .selectedbtn': {
    color: "white",
    textTransform: "none",
    background: "#14362E",
    fontFamily: 'MyFont',
    width: "90%",
  },
  '& .selectedtype': {
    fontSize: "24px",
    color: '#14362E',
    fontWeight: "bold",
    paddingLeft: '10px',
    fontFamily: "MyFont",
    lineHeight: "32px",
  },
  "& .typebox": {
    paddingLeft: "18px",
    paddingBottom: "15px",
    display: "flex",
    paddingTop: "15px",
  },
  '& .sideBarlabel': {
    letterSpacing: "8%",
    fontFamily: "Sans-serif",
    color: "#475569",
  },
  '& .flaggingcontent': {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'start',
    padding: '14px',
    justifyContent: 'center',
    color: "#0F172A",
    letterSpacing: "0.08em",
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "MyFont"
  },
  "& .checkedCircle": {
    paddingTop: "10px",
    color: "#14362E",
    width: "27.7px",
    height: "27.7px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  },
  "& .content-container": {
    paddingBottom: "80px", // Give some padding at the bottom for better UX
    overflowY: "auto",
    height: "calc(100vh - 200px)", // Adjust height based on your layout
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
});

const MenuBox = styled(Box)({
  '& .menuicon': {
    width: "18px",
    height: "18px",
    top: "9px",
    left: "16px",
    padding: "2px"
  },
  '& .menuitemcss': {
    fontFamily: "MyFont",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0.08em",
    textAlign: "left"
  },
  "@media(max-width: 600px)": {

  }
});

const BoxCustomtwo = styled(Box)({ 
  fontFamily: "myfont",
  "& .mainBoxDivider": {
      marginLeft: "10px",
      width: "95%"
  },
  "& .mainBox": {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '4px',
      height: '30px',
      marginBottom: '5px',
      paddingLeft: "10px",
      fontFamily: "Halyard Display",
      fontSize: "12px",
      fontWeight: "400"
  },
  "& .content-container": {
      height: "calc(100vh - 200px)",
      overflowY: "auto",
      paddingBottom: "80px",
  },
  "& .button-containers": {
      position: "fixed",
      bottom: 0,
      width: "540px",
      "@media (max-width: 600px)": {
          width: '100%',
      },
      background: "white",
      padding: "10px 0",
      boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  },
  "& .mediatitle": {
    margin: "15px",
    width: "370px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "12px",
    fontFamily: "MyFont"
  },
  "@media (max-width: 600px)": {
      "& .mediatitle": {
          width: "150px",
      },
  },
  '& .selectedbtn': {
      fontFamily: "MyFont",
      textTransform: "none",
      width: "90%",
      background: "#14362E",
      color: "#fff",
  },
  '@media (max-width: 601px)': {
      '& .selectedbtn': {
          marginTop: "0",
      },
  },
  '& .selectedtype': {
      paddingLeft: '5px',
      color: '#14362E',
      fontWeight: "bold",
      fontSize: "24px",
  },
  '& .sideBarlabel': {
      fontFamily: "Sans-serif",
      color: "#475569",
      letterSpacing: "8%"
  },
  '& .flaggingcontent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      padding: '14px',
      fontWeight: "700",
      fontSize: "16px",
      letterSpacing: "0.08em",
      color: "#0F172A"
  },
  "& .typebox": {
      display: "flex",
      paddingLeft: "18px",
      paddingTop: "15px",
      paddingBottom: "15px",
  },
  "& .typebox:hover": {
      backgroundColor: "#D4E5DE"
  },
  "& .checkedCircle": {
      width: "27.7px",
      height: "27.7px",
      color: "#14362E",
      paddingTop: "10px"
  }
});

const webStyle = {
  categoryDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px'
  },
  inputspace:{
    marginLeft:"5px",
    padding: '14px',
  },
  searchinputspace:{
    width:"99%",
    lineHeight: '24px',
    padding: '11px 4px',
    border: 'none',
    outline: 'none',
    borderRadius: '7px',
    fontSize: '16px',
    fontFamily:  'MyFont'
  },
  drawer: {
    position: "relative",
    fontFamily: "MyFont",
    width: "540px",
    display: "block",
    '@media (min-width: 600px)': {
      display: "none", // For 'sm'
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "100%",
    },
    '@media (max-width: 600px)': {
      '& .MuiDrawer-paper': {
        width: '100%',
      },
    }
  },
  borderBottomStyle: {
    borderBottom: '1px solid #CBD5E1'
  },
  innercontentParent: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "20px",
    marginBottom: "10px",
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "50%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "70%", md: "25%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },
  checkCircleCss: {
    width: " 66.67px",
    height: "66.67px",
    color: "#73A58E"
  },
  modalStyleTypo: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#14362E",
    marginTop: "5%",
    width: { xs: "90%", md: "70%" },
    fontFamily: "'MyFont', 'MyFont'",
    textAlign: "center",
    letterSpacing: "0.05em"
  },
  modalStyleTypoSecond: {
    fontSize: "14px",
    marginTop: "3%",
    color: "#0F172A",
    width: "100%",
    fontFamily: "'MyFont', 'MyFont'",
    textAlign: "center",
    letterSpacing: "0.08em"
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'MyFont'",
    letterSpacing: "1px",
    marginTop: "20px",
    width: "80%",
  },
  toggleStyle: {
    fontSize: "14px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontFamily: "MyFont",
    cursor: "pointer" 
  },
  cardflexbox: { display: "flex", justifyContent: "space-between" },
   width: "50px",
    roundBox :{
    borderRadius: "50%",
    backgroundColor: "#73A58E",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white"
  },

  imgflexbox :{ display: "flex" },
  inercontentheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headercontent: {
    fontFamily: "MyFont",
    letterSpacing: "0.8px",
    color: "#14362E",
    fontWeight: "bold",
    fontSize: "16px"
  },
  headersecondcontent: {
    color: "#73A58E",
    fontFamily: "MyFont !important",
    letterSpacing: "0.5px",
    fontSize: "13px",
    fontWeight: '600',
    cursor: 'pointer',
  },
  innermaincontenttext: {
    color: "#0F172A",
    fontSize: "14px",
    fontFamily: "MyFont",
    letterSpacing: "0.7px",
  },

  followBtn: {
    width: "100%",
    height: "36px",
    borderRadius: "16px",
    color: "#D4E5DE",
    fontFamily: "MyFont",
    backgroundColor: "#14362E",
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.8px",
    textAlign: "center",
    marginTop: "12px",
   cursor: "pointer"
  },

  followedBtn: {
    width: "100%",
    height: "36px",
    borderRadius: "16px",
    color: "#D4E5DE",
    letterSpacing: "0.8px",
    textAlign: "center",
    marginTop: "12px",
    fontFamily: "MyFont",
    backgroundColor: "#73A58E",
    fontSize: "16px",
    fontWeight: "700",
   cursor: "pointer"
  },
  
}
// Customizable Area End
