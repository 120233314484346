import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export const frameworkConfigJSON = require("../../../framework/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  qrcodeholder: any;
  token: string;
  copied: boolean;
  selectedTab: 'qrCodeTab' | 'qrScanTab';
  getQrAllData: any;
  getScanQrUserData: any;
  isGetScanQrUserDataLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class QrCodesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getQRApiCallId: any;
  apiQrCodeDataId: any;
  apiScanQrUserDataId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      qrcodeholder: "",
      token: "",
      copied: false,
      selectedTab: "qrCodeTab",
      getQrAllData: {},
      getScanQrUserData: {},
      isGetScanQrUserDataLoading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.callApiData()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  requestQRCode = (token: any) => {
    const data = {
      random: "email_account"
    };

    const httpBody = {
      data: data
    };

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQRApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      this.requestQRCode(token);
    }

    if (this.apiQrCodeDataId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.setState({ getQrAllData: apiResponse?.data });
    }

    if (this.apiScanQrUserDataId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        this.setState({ getScanQrUserData: apiResponse?.data?.attributes,
           isGetScanQrUserDataLoading: false });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQRApiCallId != null &&
      this.getQRApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        let data = responseJson.data.attributes.qr_code;
        data = data
          .replace("https://localhost:3000", frameworkConfigJSON.baseURL)
          .replace("http://localhost:3000", frameworkConfigJSON.baseURL);
        this.setState({ qrcodeholder: data });
        runEngine.debugLog("qrcodeholder", this.state.qrcodeholder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  callApiData = () => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      this.getQrCodeDataCall()
    } else {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'EmailAccountLoginBlock');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)
    }
  }

  getQrCodeDataCall = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("accessToken")
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiQrCodeDataId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.qrCodeApiEndPoint
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodForGet
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getScanQrUserDataApiCall = (id:any) => {
    this.setState({isGetScanQrUserDataLoading: true})
    const token = localStorage.getItem("accessToken");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiScanQrUserDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCopy = () => {
    const userId = localStorage?.getItem("account_id")

    const baseUrl = this.state?.getQrAllData?.attributes?.qr_code?.url?.split("/rails");
    const link:any = `${baseUrl?.[0].replace("ruby", "react")}/UserProfileBasicBlock/${userId}`

    navigator?.clipboard?.writeText(link)
  };
  // Customizable Area End
}
