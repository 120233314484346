export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const women = require("../assets/women.png");
export const mobileBgLogo = require("../assets/group.jpeg");
export const mobileLogo = require("../assets/mobileLogo.png");
export const weblogo = require("../assets/weblogo.png");
export const newlogo = require("../assets/newlogo.png");
export const newstepper = require("../assets/newstepper.png");
export const Success = require("../assets/success.svg");
export const Warning = require("../assets/warning.svg");
export const DefaultAvatar = require("../assets/defaultAvatar.png");
export const finalBanner = require("../assets/finalbanner.jpeg");
export const finalmobilelogo = require("../assets/finalnewmobile.svg")