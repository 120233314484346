import React from "react";

// Customizable Area Start
import {
  Box,
  Container,
  Grid,
  Typography,
  ThemeProvider,
  createTheme,
  Button,
  TextField,
  Avatar,
  InputLabel,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Radio,
  Modal,
  withStyles,
  RadioGroup,
  FormControl,
  FormControlLabel,styled
} from "@material-ui/core";
const configJSON = require("./config");
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import PhoneInput from "react-phone-input-2";
import CloseIcon from "@material-ui/icons/Close";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { defaultAvatar, greeTickImg } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#14362E",
      contrastText: "#fff",
    },
  },
});

import { AppHeader } from "../../../components/src/AppHeader.web";
// Customizable Area End

import EditUserProfileController, {Props} from "./EditUserProfileController";
class EditUserProfile extends EditUserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  personalInformationWeb = () => {
    const { options } = this.state;
    return (
      <Box style={{ padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webpageStyle.firstNameLabel}>
              First Name
            </InputLabel>
            <TextField
              fullWidth
              type="text"
              data-test-id="firstnameInput"
              variant="outlined"
              placeholder="Enter your first name"
              value={this.state.firstName}
              onChange={(e) => {
                const value = e.target.value;
                const onlyLetters = value.replace(/[^A-Za-z]/g, '');
                this.handleFirstName(onlyLetters.trim())
              }}
              error={!!this.state.errors.firstName}
              helperText={this.state.errors.firstName ? 'First Name is required' : ''}
              InputProps={{
                style: { borderRadius: "8px", marginTop: "2%" }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webpageStyle.firstNameLabel}>
              Last Name
            </InputLabel>
            <TextField
              fullWidth
              data-test-id="lastnameInput"
              type="text"
              variant="outlined"
              placeholder="Enter your last name"
              value={this.state.lastName}
              onChange={(e) => {
                const value = e.target.value;
                const onlyLetters = value.replace(/[^A-Za-z]/g, '');
                this.handleLastName(onlyLetters.trim())
              }}
              error={!!this.state.errors.lastName}
                helperText={this.state.errors.lastName ? 'Last Name is required' : ''}
              InputProps={{
                style: { borderRadius: "8px", marginTop: "2%" },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel style={webpageStyle.firstNameLabel}>Bio</InputLabel>
            <TextareaAutosize
              style={{
                width: "100%",
                minHeight: "100px",
                borderRadius: "10px",
                borderColor: "#CBD5E1",
                padding: "8px",
                marginTop: "1%",
                fontFamily: "MyFont",
                color: "currentColor"
              }}
              value={this.state.bio}
              onChange={this.handleBioChange}
            />
            <div style={{ textAlign: 'right', marginTop: '5px' }}>
              {this.state.bio.length}/140
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webpageStyle.firstNameLabel}>
              Phone Number
            </InputLabel>
            <PhoneInput
              data-test-id="txtWebPhoneInput"
              specialLabel=""
              value={this.state.phoneNumber}
              country={"ie"}
              onChange={(phoneNumber: any) => this.setState({ phoneNumber })}
              inputProps={{
                style: {
                  marginTop: "2%",
                  borderRadius: "8px",
                  width: "100%",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webpageStyle.firstNameLabel}>Country</InputLabel>
            <TextField
              id="country"
              fullWidth
              placeholder="Select an option"
              variant="outlined"
              data-test-id="countryWeb"
              value={this.state.country}
              onClick={this.toggleCountryWebDrawer(true)}
              InputProps={{
                style: { marginTop: "2%", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.toggleCountryWebDrawer(true)}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webpageStyle.firstNameLabel}>Email</InputLabel>
            <TextField
              type="email"
              data-test-id="txtWebInputEmail"
              placeholder="example@gmail.com"
              fullWidth
              value={this.state.email}
              onChange={(e) => this.setEmail(e.target.value.trim())}
              variant="outlined"
              InputProps={{
                style: { borderRadius: "8px", marginTop: "2%" },
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel style={webpageStyle.firstNameLabel}>
              Password
            </InputLabel>
            <TextField
              data-test-id="pwdWebInput"
              type={this.state.enablePassword ? "text" : "password"}
              placeholder="Your password"
              error={this.state.errorPasswordFlag}
              value={this.state.password}
              helperText={this.state.errorPasswordFlag ? this.state.error : ''}
              fullWidth
              variant="outlined"
              onChange={(e) => this.setPassword(e.target.value.trim())}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.handleClickShowPassword}
                      aria-label="toggle password visibility"
                      edge="end"
                      data-test-id="showPass"
                    >
                      {this.state.enablePassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { marginTop: "5px", borderRadius: "8px" },
              }}
            />
            {this.state.passwordError && (
              <div style={{ color: "red" }}>{this.state.passwordError}</div>
            )}
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={this.state.countryWebDrawer}
          onClose={this.toggleCountryWebDrawer(false)}
          PaperProps={{
            className:"countryDrawer"
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box style={{ display: "flex", padding: "10px" }}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#14362E",
                  fontFamily: "MyFont",
                  letterSpacing: "1.4",
                }}
              >
                Country
              </Typography>
            </Box>
            <Box>
              <IconButton
                style={{ right: "10px" }}
                onClick={this.toggleCountryWebDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box style={{ display: "flex", padding: "10px" }}>
            <TextField
              fullWidth
              placeholder="Search countries"
              data-test-id="web-search"
              variant="outlined"
              value={this.state.searchQuery}
              onChange={this.handleSearch}
              InputProps={{
                style: { borderRadius: "8px", marginTop: "5px" },
                endAdornment: this.state.searchQuery && (
                  <InputAdornment position="end">
                    <IconButton onClick={this.searchQueryfunc}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box style={{ overflow: "auto", marginTop: "30px" }}>
            <List>
              {Object.keys(options).map((code) => {
                const searchQuery = this.state.searchQuery.toLowerCase();
                const countryName = options[code].name.toLowerCase();
                if (!searchQuery || countryName.includes(searchQuery)) {
                  return (
                    <ListItem key={code} button>
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <ListItemText primary={options[code].name} />
                        </Box>
                        <Box>
                          <Radio
                            data-test-id="webRadioBtn"
                            checked={this.state.country === code}
                            onChange={() => {
                              this.handleChangeCountry({
                                target: { value: code },
                              });
                              // this.setState({
                              //   searchQuery: options[code].name,
                              // });
                            }}
                            style={{ color: "green" }}
                          />
                        </Box>
                      </Box>
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </List>
          </Box>
        </Drawer>
      </Box>
    );
  };

  accountDetailsWeb = () => {
    return (
      <Box style={{ paddingLeft: "3%", paddingRight: "3%" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <InputLabel style={webpageStyle.InputLabelStyle}>
              {configJSON.accountDetailsHeading1}
            </InputLabel>
            <TextField
              fullWidth
              type="text"
              data-test-id="txtInputCompany"
              variant="outlined"
              placeholder="Company Name"
              value={this.state.companyTextFieldValue}
              onChange={(e) => {
                const value = e.target.value;
                const onlyLetters = value.replace(/[^A-Za-z\s]/g, '');
                this.handleCompanyName(onlyLetters)
              }}
              style={{marginTop: "2%"}}
              error={!!this.state.errors.companyName}
              helperText={this.state.errors.companyName? 'Company Name is required' : ''}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel style={webpageStyle.InputLabelStyle}>
              {configJSON.accountDetailsHeading2}
            </InputLabel>
            <TextField
              fullWidth
              data-test-id="txtInputRole"
              type="text"
              variant="outlined"
              placeholder="Select an option"
              value={this.state.roleTextFieldValue}
              onClick={this.toggleWebRoleDrawer(true)}
              InputProps={{
                style: { marginTop: "2%", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.toggleWebRoleDrawer(true)}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {this.roleWebDrawer()}
          </Grid>

          <Grid item xs={12}>
            <InputLabel style={webpageStyle.InputLabelStyle}>
              {configJSON.accountDetailsHeading3}
            </InputLabel>
            <TextField
              fullWidth
              data-test-id="txtInputIndustry"
              type="text"
              variant="outlined"
              placeholder="Select an option"
              value={this.state.industryTextFieldValue}
              onClick={this.toggleWebIndustryDrawer(true)}
              InputProps={{
                style: { marginTop: "2%", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.toggleWebIndustryDrawer(true)}>
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {this.industryWebDrawer()}
          </Grid>

          <Grid item xs={12}>
            <InputLabel style={webpageStyle.InputLabelStyle}>
              {configJSON.accountDetailsHeading4}
            </InputLabel>
            <TextField
              fullWidth
              data-test-id="txtInputLinkedin"
              type="text"
              variant="outlined"
              placeholder="Paste link to profile"
              value={this.state.linkedInProfile}
              error={this.state.linkedInProfileError}
              helperText={
                this.state.linkedInProfileError
                  ? "Please enter a valid URL"
                  : null
              }
              onChange={this.handleChangeLinkedInProfile}
              InputProps={{
                style: { marginTop: "2%", borderRadius: "8px", width: "100%", marginBottom: "10px" },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  professionalDetailsWeb = () => {
    const { dummyRadioOptions } = this.state;
    return (
      <Box style={{ paddingLeft: "3%", paddingRight: "3%" }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <InputLabel
              style={{fontWeight: 700, fontSize: "16px",  color: "#334155" }}
            >
              {configJSON.profDetailsHeading1}
            </InputLabel>
            <TextField
              fullWidth
              value={this.state.professionalLevel}
              placeholder="Select an option"
              data-test-id="prof-level"
              variant="outlined"
              onClick={this.toggleDrawer(
                "web",
                true,
                `${configJSON.profDetailsHeading1}`,
                1
              )}
              InputProps={{
                style: { marginTop: "5px", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.toggleDrawer(
                        "web",
                        true,
                        `${configJSON.profDetailsHeading1}`,
                        1
                      )}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}
            >
              {configJSON.profDetailsHeading2}
            </InputLabel>
            <TextField
              fullWidth
              value={this.state.employeesReporting}
              placeholder="Select an option"
              data-test-id="emp-prof-level"
              variant="outlined"
              onClick={this.toggleDrawer(
                "web",
                true,
                `${configJSON.profDetailsHeading2}`,
                2
              )}
              InputProps={{
                style: { marginTop: "5px", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.toggleDrawer(
                        "web",
                        true,
                        `${configJSON.profDetailsHeading2}`,
                        2
                      )}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}
            >
              {configJSON.profDetailsHeading3}
            </InputLabel>
            <TextField
              fullWidth
              value={this.state.organizationSize}
              data-test-id="organ-size"
              variant="outlined"
              placeholder="Select an option"
              onClick={this.toggleDrawer(
                "web",
                true,
                `${configJSON.profDetailsHeading3}`,
                3
              )}
              InputProps={{
                style: { marginTop: "5px", borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={this.toggleDrawer(
                        "web",
                        true,
                        `${configJSON.profDetailsHeading3}`,
                        3
                      )}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel
              style={{ fontSize: "16px", fontWeight: 700, color: "#334155" }}
            >
              Are you supported by your company?
            </InputLabel>
            <Typography
              style={{
                color: "#64748B",
                fontSize: "11px",
                fontWeight: 500,
                lineHeight: "18px",
                fontFamily: "MyFont",
                margin: "1% 0px",
              }}
            >
              If so, please refer the financial/HR department email.
            </Typography>
            <TextField
              fullWidth
              data-test-id="supportByComp"
              type="text"
              variant="outlined"
              placeholder="Enter email"
              value={this.state.supportedByCompany}
              InputProps={{
                style: {
                  borderColor: "#CBD5E1",
                  marginTop: "5px",
                  marginBottom: "10px",
                  borderRadius: "8px",
                },
              }}
              onChange={this.handleChange}
            />
          </Grid>

          <CustomDrawer anchor="right" open={this.state.webDrawerOpen} onClose={this.toggleDrawer("web", false, '', this.state.dummyState)}

          >
            <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", width: "100%", borderBottom: "1.04px solid #CBD5E1" }}>
              <Box style={{ display: "flex", padding: '4% 0 0 4%', width: '350px' }}>
                <Typography style={{ fontSize: "24px", fontWeight: 600, color: "#14362E", fontFamily: "MyFont", letterSpacing: "1.5px" }}> {this.state.drawerHeading}</Typography>
              </Box>
              <Box>
                <IconButton
                  style={{ right: '10px', margin: '15px 5px' }}
                  onClick={this.toggleDrawer("web", false, '', this.state.dummyState)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box style={{ overflow: 'auto', marginTop: "30px" }}>
              <FormControl style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={this.state.selectedOptionProfessional}
                  onChange={this.handleOptionChangeProfessional}
                  data-test-id='webRadioBtn'
                >
                  {dummyRadioOptions.map((option: any, index: number) => (
                    <Box key={index} sx={{ margin: '0 25px', borderBottom: '1px solid #CBD5E1' }}>
                      <FormControlLabel
                        value={option.value}
                        label={option.label}
                        control={<GreenRadio />}
                        labelPlacement="start"
                        style={webpageStyle.formLabel}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box style={{ width: "95%", height: "56px", position: 'absolute', bottom: 0, padding: '9px', top: '85%'}}>
              <Button
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: "#14362E",
                  borderRadius: "8px",
                  height: "45px",
                  color: "white",
                  fontWeight: 700,
                  fontFamily: "MyFont",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
                data-test-id={"selectoptionId"}
                onClick={this.handleSelectButtonProfessional}
              >
                Select
              </Button>
            </Box>
          </CustomDrawer>
        </Grid>
      </Box>
    );
  };

  imageModalForWeb = () => {
    return (
      <>
        <Modal
          open={this.state.openImageModalWeb}
          onClose={this.handleCloseImageModalWeb}
          aria-labelledby="edit-profile-picture-title"
          aria-describedby="edit-profile-picture-description"
        >
          <Box sx={{ ...webpageStyle.modalStyle, }}>
            <Box
              style={{
                justifyContent: "center",
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "bold",
                color: "#3C3C43",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              <Typography
                id="edit-profile-picture-title"
                variant="h6"
                component="h2"
              >
                Edit profile picture
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              
              <div>
                <label htmlFor="profilePictureInput">
                  <Button
                    style={{
                      ...webpageStyle.imageModalDesign,
                      textTransform: "capitalize",
                    }}
                  >
                    Photo library
                  </Button>
                  <input
                    id="profilePictureInput"
                    type="file"
                    data-test-id="webImgUploadInput"
                    onChange={this.handleOpenPhotoLibrary}
                    accept="image/*"
                    style={{ position: "absolute", top: 0, left: 0, opacity: 0, width: "100%", height: "100%" }}
                  />
                </label>

              </div>
              <Button
                style={{
                  ...webpageStyle.imageModalDesign,
                  textTransform: "capitalize",
                }}
                onClick={this.removeProfileImage}
              >
                Remove photo
              </Button>
            </Box>

            <Button fullWidth variant="outlined" style={{ marginTop: 16 }} onClick={this.handleCloseImageModalWeb}>
              Cancel
            </Button>
          </Box>
        </Modal>
      </>
    );
  };

  imageModalForMobile = () => {
    return (
      <>
        <Modal
          open={this.state.openImageModalMobile}
          onClose={this.handleCloseImageModalMobile}
          aria-describedby="modal-modal-description"
          aria-labelledby="modal-modal-title"
          style={{
            width: 'auto',
            maxWidth: '85%',
            margin: 'auto',
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'center',

          }}
        >
          <Box sx={{ ...webpageStyle.modalStyleForMobile, }}>
            <Box style={{
              fontWeight: "bold",
              color: "#3C3C43",
              fontFamily: "MyFont",
              letterSpacing: "1.4",
              justifyContent: "center",
              textAlign: "center",
              fontSize: "18px",
            }}
            >
              <Typography
                variant="h6"
                component="h2"
                id="edit-profile-picture-title-mobile"
              >
                Edit profile picture
              </Typography>
            </Box>
            <Box style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
            >
              
              <div>
                <label htmlFor="profilePictureInput">
                  <Button style={{
                    ...webpageStyle.imageModalDesign,
                    textTransform: "capitalize",
                  }} >
                    Photo library
                  </Button>
                  <input type="file"
                    id="profilePictureInput"
                    accept="image/*"
                    data-test-id="webImgUploadInput"
                    onChange={this.handleOpenPhotoLibrary}
                    style={{ position: "absolute", top: 0, left: 0, opacity: 0, width: "100%", height: "100%" }}
                  />
                </label>

              </div>
              <Button style={{
                ...webpageStyle.imageModalDesign,
                textTransform: "capitalize",
              }}
                onClick={this.removeProfileImage}
              >
                Remove photo
              </Button>
            </Box>
            <Button fullWidth variant="outlined" onClick={this.handleCloseImageModalMobile} style={{ marginTop: 16 }} >
              Cancel
            </Button>
          </Box>
        </Modal>
      </>
    )
  };

  roleWebDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.roleWebDrawerOpen}
        onClose={this.toggleWebRoleDrawer(false)}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
          },
          className: 'drawer-paper',
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "25px",
          }}
        >
          <Box style={{ display: "flex"}}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              {configJSON.accountDetailsHeading2}
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleWebRoleDrawer(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box style={{ display: "flex", padding: "10px" }}>
          <TextField
            fullWidth
            placeholder="Search role"
            data-test-id="web-search-role"
            variant="outlined"
            onClick={this.toggleWebRoleDrawer(true)}
            onChange={this.handleRoleSearch}
            InputProps={{
              style: { borderRadius: "8px", marginTop: "5px" },
              endAdornment: this.state.searchRoleQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.searchRoleQueryfunc}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box style={{ overflow: "auto", marginTop: "30px", marginBottom: "60px" }}>
          <List>
            {this.state.role_list.map((item: any, index: number) => {
              const searchRoleQuery = this.state.searchRoleQuery.toLowerCase();
              const roleName = item.name.toLowerCase();
              if (!searchRoleQuery || roleName.includes(searchRoleQuery)) {
                return (
                  <React.Fragment key={index}>
                    <ListItem data-test-id="roleListItemButton">
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          cursor: "pointer"
                        }}
                      >
                        <Box>
                          <ListItemText primary={item.name} />
                        </Box>

                        <Box>
                          <Radio
                            data-test-id="webRadioBtn"
                            checked={this.state.selectedRole === item}
                            onChange={() => {
                              this.handleSelectRole(item);
                            }}
                            style={{ color: "green" }}
                          />
                        </Box>
                      </Box>
                    </ListItem>
                    <Box margin="auto" width="90%">
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "1px solid #CBD5E1",
                          width: "100%",
                          margin: "0",
                        }}
                      />
                    </Box>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
        <Box mr={2} ml={2}>
          <Button
            fullWidth
            variant="contained"
            data-test-id="roleButton"
            style={{
              height: "45px",
              color: "white",
              fontWeight: 700,
              fontSize: "16px",
              borderRadius: "8px",
              fontFamily: "MyFont",
              letterSpacing: "1px",
              backgroundColor: "#14362E",
              textTransform: "capitalize",
              marginBottom: "10px",
              position: "absolute",
              bottom: 0,
              width: "92%"
            }}
            onClick={this.handleRoleSelectButtonWeb}
          >
            Select
          </Button>
        </Box>
      </Drawer>
    );
  };

  industryWebDrawer = () => {
    return (
      <Drawer
        anchor="right"
        open={this.state.industryWebDrawerOpen}
        onClose={this.toggleWebIndustryDrawer(false)}
        PaperProps={{
          style: {
            borderTopLeftRadius: "20px",
            // left: '0px',
          },
          className: 'drawer-paper',
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box style={{ display: "flex", padding: "10px" }}>
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: 600,
                color: "#14362E",
                fontFamily: "MyFont",
                letterSpacing: "1.4",
              }}
            >
              Industry
            </Typography>
          </Box>
          <Box>
            <IconButton
              style={{ right: "10px" }}
              onClick={this.toggleWebIndustryDrawer(false)}
            >
              <CloseIcon />
            </IconButton>{" "}
          </Box>
        </Box>

        <Box style={{ display: "flex", padding: "10px" }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search industry"
            data-test-id="web-search-industry"
            value={this.state.searchIndustryQuery}
            onChange={this.handleIndustrySearch}
            InputProps={{
              style: { borderRadius: "8px", marginTop: "5px" },
              endAdornment: this.state.searchIndustryQuery && (
                <InputAdornment position="end">
                  <IconButton onClick={this.searchIndustryQueryfunc}>
                    <CloseIcon />{" "}
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    {" "}
                    <SearchIcon />{" "}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box style={{ overflow: "auto", marginTop: "30px" }}>
          <List>
            {this.state.industry_list.map((item: any, index: number) => {
              const searchIndustryQuery = this.state.searchIndustryQuery.toLowerCase();
              const industryName = item.name.toLowerCase();
              if (
                !searchIndustryQuery ||
                industryName.includes(searchIndustryQuery)
              ) {
                return (
                  <React.Fragment key={index}>
                    <ListItem>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.handleSelectIndustry(item);
                        }}
                      >
                        <Box>
                          <ListItemText primary={item.name} 
                          onClick={() => {
                            this.handleSelectIndustry(item);
                          }}
                          />
                        </Box>
                        <Box>
                          <Radio
                            data-test-id="webRadioBtn"
                            checked={this.state.selectedIndustry === item}
                            onChange={() => {
                              this.handleSelectIndustry(item);
                            }}
                            style={{ color: "green" }}
                          />
                        </Box>
                      </Box>
                    </ListItem>
                    <Box width="90%" margin="auto">
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "1px solid #CBD5E1",
                          margin: "0",
                          width: "100%",
                        }}
                      />
                    </Box>
                  </React.Fragment>
                );
              } else {
                return null;
              }
            })}
          </List>
        </Box>
        <Box mr={2} ml={2} mb={2}>
          <Button
            variant="contained"
            fullWidth
            style={{
              backgroundColor: "#14362E",
              borderRadius: "8px",
              height: "45px",
              color: "white",
              fontWeight: 700,
              fontFamily: "MyFont",
              letterSpacing: "1px",
              fontSize: "16px",
              textTransform: "capitalize",
              marginTop: "25%",
            }}
            onClick={this.handleIndustrySelectButtonWeb}
          >
            Select
          </Button>
        </Box>
      </Drawer>
    );
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <AppHeader />
        <Container style={{ background: "#F8FAFC"}}>
          <Box sx={{marginTop: {sm: '0', md: '30px'}}}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ position: "relative"}}
          >
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              style={{ background: "white", padding: "16px", borderRadius: "16px"}}
            >
              <Box display="flex" justifyContent="space-between">
                <a href="/UserProfileBasicBlock" style={{ color: "inherit", textDecoration: "none" }}>
                  <ArrowBackIcon />
                </a>
                <Typography style={webpageStyle.editProfileText}>
                  Edit Profile
                </Typography>
                <CheckIcon onClick={this.handleSaveButton} style={{cursor: "pointer"}}/>
                
              </Box>
              <Box component="hr" sx={webpageStyle.firstHorizontalLine} />
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{ backgroundColor: "" }}
              >
                <Avatar
                  src={this.state.profilePicture || defaultAvatar}
                  style={{ height: "100px", width: "100px", marginTop: "20px", cursor: "pointer" }}
                  onClick={this.handleAvatarClick}
                />
                {this.imageModalForWeb()}
                {this.imageModalForMobile()}
                <Box component="hr" sx={webpageStyle.secondHorizontalLine} />
                <Box style={webpageStyle.parentOfMainButtons}>
                  <Button
                    style={this.getButtonStyle("personal")}
                    onClick={() => this.setView("personal")}
                  >
                    Personal
                  </Button>
                  <Button
                    data-test-id="accountButton"
                    style={this.getButtonStyle("account")}
                    onClick={() => this.setView("account")}
                  >
                    Account
                  </Button>
                  <Button
                    style={this.getButtonStyle("professional")}
                    onClick={() => this.setView("professional")}
                  >
                    Professional
                  </Button>
                </Box>
                <Box>
                  {this.state.currentView === "personal" &&
                    this.personalInformationWeb()}
                  {this.state.currentView === "account" &&
                    this.accountDetailsWeb()}
                  {this.state.currentView === "professional" &&
                    this.professionalDetailsWeb()}
                </Box>
                <Button
                  data-test-id="submitButton"
                  variant="contained"
                  fullWidth
                  style={{ ...webpageStyle.saveButtonStyle, textTransform: "capitalize" }}
                  onClick={this.handleSaveButton}
                >
                  Save
                </Button>
                <Modal
                  open={this.state.successPopupOpen}
                  onClose={this.handleCloseSuccessPopup}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  disableEnforceFocus
                  disableAutoFocus 
                  >
                  <Box sx={{ ...webpageStyle.successModalStyle, borderRadius: "0 10px 0 0" }}>
                    <img src={greeTickImg} alt="Img" style={webpageStyle.successModalImg} />
                    <Box sx={{ ...webpageStyle.successModalStyleTypo }}>
                      Your profile is updated successfully.
                    </Box>
                  </Box>
                </Modal>
              </Box>
            </Grid>
          </Grid>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default EditUserProfile;
const webpageStyle = {
  editProfileText: {
    fontWeight: "bold",
    color: "#14362E",
    fontFamily: "MyFont",
    letterSpacing: "1.2",
    fontSize: "20px",
  },
  parentOfMainButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    backgroundColor: "#f8fafc",
    borderRadius: "25px",
    padding: "4px",
  },
  firstNameLabel: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
    fontFamily: "MyFont",
    letterSpacing: "1.4",
  },
  firstHorizontalLine: {
    border: "none",
    borderBottom: "1px solid #CBD5E1",
    //   width: "100%",
    margin: "0",
    display: "block",
    "@media (max-width: 560px)": {
      display: "none",
    }
  },
  secondHorizontalLine: {
    borderTop: "none",
    borderBottom: "1px solid #CBD5E1",
    width: "100%",
    marginTop: "20px",
    "@media (max-width: 560px)": {
      width: "90%",
    }
  },
  saveButtonStyle: {
    backgroundColor: "#14362E",
    borderRadius: "8px",
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontFamily: "MyFont",
    letterSpacing: "1px",
    fontSize: "16px",
    width: "300px",
    marginBottom: "20px"
  },
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: 350,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    paddingLeft: "xs: 5%",
    paddingRight: "xs: 5%",
  },
  modalStyleForMobile: {
    position: "absolute" as "absolute",
    p: 2,
    width: '100%',
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  imageModalDesign: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#0F172A",
    fontFamily: "MyFont",
    letterSpacing: "1.4",
  },
  InputLabelStyle: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
    fontFamily: "MyFont",
    letterSpacing: "1.4",
  },
  "custom-menu-paper": {
    "& .MuiMenuItem-root": {
      padding: "10px",
    },
    "& .Mui-selected": {
      backgroundColor: "blue",
      color: "white",
    },
  },
  formLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: "8px 0",
    color: '#475569',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: "MyFont",
    lineHeight: '22px',
  },
  successModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "50%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "50%", md: "20%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },
  successModalImg: {
    width: "14%",
    height: "auto"
  },
  successModalStyleTypo: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#14362E",
    marginTop: "5%",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.08em",
    bgColor: "red"
  },
};
const GreenRadio = withStyles({
  root: {
    color: '#73A58E',
    '&$checked': {
      color: '#73A58E'
    }
  },
  checked: {}
})((props) => <Radio color="default" {...props} />)

const CustomBox= styled(Box)({
  display: 'flex',
  
  "@media (max-width: 601px)":{
    display: "none !important",
  }
  })

const CustomDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: '20px',
    width: '484px',
  },
  '@media (max-width: 601px)': {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  }
})
// Customizable Area End