Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.interestApiMethodType = "GET";
exports.interestApiEndPoint = "bx_block_profile/profiles/interests";
exports.interestApiContentType = "application/json";
exports.hobbiesApiMethodType = "GET";
exports.hobbiesApiEndPoint = "bx_block_profile/profiles/hobbies";
exports.hobbiesApiContentType = "application/json";
exports.worriesApiMethodType = "GET";
exports.worriesApiEndPoint = "bx_block_profile/profiles/worries";
exports.worriesApiContentType = "application/json";
exports.passionsApiMethodType = "GET";
exports.passionsApiEndPoint = "bx_block_profile/profiles/passions";
exports.passionsApiContentType = "application/json";
exports.expertisseApiMethodType = "GET";
exports.expertiseApiEndPoint = "bx_block_profile/profiles/expertise";
exports.expertiseApiContentType = "application/json";
exports.updateUserProfile = "account_block/accounts";
exports.invitedUsersApiEndPoint = "bx_block_addfriends/my_connections/";
exports.acceptedUsersApiEndPoint =
  "bx_block_addfriends/my_connections/?status=accepted";
exports.myFavoritePosts = "bx_block_posts/my_favorite";
exports.myRcomendationPosts = "bx_block_posts/my_recommendation";
exports.AddToFavIdPoint = "bx_block_posts/posts/";
exports.methodTypePatch = "PATCH";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid."
exports.stepperIndex = [0, 1, 2, 3];
exports.accountDetails = "Account Details";
exports.accountDetailsHeading1 = "Company";
exports.accountDetailsHeading2 = "Role";
exports.accountDetailsHeading3 = "Industry";
exports.accountDetailsHeading4 = "Linkedin profile (Optional)";
exports.accountDetailsRoleApiEndPoint = "bx_block_roles_permissions/roles_list";
exports.accountDetailsIndustryApiEndPoint = "bx_block_admin/industries_list";
exports.createAccountDetailsApiEndPoint = "account_block/accounts";
exports.deleteMypostApiEnd = "bx_block_posts/posts/";
exports.getReportApiPoint = 'bx_block_contentflag/contents';
exports.addProfileDataApiEndPoint = 'bx_block_profile/profiles';
exports.getIdentitiesApiEndPoint = '/bx_block_profile/profiles/identities';
exports.getEthnicitiesApiEndPoint = '/bx_block_profile/profiles/ethnicities'
exports.accountDetailsAPiContentType = "application/json";
exports.applicationJsonContentType = "application/json";
//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPatchMethod = "PATCH";
exports.httpDeleteMethod = "DELETE";
exports.profDetailsHeading1 = "What is your professional level?";
exports.profDetailsHeading2 = "How many employees report to you?";
exports.profDetailsHeading3 = "What is the size of your organisation?";
exports.profDetailsHeading4 = "How did you hear about Kella?";
exports.professionalDetails = "Professional details";
exports.confrmCall = "Confirmation Call";
exports.pending = "Pending";
exports.approved = "Approved";
exports.waitlisted = "Waitlisted";
exports.editProfileApiEndPoint = "account_block/accounts/2";
exports.createEditProfileApiEndPoint = "account_block/accounts";
exports.mail = 'https://mail.google.com/mail/u/0/#inbox?compose=new'
exports.removeProfileImageApiEndPoint =
  "account_block/accounts/remove_profile_picture";
exports.welcomeBelowText =
  "Welcome to KELLA, you are now part of largest community of female leaders in Europe. Please answer the following question to complete your professional profile. This process takes approximately five minutes.";
exports.welcomeText = "Welcome!";
exports.select = "Select";
exports.responsejsondata = {
  data: [
    {
      attributes: {
        personal_information: {
          profile_picture: {
            url: "usrlimggfffff",
          },
          first_name: "helloo",
          last_name: "ddddd",
        },
        account_details: {
          company: "testese",
        },
        accepted: "1",
        invited: "0",
      },
    },
  ],
  errors: [
    {
      messge: "invalid",
    },
  ],
};
exports.e = {
  currentTarget: "done",
};
exports.ed = "data";
exports.MyprofilePostDatad = [
  {
    id: '1',
    attributes: {
      body: "test",
      media: [
        {
          url: "testusrr",
        },
      ],
      is_liked: true,
      like_counts: "0",
      link_title: "googlelink",
      is_block_by_me: "true",
      posted_user: {
        profile_picture: {
          url: "imgurl",
        },
        first_name: "test",
        last_name: "user",
      },
      created_at: "22/5/2024",
      category: "itindustrie",
    },
  },
];
exports.mypostresponse = {
  data: [
    {
      attributes: {
        body: "test",
        media: [
          {
            url: "testusrr",
          },
        ],
        is_liked: true,
        like_counts: "0",
        link_title: "googlelink",
        is_block_by_me: "true",
        posted_user: {
          profile_picture: {
            url: "imgurl",
          },
          first_name: "test",
          last_name: "user",
        },
        created_at: "22/5/2024",
        category: "itindustrie",
      },
    },
  ],
};
exports.favoriteRecomendationPosts = [
  {
    id: "130",
    type: "post",
    attributes: {
      id: 130,
      name: null,
      description: null,
      body: "vgdfgvdfxv",
      location: null,
      account_id: '1',
      external_link: "",
      link_title: "",
      is_public: true,
      is_recommendation: false,
      city: null,
      recommendation_type: null,
      created_at: "2024-06-06T13:03:11.276Z",
      updated_at: "2024-06-06T13:03:11.379Z",
      model_name: "BxBlockPosts::Post",
      category: "Industry",
      sub_category: "Manufacturing & Engineering",
      posted_user: {
        id: '1',
        first_name: "Test",
        last_name: "ghgh",
        role: "Chief Operating Officer (COO)",
        profile_picture: {
          id: 239,
          filename: "image_ (1).png",
          content_type: "image/png",
          url: "test",
        },
      },
      tagged_users: [
        {
          id: 13,
          first_name: "Australia",
          last_name: "Unitedd",
          role: null,
          profile_picture: {},
        },
      ],
      images_and_videos: [
        {
          id: 355,
          filename: "angele-kamp-bDuh4oK_MCU-unsplash.jpg",
          content_type: "image/jpeg",
          url: "test",
        },
      ],
      media: [],
      time_ago: "2 days ago",
      like_counts: 0,
      is_liked: false,
      is_favorite: false,
      comment_counts: 0,
    },
  },
  {
  id: "131",
  type: "post",
  attributes: {
    id: 131,
    name: null,
    description: null,
    body: "vgdfgvdfxv",
    location: null,
    account_id: 311,
    external_link: "",
    link_title: "",
    is_public: true,
    is_recommendation: true,
    recommendation_type: {
      name: 'test'
    },
    created_at: "2024-06-06T13:03:11.276Z",
    updated_at: "2024-06-06T13:03:11.379Z",
    model_name: "BxBlockPosts::Post",
    sub_category: "Manufacturing & Engineering",
    posted_user: {
      id: '1',
      first_name: "Test",
      last_name: "ghgh",
      profile_picture: {
        id: 239,
        filename: "image_ (1).png",
        content_type: "image/png",
      },
    },
    tagged_users: [
      {
        id: 13,
        first_name: "Australia",
        last_name: "Unitedd",
        role: null,
        profile_picture: {},
      },
    ],
    images_and_videos: [
      {
        id: 355,
        filename: "angele-kamp-bDuh4oK_MCU-unsplash.jpg",
        content_type: "image/jpeg",
        url: "test",
      },
    ],
    media: [],
    time_ago: "2 days ago",
    like_counts: 0,
    is_liked: true,
    is_favorite: true,
    comment_counts: 0,
  },
},
];
exports.element = {
  title: "blocktitle",
  value: "blockvalue",
};
exports.responsss = {
  data: {
    name: "dd",
  },
  errors: [
    {
      message: "message",
    },
  ],
};
exports.final = {
  attributes: {
    friend: {
      status: "pending",
      connection_status:"pending"
    },
    is_block_by_me: "true",
    personal_information: {
      profile_picture: {
        url: "usrlimggfffff",
      },
      first_name: "helloo",
      last_name: "ddddd",
    },
    account_details: {
      company: "testese",
    },
    accepted: "1",
    invited: "0",
  },
};
exports.acceptedInvitedUsers = [
  {
    first_name: "test",
    last_name: "test",
    profile_picture: {
      url: "test url",
    },
    role: "Test role",
    online: true,
  },
];
exports.event = {
  value: "dddd",
};
exports.newValue = "dd";
exports.emptyResponseData = {
  data: null,
  errors: [{ message: "Something wnt wrong" }],
};
exports.userData = {"data":{"id":"1","attributes":{"personal_profile":{"date_of_birth":"2024-06-11","sexual_identity":"Prefer not to say","ethnicity":"Irish people","interests":["13","17","Leadership Development","Work-Life Balance","Entrepreneurship"],"hobbies":["9","4","Sports","Photography","Outdoor Pursuits"],"worries":["18","12"],"passions":["12","12","Driving innovation and finding creative solution"],"expertise":["22","24"]},"friend":null,"my_circle_count":1,"invited":3,"accepted":0,"is_block_by_me":null,"is_user_block_me":null}}}
exports.userData_2 = {"data":{"id":"1","attributes":{"personal_profile":{"date_of_birth":null,"sexual_identity":"Prefer not to say","ethnicity":"Irish people","interests":["13","17","Leadership Development","Work-Life Balance","Entrepreneurship"],"hobbies":["9","4","Sports","Photography","Outdoor Pursuits"],"worries":["18","12"],"passions":["12","12","Driving innovation and finding creative solution"],"expertise":["22","24"]},"friend":null,"my_circle_count":1,"invited":3,"accepted":0,"is_block_by_me":null,"is_user_block_me":null}}}

exports.personalInforamtionGroup = {
  data: [{
  id: 1,
  title: 'test',
  created_at: '1111',
  updated_at: '11111',
}]}
exports.otheruserMainData = {
	"data": [
		{
			"id": "365",
			"type": "post",
			"attributes": {
				"id": 365,
				"name": null,
				"description": null,
				"body": "gf",
				"location": null,
				"account_id": 390,
				"external_link": "",
				"link_title": "",
				"is_public": true,
				"is_recommendation": false,
				"city": null,
				"recommendation_type": null,
				"created_at": "2024-07-11T09:10:43.205Z",
				"updated_at": "2024-07-11T09:10:43.491Z",
				"category": "Industry",
				"sub_category": "Technology & IT",
				"posted_user": {
					"id": 390,
					"first_name": "Levi",
					"last_name": "Whitaker",
					"role": "Chief Executive Officer (CEO)",
					"profile_picture": {}
				},
				"tagged_users": [],
				"images_and_videos": [
					{
						"id": 985,
						"url": "https://hannahwrixenkellafinalbuildv3-434558-ruby.b434558.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdGtEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7fb1b8b03bb227f10df73caf38979a0fbe3a594f/pexels-lucianphotography-3566191.jpg",
						"filename": "pexels-lucianphotography-3566191.jpg",
						"content_type": "image/jpeg"
					},
					{
						"id": 986,
						"url": "https://hannahwrixenkellafinalbuildv3-434558-ruby.b434558.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdG9EIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f117a5e01023064762c6611efca15d4553bc6af9/pexels-shylands-1650829.jpg",
						"filename": "pexels-shylands-1650829.jpg",
						"content_type": "image/jpeg"
					},
					{
						"id": 987,
						"url": "https://hannahwrixenkellafinalbuildv3-434558-ruby.b434558.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHNEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a3f2750dc4726625637e92a5d85496c2acfecc32/pexels-themarkdalton-962987.jpg",
						"filename": "pexels-themarkdalton-962987.jpg",
						"content_type": "image/jpeg"
					},
					{
						"id": 988,
						"url": "https://hannahwrixenkellafinalbuildv3-434558-ruby.b434558.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdHdEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--1017fe105e7798804b91369c152793db2e915a31/pexels-lkloeppel-2416653.jpg",
						"filename": "pexels-lkloeppel-2416653.jpg",
						"content_type": "image/jpeg"
					}
				],
				"media": [],
				"time_ago": "about 20 hours ago",
				"like_counts": 1,
				"is_liked": false,
				"is_favorite": false,
				"comment_counts": 0
			}
		}
	]
}

exports.otheruserprofileData = {
	"data": {
		"id": "390",
		"type": "email_account",
		"attributes": {
			"is_email_notification": true,
			"is_push_notification": true,
			"online": false,
			"created_at": "2024-06-10T09:40:43.191Z",
			"personal_information": {
				"first_name": "Levi",
				"last_name": "Whitaker",
				"full_phone_number": "19154829045",
				"country_code": 1,
				"country": "UZ",
				"phone_number": 9154829045,
				"email": "spana@yopmail.com",
				"bio": null,
				"is_public": true,
				"activated": false,
				"profile_picture": {},
				"application_status": "approved"
			},
			"account_details": {
				"company": "Stout and Fischer Trading",
				"role": {
					"id": 9,
					"name": "Chief Executive Officer (CEO)",
					"created_at": "2024-04-19T10:47:29.114Z",
					"updated_at": "2024-04-19T10:47:29.114Z"
				},
				"industry": {
					"id": 7,
					"name": "Retail and E Commerce",
					"created_at": "2024-04-11T17:01:51.750Z",
					"updated_at": "2024-04-19T10:56:55.447Z"
				},
				"linked_in_profile": "Corporis consequatur"
			},
			"professional_details": {
				"professional_level": "Quo tempor vero numq",
				"employees_reporting": "Fugit est ullam co",
				"organization_size": "Rocha and Gonzalez LLC",
				"supported_by_company": "Wells and Decker Inc",
				"heard_about_kella_from": "Est eveniet dolore"
			},
			"personal_profile": {
				"date_of_birth": null,
				"sexual_identity": null,
				"ethnicity": null,
				"interests": [],
				"hobbies": [],
				"worries": [],
				"passions": [],
				"expertise": []
			},
			"friend": {
				"id": 245,
				"account_id": 336,
				"receipient_id": 390,
				"status": "pending",
				"created_at": "2024-07-11T10:23:36.540Z",
				"updated_at": "2024-07-11T10:23:36.540Z",
				"connection_status": "pending"
			},
			"my_circle_count": 0,
			"invited": 2,
			"accepted": 0,
			"is_block_by_me": false,
			"is_user_block_me": false
		}
	}
}
// Customizable Area End

