import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  companyTextFieldValue: string;
  roleTextFieldValue: string;
  industryTextFieldValue: string;
  roleWebDrawerOpen: boolean;
  roleMobileDrawerOpen: boolean;
  industryWebDrawerOpen: boolean;
  industryMobileDrawerOpen: boolean;
  searchRoleQuery: any;
  searchIndustryQuery: any;
  options: any;
  selectedRole: any;
  selectedIndustry: any;
  roleError: boolean;
  stepperIndex: any;
  role_list: [];
  industry_list: [];
  companyFieldValid: boolean;
  roleFieldValid: boolean;
  industryFieldValid: boolean;
  linkedInProfile: any;
  linkedInProfileError: boolean;
  error:string;
  token: any;
  errorModal: boolean;
  errorMessage: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  options: any;
  getRolesApiCallID: any;
  getIndustryApiCallID: any;
  createAccountDetailsApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handleClose = this.handleClose.bind(this);
    this.subScribedMessages = [getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.RestAPIResponceSuccessMessage),];

    this.state = {
      companyTextFieldValue: "",
      roleTextFieldValue: "",
      industryTextFieldValue: "",
      roleWebDrawerOpen: false,
      roleMobileDrawerOpen: false,
      industryWebDrawerOpen: false,
      industryMobileDrawerOpen: false,
      searchRoleQuery: "",
      searchIndustryQuery: "",
      options: "",
      selectedRole: "",
      selectedIndustry: "",
      roleError: false,
      stepperIndex: configJSON.stepperIndex,
      role_list: [],
      industry_list: [],
      companyFieldValid: true,
      roleFieldValid: true,
      industryFieldValid: true,
      linkedInProfile: "",
      linkedInProfileError: false,
      error: "",
      token: "",
      errorModal: false,
      errorMessage: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getRolesApiCallID) {
        this.handleRoleData(responseJson);
      }
      else if (apiRequestCallId === this.getIndustryApiCallID){
        this.hahandleIndustryData(responseJson);
      }
      else if(apiRequestCallId === this.createAccountDetailsApiCallId){
        this.handleCreateAccountDetailsData(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getRoleList();
    this.getIndustryList();
  }

  handleRoleData = (responseJson: any) => {
    if (responseJson.roles) {
      this.setState({ role_list: responseJson.roles });
    }
  };

  hahandleIndustryData = (responseJson: any) => {
    if (responseJson.industries) {
      this.setState({ industry_list: responseJson.industries });
    }
  }

  handleCreateAccountDetailsData = (responseJson: any) => {
    if (!responseJson.errors) {
      this.handlesProfessionalDetailsNavigation();
    }
    else {
      this.setState({ 
        errorModal: true,
        errorMessage: responseJson.errors[0]
      }); 
    }
  }

  getRoleList() {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRolesApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsRoleApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getIndustryList() {
    const header = {
      "Content-Type": configJSON.accountDetailsAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndustryApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailsIndustryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNextButtonClick = () => {
    const header = {
      token: localStorage.getItem("token")
    };

    let formdata = new FormData();
    formdata.append("account[company]", this.state.companyTextFieldValue);
    formdata.append("account[role_id]", this.state.selectedRole.id);
    formdata.append("account[industry_id]", this.state.selectedIndustry.id);
    formdata.append("account[linked_in_profile]", this.state.linkedInProfile);
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountDetailsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPatchMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleWebRoleDrawer = (open: any) => () => {
    this.setState({ roleWebDrawerOpen: open });
  };
  toggleMobileRoleDrawer = (open: any) => () => {
    this.setState({ roleMobileDrawerOpen: open });
  };
  toggleWebIndustryDrawer = (open: any) => () => {
    this.setState({ industryWebDrawerOpen: open });
  };
  toggleMobileIndustryDrawer = (open: any) => () => {
    this.setState({ industryMobileDrawerOpen: open });
  };


  handleRoleSearch = (event: any) => {
    this.setState({ searchRoleQuery: event.target.value });
  };

  handleIndustrySearch = (event: any) => {
    this.setState({ searchIndustryQuery: event.target.value });
  }

  searchRoleQueryfunc = () => {
    this.setState({ searchRoleQuery: "" });
  };

  searchIndustryQueryfunc = () => {
    this.setState({ searchIndustryQuery: "" });
  };

  handleRoleSelectButtonWeb = () => {
    const selectedRole = this.state.selectedRole;
    if (selectedRole) {
      this.setState({
        roleFieldValid:true,
        roleWebDrawerOpen: false,
        roleTextFieldValue: selectedRole.name
      });
      this.searchRoleQueryfunc()
    }
  };

  handleRoleSelectButtonMobile = () => {
    const selectedRole = this.state.selectedRole;
    if (selectedRole) {
      this.setState({
        roleFieldValid:true,
        roleMobileDrawerOpen: false,
        roleTextFieldValue: selectedRole.name
      });
      this.searchRoleQueryfunc()
    }
  };

  handleIndustrySelectButtonWeb = () => {
    const selectedIndustry = this.state.selectedIndustry;
    if (selectedIndustry) {
      this.setState({
        industryFieldValid:true,
        searchIndustryQuery: selectedIndustry.name,
        industryWebDrawerOpen: false,
        industryTextFieldValue: selectedIndustry.name
      });
      this.setState({searchIndustryQuery:""})
    }
  };

  handleIndustrySelectButtonMobile = () => {
    const selectedIndustry = this.state.selectedIndustry;
    if (selectedIndustry) {
      this.setState({
        industryFieldValid:true,
        searchIndustryQuery: selectedIndustry.name,
        industryMobileDrawerOpen: false,
        industryTextFieldValue: selectedIndustry.name
      });
      this.setState({searchIndustryQuery:""})
    }
  };

  handleSelectRole = (role: any) => {
    this.setState({ selectedRole: role });
  };

  handleSelectIndustry = (industry: any) => {
    this.setState({ selectedIndustry: industry });
  };

  handlesProfessionalDetailsNavigation = () => {
    if (!this.state.linkedInProfileError) {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), 'ProfessionalDetails');
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      localStorage.setItem("countSet", "false");
  }
   
  }
  validateFields = () => {
    const { companyTextFieldValue, roleTextFieldValue, industryTextFieldValue ,linkedInProfile} = this.state;
    const companyFieldValid = companyTextFieldValue.trim() !== "";
    const roleFieldValid = roleTextFieldValue.trim() !== "";
    const industryFieldValid = industryTextFieldValue.trim() !== "";
    const linkedInProfileErrorIs=linkedInProfile.trim()=="";
    const linkedInProfileError=linkedInProfileErrorIs?false: !this.isValidURL(linkedInProfile)
    this.setState({
      linkedInProfileError,
      companyFieldValid,
      roleFieldValid,
      industryFieldValid,
    });

    return companyFieldValid && roleFieldValid && industryFieldValid && !linkedInProfileError;
  };
  handleChangeLinkedInProfile = (event: any) => {
    const inputValue = event.target.value.trim();
    this.setState({
      linkedInProfile: inputValue,
      linkedInProfileError:false
    });
  };

  isValidURL = (url: any) => {
    const urlRegex = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[^\s]*)?$/;
    return urlRegex.test(url);
  };

  handleClose = () => {
    this.setState({ errorModal: false});
  };  

  handleGoBack = () => {
    window.location.href = "OTPInputAuthBlock"
  }
  // Customizable Area End
}
