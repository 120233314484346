Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.patchMethod = 'PATCH'
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.deleteApiMethod = 'DELETE'
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NewsFeed";
exports.targetedFeedEndpoint = "bx_block_posts/posts";
exports.labelBodyText = "NewsFeed Body";
exports.btnExampleTitle = "CLICK ME";
exports.NewsFeedFlatList = 'NewsFeedFlatList'
exports.httpGetMethod = "GET";
exports.searchHistoryApiEndPoint = "bx_block_search_history/search_bar";
exports.searchFilterApiEndPoint = "bx_block_advanced_search/filter";
exports.rolesApiEndPoint = "bx_block_roles_permissions/roles_list";
exports.industriesApiEndPoint = "bx_block_admin/industries_list";
exports.categoryApiEndPoint = "bx_block_categories/categories";
exports.subCategoryApiEndPoint = "bx_block_categories/sub_categories";
exports.citiesApiEndPoint = "bx_block_posts/list_cities";
exports.typesApiEndPoint = "/bx_block_posts/list_recommendation_type";
exports.NewsFeedFlatList = 'NewsFeedFlatList';
exports.commentsEndPoint = 'bx_block_mentions_and_tagging/comments';
exports.createcommentEndPoint = 'bx_block_mentions_and_tagging/comments';
exports.commentsContentType = "application/json";
exports.likeCommentapi = "bx_block_like/likes"
exports.commentreplyEndpoint = "bx_block_mentions_and_tagging/comments/create_reply";
exports.AddToFavIdPoint = 'bx_block_posts/posts/'
exports.AddToFavIdPoint = 'bx_block_posts/posts/';
exports.getReportApiPoint = 'bx_block_contentflag/contents';
exports.addToBlockApiPoint = 'bx_block_block_users/blocked_user';
exports.addConnectionEndPoint = "bx_block_addfriends/connections";
exports.deleteSearchHistoryApiEndPoint = "bx_block_search_history/delete_search_history?name=";
exports.getAllTermsCondsApiEndPoint = 'bx_block_terms_and_conditions/terms_and_conditions?name=Terms %26 Conditions';

exports.userLists ='bx_block_addfriends/connections'
exports.getFilterNewsfeedApiEndPoint = '/bx_block_advanced_search/filter';
exports.blockReasons = [
  {
    id: 1,
    title: "Unwanted Interactions",
    description: "Blocking helps prevent harassment, bullying, and unwanted attention from other users.",
    backgroundColor: "#F1E3E4",
  },
  {
    id: 2,
    title: "Content Shielding",
    description: "Blocking shields users from offensive or inappropriate content posted by others.",
    backgroundColor: "#E7E3F1",
  },
  {
    id: 3,
    title: "Privacy Control",
    description: "Users can block others to control who views their profile, posts, and personal information, addressing privacy concerns.",
    backgroundColor: "#D4E5DE",
  },
  {
    id: 4,
    title: "Maintaining a Positive Environment",
    description: "Blocking aids in creating a positive online experience by preventing distractions, trolls, and disruptive behavior.",
    backgroundColor: "#E5E3E3",
  },
  {
    id: 5,
    title: "Safety and Trust",
    description: "Blocking serves as a layer of protection against safety concerns, trust breaches, and unwanted solicitations, ensuring a safer and more trustworthy online presence.",
    backgroundColor: "#E3F1E5",
  },
];
exports.mockDataNewsFeed =  {
  "id": "478",
  "type": "post",
  "attributes": {
    "id": 478,
    "name": null,
    "description": null,
    "body": "New post 16 aug",
    "location": null,
    "account_id": '100',
    "external_link": "test link",
    "link_title": "test",
    "is_public": true,
    "is_recommendation": true,
    "city": null,
    "recommendation_type": null,
    "created_at": "2024-08-16T05:10:21.638Z",
    "updated_at": "2024-08-16T05:10:21.752Z",
    "category": "Industry, Professional Interests, Personal Interests",
    "sub_category": "MEDIA & ENTERTAINMENT, PROFESSIONAL DEVELOPMENT, FITNESS",
    "posted_user": {
      "id": 554,
      "first_name": "ismail",
      "last_name": "Sha",
      "role": "President",
      "profile_picture": {
        "id": 1149,
        "url": "https://hannahwrixenkellafinalbuildv3-434558-ruby.b434558.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbjBFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--07fea658458de6e482b9b7f8ef56d58ebc58ab41/pexels-wwarby-19589639.jpg",
        "filename": "pexels-wwarby-19589639.jpg",
        "content_type": "image/jpeg"
      }
    },
    "tagged_users": [],
    "images_and_videos": [
      {
        "id": 1274,
        "url": "https://hannahwrixenkellafinalbuildv3-434558-ruby.b434558.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdm9FIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--6494e300f0bb7666d475859f2d7fb629a18e75a2/original-simple-united-kingdom-england-260nw-156584273.webp",
        "filename": "original-simple-united-kingdom-england-260nw-156584273.webp",
        "content_type": "image/webp"
      }
    ],
    "media": [],
    "time_ago": "about 4 hours ago",
    "like_counts": 0,
    "is_liked": false,
    "is_favorite": false,
    "comment_counts": 0
  }
}
// Customizable Area End