import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { Link } from "react-router-dom";

const QrCodeCameraScan = ({ getScanUserQrData, scanQrUserData, isLoading }: any) => {
  const [qrData, setQrData] = useState<any>(null);
  const [isValid, setIsValid] = useState(undefined);
  const [cameraError, setCameraError] = useState(false);
  const [borderColor, setBorderColor] = useState("transparent");

  const handleScan = (data: any) => {
    if (data) {
      const regex: any = /UserProfileBasicBlock\/\d+$/;
      const isValidQr = regex.test(data)
      setIsValid(isValidQr);
      setBorderColor(isValidQr ? "#73A58E" : "#D1584C");
      if (isValidQr) {
        const getUserId = data?.split('UserProfileBasicBlock/')[1];
        setQrData(getUserId);
        getScanUserQrData(getUserId)
      }
    }
  };

  const handleError = (error: any) => {
    console.error("Error in QR scanning", error);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isValid === undefined) {
        setBorderColor("#D1584C");
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [isValid]);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center", width: "100%" }}>
        {cameraError ? (
          <p>
            "Camera access denied. Please allow access and refresh the page."
          </p>
        ) : (
          <div style={{ position: "relative", width: "100%" }}>
            <QrReader
              onResult={(result: any, error) => {
                if (result) handleScan(result?.text);
                if (error) handleError(error);
              }}
              constraints={{ facingMode: "environment" }}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                width: "60%",
                height: "50%",
                border: `3.5px solid ${borderColor}`,
                transform: "translate(-50%, -50%)",
                borderRadius: "8px",
                pointerEvents: "none",
              }}
            />
            {isLoading &&   
            <div
                style={{
                  position: "absolute",
                  top: "85%",
                  left: "0",
                  right: "0",
                  textDecoration: "none",
                  cursor: "pointer"
                }}
              >
                <button
                  style={{
                    background: "#14362E",
                    color: "#ffffff",
                    borderRadius: "26px",
                    padding: "6px 10px",
                    fontWeight: 500,
                    border: "none"
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    Loading Profile....
                  </div>
                </button>
              </div>
            }
            {(isValid && scanQrUserData?.personal_information?.first_name) && (
              <Link
                to={`UserProfileBasicBlock/${qrData}`}
                style={{
                  position: "absolute",
                  top: "85%",
                  left: "0",
                  right: "0",
                  textDecoration: "none",
                  cursor: "pointer"
                }}
              >
                <button
                  style={{
                    background: "#D4E5DE",
                    color: "#14362E",
                    borderRadius: "26px",
                    padding: "5px 8px",
                    fontWeight: 500,
                    border: "none"
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    {`${scanQrUserData?.personal_information?.first_name}
                      ${scanQrUserData?.personal_information?.last_name}`}
                  </div>
                </button>
              </Link>
            )}

            {isValid === false && (
              <div
                style={{
                  position: "absolute",
                  top: "85%",
                  left: "0",
                  right: "0",
                  textDecoration: "none",
                  cursor: "pointer"
                }}
              >
                <button
                  style={{
                    background: "#EEC3BE",
                    color: "#D1584C",
                    borderRadius: "26px",
                    padding: "5px 8px",
                    fontWeight: 500,
                    border: "none"
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: "12px" }}
                  >
                    User not found
                  </div>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default QrCodeCameraScan;