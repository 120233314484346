import React from "react";
import { Box, Chip, Typography, styled } from "@material-ui/core"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

type CardOptionT = {
    title: string;
    description?: string;
    isSummary?: boolean
  } 

  type TFeature = {
    title: string,
    description: string,
    id: number
    plan_id: number
    show_in_summary: boolean
}
 const SubscriptionCard =({name, features, handleViewDetails, amount, status, title, interval, selectable, isSelected, onSelect,isSummary, isShowStatus,crossed_amount }: any) => {
    const cardClasses = ['subscriptionOverride'];
    if(selectable){
      if(isSelected){
        cardClasses.push('selected')
      }
      cardClasses.push('selectable')
    }
    if(status === 'Active') {
      cardClasses.push('active')
    } else if(status === 'Expired'){
      cardClasses.push('expired')
    } else if(status === 'Inactive') {
      cardClasses.push('inactive')

    }
    return (
        <StyledWrapper className={cardClasses.join(' ')} onClick={selectable && onSelect}>
        <Box style={{display:'flex', gap:'8px', flexDirection:'column'}}>
          <Box style={{display:'flex', justifyContent:'space-between'}}>
            {name && <Typography style={{fontWeight: 700, fontSize:'16px' }}>{name}</Typography>}
            {isShowStatus && <StyledChip  size='small'  label={status} />}
          </Box>
          {title && <Typography style={{
            fontFamily: 'MyFont',
            fontSize: '11px',
            fontWeight: 400,
            lineHeight: '16px',
            letterSpacing: '0.08em',
          }}>{title}</Typography>}
          {features?.filter(({show_in_summary}:any) => isSummary ? show_in_summary: true).map(({title,description, id}: TFeature) => <CardOtpion key={id} title={title} description={description} isSummary={isSummary}/>)}
        </Box>
        <Box style={{display: 'flex', justifyContent: 'space-between', marginTop: '16px'}}>
            <Box style={{ width:"212px", height:"28px",display:"flex"}}>
              <CustomTypography style={{lineHeight:"28px", fontFamily:"Montserrat",fontWeight:700,fontSize:"20px",}}>&#x20AC; {amount}</CustomTypography>
              <Typography style={{lineHeight:"16px", fontWeight:400,fontSize:"11px", fontFamily: "'MyFont', 'Halyard Display'", textAlign:"center",color:"#94A3B8",paddingTop:"5px", marginLeft:'4px'}}> +VAT </Typography>
              <Typography style={{lineHeight:"22px", fontWeight:500,fontSize:"14px",fontFamily: "'MyFont', 'Halyard Display'",textAlign:"center",color:"#94A3B8",paddingTop:"1px"}}> / {interval}</Typography>
            </Box>
            {handleViewDetails && <Typography style={{
              color: "#14362E",
              letterSpacing: "0.08em",
              fontFamily: "'MyFont', 'Halyard Display'",
              lineHeight:"22px",
              fontSize:"14px",
              fontWeight:700,
              textAlign:"center",
              cursor:"pointer",
              }}
              onClick={handleViewDetails}
              >View details</Typography>}
        </Box>
        {interval === 'annual' && crossed_amount && <Typography style={{fontFamily: 'MyFont', fontSize:'11px', }}><s>€{crossed_amount}</s> only pay for 11 months </Typography>}
    </StyledWrapper>
    )
}

const CardOtpion = ({title, description, isSummary}:CardOptionT) => {
    return (
        <Box style={{display:'flex', gap: '12px'}}>
        <StyledCheckIcon fontSize='small'/>
        <Box>
            <Typography style={webStyle.cardItemTitle}>{title}</Typography>
            {!isSummary && description && <Typography style={webStyle.cardItemDescription}>{description}</Typography>}
        </Box>
        </Box>
    )
    
}

const CustomTypography = styled(Typography)({
    '& .MuiTypography-body1': {
      fontFamily: 'MyFont',
    },
  
  });
const StyledWrapper = styled(Box)({
    maxWidth: "450px",
    width:'100%',
    borderRadius: "8px 8px 32px 8px",
    padding:"16px",
    boxSizing: 'border-box' as const,
    letterSpacing: ' 0.08em',
    '&.active': {
        border: '2px solid #73A58E'
    },
    '&.expired': {
      border: '2px solid #D1584C',
    },
    "&.selected": {
      border: '1px solid black'
    },
    "&.inactive": {
      border: '2px solid #CBD5E1',
    },
    '&.selectable': {
      cursor:'pointer',
    }
})
const StyledCheckIcon = styled(CheckCircleIcon)({
  fill:"#73A58E",
  width: '16px',
  height:'16px',
  ".inactive &, .expired &":{
    fill:"#CBD5E1",
  }
})
const StyledChip = styled(Chip)({
  color:' white',
  fontWeight: 700,
  justifySelf:'flex-end',
  fontSize:'11px',
  fontFamily: "'MyFont', 'Halyard Display'",
  ".expired &": {
    backgroundColor: "#D1584C"
  },
  '.active &': {
    backgroundColor: "#14362E",
  },
  '.inactive &': {
    backgroundColor: '#CBD5E1',
  }
});
const webStyle = {
    cardItemTitle: {
      fontSize:"12px",
      fontFamily: "'MyFont', 'Halyard Display'",
      fontWeight:700,
      letterSpacing:"8%",
      lineHeight:"18px",
    },
    cardItemDescription: {
      fontSize:"12px",
      fontFamily: "'MyFont', 'Halyard Display'",
      fontWeight:500,
      letterSpacing:"8%",
      lineHeight:"18px",
      color: '#64748B'
    },
  };

  export default SubscriptionCard;