export const insertPhoto = require("../assets/avatar.jpeg");
export const circleimg = require("../assets/view_circle.jpeg");
export const chatavatar = require("../assets/chatavatar.jpeg")
export const chatOpenavatar = require("../assets/chatopenavatar.jpeg")
export const groupicon = require("../assets/groupicon.jpeg")
export const groupprofile = require("../assets/groupprofile.jpeg");
export const grouponline = require("../assets/grouponline.jpeg");
export const addImg = require("../assets/addimg.jpeg");
export const info = require("../assets/info.jpeg");
export const secondinfo = require("../assets/secondinfo.jpeg")
export const sendicon = require("../assets/arrowsend.jpeg");
export const checkCircle = require("../assets/check_circle.png");
