export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const checked = require("../assets/checked.png");
export const plus = require("../assets/plus.png");
export const blockImg = require("../assets/block_img.png");
export const msgImg = require("../assets/messageIcon.png");
export const contactImg = require("../assets/image_supervisor.png");
export const unblockImg = require("../assets/unblocked_img.png");
export const groupImg = require("../assets/group_add_24px.png");
export const checkCircle = require("../assets/check_circle.png");
export const ErrorImg = require("../assets/404Error.png")
