import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Modal,
  Button,
  Grid,
  IconButton,
  Container,
  Hidden,
} from "@material-ui/core";
import { ArrowBack, CreditCardOutlined } from "@material-ui/icons";
import {
  help,
  notification,
  logout,
  privacy,
  image_chevron,
} from "./assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ContactUs from "./ContactUs.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const dummyProps = {
    navigation: {},
    id: "FAQ"
  };
// Customizable Area End

import SettingsController, { Props } from "./SettingsController.web";

class Settings extends SettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  SettingsForMobile() {
    return (
      <Box style={{ padding: "10px", marginTop:"20px", minHeight: "100vh", overflowX: "hidden" }}>
        <IconButton
          style={{ marginBottom: "15px", }}
          onClick={this.navigateToHome}
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" style={webStyle.mobileHeading}>
          Settings
        </Typography>
        <Box
          style={{
            width: "100%",
            maxWidth: "600px",
            margin: "0 auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: "14px",
          }}
        >
          <Box style={webStyle.mobileMainBox}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Typography style={webStyle.mobileAccount}>Account</Typography>
            </Box>
 
              <a href="/Payment" style={{textDecoration: "none"}}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <CreditCardOutlined />
                <Typography style={webStyle.mobileContent}>
                 Payment
                </Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron}
                      alt="image_chevron"
                      style={{ position: "absolute", right: 0 }}
                    />
                </IconButton>
              </Box>
            </Box>
             </a>
             <a href="/HelpCentre" style={{textDecoration: "none"}}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <img src={help} alt="help" />
                <Typography style={webStyle.mobileContent}>
                  Help Centre
                </Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron}
                      alt="image_chevron"
                      style={{ position: "absolute", right: 0 }}
                    />
                </IconButton>
              </Box>
            </Box>
            </a>
            <a href="/PrivacySettings" style={{textDecoration: "none"}}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <img src={privacy} alt="privacy" />
                <Typography style={webStyle.mobileContent}>
                  Privacy Settings
                </Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron}
                      alt="image_chevron"
                      style={{ position: "absolute", right: 0 }}
                    />
                </IconButton>
              </Box>
            </Box>
             </a>
             <a href="/NotificationSettings" style={{textDecoration: "none"}}>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }}>
              <Box style={webStyle.mobileIconContent}>
                <img src={notification} alt="notification" className="aLink" />
                <Typography style={webStyle.mobileContent}>
                  Notification Settings
                </Typography>
                  <img
                    src={image_chevron}
                    alt="image_chevron"
                    style={{ position: "absolute", right: 0 }}
                  />
              </Box>
            </Box>
            </a>
            <Box style={{ ...webStyle.mobileItems, position: "relative" }} onClick={this.handleLogoutOpen}>
              <Box style={webStyle.mobileIconContent}>
                <img src={logout} alt="logout" />
                <Typography
                  style={webStyle.mobileContent}
                  onClick={this.handleLogoutOpen}
                >
                  Logout
                </Typography>
              </Box>
            </Box>
            <Modal
              open={this.state.logoutPopup}
              onClose={this.handleLogoutClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              disableEnforceFocus
              disableAutoFocus 
            >
              <Box sx={{ ...webStyle.mobileLogoutModalStyle }}>
                <Box sx={{ ...webStyle.mobileLogoutModalTxtStyle }}>
                  Are you sure you want to log out?
                </Box>
                <Box
                  style={{
                    marginTop: "40px",
                    width: "100%",
                    marginBottom: "-8%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box style={{ height: "56px" }}>
                      <Button
                        variant="contained"
                        fullWidth
                        style={{
                          ...webStyle.logoutBtn,
                          textTransform: "capitalize",
                        }}
                        onClick={this.navigateToSignup}
                      >
                        Logout
                      </Button>
                    </Box>
                    <Box style={{ height: "56px", marginTop: "-10px" }}>
                      <Button
                        variant="outlined"
                        fullWidth
                        style={{
                          ...webStyle.logoutCancelBtn,
                          textTransform: "capitalize",
                        }}
                        onClick={this.handleLogoutClose}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Modal>
            <ContactUs {...dummyProps} />
          </Box>
        </Box>
      </Box>
    );
  }

  SettingsForWeb() {
    return (
      <Setting>
        <Box className="outerBox">
          <Box className="arrow">
            <IconButton onClick={this.navigateToHome}>
              <ArrowBack />
            </IconButton>
          </Box>
          <Box className="heading">Settings</Box>
          <Box className="mainBox">
            <Box className="items">
              <Typography className="account">Account</Typography>
            </Box>
                  <a href="/Payment" className="aLink">
            <Box className="items">
              <Box className="icon-content">
                <CreditCardOutlined />
                <Typography className="content">Payment</Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron}
                      alt="image_chevron"
                      style={{ position: "absolute", right: 0 }}
                    />
                </IconButton>
              </Box>
            </Box>
                    </a>
                  <a href="/HelpCentre" className="aLink">
            <Box className="items">
              <Box className="icon-content">
                <img src={help} alt="help" />
                <Typography className="content">Help Centre</Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron}
                      alt="image_chevron"
                      style={{ position: "absolute", right: 0 }}
                    />
                </IconButton>
              </Box>
            </Box>
                    </a>
                  <a href="/PrivacySettings" className="aLink">
            <Box className="items">
              <Box className="icon-content">
                <img src={privacy} alt="privacy" />
                <Typography className="content">Privacy Settings</Typography>
                <IconButton style={{ position: "absolute", right: 0 }}>
                    <img
                      src={image_chevron}
                      alt="image_chevron"
                      style={{ position: "absolute", right: 0 }}
                    />
                </IconButton>
              </Box>
            </Box>
                  </a>
                <a href="/NotificationSettings" className="aLink">
            <Box className="items">
              <Box className="icon-content">
                <img src={notification} alt="notification" />
                <Typography className="content">
                  Notification Settings
                </Typography>
                  <img
                    src={image_chevron}
                    alt="image_chevron"
                    style={{ position: "absolute", right: 0 }}
                  />
              </Box>
            </Box>
                </a>
            <Box className="items" onClick={this.handleLogoutOpen}>
              <Box className="icon-content">
                <img src={logout} alt="logout" />
                <Typography className="content" onClick={this.handleLogoutOpen}>
                  Logout
                </Typography>
              </Box>
            </Box>
            <ContactUs {...dummyProps} />
          </Box>
          <Modal
            open={this.state.logoutPopup}
            onClose={this.handleLogoutClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableEnforceFocus
            disableAutoFocus 
          >
            <Box sx={{ ...webStyle.logoutModalStyle }}>
              <Box sx={{ ...webStyle.logoutModalTxtStyle }}>
                Are you sure you want to log out?
              </Box>
              <Box style={{ marginTop: "30px", marginBottom: "-8%" }}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Box style={{ width: "190px", height: "56px" }}>
                    <Button
                      variant="outlined"
                      fullWidth
                      style={{
                        ...webStyle.logoutCancelBtn,
                        textTransform: "capitalize",
                      }}
                      onClick={this.handleLogoutClose}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box style={{ width: "190px", height: "56px" }}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        ...webStyle.logoutBtn,
                        textTransform: "capitalize",
                      }}
                      onClick={this.navigateToSignup}
                    >
                      Logout
                    </Button>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Setting>
    );
  }
  // Customizable Area End
  render() {
    this.checkingToken();
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={false}>
          <Hidden smDown>{this.SettingsForWeb()}</Hidden>
          <Hidden mdUp>{this.SettingsForMobile()}</Hidden>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export default Settings;

const Setting = styled(Box)({
  "& .aLink": {
    color: "black",
    textDecoration: "none"
  },
  "& .outerBox": {
    width: "100%",
    height: "100%",
    backgroundColor: "#F8FAFC",
  },
  "& .arrow": {
    height: "48px",
    padding: "0px 2%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
  },
  "& .heading": {
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    paddingLeft: "6%",
  },
  "& .mainBox": {
    margin: "2% auto",
    width: "816px",
    backgroundColor: "#FFFFFF",
    borderRadius: "16px",
    padding: "24px 40px",
  },
  "& .items": {
    position: "relative",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #E2E8F0",
    height: "42px",
    padding: "12px 0px",  
    cursor: "pointer",
  },
  "& .account": {
    color: "#64748B",
    fontWeight: 800,
    fontSize: "16px",
    fontFamily: "MyFont",  
  },
  "& .icon-content": {
    display: "flex",
    gap: "10px",   
    alignItems: "center",
  },
  "& .content": {
    fontFamily: "MyFont",
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 700,   
  },
  "& .contact": {
    display: "flex",
    height: "120px",
    padding: "28px 0px",  
    alignItems: "center",
    position: "relative",
  },
  "& .mobileMainBox": {
    padding: "24px 40px",
    backgroundColor: "#FFFFFF",
    margin: "2% auto",
    borderRadius: "16px",  
  },
});

const webStyle = {
  mobileHeading: {
    height: "56px",
    fontSize: "24px",
    fontWeight: 700,
    fontFamily: "MyFont",
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    paddingLeft: "1%",
  },
  mobileMainBox: {
    backgroundColor: "#FFFFFF",
    margin: "1% auto",
    borderRadius: "16px",
  },
  mobileItems: {
    height: "42px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    marginRight: "40px",
    marginTop: "18px",
    cursor: "pointer",
    color: "black",
  },
  mobileAccount: {
    color: "#64748B",
    marginBottom: "18px",
    letterSpacing: "0.05em",
    fontSize: "16px",
    fontWeight: 800,
    fontFamily: "MyFont",   
  },
  mobileIconContent: {
    display: "flex",
    alignItems: "center",
    gap: "10px",  
    marginBottom: "18px",
  },
  mobileContent: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",  
    fontFamily: "MyFont",
    marginButtom: "10px",
  },
  mobileContact: {
    display: "flex",
    alignItems: "center",
    height: "120px",
    paddingTop: "28px", 
  },
  mobileIconContentContact: {
    display: "flex",
    alignItems: "center",
    marginBottom: "18px",
    gap: "10px", 
    // marginRight: "-200px"
  },
  logoutModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "50%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: "380px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
    borderBottomRightRadius: "16px",
  },
  logoutModalTxtStyle: {
    fontSize: "24px",
    fontWeight: "bolder",
    color: "#14362E",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.08em",
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  mobileLogoutModalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "50%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: "270px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
    borderBottomRightRadius: "16px",
  },
  mobileLogoutModalTxtStyle: {
    fontSize: "24px",
    fontWeight: "bolder",
    color: "#14362E",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.08em",
  },
  logoutCancelBtn: {
    border: "1px solid #14362E",
    borderRadius: "8px",
    height: "45px",
    color: "#14362E",
    fontWeight: 700,
    fontFamily: "MyFont",
    letterSpacing: "1px",
    fontSize: "16px",
  },
  logoutBtn: {
    backgroundColor: "#14362E",
    borderRadius: "8px",
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontFamily: "MyFont",
    letterSpacing: "1px",
    fontSize: "16px",
  },
  contactUsDrawerTxt: {
    fontFamily: "MyFont",
    letterSpacing: "1.4",
    color: "#1E1E1E",
    fontSize: "14px",
    marginBottom: "20px",
  },
  contactUsDrawerInputLabel: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
  },
  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    bottom: "10px",
    //   marginTop: "25%",
  },
};

// Customizable Area End
