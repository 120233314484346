import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
interface ProfilePicture {
  id: number;
  filename: string;
  content_type: string;
  url: string;
}

interface Account {
  id: number;
  first_name: string;
  last_name: string;
  online: boolean;
  role: string;
  profile_picture: ProfilePicture;
  friend: boolean;
  mutual_friends: {
    count: number;
    profile_icons: string[];
  };
  created_at: string;
  connection_id: number;
}

interface NotificationAttributes {
  push_notificable_id: number;
  push_notificable_type: string;
  remarks: string;
  is_read: boolean;
  created_at: string;
  updated_at: string;
  account: Account;
  title: string;
  redirect_id: any;
}

interface Notification {
  id: string;
  type: string;
  attributes: NotificationAttributes;
}

interface NotificationsResponse {
  data: Notification[];
  meta: {
    pagy: {
      next: number | null
      count: number
    }
  }
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedData: any;
  token: any;
  notificationsResponse: NotificationsResponse;
  anchorEl: null,
  currentNotificationId:string,
  popoverText: any,
  isNotificationLoading: boolean;
  nextPage: number | null;
  isHasNextPage: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  markAsReadCallId: string = "";
  deleteCallId: string = "";
  getNotificationsDataCallId:string="";
  updateNotificationsDataCallId:string="";
  getNotificationPostDataId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
       this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedData: null,
      token: "",
      notificationsResponse: { data: [], meta: {pagy:{next: null, count: 0}} },
      anchorEl: null,
      currentNotificationId:"",
      popoverText: "",
      isNotificationLoading: false,
      nextPage: 1,
      isHasNextPage: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getNotificationsData();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    } else if (
      this.markAsReadCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getNotificationsData();
    } else if (
      this.deleteCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      
      this.getNotificationsData();
    }
    else if (
      this.getNotificationsDataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
       this.handleGetNotificationsData(apiResponse);
           
      
    }
    else if (
      this.updateNotificationsDataCallId===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      localStorage.removeItem("unreadnotification")
      window.location.reload()
      this.getNotificationsData();
    }
    else if (
      this.getNotificationPostDataId===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.postshareresponse(apiResponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  deleteNotifications(id: string) {
      const token = localStorage.getItem("accessToken")
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: token,
      })
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(deletedMsg.id, deletedMsg);
    this.setState({anchorEl: null});
  }

  
  
  timeSince(date: string) {
    var seconds = Math.floor(
      (new Date().valueOf() - new Date(date).valueOf()) / 1000
    );
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }
  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  getNotificationsData = () => {
    const token = localStorage.getItem("accessToken")
    const queryParams = {
      page: this.state.nextPage,
      items: 10
    }
    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotificationsDataCallId = getNewsFeedMessage.messageId;
    this.setState({isNotificationLoading: true});

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotificationsDataEndpoint}${this.createQueryParams(queryParams)}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }
  handleGetNotificationsData=(responseJSON:NotificationsResponse)=>{
    this.setState(prev => ({ 
      notificationsResponse: {
        ...responseJSON,
        data: [...prev.notificationsResponse.data, ...responseJSON.data]
      },
      isNotificationLoading: false,
      nextPage: responseJSON.meta.pagy.next,
      isHasNextPage: !!responseJSON.meta.pagy.next
    }));
  };
  markAsAllRead=()=>{
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateNotificationsDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateNotificationsDataEndpoint}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }
 formatDate(date:string) {

    const currentDate = new Date();
    const formattedDate = new Date(date);
    if (
      currentDate.getDate() === formattedDate.getDate() &&
      currentDate.getMonth() === formattedDate.getMonth() &&
      currentDate.getFullYear() === formattedDate.getFullYear()
    ) {
      return `Today, ${formattedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    } else {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      if (
        yesterday.getDate() === formattedDate.getDate() &&
        yesterday.getMonth() === formattedDate.getMonth() &&
        yesterday.getFullYear() === formattedDate.getFullYear()
      ) {
        return `Yesterday, ${formattedDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
      } else {
        return formattedDate.toLocaleString();
      }
    }
  }
  handleGoToAccount = (accountId: number) => {
    window.location.href = '/UserProfileBasicBlock/' + accountId
  }
  handleClick = (event:any,id:string) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentNotificationId: id,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      currentNotificationId: ""
    });
  };
  handleBack = () => {
    window.history.back();
  }
  createQueryParams(params: Record<string, any>) {
    const query = new URLSearchParams(params).toString();
    return query ? `?${query}` : '';
  }

  postshareresponse(responseJson:any){
    if(responseJson.page_number){
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), `NewsFeed`);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg)

      localStorage.setItem("indexpage",responseJson.index_in_page);
      localStorage.setItem("pagenumber",responseJson.page_number)
    }
  }

  handleNotificationClick(notification:any) {
    if (notification?.title==="You've got a new message") {
      window.location.href = '/chat'
    } else if (notification?.title==="New Connection Request") {
      window.location.href = '/MyCircle'
    }
  }

  handlePostRedirectionToNewsfeed=(notification:any)=>{

    if (!notification?.redirect_id) {
      this.handleNotificationClick(notification)
    }

    const token = localStorage.getItem("accessToken")

    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getNotificationPostDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/find_record?items=20&id=${notification?.redirect_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
