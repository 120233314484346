import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  confirmationCall: boolean;
  pending: boolean;
  approved: boolean;
  waitlisted: boolean;
  applicationStatus: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ApprovalStatesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      activeStep: 0,
      confirmationCall: true,
      pending: false,
      approved: false,
      waitlisted: false,
      applicationStatus: ""
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    let status = await getStorageData("applicationStatus")

      this.setState({
         applicationStatus: status
      })
  }
  handleChange = (state: number) => {
    if(this.state.applicationStatus == "approved") {
      this.setState({ confirmationCall: false, pending: false, approved: true, waitlisted: false })
    } else if(this.state.applicationStatus == "pending" ) {
      this.setState({ confirmationCall: false, pending: true, approved: false, waitlisted: false })
    } else if(this.state.applicationStatus == "waitlisted" ) {
      this.setState({ confirmationCall: false, pending: false, approved: false, waitlisted: true })
    }else {
      this.setState({ confirmationCall: false, pending: true, approved: false, waitlisted: false })
    }
  }
  moveToLogin = () => {
    window.location.href = '/EmailAccountLoginBlock'
  }
  moveToOnboarding = () => {
    window.location.href = '/OnboardingScreen'
  }
  openCalendar = () => {
    window.open(' http://calendly.com/hello-kellaleadership', '_blank', 'noopener');
  }
  // Customizable Area End
}