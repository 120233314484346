import React, { useState, useRef, useEffect, forwardRef } from 'react'
import { Box, Button, Typography, AppBar, Toolbar, Avatar, List, InputAdornment, TextField, ListItem, Drawer, Chip, MenuItem, IconButton, Checkbox, Collapse, Badge
  , styled
 } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Search as SearchIcon, ExpandLess, ExpandMore } from "@material-ui/icons";
const Headerlogo = require("./headerlogo.jpeg");
const Avtar = require("./avatar.jpeg");
const searchVectorImg = require("./Vector.png");
const ChatImg = require("./chatimg.jpeg")
const newhome = require("./homenew.png");
const newchat = require("./chaticon.png");
const bell = require("./bellicon.png");
const exploreicon = require("./exploreicon.png");
const event = require("./newevent.png");
import { countries } from "countries-list";
import { removeStorageData, setStorageData } from 'framework/src/Utilities';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import {Link} from "react-router-dom"
interface FilterParams {
  search: string;
  search_type: string;
  country: string[];
  role: number[];
  industry: number[];
  category: number[];
  sub_category: number[];
  city: string[];
  recommendation_type_id: number[];
}
const BoxWithRef = forwardRef<HTMLDivElement, any>((props, ref) => (
  <Box ref={ref} {...props} />
));

const dummyPeopleData = [
  { name: 'Emily Landry', position: 'Position', status: 'Invite to My Circle', country: 'USA', industry: 'Technology', role: 'Engineer' },
  { name: 'Emily Landry', position: 'Position', status: 'My Circle', country: 'UK', industry: 'Healthcare', role: 'Doctor' },
  { name: 'Emily Landry', position: 'Position', status: 'Invite to My Circle', country: 'USA', industry: 'Education', role: 'Teacher' },
];
const countryNames = Object.values(countries).map(country => country.name);

const transformFilterParams = (params: FilterParams) => {
  const transformedParams: { [key: string]: string } = {};
  for (const key in params) {
    if (Array.isArray(params[key as keyof FilterParams]) && params[key as keyof FilterParams].length > 0) {
      transformedParams[key] = JSON.stringify(params[key as keyof FilterParams]);
    } else if (params[key as keyof FilterParams]) {
      transformedParams[key] = params[key as keyof FilterParams] as string;
    }
  }
  return transformedParams;
};

export function AppHeader(props: any) {
  const classes = styles();
  const [searchFocus, setSearchFocus] = useState(false);
  const [peopleFilterOpen, setPeopleFilterOpen] = useState(false);
  const [postsFilterOpen, setPostsFilterOpen] = useState(false);
  const [recommendationsFilterOpen, setRecommendationsFilterOpen] = useState(false);

  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState<string[]>([]);

  const [industryMap, setIndustryMap] = useState<{ [key: string]: number }>({});
  const [roleMap, setRoleMap] = useState<{ [key: string]: number }>({});
  const [categoryMap, setCategoryMap] = useState<{ [key: string]: number }>({});
  const [subCategoryMap, setSubCategoryMap] = useState<{ [key: string]: number }>({});
  const [typeMap, setTypeMap] = useState<{ [key: string]: number }>({});

  const [filterParams, setFilterParams] = useState<FilterParams>({
    search: '',
    search_type: 'Posts',
    country: [],
    industry: [],
    role: [],
    category: [],
    sub_category: [],
    city: [],
    recommendation_type_id: [],
  });

  const [industryData, setIndustryData] = useState<any[]>([]);
  const [roleData, setRoleData] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [subCategoryData, setSubCategoryData] = useState<any[]>([]);
  const [cityData, setCityData] = useState<any[]>([]);
  const [typesData, setTypesData] = useState<any[]>([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [EventDataresponse,setEventResponse] = useState<any[]>([]);

  const handleInviteClick = (index: number, inviteId: string) => {
    props.inviteCircle(inviteId);
  };
  interface GenericObject<T> {
    [key: string]: T;
  }

  useEffect(() => {
    const filterState = [peopleFilterOpen, postsFilterOpen, recommendationsFilterOpen];
    localStorage.setItem("isFilterOpen", JSON.stringify(filterState));
  }, [postsFilterOpen, peopleFilterOpen, recommendationsFilterOpen]);

  useEffect(()=> {
    if (window.innerWidth <= 700) {
      localStorage.setItem("stopBgScroll", isInputFocused.toString())
      return;
    }
    localStorage.setItem("stopBgScroll", "false")
  },[isInputFocused])

  const redirectexplore = () => {
    if (window.location.pathname.includes('/UserProfileBasicBlock/') || /\/Categorydetails\/\d+/.test(window.location.pathname)) {
      window.location.replace("/ExploreBlock")
    } else {
      window.location.href = "ExploreBlock"

    }
  }

  const myprfileredirect = () => {
    if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
      window.location.replace("/UserProfileBasicBlock")
    } else {
      window.location.href = "UserProfileBasicBlock"
    }
  }

  const postredirect =  async () => {
    await removeStorageData('editPostId')
    if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
      window.location.replace("/PostCreation")
    } else {
      window.location.href = "PostCreation"
    }
  }

  const Logoredirect = () => {
    if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
      window.location.replace("/newsfeed")
    } else {
      window.location.href = "newsfeed"
    }
  }

  const Homeredirect = () => {
    if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
      window.location.replace("/newsFeed")
    } else {
      window.location.href = "newsFeed"
    }
  }

  const chatredirect = () => {
    if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
      window.location.replace("/Chat")
    } else {
      window.location.href = "Chat"

    }
  }

  const notificationredirect = () => {
    if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
      window.location.replace("/Notifications")
    } else {
      window.location.href = "Notifications"
    }
  }

  const [userprofile, setUserProfile] = useState<GenericObject<any>>({});
  const [usermainnotification, setUserMainNotificaion] = useState<number>(0);

  const userData: any = localStorage?.getItem("userdata");
  useEffect(() => {
    const finaldata = JSON?.parse(userData)
    if (finaldata) {
      setUserProfile(finaldata)
      localStorage.setItem("userprofile", finaldata.attributes.personal_information.profile_picture?.url)
    }
  }, [userData])


  useEffect(() => {
    const usernotification: any = localStorage?.getItem("unreadnotification");

    if (usernotification) {
      const parsedNotification = parseInt(usernotification);
      if (!isNaN(parsedNotification)) {
        setUserMainNotificaion(parsedNotification);
      }
    }
    
  }, [])

  const searchHistoryArray = props.searchHistoryData;

  const filteredSearchHistory =
    searchHistoryArray?.filter((item: string) => item.toLowerCase().includes(filterParams.search.toLowerCase()))
      .slice(0, 4) || [];

  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      searchInputRef.current &&
      !searchInputRef.current.contains(event.target as Node)
    ) {
      setIsInputFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSearchFocus = () => {
    if (window.location.pathname.toLowerCase() !== '/newsfeed') {
      window.location.href = "newsFeed"
    }
  };

  const clearSearch = () => {
    setFilterParams(prev => ({
      ...prev,
      search: ""
    }));
  }
  

  const getButtonStyle = (buttonName: any) => {
    const baseStyle = {
      fontWeight: "bold" as "bold",
      fontFamily: "MyFont",
      letterSpacing: "1px",
      fontSize: "16px",
      textTransform: "capitalize" as "capitalize",
    };

    if (buttonName === filterParams.search_type) {
      return {
        ...baseStyle,
        border: "1px solid #73A58E",
        borderRadius: "50px",
        color: "#14362E",
      };
    } else {
      return {
        ...baseStyle,
        color: "#64748B",
      };
    }
  };

  const setView = (view: any) => {
    setFilterParams(prev => ({
      ...prev,
      search_type: view,
    }));
    setIsInputFocused(true);
  };

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof filterParams;
    setFilterParams({
      ...filterParams,
      [name]: event.target.value
    });
  };

  const handleFilterClick = async () => {
    if (filterParams.search_type === 'People') {
      setPeopleFilterOpen(true);
      // Process and set the industry data
      const industryResponse = await props.industryData();
      setIndustryData(industryResponse.industries);
      const industryMap: { [key: string]: number } = {};
      industryResponse.industries.forEach((industry: any) => {
        industryMap[industry.name] = industry.id;
      });
      setIndustryMap(industryMap);
      // Process and set the roles data
      const roleResponse = await props.roleData();
      setRoleData(roleResponse.roles);
      const roleMap: { [key: string]: number } = {};
      roleResponse.roles.forEach((role: any) => {
        roleMap[role.name] = role.id;
      });
      setRoleMap(roleMap);

    } else if (filterParams.search_type === 'Posts') {
      setPostsFilterOpen(true);
      // Process and set the category data
      const categoryResponse = await props.categoryData();
      const processedCategoryData = categoryResponse.data.map((category: any) => category.attributes);
      setCategoryData(processedCategoryData);
      const categoryMap: { [key: string]: number } = {};
      categoryResponse.data.forEach((category: any) => {
        categoryMap[category.attributes.name] = category.id;
      });
      setCategoryMap(categoryMap);
      // Process and set the sub-category data
      const subCategoryResponse = await props.subCategoryData();
      const processedSubCategoryData = subCategoryResponse.data.map((subCategory: any) => subCategory.attributes);
      setSubCategoryData(processedSubCategoryData);
      const subCategoryMap: { [key: string]: number } = {};
      subCategoryResponse.data.forEach((subCategory: any) => {
        subCategoryMap[subCategory.attributes.name] = subCategory.id;
      });
      setSubCategoryMap(subCategoryMap);
    } else if (filterParams.search_type === 'Recommendations') {
      setRecommendationsFilterOpen(true);
      // Process and set the city data
      const cityResponse = await props.citiesData();
      const processedCityData = cityResponse.data.map((city: any) => city.name);
      setCityData(processedCityData);
      // Process and set the type data
      const typeResponse = await props.typesData();
      const processedTypeData = typeResponse.data.map((type: any) => type.attributes);
      setTypesData(processedTypeData);

      const typeMap: { [key: string]: number } = {};
      typeResponse.data.forEach((type: any) => {
        typeMap[type.attributes.name] = type.id;
      });
      setTypeMap(typeMap);
    }else if(filterParams.search_type === "Events"){
      
    }
  };

  const handleSearchHistoryClick = (item: string) => {
    setFilterParams(prev => ({
      ...prev,
      search: item,
    }));
  };

  const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    setFilterParams(prev => ({
      ...prev,
      search: searchValue,
    }));
  };

  useEffect(()=>{
    if(props?.EventData?.length > 0){
      setEventResponse(props?.EventData)
    }
  },[props?.EventData])

  const setEventData = (data:any)=>{
    localStorage.setItem("eventsearchId",data.id)
  }

  const handleApplyFilters = () => {
    setPeopleFilterOpen(false);
    setPostsFilterOpen(false);
    setRecommendationsFilterOpen(false);

    props.searchFilterData(filterParams);
  };

  const renderPeopleDrawer = () => {
    const [countryOpen, setCountryOpen] = useState(false);
    const [industryOpen, setIndustryOpen] = useState(false);
    const [roleOpen, setRoleOpen] = useState(false);
    const [countrySearchQuery, setCountrySearchQuery] = useState('');
    const [industrySearchQuery, setIndustrySearchQuery] = useState('');
    const [roleSearchQuery, setRoleSearchQuery] = useState('');
    const classes = useStyles();

    const handleCountryClick = () => {
      setCountryOpen(!countryOpen);
    };

    const handleIndustryClick = () => {
      setIndustryOpen(!industryOpen);
    };

    const handleRoleClick = () => {
      setRoleOpen(!roleOpen);
    };

    const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;
      setFilterParams((prev: FilterParams): FilterParams => {
        const updatedCountry = prev.country.includes(selectedValue)
          ? prev.country.filter((item: any) => item !== selectedValue)
          : [...prev.country, selectedValue];
        return {
          ...prev,
          country: updatedCountry,
        };
      });
    };

    const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;
      setSelectedRoles(prev => {
        const updatedRoles = prev.includes(selectedValue)
          ? prev.filter(item => item !== selectedValue)
          : [...prev, selectedValue];

        const selectedId = roleMap[selectedValue];
        setFilterParams(filterParams => {
          const updatedRole = filterParams.role.includes(selectedId)
            ? filterParams.role.filter((item) => item !== selectedId)
            : [...filterParams.role, selectedId];
          return {
            ...filterParams,
            role: updatedRole,
          };
        });

        return updatedRoles;
      });
    };

    const handleIndustryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;
      setSelectedIndustries(prev => {
        const updatedIndustries = prev.includes(selectedValue)
          ? prev.filter(item => item !== selectedValue)
          : [...prev, selectedValue];

        const selectedId = industryMap[selectedValue];
        setFilterParams(filterParams => {
          const updatedIndustry = filterParams.industry.includes(selectedId)
            ? filterParams.industry.filter((item: any) => item !== selectedId)
            : [...filterParams.industry, selectedId];
          return {
            ...filterParams,
            industry: updatedIndustry,
          };
        });

        return updatedIndustries;
      });
    };

    const handleCountrySearchChange = (event: any) => {
      setCountrySearchQuery(event.target.value);
    };
    const handleIndustrySearchChange = (event: any) => {
      setIndustrySearchQuery(event.target.value.toLowerCase());
    };
    const handleRoleSearchChange = (event: any) => {
      setRoleSearchQuery(event.target.value);
    };

    const clearCountrySearch = () => {
      setCountrySearchQuery('');
    };
    const clearIndustrySearch = () => {
      setIndustrySearchQuery('');
    };
    const clearRoleSearch = () => {
      setRoleSearchQuery('');
    };

    const handleDeleteCountry = (countryToDelete: string) => {
      setFilterParams((prev: FilterParams): FilterParams => {
        const updatedCountry = prev.country.filter(country => country !== countryToDelete);
        return {
          ...prev,
          country: updatedCountry,
        };
      });
    };

    const handleDeleteIndustry = (industryToDelete: any) => {
      setFilterParams((prev: FilterParams): FilterParams => {
        const updatedIndustry = prev.industry.filter(industry => industry !== industryToDelete);
        return {
          ...prev,
          industry: updatedIndustry,
        };
      });
    };

    const handleDeleteRole = (roleToDelete: any) => {
      setFilterParams((prev: FilterParams): FilterParams => {
        const updatedRole = prev.role.filter(role => role !== roleToDelete);
        return {
          ...prev,
          role: updatedRole,
        };
      });
    };

    return (
       <DrawerResponsive>
               <Drawer
        anchor="right"
        open={peopleFilterOpen}
        onClose={() => setPeopleFilterOpen(false)}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex", justifyContent: "space-between", alignItems: "center",
              width: "96%", borderBottom: "1px solid #E2E8F0", marginLeft: "14px"
            }} >
            <Typography
              style={{
                fontSize: "24px", fontWeight: 600, color: "#14362E",
                fontFamily: "MyFont", letterSpacing: "1.4px",
              }} >
              Filter
            </Typography>
            <IconButton style={{ right: "10px" }} onClick={() => setPeopleFilterOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box style={{ padding: '10px' , marginBottom: "80px" }}>
            {/* Country Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleCountryClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  Country
                </Typography>
                {countryOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!countryOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {filterParams.country.join(', ')}
                </Typography>
              )}
              <Collapse in={countryOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {filterParams.country.map((country) => (
                    <Chip
                      key={country}
                      label={country}
                      onDelete={() => handleDeleteCountry(country)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont",
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={countrySearchQuery}
                    onChange={handleCountrySearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: countrySearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearCountrySearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" }}>
                  {countryNames.map((country: any) => {
                    const countryName = country.toLowerCase();
                    if (!countrySearchQuery || countryName.includes(countrySearchQuery)) {
                      return (
                        <Box
                          key={country}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {country}
                          </Typography>
                          <Checkbox
                            checked={filterParams.country.includes(country)}
                            onChange={handleCountryChange}
                            value={country}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

            {/* Industry Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleIndustryClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  Industry
                </Typography>
                {industryOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!industryOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {selectedIndustries.join(', ')}
                </Typography>
              )}
              <Collapse in={industryOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >

                  {selectedIndustries.map((industry) => (
                    <Chip
                      key={industry}
                      label={industry}
                      onDelete={() => handleDeleteIndustry(industry)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont",
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={industrySearchQuery}
                    onChange={handleIndustrySearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: industrySearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearIndustrySearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" }}>
                  {industryData && industryData.map((industry: any, index: number) => {
                    const industryName = industry.name.toLowerCase();
                    if (!industrySearchQuery || industryName.includes(industrySearchQuery)) {
                      return (
                        <Box
                          key={industry.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {industry.name}
                          </Typography>
                          <Checkbox
                            checked={selectedIndustries.includes(industry.name)}
                            onChange={handleIndustryChange}
                            value={industry.name}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

            {/* Role Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleRoleClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  Role
                </Typography>
                {roleOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!roleOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {selectedRoles.join(', ')}
                </Typography>
              )}
              <Collapse in={roleOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {selectedRoles.map((role) => (
                    <Chip
                      key={role}
                      label={role}
                      onDelete={() => handleDeleteRole(role)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont",
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={roleSearchQuery}
                    onChange={handleRoleSearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: roleSearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearRoleSearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" }}>
                  {roleData.map((role: any, index: number) => {
                    const roleName = role.name.toLowerCase();
                    if (!roleSearchQuery || roleName.includes(roleSearchQuery)) {
                      return (
                        <Box
                          key={role.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {role.name}
                          </Typography>
                          <Checkbox
                            checked={selectedRoles.includes(role.name)}
                            onChange={handleRoleChange}
                            value={role.name}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

          </Box>

          <FilterBox style={{ width: "29%", height: "56px", position: 'fixed', bottom: 0, paddingLeft: '8px', paddingRight: "8px" }}>
              <Button
                className='FilterBoxBtn'
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: "#14362E",
                  borderRadius: "8px",
                  height: "45px",
                  color: "white",
                  fontWeight: 700,
                  fontFamily: "MyFont",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
                data-test-id={"selectoptionId"}
                onClick={handleApplyFilters}
              >
                Apply Filter
              </Button>
            </FilterBox>
        </Box>
      </Drawer>
       </DrawerResponsive>
    );
  };

  const renderPostsDrawer = () => {
    const [categoryOpen, setcategoryOpen] = useState(false);
    const [subCategoryOpen, setSubCategoryOpen] = useState(false);
    const [categorySearchQuery, setCategorySearchQuery] = useState('');
    const [subCategorySearchQuery, setSubCategorySearchQuery] = useState('');
    const classes = useStyles();

    const handleCategoryClick = () => {
      setcategoryOpen(!categoryOpen);
    };
    const handleSubCategoryClick = () => {
      setSubCategoryOpen(!subCategoryOpen);
    };

    const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;

      setSelectedCategories(prev => {
        const updatedCategories = prev.includes(selectedValue)
          ? prev.filter(item => item !== selectedValue)
          : [...prev, selectedValue];

        const selectedId = categoryMap[selectedValue];

        setFilterParams(prevFilterParams => {
          const updatedCategory = prevFilterParams.category.includes(selectedId)
            ? prevFilterParams.category.filter(item => item !== selectedId)
            : [...prevFilterParams.category, selectedId];

          return {
            ...prevFilterParams,
            category: updatedCategory,
          };
        });

        return updatedCategories;
      });
    };

    const handleSubCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;
      setSelectedSubCategories(prev => {
        const updatedSubCategories = prev.includes(selectedValue)
          ? prev.filter(item => item !== selectedValue)
          : [...prev, selectedValue];

        const selectedId = subCategoryMap[selectedValue];

        setFilterParams(prevFilterParams => {
          const updatedSubCategory = prevFilterParams.sub_category.includes(selectedId)
            ? prevFilterParams.sub_category.filter(item => item !== selectedId)
            : [...prevFilterParams.sub_category, selectedId];

          return {
            ...prevFilterParams,
            sub_category: updatedSubCategory,
          };
        });

        return updatedSubCategories;
      });
    };

    const handleCategorySearchChange = (event: any) => {
      setCategorySearchQuery(event.target.value);
    };
    const handleSubCategorySearchChange = (event: any) => {
      setSubCategorySearchQuery(event.target.value);
    };

    const clearCategorySearch = () => {
      setCategorySearchQuery('');
    };
    const clearSubCategorySearch = () => {
      setSubCategorySearchQuery('');
    };

    const handleDeleteCategories = (categoryToDelete: string) => {
      setSelectedCategories(prev => {
        const updatedCategories = prev.filter(category => category !== categoryToDelete);
        setFilterParams(filterParams => {
          const updatedCategory = filterParams.category.filter(id => categoryMap[categoryToDelete] !== id).map(id => Number(id)); // Ensure IDs are numbers
          return {
            ...filterParams,
            category: updatedCategory,
          };
        });
        return updatedCategories;
      });
    };

    const handleDeleteSubCategories = (subCategoryToDelete: string) => {
      setSelectedSubCategories(prev => {
        const updatedSubCategories = prev.filter(subCategory => subCategory !== subCategoryToDelete);
        setFilterParams(filterParams => {
          const updatedSubCategory = filterParams.sub_category.filter(id => subCategoryMap[subCategoryToDelete] !== id).map(id => Number(id)); // Ensure IDs are numbers
          return {
            ...filterParams,
            sub_category: updatedSubCategory,
          };
        });
        return updatedSubCategories;
      });
    };

    return (
      <DrawerResponsive>
      <Drawer
        anchor="right"
        open={postsFilterOpen}
        onClose={() => setPostsFilterOpen(false)}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #E2E8F0"
            }}
          >
            <Box style={{ display: "flex", padding: "10px" }}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#14362E",
                  fontFamily: "MyFont",
                  letterSpacing: "1.4",
                }}
              >
                Filter
              </Typography>
            </Box>
            <Box>
              <IconButton style={{ right: "10px" }} onClick={() => setPostsFilterOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box style={{ padding: '10px' ,  marginBottom: "80px" }}>
            {/* Category Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleCategoryClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  Category
                </Typography>
                {categoryOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!categoryOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {selectedCategories.join(', ')}
                </Typography>
              )}
              <Collapse in={categoryOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {selectedCategories.map((category) => (
                    <Chip
                      key={category}
                      label={category}
                      onDelete={() => handleDeleteCategories(category)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont",
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={categorySearchQuery}
                    onChange={handleCategorySearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: categorySearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearCategorySearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" }}>
                  {categoryData.map((category: any, index: number) => {
                    const categoryName = category.name.toLowerCase();
                    if (!categorySearchQuery || categoryName.includes(categorySearchQuery)) {
                      return (
                        <Box
                          key={category.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {category.name}
                          </Typography>
                          <Checkbox
                            checked={selectedCategories.includes(category.name)}
                            onChange={handleCategoryChange}
                            value={category.name}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

            {/* SubCategory Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleSubCategoryClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  Sub-Category
                </Typography>
                {subCategoryOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!subCategoryOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {selectedSubCategories.join(', ')}
                </Typography>
              )}
              <Collapse in={subCategoryOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {selectedSubCategories.map((subCategory) => (
                    <Chip
                      key={subCategory}
                      label={subCategory}
                      onDelete={() => handleDeleteSubCategories(subCategory)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont",
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={subCategorySearchQuery}
                    onChange={handleSubCategorySearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: subCategorySearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearSubCategorySearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" , marginBottom:"30px" }}>
                  {subCategoryData.map((subCategory: any, index: number) => {
                    const subCategoryName = subCategory.name.toLowerCase();
                    if (!subCategorySearchQuery || subCategoryName.includes(subCategorySearchQuery)) {
                      return (
                        <Box
                          key={subCategory.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {subCategory.name}
                          </Typography>
                          <Checkbox
                            checked={selectedSubCategories.includes(subCategory.name)}
                            onChange={handleSubCategoryChange}
                            value={subCategory.name}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

            <FilterBox style={{ width: "29%", height: "56px", position: 'fixed', bottom: 0,paddingRight: "8px" }}>
              <Button
                className='FilterBoxBtn'
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: "#14362E",
                  borderRadius: "8px",
                  height: "45px",
                  color: "white",
                  fontWeight: 700,
                  fontFamily: "MyFont",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
                data-test-id={"selectoptionId"}
                onClick={handleApplyFilters}
              >
                Apply Filter
              </Button>
            </FilterBox>
          </Box>
        </Box>
      </Drawer>
      </DrawerResponsive>

    )
  }

  const renderRecommendationsDrawer = () => {
    const [cityOpen, setCityOpen] = useState(false);
    const [typeOpen, setTypeOpen] = useState(false);
    const [selectedCities, setSelectedCities] = useState<string[]>([]);
    const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
    const [citySearchQuery, setCitySearchQuery] = useState('');
    const [typeSearchQuery, setTypeSearchQuery] = useState('');
    const classes = useStyles();

    const handleCityClick = () => {
      setCityOpen(!cityOpen);
    };
    const handleTypeClick = () => {
      setTypeOpen(!typeOpen);
    };

    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;
      setSelectedCities(prev => {
        const updatedCities = prev.includes(selectedValue)
          ? prev.filter(item => item !== selectedValue)
          : [...prev, selectedValue];

        setFilterParams(filterParams => ({
          ...filterParams,
          city: updatedCities,
        }));

        return updatedCities;
      });
    };

    const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedValue = event.target.value;
      setSelectedTypes(prev => {
        const updatedTypes = prev.includes(selectedValue)
          ? prev.filter(item => item !== selectedValue)
          : [...prev, selectedValue];

        const selectedId = typeMap[selectedValue];
        setFilterParams(filterParams => {
          const updatedRecommendationTypeId = filterParams.recommendation_type_id.includes(selectedId)
            ? filterParams.recommendation_type_id.filter(item => item !== selectedId)
            : [...filterParams.recommendation_type_id, selectedId].map(id => Number(id));
          return {
            ...filterParams,
            recommendation_type_id: updatedRecommendationTypeId,
          };
        });

        return updatedTypes;
      });
    };

    const handleCitySearchChange = (event: any) => {
      setCitySearchQuery(event.target.value);
    };
    const handleTypeSearchChange = (event: any) => {
      setTypeSearchQuery(event.target.value);
    };

    const clearCitySearch = () => {
      setCitySearchQuery('');
    };
    const clearTypeSearch = () => {
      setTypeSearchQuery('');
    };

    const handleDeleteCity = (cityToDelete: string) => {
      setSelectedCities(prev => {
        const updatedCities = prev.filter(city => city !== cityToDelete);

        setFilterParams(filterParams => ({
          ...filterParams,
          city: updatedCities,
        }));

        return updatedCities;
      });
    };

    const handleDeleteType = (typeToDelete: string) => {
      setSelectedTypes(prev => {
        const updatedTypes = prev.filter(type => type !== typeToDelete);
        setFilterParams(filterParams => {
          const updatedRecommendationTypeId = filterParams.recommendation_type_id.filter(id => typeMap[typeToDelete] !== id).map(id => Number(id)); // Ensure IDs are numbers
          return {
            ...filterParams,
            recommendation_type_id: updatedRecommendationTypeId,
          };
        });
        return updatedTypes;
      });
    };

    return (
      <DrawerResponsive>

      <Drawer
        anchor="right"
        open={recommendationsFilterOpen}
        onClose={() => setRecommendationsFilterOpen(false)}
        PaperProps={{
          className: classes.drawerPaper,
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              borderBottom: "1px solid #E2E8F0"
            }}
          >
            <Box style={{ display: "flex", padding: "10px" }}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#14362E",
                  fontFamily: "MyFont",
                  letterSpacing: "1.4",
                }}
              >
                Filter
              </Typography>
            </Box>
            <Box>
              <IconButton style={{ right: "10px" }} onClick={() => setRecommendationsFilterOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <Box style={{ padding: '10px', marginBottom: "80px" }}>
            {/* City Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleCityClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  City
                </Typography>
                {cityOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!cityOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {selectedCities.join(', ')}
                </Typography>
              )}
              <Collapse in={cityOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {selectedCities.map((city) => (
                    <Chip
                      key={city}
                      label={city}
                      onDelete={() => handleDeleteCity(city)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont",
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={citySearchQuery}
                    onChange={handleCitySearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: citySearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearCitySearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" }}>
                  {cityData.map((city: any) => {
                    const cityName = city.toLowerCase();
                    if (!citySearchQuery || cityName.includes(citySearchQuery)) {
                      return (
                        <Box
                          key={city}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {city}
                          </Typography>
                          <Checkbox
                            checked={selectedCities.includes(city)}
                            onChange={handleCityChange}
                            value={city}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

            {/* Type Field */}
            <Box style={{ paddingBottom: "16px", border: "1px solid #E2E8F0", marginTop: "16px", }}>
              <Box onClick={handleTypeClick}
                style={{
                  display: "flex", justifyContent: "space-between", alignItems: "center",
                  cursor: "pointer", borderRadius: "8px", padding: "8px 16px",
                }}>
                <Typography
                  variant="body1"
                  style={{
                    color: "#0F172A",
                    fontSize: "16px",
                    fontWeight: 500,
                    letterSpacing: "0.05em",
                    fontFamily: "MyFont",
                    marginTop: "",
                  }}
                >
                  Type
                </Typography>
                {typeOpen ? <ExpandLess /> : <ExpandMore />}
              </Box>
              {!typeOpen && (
                <Typography variant="body1" style={{ color: "#475569", fontSize: "14px", fontWeight: 500, letterSpacing: "0.05em", fontFamily: "MyFont", marginLeft: "10px" }}>
                  {selectedTypes.join(', ')}
                </Typography>
              )}
              <Collapse in={typeOpen} timeout="auto" unmountOnExit>
                <Box
                  style={{
                    marginTop: "8px",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                  }}
                >
                  {selectedTypes.map((type) => (
                    <Chip
                      key={type}
                      label={type}
                      onDelete={() => handleDeleteType(type)}
                      style={{
                        backgroundColor: "#F1F5F9",
                        color: "#475569",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "MyFont"
                      }}
                    />
                  ))}
                </Box>
                <Box style={{ padding: "10px" }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search"
                    data-test-id="searchField"
                    value={typeSearchQuery}
                    onChange={handleTypeSearchChange}
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        marginTop: "5px",
                        fontFamily: "'MyFont', 'Halyard Display'",
                      },
                      endAdornment: typeSearchQuery && (
                        <InputAdornment position="end">
                          <IconButton onClick={clearTypeSearch}>
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box style={{ padding: "10px" }}>
                  {typesData.map((type: any) => {
                    const typeName = type.name.toLowerCase();
                    if (!typeSearchQuery || typeName.includes(typeSearchQuery)) {
                      return (
                        <Box
                          key={type.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingLeft: "10px",
                            borderBottom: "1px solid #CBD5E1",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              color: "#475569",
                              fontSize: "14px",
                              fontWeight: 500,
                              letterSpacing: "0.05em",
                              fontFamily: "MyFont",
                            }}
                          >
                            {type.name}
                          </Typography>
                          <Checkbox
                            checked={selectedTypes.includes(type.name)}
                            onChange={handleTypeChange}
                            value={type.name}
                            style={{
                              color: "#73A58E",
                              borderColor: "#64748B",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Box>
              </Collapse>
            </Box>

            <FilterBox style={{ width: "29%", height: "56px", position: 'fixed', bottom: 0}}>
              <Button className='FilterBoxBtn'
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: "#14362E",
                  borderRadius: "8px",
                  height: "45px",
                  color: "white",
                  fontWeight: 700,
                  fontFamily: "MyFont",
                  letterSpacing: "1px",
                  fontSize: "16px",
                  textTransform: "capitalize",
                }}
                data-test-id={"selectoptionId"}
                onClick={handleApplyFilters}
              >
                Apply Filter
              </Button>
            </FilterBox>
          </Box>
        </Box>
      </Drawer>
      </DrawerResponsive>

    )
  }

  type YourComponentProps = {
    person: any;
    index: number;
    handleInviteClick: (index: number, inviteId: string) => void; // Adjust the type as necessary
  };

  const YourComponent: React.FC<YourComponentProps> = ({ person, index, handleInviteClick }) => {
    const [connectionStatus, setConnectionStatus] = useState(person.attributes?.friend?.connection_status);

    const inviteRefs = useRef<(HTMLButtonElement | null)[]>([]);

    const handleInvite = (index: number) => {
      handleInviteClick(index, person?.id);
      
      handleApplyFilters()
      setConnectionStatus('pending');
    };

    const handlePendingClick = () => {
      props.handleUserRequestUndoApiCall(person?.attributes?.friend?.id)
    }

    return (
      <Box style={{ display: 'flex' }}>
        <Link to={`/UserProfileBasicBlock/${person?.id}`} style={{textDecoration:"none", cursor: "pointer"}}>
        <Avatar style={{ marginTop: "10px", marginRight: "10px", cursor: "pointer" }} src={person.attributes.personal_information.profile_picture.url} alt='' 
        />
        </Link>
        <ListItem
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0',
            borderBottom: '1px solid #E2E8F0',
            flexWrap: 'wrap',
          }}>
        <Link to={`/UserProfileBasicBlock/${person?.id}`} style={{textDecoration:"none", cursor: "pointer"}}>
          <Box style={{ flex: '1 1 auto', minWidth: '0' , maxWidth:"100px", cursor: "pointer" }}>
            <Typography style={{   color: '#0F172A',
          fontFamily: 'MyFont',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap', }}
          >
              {person.attributes.personal_information?.first_name + " " + person.attributes.personal_information?.last_name}
            </Typography>
            <Typography style={{
                color: '#64748B',
                fontFamily: 'MyFont',
                fontWeight: 500,
                fontSize: 12,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>
              {person.attributes.account_details?.role?.name}
            </Typography>
          </Box>
          </Link>
          <Box style={{marginInlineEnd:"20px"}}>
            {connectionStatus === 'accepted' ? (
              <Button
                style={{
                  backgroundColor: '#becec7',
                  color: person.status === 'My Circle' ? '#14362E' : '#FFFFFF',
                  borderRadius: '7px',
                  textTransform: 'capitalize',
                  fontWeight: 700,
                  width: '160px',
                }}
              >
                My Circle
              </Button>
            ) : connectionStatus === 'pending' ? (
              <Button
                style={{
                  backgroundColor: 'rgb(123, 11, 111)',
                  padding: '6px 12px',
                  borderRadius: '7px',
                  textTransform: 'capitalize',
                  textAlign: 'center',
                  width: '160px',
                  color: '#FFFFFF',
                  fontWeight: 700,
                }}
                onClick={() => handlePendingClick()}
              >
                Pending
              </Button>
            ) : (
              <Button
                ref={el => inviteRefs.current[index] = el}
                onClick={() => handleInvite(index)}
                style={{
                  backgroundColor: '#14362E',
                  color: '#FFFFFF',
                  borderRadius: '7px',
                  textTransform: 'capitalize',
                  fontWeight: 700,
                  width: '160px',
                }}
              >
                Invite to My Circle
              </Button>
            )}
          </Box>
        </ListItem>
      </Box>
    );
  };

  const deleteSearchHistory = (item:any) => {
    props.handleDeleteSearchHistory(item)
  }

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      localStorage.setItem("dynamicwidth", window.innerWidth.toString())
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    localStorage.setItem("dynamicwidth", window.innerWidth.toString());

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width]);
  return <Box sx={{ bgcolor: "#fff", position: "relative", display: {xs: props.component === 'Home' ? 'block':'none', md: 'block', }, paddingTop: '76px' }}>
    <AppBar position="static" style={{ background: "white", position: "fixed", top: "0", display: props.component == "Add" && width < 500 ? "none" : "block" }}>
      <Toolbar className={classes.container}>
        <Box className={classes.leftHeader}>
          <Box className={classes.mainlogo} onClick={() => Logoredirect()}>
            <img src={Headerlogo} alt="" />
          </Box>
          <Box className={classes.feeds}>
            <Box className={classes.icon} onClick={() => Homeredirect()}>
              <img src={newhome} alt="" style={{ border: props.component == "Home" ? "1px solid #E0B667" : "", borderRadius: "50%", padding: "7px", color: "#64748B" }}/>&nbsp;&nbsp;
              <Typography className={classes.property}>Home</Typography>
            </Box>
            <Box className={classes.icon}>
              <Link to="/AllEvents" style={{textDecoration:"none",color:"black",display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={event} style={{ border: props.component == "Events" ? "1px solid #E0B667" : "", borderRadius: "50%", padding: "0px", color: "#64748B" }} alt="" />&nbsp;&nbsp;
              <Typography className={classes.property}>Events</Typography>
              </Link>
            </Box>
            <Box className={classes.icon} onClick={() => redirectexplore()} >

                <img src={exploreicon} style={{ color: "#64748B", fontSize: "18px", border: props.component == "explore" ? "1px solid #E0B667" : "", borderRadius: "50%", padding: "5px" }} alt="" />&nbsp;&nbsp;

              <Typography className={classes.property} style={{
                display: 'flex',
                justifyContent: props.component == "Add" ? "space-between" : "space-around",
                alignItems: "center", width: "70px",
                color:"#334155"
              }} >Explore</Typography>

            </Box>
          </Box>
          <Box className={classes.Addfeeds} >
            <Typography className={classes.property} style={{
              display: 'flex',
              justifyContent: props.component == "Add" ? "space-between" : "space-around",
              alignItems: "center", width: "70px"
            }} onClick={() => postredirect()}><span className={props.component == "Add" ? classes.propertyAddfeeds : classes.AddfeedsWidth}><i className="fa-solid fa-plus" style={{color:"#64748B"}}></i></span>Add</Typography>
          </Box>

        </Box>
        <Box className={classes.rightHeader}>
          <Box>
          {
             window.location.pathname.includes(`/newsfeed`) ||   window.location.pathname.includes(`/newsFeed`) ||  window.location.pathname.includes(`/NewsFeed`) ? 
             <Box className={classes.searchbox}>
             <Box>
               <i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;
               <input type="text" name="" className={classes.inputbox} placeholder='Search' id=""
                 ref={searchInputRef}
                 onFocus={() => setIsInputFocused(true)}
                 onClick={toggleSearchFocus}
                 onKeyPress={(event) => {
                   if (event.key === 'Enter') {
                     handleApplyFilters()
                   }
                 }} 
                 onChange={(e) => {
                   const newSearchValue = e.target.value;
                   setFilterParams((prevState) => ({
                     ...prevState,
                     search: newSearchValue,
                   }))
                 }}
                 value={filterParams.search} />
             </Box>

             <i className="fa-solid fa-arrow-up-wide-short" style={{ color: "#0F172A" }} onClick={() => handleFilterClick()}></i>
           </Box> : ""
          } 
            {isInputFocused && (
              <MenuBox>
              <BoxWithRef ref={dropdownRef} className={"searchDropdown"}>
                <Box display="flex" flexDirection="column" alignItems="center" style={{overflowX:"auto"}}>
                  <Box style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                    width: "auto",
                    backgroundColor: "#f2fff2",
                    borderRadius: "20px",
                    justifyContent: "space-between",
                  }}
                  sx={webStyle.searchFilter}>
                    <Button
                      data-test-id="peopleButton"
                      style={getButtonStyle("People")}
                      onMouseDown={() => setView("People")}
                    >
                      People
                    </Button>
                    <Button
                      data-test-id="postsButton"
                      style={getButtonStyle("Posts")}
                      onMouseDown={() => setView("Posts")}
                    >
                      Posts
                    </Button>
                    <Button
                      data-test-id="postsButton"
                      style={getButtonStyle("Events")}
                      onMouseDown={() => setView("Events")}
                    >
                      Events
                    </Button>
                    <Button
                      data-test-id="recommendationButton"
                      style={getButtonStyle("Recommendations")}
                      onMouseDown={() => setView("Recommendations")}
                    >
                      Recommendations
                    </Button>
                  </Box>
                </Box>
                <Box style={{ marginLeft: "5%", color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: "MyFont", letterSpacing: "0.05em", width: "100%" }}>
                  Search History
                  <List style={{ marginTop: "10px" }}>
                    {filteredSearchHistory.map((item: any, index: number) => (
                      <ListItem onClick={() => handleSearchHistoryClick(item)}
                        key={index}
                        style={{
                          fontFamily: "'MyFont', 'Halyard Display'",
                          display: "flex",
                          alignItems: "flex-start",
                        }}>
                        <Box style={{ display: "flex", width: "85%", backgroundColor: "", paddingBottom: "8px", borderBottom: "1px solid #E2E8F0", }}>
                          <Box><img src={searchVectorImg} alt='SearchVectorImage' /></Box>
                          <Box
                            style={{
                              width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"
                            }}>
                            <Box style={{ marginLeft: "10px", fontFamily: "MyFont", letterSpacing: "0.05em", color: "#0F172A", fontSize: "14px", fontWeight: 500, }}
                            >
                              {item}
                            </Box>
                            <CloseIcon
                              style={{ width: "22px", height: "18px", color: "rgb(155 169 189)", cursor: "pointer" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                deleteSearchHistory(item);
                              }}
                            />
                          </Box>

                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </Box>
                {props.dummyPeopleData.length > 0 && filterParams.search_type === 'People' && (
                  <Box style={{ marginLeft: "5%", color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: "MyFont", letterSpacing: "0.05em", maxHeight:"380px" , overflowY:"auto"}}>
                    People
                    <List style={{ marginTop: "10px" }}>
                      {props.dummyPeopleData && props.dummyPeopleData.map((person: any, index: number) => (
                        <YourComponent
                          person={person}
                          index={index}
                          handleInviteClick={handleInviteClick}
                        />
                      ))}
                    </List>

                  </Box>
                )}
                {
                  EventDataresponse.length > 0 && (
                    <>
                     <Box style={{ marginLeft: "5%", color: "#475569", fontSize: "14px", fontWeight: 500, fontFamily: "MyFont", letterSpacing: "0.05em", maxHeight:"380px" , overflowY:"auto"}}>
                      Events
                    <List style={{ marginTop: "2px" }}>
                      {EventDataresponse && EventDataresponse.map((element: any, index: number) => (
                        <Box style={{cursor:"pointer"}}>
                          <Link to="/AllEvents" style={{textDecoration:"none",color:"black"}} onClick={()=>setEventData(element)}>
                          {element.attributes.title}
                          </Link>
                        </Box>
                      ))}
                    </List>

                  </Box>
                    </>
                  )
                }
              </BoxWithRef>
              </MenuBox>
            )}
          </Box>
          <Box className={classes.righttext}>
            <Box className={classes.righticon}>
              <Box>
                {
                  usermainnotification !== 0 && (
                    <>
                      <Box style={{
                        backgroundColor: '#f50057',
                        borderRadius: '50%',
                        color: 'white',
                        padding: '0 6px',
                        width: '10px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginRight: '8px',
                        position: "absolute",
                        right: "25",
                        top: "-8px",
                      }}>
                        {usermainnotification}
                      </Box>
                    </>
                  )
                }
                <Box onClick={() => notificationredirect()} style={{width:'31px', height:'31px', cursor: 'pointer',background: props.component === 'Notifications' ? 'linear-gradient(180deg, #E0B667 0%, #F7D18A 20.5%, #FBE6C0 47%, #F7D18A 72%, #FBE6C0 100%)': '#73A58E', borderRadius: "50%",padding:'1px'}}>
                  <Box style={{width:'100%',height:'100%',background: 'white', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems: 'center' }}>
                   <img src={bell} style={{width:"17px"}} alt="" />
                  </Box>
                </Box>
              </Box>
              <Box onClick={() => chatredirect()} style={{ border:props.component == "chat"?"1px solid #e0b667": "1px solid #73A58E", borderRadius: "50%", width: "31px", height: "31px",cursor: "pointer", display:'flex', justifyContent:'center', alignItems:'center',  }}>
                <img src={newchat} style={{width:"20px"}} alt="" />
              </Box>
            </Box>
            <Box className={classes.avtardata} onClick={() => myprfileredirect()}>
              <Box className={`${classes.avtar} ${props.component === 'profile' && 'activeAvatar'}`}>
                <Avatar src={userprofile?.attributes?.personal_information?.profile_picture?.url && userprofile?.attributes?.personal_information?.profile_picture?.url}></Avatar>
              </Box>
              <Box style={{ marginLeft: "4px" }}>
                <Typography style={{ fontFamily: "MyFont", letterSpacing: "1.2px", fontWeight: 700, fontSize: "13px" }}>
                  {userprofile?.attributes?.personal_information ? (() => {
                    let firstName = userprofile.attributes.personal_information.first_name || '';
                    let lastName = userprofile.attributes.personal_information.last_name || '';

                    if ((firstName.length + lastName.length) >= 13) {
                      firstName = firstName.substring(0, 6);
                      lastName = lastName.length > 5 ? `${lastName.substring(0, 5)}...` : lastName;
                    }
                    return `${firstName} ${lastName}`.trim();
                  })() : ''}
                </Typography>


                <Typography style={{ fontFamily: "MyFont", letterSpacing: "1.2px", fontWeight: 500, fontSize: "13px",color:"#14362E" }}>My Circle <span style={{ color: "#14362E", fontSize: "12px" }}>{userprofile.attributes?.my_circle_count !== undefined
                  ? `(${userprofile.attributes.my_circle_count})`
                  : ''}</span></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* mobile view header */}
        <Box className={classes.mobilerighttext}>
          <i className="fa-regular fa-bell" onClick={() => notificationredirect()} style={{ border: "1px solid #73A58E", borderRadius: "50%", padding: "7px", fontSize: "20px", cursor: "pointer" }}></i>
          <Box style={{ display: "flex" , flexDirection: "column",justifyContent: "center",  
                      alignItems: "center",             

          }}>
          <Typography style={{ fontWeight: 600, fontSize: "20px", fontFamily: "MyFont", letterSpacing: "1px",color:"#14362E" }} onClick={() => Homeredirect()}>My Circle&nbsp;<sub style={{ fontSize: "9px", fontWeight: 400, color: "#065F46", fontFamily: "MyFont", letterSpacing: "1px" }} >{userprofile.attributes?.my_circle_count !== undefined
            ? `(${userprofile.attributes.my_circle_count})`
            : ''}</sub></Typography>
             
          </Box>

          <Box onClick={() => chatredirect()} style={{ border: "1px solid #73A58E", borderRadius: "50%", width: "20px", height: "20px", padding: "7px", cursor: "pointer" }}>
            <img src={ChatImg} style={{ width: "100%", height: "100%" }} alt="" />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
    
    <SearchbarBox style={webStyle.searchBar}>
      <Box style={{ padding: "2px", border: "1px solid gray", borderRadius: "6px", 
      width: "90%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <i className="fa-solid fa-magnifying-glass" style={webStyle.searchimg}></i>&nbsp;&nbsp;
        <input type="text" name="" className={classes.inputbox} placeholder='Search' id=""
          ref={searchInputRef}
          onFocus={() => setIsInputFocused(true)}
          onClick={toggleSearchFocus}
          style={{ width: "100%"}}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleApplyFilters()
            }
          }}
          onChange={(e) => {
            const newSearchValue = e.target.value;
            setFilterParams((prevState) => ({
              ...prevState,
              search: newSearchValue,
            }))
          }}
          
          value={filterParams.search} />
            <IconButton >
                <CloseIcon onClick={() => clearSearch()} /> 
            </IconButton>
      </Box>
      <i className="fa-solid fa-arrow-up-wide-short" style={{ color: "#0F172A", padding: "10px 12px" }} onClick={() => handleFilterClick()}></i>

    </SearchbarBox>
    {renderPeopleDrawer()}
    {renderPostsDrawer()}
    {renderRecommendationsDrawer()}
  </Box>
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "30%",
    borderTopLeftRadius: "20px",
    [theme.breakpoints.down('sm')]: {  // For small screens and down
      width: "100%",   // Full width on small screens
    },
  },
}));

const DrawerResponsive = styled(Box)({
    "& .drawerPaperCss":{
        width: "30%",
        borderTopLeftRadius: "20px",
    },
    "& .filterButton" : {
     
      border: "2px solid red"
    },
    "& .sgfsdgf" : {
            color:"red important" 
    },
    "@media(max-width: 600px)": {
      "& .drawerPaperCss":{
        width: "100%",
        borderTopLeftRadius: "20px",
     },
     "& .filterButton" : {
      width: "82%", height: "56px", position: 'fixed', bottom: 0, paddingLeft: '20px', paddingRight: "20px" 
      },
    }
})

const MenuBox = styled(Box)({
  "& .searchDropdown": {
    position: 'absolute',
    top: '50px',
    left: 0,
    width: '400px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 1)',
    borderRadius: '10px',
    padding: '10px',
    marginTop: "30px",
    marginLeft: "50%",
    zIndex: 1000,

  },
  "@media(max-width: 600px)": {
    "& .searchDropdown": {
      position: 'absolute',
      top: '120px',
      left: 0,
      width: '95%',
      backgroundColor: '#fff',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 1)',
      borderRadius: '10px',
      padding: '10px',
      marginTop: "30px",
      marginLeft: "0%",
      zIndex: 1000,
  
    }
  }
});

const webStyle = {
  searchBar:{
    display:"flex",
    margin:"20px"
  },
  searchimg:{
    padding:"8px 5px 8px 8px"
  },
  searchFilter:{
    marginLeft:"0px",
    "@media(max-width: 370px)": {
      marginLeft:"90px"
    }
  }
}

const SearchbarBox = styled(Box)({
    display:"none !important",
    "@media(max-width: 959px)": {
      display:"flex !important"
      
    },
    "& .MuiIconButton-root" :{
      padding:"0",
    },
    "& .makeStyles-inputbox-5":{
      width:"77%"
    }
});

const FilterBox = styled(Box)({
  "& .FilterBoxBtn": {
    "@media(max-width: 430px)": {
      width:"380px"
    },
    "@media(max-width: 390px)": {
      width:"340px"
    }
  },
});

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '76px',
    color: "black",
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      // height: "40px",
      display: "block",
      width: 'auto',
    },
  },
  // left header style
  leftHeader: {
    display: 'flex',
    color: "black",
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '46%',
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  mainlogo: {
    cursor: "pointer"
  },
  inputbox: {
    border: "none",
    outline: "none",
    fontFamily: "MyFont",
    letterSpacing: "1px"
  },
  feeds: {
    fontFamily: "MyFont !important",
    width: "400px",
    display: "flex",
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  Addfeeds: {
    width: "60px",
    cursor: "pointer"
  },
  AddfeedsWidth: {
    fontFamily: "MyFont",
    letterSpacing: "1.3px",
    fontSize: "17px"
  },
  icon: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  property: {
    fontFamily: "MyFont",
    letterSpacing: "1.3px",
    fontSize: "17px",
    color:"#334155"
  },
  propertyAddfeeds: {
    border: "1px solid #E0B667",
    borderRadius: "50%",
    padding: "7px",
    color: "#64748B"
  },
  exploreFeeds: {
    border: "1px solid #E0B667",
    borderRadius: "50%",
    // padding: "2px",
    color: "#64748B"
  },
  // right header sty;e
  rightHeader: {
    // border: "2px solid black",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%"
  },
  searchbox: {
    fontFamily: "MyFont",
    background: "white",
    border: "1px solid #CBD5E1",
    borderRadius: "10px",
    color: "#CBD5E1",
    height: "40px",
    width: "300px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
 
  righttext: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
    gap:'12px',
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  avtar: {
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
    '&.activeAvatar': {
      background: 'linear-gradient(180deg, #E0B667 0%, #F7D18A 20.5%, #FBE6C0 47%, #F7D18A 72%, #FBE6C0 100%)',
      padding: '2px',
      boxSizing: 'border-box',
      borderRadius: '50%'
    }
  },
  avtardata: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingRight: "12px",
    width: "147px",
    cursor: 'pointer'
  },
  righticon: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    gap:'16px',
    fontSize: "20px",
  },

  // mobile view header
  mobilerighttext: {
    display: "none",
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "100%"
    },
  },
}));
