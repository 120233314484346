import React from "react";

import {
    Container,
    Button,
    Box,
    Typography,
    Tabs,
    Tab,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import QrCodesController, {
    Props,
    configJSON,
} from "./QrCodesController";
import { styled } from "@material-ui/styles";
import { AppHeader } from "../../../components/src/AppHeader.web";
import QrCodeCamScan from "../../../components/src/QrCodeCamScan.web";
import { AppFooter } from "../../../components/src/AppFooter.web";

export default class QrCodes extends QrCodesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <AppHeader />
                <Box sx={{ ...webStyle.qrWrapper }}>
                    <Box sx={{ ...webStyle.mainBox, 
                        borderRadius: "16px", width: "816px", height: "auto", margin: "30px 0 22px" }}>
                        <Box>
                            <Box sx={{ ...webStyle.titleHeader }}>
                                <ArrowBackIcon style={{
                                    cursor: "pointer"
                                }} onClick = {()=> this.props.navigation.goBack()}/>
                            <Typography style={{ fontWeight: 600, fontSize: "20px",
                             color: "#14362E", lineHeight: "28px" }}>Share Profile</Typography>
                             </Box>

                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Box sx={{...webStyle.tabs, display: "flex", justifyContent: "center", marginBottom: this.state.selectedTab === "qrScanTab" ? "0px" : "40px",  borderRadius: "50px", padding: "5px 14px" }}>
                                    <CustomTab1
                                        data-testid="custom-tab-qr-code"
                                        style={{
                                            border: this.state.selectedTab === "qrCodeTab" ?
                                                "1px solid #D4E5DE" : "0px",
                                        }}
                                        onClick={() => this.setState({ selectedTab: "qrCodeTab" })}
                                    >My Code</CustomTab1>
                                    <CustomTab2
                                        data-testid="custom-tab-qr-code2"
                                        style={{
                                            border:
                                                this.state.selectedTab === "qrScanTab" ?
                                                    "1px solid #D4E5DE" : "0px"
                                        }}
                                        onClick={() => this.setState({ selectedTab: "qrScanTab" })}
                                    >Scan a Code</CustomTab2>
                                </Box>
                            </Box>
                            {this.state.selectedTab === "qrCodeTab" && <>
                                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                                    <Box sx={{ ...webStyle.qrCodeImg, width: "380px", height: "380px", display: "flex" }}>
                                        <img src={this.state.getQrAllData?.attributes?.qr_code?.url} data-testid="qr-code-img" />
                                    </Box>
                                </Box>

                                <Box sx={{ marginBottom: "60px", display: "flex", justifyContent: "center" }}><Typography style={{ fontSize: "14px", lineHeight: "22px", textAlign: "center", color: "#0F172A", fontWeight: "600" }}>SCAN QR CODE TO SHARE YOUR PROFILE <br />WITH OTHER USERS</Typography></Box>

                                <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "100px" }}>
                                    <Button variant="outlined" style={{ textTransform: "capitalize", color: "#14362E", padding: "12px 80px", fontWeight: "600", fontFamily: "MyFont", border: "1px solid #14362E" }} onClick={this.handleCopy}>
                                        <FileCopyOutlined /> Copy Profile Link
                                    </Button>
                                </Box>
                            </>}
                            {this.state.selectedTab === "qrScanTab" && <>
                                <QrCodeCamScan 
                                getScanUserQrData={this.getScanQrUserDataApiCall}
                                scanQrUserData={this.state.getScanQrUserData}
                                isLoading={this.state.isGetScanQrUserDataLoading}
                                />
                            </>}
                        </Box>
                    </Box>
                </Box>
                <AppFooter />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainBox: {
        bgcolor: "white" ,
        '@media (max-width: 560px)': {
            width: "100%",
            height: "100%",
            bgcolor: "#F8FAFC",
            margin: "10px 0 22px"
        }
    },
    titleHeader: {
        display: "flex",
        gap: "40%",
        padding: "10px",
        margin: "14px 0 32px 0",
        '@media (max-width: 560px)': {
            gap: "30%",
            margin: 0,
        },
    },
    qrCodeImg: {
        '@media (max-width: 560px)': {
            width: "250px",
            height: "250px"
        }
    },
    qrWrapper: {
        bgcolor: "#F8FAFC" ,display: "flex", justifyContent: "center",
        '@media (max-width: 560px)': {
            bgcolor: "white"
        }
    },
    tabs: {
        bgcolor: "#F8FAFC",
        '@media (max-width: 560px)': {
            bgcolor: "white"
        }
    }
    
};

const CustomTab1 = styled(Button)({
    borderRadius: '50px',
    width: "158px",
    fontWeight: 700,
    fontSize: "16px",
    textTransform: "initial",
    "@media(max-width: 380px)": {
        fontSize: "14px",
      },
});
const CustomTab2 = styled(Button)({
    borderRadius: '50px',
    width: "156px",
    fontWeight: 700,
    fontSize: "16px",
    display: "none",
    textTransform: "initial",
    "@media(max-width: 600px)": {
        display: "block"
    },
    "@media(max-width: 380px)": {
        fontSize: "14px",
    },
});
// Customizable Area End
