import React from "react";
import { Box, Button, Modal } from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const webStyle = {

    inputspace:{
      marginLeft:"5px",
      padding: '14px',
    },
    searchinputspace:{
      width:"99%",
      lineHeight: '24px',
      padding: '11px 4px',
      border: 'none',
      outline: 'none',
      borderRadius: '7px',
      fontSize: '16px',
      fontFamily:  'MyFont'
    },
    searchbox: {
      // marginTop: "50px",
      borderRadius: "7px",
      fontFamily: "MyFont",
      background: "white",
      border: "1px solid #CBD5E1",
      color: "#CBD5E1",
      height: "40px",
      width: "86%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "50px auto auto auto",
      padding: "0px 10px"
    },
  
    recipBox: {
      display: 'flex', alignItems: 'center', gap: "1", justifyContent: "center"
    },
  
    listItemCss: {
      fontSize: "16px",
      fontWeight: "500",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      fontFamily: "MyFont"
    },
    smileIconCss: { paddingRight: "0px", cursor: "pointer", marginRight: "70px"},
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      left: { xs: "50%", md: "50%" },
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      width: { xs: "70%", md: "25%" },
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      boxShadow: 24,
      p: 4,
    },
  
    modalStyleTypo: {
      fontSize: "24px",
      fontWeight: "600",
      color: "#14362E",
      marginTop: "5%",
      width: { xs: "90%", md: "70%" },
      fontFamily: "'MyFont', 'Halyard Display'",
      textAlign: "center",
      letterSpacing: "0.05em"
    },
    modalStyleTypoSecond: {
      fontSize: "14px",
      marginTop: "3%",
      color: "#0F172A",
      width: "100%",
      fontFamily: "'MyFont', 'Halyard Display'",
      textAlign: "center",
      letterSpacing: "0.08em"
    },
    checkCircleCss: {
      width: " 66.67px",
      height: "66.67px",
      color: "#73A58E"
    },
  
    contactDrawerSubmitBtn: {
      height: "45px",
      color: "white",
      fontWeight: 700,
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "'MyFont', 'Halyard Display'",
      letterSpacing: "1px",
      marginTop: "20px",
      width: "80%",
    },
    categoryCard : { maxWidth: 850, marginBottom: "25px", width: "100%", marginTop: "20px", fontFamily: "MyFont"},
    inputparent: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    searchinput: {
      width: "100%",
      border: "none",
      outline: "none"
    },
    tabStyle: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      backgroundColor: "#F8FAFC",
      margin: "auto",
      marginTop: "20px",
      borderRadius: "100px",
      width: "350px",
      paddingBottom: "12px",
      paddingTop: "12px",
      marginBottom: "20px"
    },
    openGridBox: {
      display: "grid", 
      gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", 
      gap: "15px", 
      alignItems: "center",
      padding: "20px"
    }, 
    trending: {
      // width: "50%",
      padding: "3px 6px",
      borderRadius: 25,
      textAlign: "center",
      cursor: "pointer",
      fontFamily: "MyFont",
      letterSpacing: "0.05em",
      color: "#64748B"
    },
  
    categoryDropdown: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px'
    },
    trendingselected: {
      border: "1px solid #73A58E",
      // width: "50%",
      padding: "6px 8px",
      borderRadius: 25,
      textAlign: "center",
      cursor: "pointer",
      fontFamily: "MyFont",
      letterSpacing: "0.05em",
      fontWeight: "600",
      color: "var(--900, #14362E)"
    },
    recommendationtab: {
      // border: "2px solid #73A58E",
      // width: "50%",
      borderRadius: "100px",
      cursor: "pointer",
      fontFamily: "MyFont",
      padding: "4px 6px",
      letterSpacing: "0.05em",
      fontWeight: "bold",
      color: "#64748B"
    },
    recommendationtabselected: {
      borderRadius: "100px",
      cursor: "pointer",
      fontFamily: "MyFont",
      padding: "4px 6px",
      letterSpacing: "0.05em",
      fontWeight: "500",
      color: "black",
      border: "1px solid #73A58E",
    },
    titlename : { fontFamily: "MyFont", letterSpacing: "0.8px" },
    foryou: {
      // width: "100px"
      borderRadius: "100px",
      cursor: "pointer",
      fontFamily: "MyFont",
      padding: "2px 6px",
      letterSpacing: "0.05em",
      fontWeight: "bold",
      color: "#64748B"
    },
    cardflexbox: { display: "flex", justifyContent: "space-between" },
    foryouselected: {
      borderRadius: "100px",
      cursor: "pointer",
      fontFamily: "MyFont",
      padding: "2px 6px",
      letterSpacing: "0.05em",
      fontWeight: "bold",
      color: "black",
      border: "1px solid #73A58E",
    }
    ,
    categoryDropwdown: {
      margin: "15px",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "12px",
      fontFamily: "MyFont"
    },
    borderBottomstyle: {
      border: "1px solid #E2E8F0"
    },
    drawer: {
      width: "540px",
      display: "block", // Default value for 'xs'
      '@media (min-width: 600px)': {
        display: "none", // For 'sm'
      },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        width: "100%",
      },
    },
    // mainforyouparent
    mainforyouparent: {
      padding: "10px",
      marginBottom: "25px"
    },
    cityTYpeBox :{
      width: "41%",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      padding: "10px",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer'
    },
    selectedItems : { fontFamily: "MyFont", letterSpacing: "0.8px", color: "#475569" },
    mainHeader: {
      background: "#14362E",
      color: "white",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: "6px",
    },
    innercontentParent: {
      marginTop: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      // flexWrap: "wrap",
      marginBottom: "10px",
  
    },
    innercontent: {
      borderRadius: "10px",
      border: "1px solid #73A58E",
      padding: "10px",
      width: "46%",
      '@media (max-width: 400px)': {
        width: "100%", // For 'sm'
      }
    },
    inercontentheader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headercontent: {
      fontFamily: "MyFont",
      fontWeight: "bold",
      letterSpacing: "0.8px",
      color: "#14362E",
      fontSize: "22px"
    },
    headersecondcontent: {
      color: "#73A58E",
      fontSize: "13px",
      fontWeight: '600',
      fontFamily: "MyFont !important",
      letterSpacing: "0.5px",
    },
    innermaincontenttext: {
      color: "#0F172A",
      fontSize: "14px",
      fontFamily: "MyFont",
      letterSpacing: "0.7px",
    }
  }

  export const formSubmittedSuccessfully = (successModalOpen: boolean, handleCloseSuccessModal: React.MouseEventHandler<HTMLButtonElement> | undefined, closeOkayModal: React.MouseEventHandler<HTMLButtonElement> | undefined) => {
    return (
      <Modal
        data-test-id="testmodalclose"
        open={successModalOpen}
        onClose={handleCloseSuccessModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
          <CheckCircleOutlineOutlinedIcon style={webStyle.checkCircleCss} />
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            Report Added
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
            Thank you for added report for  harmful content. Our moderation team will be looking into it
          </Box>
          <Button
            data-test-id="closeReportPop"
            fullWidth
            variant="contained"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
            onClick={handleCloseSuccessModal}
          >
            Okay
          </Button>
        </Box>
      </Modal>
    );
  }

  export const  blockedSuccessfully = (openBlockedModal: boolean, closeOkayModal: React.MouseEventHandler<HTMLButtonElement> | undefined) => {
    return (
      <Modal
        open={openBlockedModal}
        onClose={closeOkayModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box sx={{ ...webStyle.modalStyle, borderRadius: "0 10px 0 0" }}>
          <Box sx={{ ...webStyle.modalStyleTypo }}>
            User Blocked
          </Box>
          <Box sx={{ ...webStyle.modalStyleTypoSecond }}>
            They won't be able to find your profile or posts. We won't let them know you blocked them.
          </Box>
          <Button
            fullWidth
            variant="contained"
            data-test-id="closeReportPop"
            style={{
              ...webStyle.contactDrawerSubmitBtn,
              position: "relative",
              textTransform: "capitalize",
              backgroundColor: "#14362E"
            }}
            onClick={closeOkayModal}
          >
            Understand
          </Button>
        </Box>
      </Modal>
    );
  }