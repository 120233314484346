import React from 'react';
import { Box, Checkbox, Typography, styled } from "@material-ui/core"
type TProps = {
    label: string;
    handleClick: () => void;
    isChecked: boolean
}
const SelectebleListItem = ({label, handleClick, isChecked}: TProps) => {
    return (
        <StyledItem key={label} onClick={handleClick}>
            <StyledLabel>{label}</StyledLabel>
            <StyledCheckbox checked={isChecked} size="small"/>
        </StyledItem>
    )
}

const StyledLabel = styled(Typography)({
    fontFamily:'MyFont',
    fontSize: '14px',
    lineHeight: '22px',
    color: '#475569',
    letterSpacing: '0.08em',
    cursor:'pointer'

}) 
const StyledItem = styled(Box)({
    display:'flex',
    justifyContent: 'space-between',
    borderBottom:"1px solid #CBD5E1",
    paddingBottom:'8px',
    alignItems:'center'
})
const StyledCheckbox = styled(Checkbox)({
    bordrRadius:'',
    '&.Mui-checked': {
        color: '#73A58E'
    }
})
export default SelectebleListItem;