import React from 'react'
import { BottomNavigation, BottomNavigationAction,Box,Container } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom"

export function AppFooter(props:any) {
    const classes = styles();

    const redirectexplore = () => {
        if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
          window.location.replace("/ExploreBlock")
        } else {
          window.location.href = "ExploreBlock"
    
        }
      }
    
      const myprfileredirect = () => {
        if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
          window.location.replace("/UserProfileBasicBlock")
        } else {
          window.location.href = "UserProfileBasicBlock"
    
        }
      }
    
      const postredirect = () => {
        if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
          window.location.replace("/PostCreation")
        } else {
          window.location.href = "PostCreation"
        }
      }
    
      const Homeredirect = () => {
        if (window.location.pathname.includes(`/UserProfileBasicBlock/`)) {
          window.location.replace("/newsFeed")
        } else {
          window.location.href = "newsFeed"
        }
      }
    


    return (
        <>
        <Box className={classes.container}>
            <BottomNavigation style={{position:"fixed",bottom:"0",width:"100%"}} value="test">
                <BottomNavigationAction
                    label="Recents"
                    value="recents"
                    icon={<i className="fa-solid fa-house" onClick={Homeredirect} style={{border:props.component == "Home" ? "1px solid #E0B667":"",borderRadius:"50%",cursor:"pointer",padding:"7px",fontSize:"18px"}}></i>}
                />
                <BottomNavigationAction
                    label="Favorites"
                    value="favorites"
                    icon={<i className="fa-regular fa-compass" style={{border:props.component == "explore" ? "1px solid #E0B667":"",borderRadius:"50%",cursor:"pointer",padding:"7px",fontSize:"18px"}} onClick={redirectexplore}></i>}
                />
                <BottomNavigationAction
                    label="Nearby"
                    value="nearby"
                    icon={<i className="fa-solid fa-plus" onClick={postredirect} style={{fontSize:"18px",border:props.component == "Add" ? "1px solid #E0B667":"",
                    borderRadius:"50%",
                    padding:"7px",
                    color:"#64748B"}}>
                    </i>}
                />

                <Link to="/AllEvents" style={{marginTop:"-6px"}}>
                 <BottomNavigationAction
                    label="Nearby"
                    value="nearby"
                    icon={ <i className="fa-solid fa-calendar-days"  style={{fontSize:"18px",border:props.component == "Events" ? "1px solid #E0B667":"",
                    borderRadius:"50%",cursor:"pointer",padding:"7px"}}></i>}
                />
                </Link>
                 <BottomNavigationAction
                    label="Nearby"
                    value="nearby"
                    icon={ <i className="fa-solid fa-user" onClick={myprfileredirect} style={{fontSize:"18px",border:props.component == "profile" ? "1px solid #E0B667":"",
                    borderRadius:"50%",cursor:"pointer",padding:"7px"}}></i>}
                />
            </BottomNavigation>
            </Box>
        </>
    )
}

const styles = makeStyles((theme) => ({
    container: {
        display:"none",
        [theme.breakpoints.down('sm')]: {
            display: "block",
            position:"relative",
            width:"100vw",
            borderTop:"1px solid #E2E8F0"
        },
    }
}))