//@ts-nocheck
import React from "react";

// Customizable Area Start
import ResponsiveDrawer from "../../../components/src/ResponsiveDrawer.web";
import ShareDrawer from "../../../components/src/ShareDrawer.web";
import { formSubmittedSuccessfully, blockedSuccessfully } from "../../../components/src/CommonForms";
import { Card ,CardHeader,ListItem,TextField, Avatar, CardContent,Collapse, Typography,InputAdornment,List, Box, MenuItem, Menu, IconButton, styled, Drawer, Container, Button, Modal, Divider } from "@material-ui/core";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web"
import SelectebleListItem from "../../../components/src/SelectebleListItem";

import moment from "moment";
import { emptydataimg, LinkImg } from "./assets";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ReportIcon from '@material-ui/icons/Report';
import BlockIcon from '@material-ui/icons/Block';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Search from '@material-ui/icons/SearchOutlined'
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Toaster } from 'react-hot-toast';
import Carousel from "react-multi-carousel";
import {
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import EmojiPicker from "emoji-picker-react";

export interface Item {
  attributes: {
    availability: number;
    breadth: number;
    description: string;
    discount: string;
    height: number;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
    length: number;
    manufacture_date: string;
    name: string;
    on_sale: true;
    price: number;
    recommended: false;
    sale_price: string;
    sku: string;
    stock_qty: number;
    weight: string;
  };
  id: string;
  type: string
}

interface DrawerHeaderProps {
  title: string;
  onClose: () => void;
}

interface UserSuggestion extends SuggestionDataItem {
  profile_picture: string;
  email: string;
}

interface SearchInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
}

interface SelectedMembersProps {
  members: Array<{ value: string }>;
  onDelete: (index: number) => void;
}

interface ContinueButtonProps {
  onClick: () => void;
}
// Customizable Area End

import TrendingController, {
  Props,
  configJSON,
} from "./TrendingController.web";

 class Trending extends TrendingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  Comment=(element:any)=>{
    return element.attributes.comment_counts == "0" ? "Comment" : element.attributes.comment_counts
  }
  
  repliesCount = (element: any) => {
    return element.attributes.replies_counts == "0" ? "Reply": element.attributes.replies_counts;
  }

  cardData = (images , videos , isFavorite ,responsive, element, index) => {
             /*istanbul ignore next */
     return (
      <Card key={element} style={{ maxWidth: 750, fontFamily: "MyFont", width: "100%", marginTop: "0px", marginBottom: "25px",paddingTop:"0" }}>
      <Box style={webStyle.cardflexbox}>
      <CardHeader
        style={{ fontFamily: "MyFont", letterSpacing: "0.1px", paddingTop:"9px", cursor: 'pointer' }}
        avatar={
          <a href={`UserProfileBasicBlock/${element?.attributes?.account_id}`}>
          <Avatar src={element.attributes?.posted_user?.profile_picture?.url}>

          </Avatar>
          </a>
        }

        title={<div style={{ fontFamily: "MyFont", fontWeight: "700", letterSpacing: "1px", fontSize: "18px",paddingTop:"0" }}>{element.attributes.posted_user?.first_name + element.attributes.posted_user?.last_name} . <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>{element.attributes.posted_user?.role}</span></div>}
        subheader={<div style={{ fontSize: "14px", letterSpacing: "1px", fontWeight: "400", fontFamily: "MyFont" }}>{moment(element.attributes.updated_at).format('dddd, hh:mm A')} <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes?.category}</span></div>}
      />

<Box key={element.id} style={{ cursor: "pointer", textAlign: "right", display: 'flex' }}>
  {isFavorite && (
    <IconButton
      data-test-id="icon-button"
      edge="start"
      aria-haspopup="true"
      aria-controls={`menu-${index}`}
      aria-label="more"
      onClick={() => this.getToFavriouteData(element.id)}
    >
      <BookmarkIcon style={{ color: "#14362E" }} />
    </IconButton>
  )}

  <IconButton
    data-test-id="handleOpenMenu"
    edge="start"
    aria-haspopup="true"
    aria-controls={`menu-${index}`}
    aria-label="more"
    onClick={(event) => this.handleOpenMenu(event, element.id)}
  >
    <MoreVertIcon />
  </IconButton>
  {/* menu box start */}

  <MenuBox style={{ position: "relative" }}>
    <Menu
      key={index}
      anchorEl={this.state.openMenu}
      id={`menu-${index}`}
      open={this.state.openMenuId === element.id}
      onClick={this.handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      style={{
        marginTop: "34px",
        cursor: 'pointer'
      }}
    >
      <MenuItem
        data-test-id="addtofavBtn"
        onClick={() => this.getToFavriouteData(element.id)}
        style={{
          fontFamily: "myfont",
          cursor: "pointer",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "22px",
          letterSpacing: "0.08em",
          textAlign: "left",
        }}
      >
        {isFavorite ? (
          <>
            <BookmarkBorderIcon
              style={{
                height: "20px",
                fontWeight: "500",
              }}
            />
            Remove from favourites
          </>
        ) : (
          <>
            <BookmarkBorderIcon
              style={{
                height: "20px",
                fontWeight: "500",
                cursor: "pointer"
              }}
            />
            Add to favourites
          </>
        )}
      </MenuItem>
      { Number(element?.attributes?.account_id) !== Number(this.state.accountId) ? <MenuItem
        data-test-id="addtoReportBtn"
        onClick={(event) => this.handlePostReport(element.id)}
        style={{
          color: "#D1584C",
          fontFamily: "myfont",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "22px",
          letterSpacing: "0.08em",
          cursor: "pointer",
          textAlign: "left",
        }}
      >
        <ReportIcon
          style={{
            height: "20px",
            fontWeight: "500",
          }}
        />
        &nbsp; Report
      </MenuItem> : ""
  }
  {Number(element?.attributes?.account_id) !== Number(this.state.accountId) && <MenuItem
        data-test-id="addtoBlockBtn"
        onClick={(event) => this.handleBlockUser(element.attributes.account_id)}
        style={{
          color: "#D1584C",
          fontFamily: "myfont",
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "22px",
          letterSpacing: "0.08em",
          textAlign: "left",
        }}
      >
        <BlockIcon
          style={{
            height: "20px",
            fontWeight: "500",
          }}
        />
        &nbsp; Block user
      </MenuItem> }
    </Menu>
  </MenuBox>
</Box>
   </Box>    
      <Typography style={{
        fontSize: "14px", fontFamily: "MyFont", fontWeight: 500, padding: "11px", marginTop: "10px", letterSpacing: "1px",paddingTop:"0"
      }}>{element.attributes.body}</Typography>


      {
        (element.attributes.link_title && element.attributes.external_link) && (
          <>
            <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", padding: "4px", justifyContent: "space-between", alignItems: "center", marginBottom: "10px", marginTop: "10px", height: "60px", width: "95%", borderRadius: "10px", margin: "15px auto" }}>
              <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center",paddingTop:"0" }}>
                <img src={LinkImg} alt="" />
                <Box style={{ marginLeft: "10px",paddingTop:"0"}}>
                     <p>{element.attributes.link_title}</p>
                   </Box>
                 </Box>
                 <span style={{ fontSize: "20px", fontFamily: "MyFont", cursor: "pointer" }}>
                 {this.state.showCopiedMessage && <span style={{
                  backgroundColor: '#555',
                  color: '#fff',
                  textAlign: 'center',
                  borderRadius: '5px',
                  padding: '5px',
                  fontSize: '12px',
                }}>Link copied to clipboard!
                </span>}
                  <i className="fa-regular fa-copy"
                 onClick={() => {          
                  navigator.clipboard.writeText(element.attributes.external_link)
                      .then(() => {
                          this.setState({showCopiedMessage: true});
                          setTimeout(() => {
                            this.setState({showCopiedMessage: false});
                          }, 2000);
                      })
                      .catch(err => {
                          console.error('Failed to copy: ', err);
                      });
              }}></i>
              </span>
               </Box>
             </>
           )
         }



         <Carousel
           responsive={responsive}
           autoPlay={false}
           swipeable={true}
           draggable={true}
           showDots={false}
           infinite={false}
           partialVisible={false}
           dotListClass="custom-dot-list-style"
         >
           {images.map((media, index) => (
             <div style={{ height: "200px", display: "flex", alignItems: "center" }} key={`image-${index}`}>
               <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
             </div>
           ))}
           {videos.map((media, index) => (
             <div key={`video-${index}`}>
               <video style={{ width: "100%", height: "250px" }} controls>
                 <source src={media.url} type="video/mp4" />
                 Your browser does not support the video tag.
               </video>
             </div>
           ))}
         </Carousel>

         <CardContent>

           <Box style={{ fontFamily: "MyFont", display: "flex", justifyContent: "space-evenly", alignItems: "center", paddingTop: "0" }}>


          <Box>
            {element.attributes.is_liked ? (
              <>
                <i data-test-id="likecomment1" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
              </>
            ) : (
              <>
                <i  data-test-id="likecomment1" className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
               <span > {element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}</span>
              </>
            )}
               &nbsp;&nbsp;
             </Box>


             <Box data-test-id = "commentTrending" onClick={this.handleClick.bind(this, element.id)}>
               <i className="fa-regular fa-message" style={{cursor: 'pointer'}}></i>&nbsp;&nbsp;
               {this.Comment(element)}&nbsp;&nbsp;
             </Box>
             <Box onClick={this.drawerSidebaar.bind(this, element.id)}>
               <i className="fa-solid fa-arrow-up-from-bracket" style={{cursor: 'pointer'}}></i>&nbsp;&nbsp;
               Share
             </Box>
           </Box>
         </CardContent>
    </Card>
     )
  }

  drawerBox = () => {
    return (
      <>
      <Drawer  anchor="right" open={this.state.open} style={webStyle.drawer as any} onClose={this.toggleDrawer.bind(this, false, "")} >
          <BoxMain>
            <Box style={webStyle.categoryDropdown}>

              <Typography className="selecttype">Report Content</Typography>
              <span onClick={this.drawerClose}>
                <i className="fa-solid fa-xmark"></i>

              </span>
            </Box>
            <Box style={webStyle.borderBottomStyle}></Box>
            <Box className="flaggingcontent">Why are you flagging this content?</Box>
            <Container className="content-container">
              {
                this.state.reportListData.map((item: { reason: string, id: number, description: string }, index: number) => {
                  return <Box
                    className="typebox"
                    onClick={() => this.handleSelect(item, index)}
                    key={index}
                    data-test-id="reportSelectedId"
                    style={{ backgroundColor: this.state.selected === `${index}` ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box>
                      <Typography className="titlemyfont">{item.reason}</Typography>
                      <Typography className="titleReason">{item.description}</Typography>
                    </Box>
                    {this.state.selected === `${index}` && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />
            </Container>
            <Container className="button-container">
             <Box style={{width: '100%', display: 'flex', justifyContent: 'center'}}> <Button  disabled={this.state.reportId == 0} variant="contained" className="selectbtn" onClick={this.addedReport} data-test-id="continueBtn">Continue</Button></Box>
            </Container>
          </BoxMain>
        </Drawer>
        <ShareDrawer
          open={this.state.sidebaarOpen}
          handleClose={this.drawerSidebaarClose}
          handleSave={this.handlePostshare} 
          searchValue={this.state.setSearchvalue}
          hadnleChangeSearchValue={this.handlesearchTag}
          clearSerchValue={this.removesearchdata}
          selectedElements={this.state.selectedmember}
          handleDeleteSelected={this.handleselectmemberDelete}
          serachData={this.state.shareconnectiondata}
          handleCheckItem={this.getvalueconnectioncheck}
          messageValue={this.state.sharemessage}
          handleChangeMessageValue={this.handlewritemessage}
          disabled={this.state.postShareTrendingLoading}
        />
        <Drawer style={webStyle.drawer as any} anchor="right" open={this.state.blockUserSideBar} onClose={this.drawerBlockUserClose} >
            <BoxMain>
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px" }}>
                    <Typography className="selecttype">Block User</Typography>
                    <span onClick={this.drawerBlockUserClose}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </Box>
                <Box style={webStyle.borderBottomStyle}></Box>
                <Box className="flaggingcontent">Why are you blocking this user?</Box>
                <Box style={webStyle.borderBottomStyle}></Box>
                <Container className="content-container">
              {
                this.state.deletedResponseData.map((item: { id: number, title: string, description: string }, index: number) => {
                  return <Box
                    data-test-id="selectBlockMsg"
                    className="typebox"
                    key={index}
                    onClick={() => this.handleSelect1(item, index)}
                    style={{ backgroundColor: this.state.indexBlockList === index ? '#D4E5DE' : 'transparent' }}
                  >
                    <Box>
                      <Typography className="titlemyfont" >{item.title}</Typography>
                      <Typography className="titleReason">{item.description}</Typography>
                    </Box>
                    {this.state.indexBlockList === index && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                  </Box>
                })
              }
              <Divider />

            </Container>
            <Container className="button-container">
            <Box style={{width: '100%', display: 'flex', justifyContent: 'center'}}> <Button data-test-id="addedToBlock" variant="contained" className="selectbtn" onClick={this.addedToBlock}>Continue</Button></Box>
            </Container>
            </BoxMain>
            <Toaster />
        </Drawer>
          {blockedSuccessfully(this.state.openBlockedModal, this.closeOkayModal)}
          {formSubmittedSuccessfully(this.state.successModalOpen, this.handleCloseSuccessModal)}
          {this.renderDrawerfunction()}
         {this.renderSharefunction()}
      </>
    )
  }

  trending = () => {
         /*istanbul ignore next */
    return (
      <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        {
          this.state.list.length > 0 ? this.state.list.map((element: object, index: number) => {
            const combinedMedia = this.getMedia(element)
            const images = combinedMedia.filter(media => media.type === 'image');
            const videos = combinedMedia.filter(media => media.type === 'video');
            const isFavorite = element.attributes.is_favorite;
            const responsive = {
              desktop: {
                breakpoint: { max: 3000, min: 324 },
                items: 1,
                slidesToSlide: 1
              },
            };
            return (
              <>
          

                { this.cardData(images , videos , isFavorite ,responsive , element, index)}
              </>
            )
          }) : (
            <>
              <Card style={{ maxWidth: 750, marginBottom: '25px', width: "100%", marginTop: "20px", fontFamily: "MyFont", height: "60vh" }}>
                <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                  <span>Loading Data...</span>
                  <img src={emptydataimg}  alt="" style={{ width: "100px", height: "100px" }} />
                </CardContent>

              </Card>
            </>
          )
        }

        {this.drawerBox()}
      </Box>
    );
  };
           /*istanbul ignore next */
  
  forYouInsideElement = (secondelement) => {
    return (
      <div style={{display: 'flex'}}> 
      {
       secondelement?.attributes?.profiles?.accounts.map((eledata: object, index: number) => {
          return (
            <>
              {index < 5 && 
                <>{index < 4 && <div style={{ width: this.state.isSmallScreen ? "45px" : "55px", height: this.state.isSmallScreen ? "50px" : "60px", borderRadius: "50%", marginTop: this.state.isSmallScreen ? "5px" : "10px", backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '-9px'}}>
                  <img src={eledata.profile_picture?.url} alt="" style={{ width: this.state.isSmallScreen ? "40px" : "50px", height: this.state.isSmallScreen ? "40px" : "50px", borderRadius: "50%",  }} /></div>
                   }
                <>{ index == 3 && <div style={{ width: this.state.isSmallScreen ? "40px" : "50px", height: this.state.isSmallScreen ? "40px" : "50px", borderRadius: "50%", marginTop: this.state.isSmallScreen ? "10px" : "15px", backgroundColor: '#73A58E', color: 'white', display: 'flex', 
                justifyContent: 'center', alignItems: 'center'}}><span>+{secondelement?.attributes?.profiles.count}</span></div>
                }</>
                </>
              }
            </>
          )
        }) 
      }
      </div>
    )
  }
  
  foryou = () => {
    return (
      <>
        <Container>
          <Box style={webStyle.mainforyouparent as object}>
            {
             this.state.categoryData?.map((element: object, index: number) => {
                return (
                  <>
                    <BoxDetails>
                    <Box style={webStyle.mainHeader as object}>
                      <Typography style={{fontFamily:"MyFont",fontSize:"16px",fontWeight:600,letterSpacing:"0.05em",textTransform:"uppercase"}}>{element.attributes?.name}</Typography>
                      <Typography data-test-id="removeStorageNavigate" onClick={() => this.removeStorageNavigate(element?.id)} style={{ cursor: "pointer", fontFamily:"MyFont !important", fontSize:"12px", fontWeight:600, letterSpacing:"0.05em" }} >View all</Typography>
                    </Box>
                    <Box style={webStyle.innercontentParent} className="boxOuter">

                      {
                        element.attributes?.sub_categories.slice(0, 2).map((secondelement: object) => {
                          return (
                            <>
                              <Box style={webStyle.innercontent} className="boxinner">
                                <Box style={webStyle.inercontentheader}>
                                  <Typography style={webStyle.headercontent}>{secondelement.attributes.name}</Typography>
                                  <Typography data-test-id = "handleviewgroup" style={webStyle.headersecondcontent} onClick={this.handleviewgroup.bind(this, element.id, secondelement.id, secondelement.attributes?.name)} >View Group</Typography>
                                </Box>
                                <Box style={webStyle.innermaincontent}>
                                  <Typography style={{...webStyle.innermaincontenttext, marginTop: this.state.isSmallScreen ? "0px" : "10px", maxHeight: this.state.isSmallScreen ? "80px" : "auto"}}>{secondelement.attributes?.description}</Typography>
                                   {this.forYouInsideElement(secondelement)}
                                </Box>
                              </Box>
                            </>
                          )
                        })
                      }
                    </Box>

                    </BoxDetails>
                  </>
                )
              })
            }

          </Box>
        </Container>
      </>
    )
  }

  typeSidebar = () => {
    return (
    <ResponsiveDrawer title='Type' handleClose={this.drawerClose} open={this.state.opentest} button={{title: 'Save', handleClick: this.settypevalueintype, disabled: this.state.selectedType.length == 0}}>
      <Box sx={{display:'flex', flexWrap:'wrap', gap:'15px', pdading:'20px', margin:'25px 0'}}>
        {
          this.state.selectedType.map((element: object, index: number) => (
            <Box key={index} style={{ borderRadius: "20px", display: "flex", alignItems: "center", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
              <Typography style={{ fontSize: "14px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
              <span onClick={this.handleselectTypeDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
            </Box>
          ))
        }

      </Box>
      {this.state.recommendationType.map((element: object) =>(
        <SelectebleListItem isChecked={this.isTypeSelected(element)} label={element.value} handleClick={() => this.getvaluetyepcheck(element)}/>
      )
      )}
    </ResponsiveDrawer>)
  }
  citySidebar = () => {
    return (
    <ResponsiveDrawer title='City' handleClose={this.drawerClose1} open={this.state.openCity} button={{title: 'Save', handleClick: this.settypevalueincity, disabled: this.state.selectedCity.length == 0}}>
       <TextField 
          value={this.state.searchCityQuery}
          onChange={(e) => this.handleSearch(e.target.value)}
          placeholder="Search..."
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
      <Box sx={{display:'flex', flexWrap:'wrap', gap:'15px', pdading:'20px', margin:'25px 0' }}>
        {
          this.state.selectedCity.map((element: object, index: number) => (
            <Box key={index} style={{ borderRadius: "20px", display: "flex", alignItems: "center",  background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
              <Typography style={{ fontSize: "14px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
              <span onClick={this.handleselectCityDelete.bind(this, index)}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
            </Box>
          ))
        }
      </Box>
      {
        this.state.filteredCities.map((element: object) =>(
          <SelectebleListItem
            isChecked={this.isCitySelected(element)}
            label={element.value}
            handleClick={() => this.getvaluecitycheck(element)}
          />)
        )
      }
    </ResponsiveDrawer>)
  }
         /*istanbul ignore next */
  insiderecommendation = () => {
    return (
      <>
      {
        this.state.list.length > 0 ? this.state.list.map((element: object, index: number) => {
          const combinedMedia = this.getMedia(element)
          const images = combinedMedia.filter(media => media.type === 'image');
          const videos = combinedMedia.filter(media => media.type === 'video');
          const isFavorite = element.attributes.is_favorite;
          const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 324 },
              slidesToSlide: 1,
              items: 1,
             
            },
          };
          return (
            <>

              { this.cardData(images , videos , isFavorite ,responsive , element, index)}
            </>
          )
        }) : (
          <>
            <Card style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: "20px", fontFamily: "MyFont", height: "60vh" }}>
              <CardContent style={{ display: "flex",  alignItems: "center",justifyContent: "center", height: "100%" }}>
                <span>Loading data...</span>
                <img src={emptydataimg} style={{ width: "100px", height: "100px" }} alt="" />
              </CardContent>

            </Card>
          </>
        )
      }

      {this.drawerBox()}
    </>
    )
  }

  nodatadound = () => {
    return (<>
      <Card style={{ maxWidth: 750, width: "100%", marginBottom: "25px", marginTop: "20px", fontFamily: "MyFont", height: "60vh" }}>
        <CardContent style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
          <span>Loading data...</span>
          <img src={emptydataimg} style={{ height: "100px",  width: "100px",}} alt="" />
        </CardContent>

      </Card>
    </>)
  }

getSendIconStyle = (comment: string) => {
    const trimmedComment = (comment ?? '').trim();
    return {
        borderRadius: '50px',
        background: trimmedComment ? '#73A58E' : '#E0E0E0',
        color: trimmedComment ? '#FFFFFF' : '#9E9E9E',
        padding: '6px 17px',
        margin: '6px',
    };
};
replyRender = (index: number, element: any) => {
    return (<Collapse data-test-id="collapse" in={this.state.commentReplyOpen[index] && this.state.openForElement === element?.id} timeout="auto" unmountOnExit>
        <Box style={{
            padding: "10px", margin: "auto", display: "flex", alignItems: "baseline",
            gap: "15px", justifyContent: "end"
        }}>
            <TextField
                data-test-id="replytext"
                fullWidth
                maxRows={4}
                variant="outlined"
                placeholder="Write a Reply...."
                value={this.state.replyComment}
                onChange={this.handleReplyCommentChange}

                style={{ marginBottom: "10px", width: "50%", height: "48px" }}
                InputProps={{
                    style: {
                        fontSize: "16px",
                        fontWeight: "500",
                        fontFamily: "MyFont",
                        borderRadius: "50px"
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <i className="fa-regular fa-face-smile custom-icon" 
                        style={{ cursor: "pointer", right: "auto"}} 
                        onClick={this.onClickReplyEmoji.bind(this)}>
                        </i>
                        {this.state.replyShowEmoji && <EmojiPicker
                        onEmojiClick={this.ReplyEmojiClick.bind(this)}
                        
                        className="reply-emoji-picker"
                        />}
                        <IconButton
                          aria-label="Submit"
                          edge="end"
                          onClick={this.handleReplycomment}
                          style={{
                            borderRadius: "8px",
                            padding: "0",
                          }}
                          disabled={!this.state.replyComment?.trim()}
        
                        >
                          <ArrowUpwardIcon
                            style={{
                              borderRadius: "50px",
                              background: this.state.replyComment?.trim() ? '#73A58E' : '#E0E0E0',
                              color: this.state.replyComment?.trim() ? '#FFFFFF' : '#9E9E9E',
                              padding: "6px 17px",
                              margin: "6px"
                            }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                }}
            />
        </Box>
    </Collapse>)
}

renderReplycomment = (element: any, index: number) => {
    return (
        element.attributes.replies.slice().reverse().map((reply: any, replyIndex: number) => {
            const commentUser = reply.attributes.comment_user;
            if (!commentUser || !commentUser.first_name || !commentUser.last_name) {
                return null;
            }
            return (
              <Collapse data-test-id="collapse-reply" in={this.state.isReplyOpen && this.state.openForElement === element?.id} timeout="auto" unmountOnExit>
                <Box key={replyIndex} sx={{ pl: 4, display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: "flex", fontFamily: "MyFont", justifyContent: "end", marginRight: "20px" }}>
                        <Avatar style={{ margin: "0px 16px" }} src={reply.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                        <Box style={{ fontSize: "14px", backgroundColor: "#F8FAFC", padding: "12px 16px", width: "50%", wordBreak: 'break-word', borderRadius: '0px 16px 16px' }}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>
                                    {`${commentUser.first_name} ${commentUser.last_name}`}
                                </Typography>
                                <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                    {this.calculateTimeGap(reply.attributes.created_at)}
                                </Typography>
                            </Box>
                            {reply.attributes.comment}
                        </Box>
                    </Box>
                    <CardContent>
                        <Box style={{ display: "flex", justifyContent: "end", alignItems: "center", fontFamily: "MyFont", gap: "50px", marginRight: "50px" }}>
                            <Box>
                                {reply.attributes.is_liked ? (
                                    <>
                                        <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, reply)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>
                                        <span style={{color: "#64748B", fontSize: '14px', fontWeight: "600"}}>{reply.attributes.like_counts}</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer", color: "#64748B" }} onClick={this.likeComment.bind(this, reply)}></i>
                                       <span style={{color: "#64748B", fontSize: '14px', fontWeight: "600"}}> {reply.attributes.like_counts === 0 ? "Like" : reply.attributes.like_counts}</span>
                                    </>
                                )}
                                &nbsp;&nbsp;
                            </Box>
                            <Box style={{color: "#64748B", fontSize: '14px', fontWeight: "600"}}>
                                <i data-test-id="sublikeReply" className="fa-regular fa-message" onClick={() => this.handleReplySection(index, element.attributes.id, element?.id)}></i>&nbsp;&nbsp;
                                Reply
                            </Box>
                        </Box>
                    </CardContent>
                </Box>
                </Collapse>
            );
        })
    );
}

 /*istanbul ignore next */
renderDrawerfunction() {
    return (
        <CustomDrawer
            anchor="right"
            open={this.state.isCommentDrawerOpen}
            onClose={this.drawerCloses}
            data-test-id="Drawer-closes"
            id="postDrawer"
        >
            <Box

                role="presentation"
                style={{backgroundColor: '#F8FAFC',}}
            >
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#F8FAFC" }}>
                    <Typography style={{ fontSize: "24px", fontWeight: "900", color: "#14362E", paddingLeft: "25px" }}>
                        Post
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent:'flex-end', p: 1 }}>
                        <IconButton onClick={this.handleClose2} data-test-id="handleClose2">
                            <CloseIcon style={{cursor: 'pointer'}}/>
                        </IconButton>
                    </Box>
                </Box>
                <Divider />
                <Box style={{backgroundColor: '#F8FAFC', height: '100vh'}}>
                    {
                        this.state.selectedPostId !== null && (
                            <Box style={{padding: '20px 22px 10px 10px'}}>
                                <Typography>
                                    {this.state.list.length > 0 && this.state.list.filter((element: any) => element.id === this.state.selectedPostId)
                                        .map((element: any, index) => (
                                            <Card
                                                key={element.id}
                                                style={{
                                                    maxWidth: 750,
                                                    width: "100%",
                                                    fontFamily: "MyFont",
                                                    boxShadow: 'none'
                                                }}
                                            >
                                                <CardHeader
                                                    data-test-id="commentsCard"
                                                    style={{ fontFamily: "MyFont" }}
                                                    avatar={
                                                        <Avatar src={element.attributes.posted_user.profile_picture.url} aria-label="recipe">
                                                        </Avatar>
                                                    }
                                                    action={
                                                      <>
                                                        {element.attributes.is_favorite && (
                                                            <IconButton
                                                              data-test-id="icon-button-post"
                                                              edge="start"
                                                              aria-haspopup="true"
                                                              aria-controls={`menu-${index}`}
                                                              aria-label="more"
                                                              onClick={() => this.getToFavriouteData(element.id)}
                                                            >
                                                              <BookmarkIcon style={{ color: "#14362E" }} />
                                                            </IconButton>
                                                          )}
                                                        <i className="fa-solid fa-ellipsis-vertical" style={{ marginTop: "12px", 
                                                        marginRight: '3px', cursor: 'pointer'
                                                        }} onClick={(event) => this.handleOpenMenu(event, element.id)}></i>
                                                        </>
                                                    }
                                                    title={
                                                        <div style={{ fontFamily: "MyFont", fontSize: "16px", fontWeight: "700", letterSpacing: "1px" }}>
                                                            {element.attributes.posted_user.first_name + ' ' + element.attributes.posted_user.last_name} .
                                                            <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>
                                                                {element.attributes.posted_user.role}
                                                            </span>
                                                        </div>
                                                    }
                                                    subheader={
                                                        <div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>
                                                            {moment(element.attributes.updated_at).format('dddd, hh:mm A')}
                                                            <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>
                                                                {element.attributes.category}
                                                            </span>
                                                        </div>
                                                    }
                                                />
                                                <Typography
                                                    style={{
                                                        fontSize: "14px",
                                                        paddingLeft: "17px",
                                                        fontFamily: "MyFont",
                                                        fontWeight: 500,
                                                        letterSpacing: "1px",
                                                        fontStyle: "normal"
                                                    }}
                                                >
                                                    {element.attributes.body}
                                                </Typography>
                                                {
                                                    (element.attributes.link_title && element.attributes.external_link) && (
                                                        <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                                                            <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                                                <img src={LinkImg} alt="" />
                                                                <Box style={{ marginLeft: "10px" }}>
                                                                    <p>{element.attributes.link_title}</p>
                                                                </Box>
                                                            </Box>
                                                            <span style={{ fontSize: "20px", cursor: "pointer" }}>
                                                            {this.state.showCopiedMessage && <span style={{
                                                              backgroundColor: '#555',
                                                              color: '#fff',
                                                              textAlign: 'center',
                                                              borderRadius: '5px',
                                                              padding: '5px',
                                                              fontSize: '12px',
                                                            }}>Link copied to clipboard!
                                                            </span>}
                                                              <i className="fa-regular fa-copy" onClick={() => {
                                                              navigator.clipboard.writeText(element.attributes.external_link)
                                                                  .then(() => {
                                                                      this.setState({showCopiedMessage: true});
                                                                      setTimeout(() => {
                                                                        this.setState({showCopiedMessage: false});
                                                                      }, 2000);
                                                                  })
                                                                  .catch(err => {
                                                                      console.error('Failed to copy: ', err);
                                                                  });
                                                          }}></i></span>
                                                        </Box>
                                                    )
                                                }
                                                <CardContent>
                                                    <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                                                        <Box>
                                                            {element.attributes.is_liked ? (
                                                                <>
                                                                    <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i><span style={{color: "#64748B", fontWeight: "600"}}>
                                                                      {element.attributes.like_counts}</span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer", color: "#64748B", }} onClick={this.likePost.bind(this, element)}></i>
                                                                    <span style={{color: "#64748B", fontSize: '14px', fontWeight: "600"}}>{element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}</span>
                                                                </>
                                                  )}
                                                  &nbsp;&nbsp;
                                                </Box>
                                                <Box style={{color: "#64748B", fontWeight: "600"}} onClick={this.handleClick.bind(this, element.id)}>
                                                  <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                                                  {this.Comment(element)}
                                                </Box>
                                                <Box style={{color: "#64748B", fontWeight: "600"}} onClick={this.drawerSidebaar.bind(this, element.id)}>
                                                  <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                                  Share
                                                </Box>
                                              </Box>
                                            </CardContent>
                                            </Card>
                                        ))}

                                </Typography>
                            </Box>
                        )
            }

            <Box style={{padding: "10px"}}>
              <Box style={{ display: 'flex', alignItems: 'center', gap: "1", justifyContent: "center", backgroundColor: '#FFFFFF', padding: "10px 10px" }}>
                <Avatar
                  src={this.state.profile?.attributes?.personal_information?.profile_picture?.url}
                  aria-label="recipe"
                />
                <StyledMentionsInput
                  style={{ width: "80%"}}
                  value={this.state.comment}
                  onChange={this.handleCommentChange}
                  placeholder="Write a comment..."
                >
                  <Mention
                    trigger="@"
                    data={this.state.users}
                    renderSuggestion={(suggestion: SuggestionDataItem) => (<>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Avatar style={{ margin: "8px" }} src={(suggestion as UserSuggestion).profile_picture} />
                        <Box style={{ display: "flex", alignItems: "center" }}>{suggestion.display}</Box></Box>
                    </>
                    )}
                  />

                </StyledMentionsInput>
                <InputAdornment position="end"
                  style={{position: "absolute", marginLeft: this.state.isSmallScreen ? "65%" :"75%"}}
                
                >
                  <i  className="fa-regular fa-face-smile custom-icon "
                    style={{ paddingRight: "0px", cursor: "pointer", right: this.state.isSmallScreen ? "20%" : "44%"}}
                    onClick={this.onClickEmoji.bind(this)}>
                  </i>
                  {this.state.showEmoji && <EmojiPicker
                    onEmojiClick={this.EmojiClick.bind(this)}

                    className="emoji-picker"
                  />}
                  <IconButton
                    data-test-id="commentSubmit"
                    aria-label="send comment"
                    edge="end"
                    onClick={this.handleCommentSubmit}
                    className="button-icon custom-icon"
                    disabled={!this.state.comment?.trim()}
                  >
                    <ArrowUpwardIcon
                      style={this.getSendIconStyle(this.state.comment)}
                    />
                  </IconButton>
                </InputAdornment>
              </Box>
            </Box>
            <List style={{padding: '5px 10px'}}>
              <Box style={{backgroundColor: "white"}}>
                <ListItem  data-test-id="allCommentsId" button onClick={this.toggleComments} disableRipple
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    fontFamily: "MyFont",
                    padding: '10px 16px 10px 30px',
                    }}
                      >
                        <Box style={{color: '#334155', fontWeight: 600, fontFamily: "MyFont", marginRight: '4px'}}>All comments</Box>
                        {this.state.isCommentsOpen ? <ExpandMoreIcon /> : < ExpandLessIcon />}
                  </ListItem>
                  <Collapse in={this.state.isCommentsOpen} timeout="auto" unmountOnExit>
                      <Box style={{ display: "flex", flexDirection: "column", marginTop: '10px' }}>
                          {this.state.commentData.filter((comment: { attributes: { parent_comment_id: null; comment: string; }; }) => comment.attributes.parent_comment_id === null).map((element: {
                              attributes: {
                                  comment_user: { first_name: string, last_name: string, profile_picture: { url: string } };
                                  created_at: string;
                                  like_counts: number;
                                  is_liked: boolean;
                                  replies: any;
                                  id: number; comment: string | null | undefined; parent_comment_id: number;
                              };
                              id: number
                          }, index: number) => (
                              <Box key={index}>
                                  <Box style={{ display: "flex", fontFamily: "MyFont" }}>
                                      <Avatar style={{ margin: "0px 16px" }} src={element.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                                      <Box style={{ fontSize: "14px", backgroundColor: "#F8FAFC", padding: "12px 16px", width: "75%", borderRadius: '0px 16px 16px', wordBreak: 'break-word' }}>
                                          <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                              <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>{`${element.attributes.comment_user.first_name} ${element.attributes.comment_user.last_name}`}</Typography>
                                              <Typography style={{ fontSize: "12px", fontWeight: "500" }}>{this.calculateTimeGap(element.attributes.created_at)}</Typography>
                                          </Box>
                                          {element.attributes.comment}
                                      </Box>
                                  </Box>
                                  <CardContent>
                                      <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont", color: '#64748B', fontWeight: "600", fontSize: '14px' }}>
                                          <Box>
                                              {element.attributes.is_liked ? (
                                                  <>
                                                      <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                                                  </>
                                              ) : (
                                                  <>
                                                      <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, element)}></i>
                                                      <span style={{color: "#64748B", fontSize: '14px', fontWeight: "600"}}>{element.attributes.like_counts === 0 ? "Like" : element.attributes.like_counts}</span>
                                                  </>
                                              )}
                                              &nbsp;&nbsp;

                                          </Box>
                                          <Box>
                                              <i data-test-id="likeReply" className="fa-regular fa-message" onClick={() => this.handleReplySection(index, element.attributes.id, element?.id)}></i>&nbsp;&nbsp;
                                              {this.repliesCount(element)}
                                          </Box>
                                          <Box>
                                              <i className="fa-solid fa-arrow-up-from-bracket" data-test-id="shareId" onClick={this.handleShareCommentClick.bind(this, element.attributes.id)}></i>&nbsp;&nbsp;
                                              Share
                                          </Box>
                                      </Box>
                                  </CardContent>
                                  <List>
                                    <ListItem data-test-id="toggleList" button onClick={() => this.toggleReply(element)} disableRipple
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "end",
                                        fontFamily: "MyFont",
                                        cursor: "pointer" 
                                      }}
                                    >
                                      <Box>All Replies</Box>
                                      {this.state.isReplyOpen && this.state.openForElement === element?.id ? < ExpandLessIcon style={{cursor: "pointer"}} /> : <ExpandMoreIcon style={{cursor: "pointer"}}/> }
                                    </ListItem>
                                  </List>
                                  {this.renderReplycomment(element, index)}
                                  {this.replyRender(index, element)}
                              </Box>
                          ))}
                      </Box>
                  </Collapse>
              </Box>
            </List>
          </Box>

          </Box>
        </CustomDrawer>)
}

renderSharefunction = () => {

    const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, onClose }) => (
        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
            <Typography className="selecttype">{title}</Typography>
            <span onClick={onClose}>
                <i className="fa-solid fa-xmark"></i>
            </span>
        </Box>
    );
    const SearchInput: React.FC<SearchInputProps> = ({ onChange, placeholder }) => (
        <Box style={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "myfont",
            padding: "15px"
        }}>
            <Box style={{
                width: "99%",
                marginBottom: "9px",
                border: "1px solid #CBD5E1",
                marginTop: "21px",
                display: "flex",
                alignItems: "center",
                borderRadius: "8px"
            }}>
                <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: "9px" }}></i>
                <input type="text" style={{
                    width: "99%",
                    fontFamily: "myfont",
                    padding: "11px 4px",
                    borderRadius: "6px",
                    border: "none",
                    outline: "none"
                }} onChange={onChange} placeholder={placeholder} />
            </Box></Box>
    );
    const SelectedMembers: React.FC<SelectedMembersProps> = ({ members, onDelete }) => (
        <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
            {members.map((member, index) => (
                <Box key={index} style={{ borderRadius: '26px', width: 'auto', display: 'flex', alignItems: 'center', background: '#F1F5F9', margin: '0px 10px 10px',
                padding: '6px 8px 6px 12px', height: '32px' }}>
                    <Typography style={{ fontSize: '14px', fontFamily: "MyFont", marginRight: '9px', lineHeight: '22px', color: '#475569' }}>{member.value}</Typography>
                    <span onClick={() => onDelete(index)} style={{padding: '0px 15px'}}>
                        <i className="fa-solid fa-xmark" style={{ fontSize: '15px' }}></i>
                    </span>
                </Box>
            ))}
        </Box>
    );
    const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => (
        <Box style={{ display: "flex", justifyContent: "center", width: '100%', alignItems: 'center', position: 'absolute', bottom: '0px', padding: '10px 0px' }}>
            <Button variant="contained" style={{
                width: "90%",
                background: "#14362E",
                textTransform: "none",
                fontFamily: "myfont",
                color: "white",
                margin: "auto"
            }} onClick={onClick}>
                Continue
            </Button></Box>
    );
    return (
        <>
            <CustomDrawer
                anchor="right" open={this.state.open3} onClose={this.drawerSidebaarClose3}>
                <BoxCustom2>
                    <DrawerHeader title="Share comment" onClose={this.drawerSidebaarClose3} />
                    <Box style={{ borderBottom: '1px solid #CBD5E1' }}></Box>

                    <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px", marginLeft: "5px" }}>
                      <Box style={{ width: "100%", marginBottom: "9px", border: "1px solid #CBD5E1", marginTop: "5px", display: "flex", alignItems: "center", borderRadius: "8px" }}>
                        <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: "9px", color:"#DADADA" }}></i>
                        <input data-test-id="connectionSearch" placeholder="Search By Name" type="text" style={{ width: "100%", padding: "11px 4px", borderRadius: "5px", border: "none", outline: "none", lineHeight: '24px', }} onChange={this.handlesearchTag} value={this.state.setSearchvalue} name="exlink" id="" />
                        {
                          this.state.setSearchvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer", marginRight: '10px' }} data-test-id="removesearch" onClick={this.removesearchdata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8", marginRight: '10px', cursor: 'pointer' }}></i>
                       }
                      </Box>
                    </Box>
                    <SelectedMembers members={this.state.selectedmember3} onDelete={this.handleselectmemberDelete3} />
                    <Box data-test-id="selectedMemberchip" style={{padding: '5px 15px', maxHeight: this.state.selectedmember3.length > 3 ? "120px" : "180px", overflowY: 'scroll'}}>
                        {this.state.shareconnectiondata2.length > 0 && this.state.shareconnectiondata2.map((element1: any, index: any) => {
                            return (
                              <Box key={index} 
                              style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 0px', display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px", height: "48px", marginTop: '11px'}}>
                              <Box 
                                style={{ display: "flex", borderRadius: "9px", alignItems: "center" }}>
                                <img src={element1.profile_picture.url} 
                                  style={{ height: "44px", width: "44px", borderRadius: '9pc' }} alt="" />
                                <Box style={{ marginLeft: "18px", display: 'flex', flexDirection: 'column' }} >
                                  <span style={{fontFamily: 'MyFont', fontSize: '16px', fontWeight: '700', color: '#0F172A', lineHeight: '24px'}}> {element1.name}
                                  </span>
                                  <span style={{fontFamily: 'MyFont', fontSize: '16px', fontWeight: '500', color: '#64748B', lineHeight: '18px'}}> {element1.rol} 
                                  </span>
                                </Box >
                              </Box >
                              <span className="inputSelectedMember">
                                <input data-test-id = "connectionInputId" value={element1.e} onClick={(elementvalue) => this.getvalueconnectioncheck3(elementvalue, element1)} type="checkbox" name="" id="" />
                                </span>
                              </Box >   
                            )
                        })}
                    </Box>
                    <Box className="box7" style={webStyle.inputspace}>
                        <Box className="box8" style={{width: '99%', border: '1px solid #CBD5E1', display: 'flex', alignItems: 'center', borderRadius: '8px'}}><input className="input2" style={webStyle.searchinputspace} type="text" onChange={this.handlewriteCommentmessage} name="exlink" placeholder="Write Message" id="" /></Box>
                    </Box>
                    <ContinueButton data-test-id="continued" onClick={this.handleCommentshare} />
                </BoxCustom2>
            </CustomDrawer>
        </>
    )
}

         /*istanbul ignore next */

   recommendation = () => {

     return (
       <>
         <Box style={{ display: "flex", justifyContent: "space-evenly", width: "80%", margin: "20px auto 25px" }}>        
               <Box
             data-test-id="toggleDrawer1City"
             onClick={() => this.toggleDrawer1("city")}
             style={webStyle.cityTYpeBox}
           >
             <Box>
               <span style={webStyle.titlename}>City</span>
               <br />
               <span style={webStyle.selectedItems}>
                 {this.state.selectedCity.slice(0, 3).map((element, index) => (
                   <React.Fragment key={index}>
                     {element.value}
                     {index < this.state.selectedCity.slice(0, 3).length - 1 && ', '}
                   </React.Fragment>
                 ))}
               </span>
             </Box>
             <ExpandMoreIcon style={{ fontSize: '24px', color: "#475569" }} />
           </Box>


           <Box
             data-test-id="toggleDrawer1Type"
             onClick={() => this.toggleDrawer2("recommendationtype")}
             style={webStyle.cityTYpeBox}
           >
             <Box>
               <span style={webStyle.titlename}>Type</span>
               <br />
               <span style={webStyle.selectedItems}>
                 {this.state.selectedType.slice(0, 3).map((element, index) => (
                   <React.Fragment key={index}>
                     {element.value}
                     {index < this.state.selectedType.slice(0, 3).length - 1 && ', '}
                   </React.Fragment>
                 ))}
               </span>
             </Box>
             <ExpandMoreIcon style={{ fontSize: '24px', color:"#475569" }} />
           </Box>
         </Box>
         <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
           {
             this.state.list.length > 0 ?
                 this.insiderecommendation()
             : (
               <>
                 {this.nodatadound()}
               </>
             )
           }
           {this.typeSidebar()}
           {this.citySidebar()}
         </Box>
       
       </>
     )
   }
  renderContent() {
    switch (this.state.TabsView) {
      case "Trending":
        return this.trending();
      case "foryou":
        return this.foryou();
      case "Recommendations":
        return this.recommendation();
      default:
        return "";
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <div style={{opacity: this.state.isCommentDrawerOpen ? '30%' : '100%',}}>
          <AppHeader component={"explore"} />
          <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC" }}>

            <BoxCard key={1} style={{backgroundColor: '#ffffff'}}>
              <Container>
                <Box style={webStyle.searchbox}>
                  <Box style={webStyle.inputparent}>
                    <i className="fa-solid fa-magnifying-glass"></i>&nbsp;&nbsp;
                    <input type="text" name="" onChange={this.searchdata} style={webStyle.searchinput} placeholder='Search' id="" />
                  </Box>
                </Box>

                <Box>
                  <Box style={webStyle.tabStyle as object}>
                    <Typography data-test-id="trendingapi" style={this.state.TabsView == "Trending" ? webStyle.trendingselected as object : webStyle.recommendationtab as object} onClick={this.handleTabChange.bind(this, "Trending")}>Trending</Typography>
                    <Typography data-test-id="foryou" style={this.state.TabsView == "foryou" ? webStyle.trendingselected : webStyle.recommendationtab} onClick={this.handleTabChange.bind(this, "foryou")}>For you</Typography>
                    <Typography style={this.state.TabsView == "Recommendations" ? webStyle.trendingselected as object : webStyle.recommendationtab} onClick={this.handleTabChange.bind(this, "Recommendations")}>Recommendations</Typography>

                  </Box>
                </Box>

                <Box>
                  {this.renderContent()}
                </Box>
              </Container>

            </BoxCard>

          </Box>
          <Toaster />
          <AppFooter component={"explore"} />
        </div>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default Trending


const StyledMentionsInput = styled(MentionsInput)<MentionsInputProps & StyledComponentProps>(() => ({
  marginLeft: "8px",
  width: '385px',
  height: '48px',
  backgroundColor: '#F1F5F9',
  border: '1px solid #E2E8F0',
  borderRadius: "50px",
  "& textarea": {
      padding: '12px 16px',
      width: '100%',
      height: '100%',
      fontSize: "16px",
      fontWeight: "500",
      fontFamily: "MyFont",
      border: "none",
      borderRadius: "50px",
      "&:focus": {
          outline: 'none',
          border: 'none',
      },
      "&:hover": {
          border: 'none',
      },
  },
  "&:focus": {
      outline: 'none',
      border: '1px solid #E2E8F0',
  },
  "&:hover": {
      border: '1px solid #E2E8F0',
  },
  '& [class*="__suggestions"]': {
      top: "25px !important",
      minWidth: "333px",
      fontFamily: "myfont",
      fontSize: "16px",
      fontWeight: "500",
      borderRadius: "16px",
      border: "1px solid #E2E8F0",
      padding: "8px 12px",
  },
  '& [class*="__suggestions__list"]': {
      border: "none",
  },
  '& [class*="__suggestions__item"]': {
      border: "none",
      borderBottom: "1px solid #E2E8F0",
      borderRadius: "0",
      padding: "8px"
  },
  "@media(max-width: 600px)": {
      "& textarea": {
          fontSize: "13px !important"
      },
      '& [class*="__suggestions"]': {
          minWidth: "100%",
          fontSize: "14px",
      },
  }

}));

const HeaderContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",

  '& .flexBoxCus': {
      position: "relative",
      textAlign: "right" as 'right',
      cursor: "pointer",
      flexWrap: "wrap",
      width: "30px",
  },
});



const BoxCustom2 = styled(Box)({ 
  fontFamily: "myfont",
  "& .mainBox": {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '4px',
      height: '30px',
      marginBottom: '5px',
      paddingLeft: "10px",
      fontFamily: "Halyard Display",
      fontSize: "12px",
      fontWeight: "400"
  },
  "& .mainBoxDivider": {
      marginLeft: "10px",
      width: "95%"
  },
  "& .mediatitle": {
      margin: "15px",
      width: "370px",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "12px",
      fontFamily: "MyFont"
  },
  "@media (max-width: 600px)": {
      "& .mediatitle": {
          width: "150px",
      },
  },
  "& .content-container": {
      height: "calc(100vh - 200px)",
      overflowY: "auto",
      paddingBottom: "80px",
  },
  "& .button-container": {
      position: "fixed",
      bottom: 0,
      width: "540px",
      "@media (max-width: 600px)": {
          width: '100%',
      },
      background: "white",
      padding: "10px 0",
      boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
  },
  '& .selectbtn': {
      fontFamily: "MyFont",
      textTransform: "none",
      width: "90%",
      background: "#14362E",
      color: "#fff",
  },
  '@media (max-width: 601px)': {
      '& .selectbtn': {
          marginTop: "0",
      },
  },
  '& .selecttype': {
      paddingLeft: '5px',
      color: '#14362E',
      fontWeight: "bold",
      fontSize: "24px",
  },
  '& .sideBarlabel': {
      fontFamily: "Sans-serif",
      color: "#475569",
      letterSpacing: "8%"
  },
  '& .flaggingcontent': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      padding: '14px',
      fontWeight: "700",
      fontSize: "16px",
      letterSpacing: "0.08em",
      color: "#0F172A"
  },
  "& .typebox": {
      display: "flex",
      paddingLeft: "18px",
      paddingTop: "15px",
      paddingBottom: "15px",
  },
  "& .typebox:hover": {
      backgroundColor: "#D4E5DE"
  },
  "& .checkedCircle": {
      width: "27.7px",
      height: "27.7px",
      color: "#14362E",
      paddingTop: "10px"
  }
});

const CustomDrawer = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '540px',
    height: '100vh',
    borderRadius: '24px 0px 0px 0px',
  },
  '& .button-icon': {
    borderRadius: "8px",
    padding: "0",
    position: "absolute",
    right: "17%"
  },
  '& .custom-icon': {
    borderRadius: "8px",
    padding: "0",
    position: "relative",
    right: "44%",
    display: 'block'
  },
  '& .emoji-picker': {
    height:"400px !important",
    width: "400px !important",
    position: "absolute",
    right: "32% ",
    top :"26px",
    zIndex : 1000
  },
  '& .reply-emoji-picker': {
    height:"400px !important",
    width: "450px !important",
    position: "absolute",
    top :'103% ',
    right : '0%',
    zIndex : 1000
  },
  '& .display_arrow':{
    display : 'none'
  },
  '& .inputSelectedMember': {
    display: 'flex', // Ensure flex is used if needed
    alignItems: 'center',
    '& input[type="checkbox"]': {
      appearance: "none",
      width: "20px",
      height: "20px",
      border: "2px solid #555",
      borderRadius: "5px",
      cursor: "pointer",
      marginLeft: "auto",
    },
  
    // Checkbox when checked (green background)
    '& input[type="checkbox"]:checked': {
      backgroundColor: "#73A58E", /* Green fill color */
      borderColor: "#73A58E",
    },
  
    // Checkmark when the checkbox is checked
    '& input[type="checkbox"]:checked::before': {
      content: '"✔"',
      color: "white",
      fontSize: "16px",
      display: "block",
      textAlign: "center",
      lineHeight: "18px",
    }  
  },
  '@media (max-width: 601px)': {
    '& .MuiDrawer-paper': {
      width: '100%',
      height: '92%',
    },
    '& .button-icon': {
      right: "7%"
    },
    '& .emoji-picker':{
      right : '5%',
      width: "370px !important",
    },
    '& .reply-emoji-picker':{
      top: "103%",
      right : '0%',
      height: '400px !important',
      width: '415px !important'
    },
    '& .display_arrow':{
      display:'flex'
    },
}})




const BoxMain = styled(Box)({
  width: "540px",
  fontFamily: "myfont",
  "@media(max-width: 600px)": {
    width: '100%',
  },
  '& .titleReason': {
    fontSize: "14px",
    fontFamily: "myfont"
  },
  '& .titlemyfont': {
    fontFamily: "myfont"
  },
  "& .mainBox": {
    padding: '4px',
    fontWeight: "400",
    height: '30px',
    fontFamily: "MyFont",
    justifyContent: 'space-between',
    marginBottom: '5px',
    paddingLeft: "10px",
    display: 'flex',
    fontSize: "12px"
  },
  "& .mainBoxDivider": {
    width: "95%",
    marginLeft: "10px"
  },
  "& .mediatitle": {
    display: "flex",
    width: "370px",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "bold",
    fontFamily: "MyFont",
    margin: "15px",
    paddingLeft: "12px",
  },
  "& .content-container": {
    paddingBottom: "80px", 
    overflowY: "auto",
    height: "calc(100vh - 200px)", 
  },
  "@media (max-width: 600px)": {
    "& .mediatitle": {
      width: "150px",
    },
  },
  "& .button-container": {
    "@media (max-width: 600px)": {
      width: '100%',
    },
    width: "540px",
    position: "fixed",
    background: "white",
    boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    bottom: 0,
    padding: "10px 0", 
  },
  '@media (max-width: 601px)': {
    '& .selectbtn': {
      marginTop: "0", 
    },
  },
  '& .selectbtn': {
    fontFamily: "MyFont",
    color: "white",
    textTransform: "none",
    background: "#14362E",
    width: "90%",
  },
  '& .selecttype': {
    fontSize: "24px",
    color: '#14362E',
    fontWeight: "bold",
    paddingLeft: '5px',
    fontFamily:"myfont"
  },
  '& .sideBarlabel': {
    letterSpacing: "8%",
    fontFamily: "Sans-serif",
    color: "#475569",
  },
  '& .flaggingcontent': {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'start',
    padding: '14px',
    justifyContent: 'center',
    color: "#0F172A",
    letterSpacing: "0.08em",
    fontWeight: "700",
    fontSize: "16px",
    fontFamily: "myfont"
  },
  "& .typebox": {
    paddingLeft: "18px",
    paddingBottom: "15px",
    display: "flex",
    paddingTop: "15px",
  },
  "& .checkedCircle": {
    paddingTop: "10px",
    color: "#14362E",
    width: "27.7px",
    height: "27.7px",
  },
  "& .typebox:hover": {
    backgroundColor: "#D4E5DE"
  }
});

const BoxCard = styled(Box)({
  maxWidth: 850,
  marginBottom: "25px",
  width: "100%",
  marginTop: "90px",
  fontFamily: "MyFont",
  "@media(max-width: 500px)": {
    marginTop: "20px",
  },
});


const webStyle = {

  inputspace:{
    marginLeft:"5px",
    padding: '14px',
  },
  searchinputspace:{
    width:"99%",
    lineHeight: '24px',
    padding: '11px 4px',
    border: 'none',
    outline: 'none',
    borderRadius: '7px',
    fontSize: '16px',
    fontFamily:  'MyFont'
  },
  searchbox: {
    // marginTop: "50px",
    borderRadius: "7px",
    fontFamily: "MyFont",
    background: "white",
    border: "1px solid #CBD5E1",
    color: "#CBD5E1",
    height: "40px",
    width: "86%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "50px auto auto auto",
    padding: "0px 10px"
  },

  recipBox: {
    display: 'flex', alignItems: 'center', gap: "1", justifyContent: "center"
  },

  listItemCss: {
    fontSize: "16px",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    fontFamily: "MyFont"
  },
  smileIconCss: { paddingRight: "0px", cursor: "pointer", marginRight: "70px"},
  modalStyle: {
    position: "absolute" as "absolute",
    top: "50%",
    left: { xs: "50%", md: "50%" },
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { xs: "70%", md: "25%" },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: 24,
    p: 4,
  },

  modalStyleTypo: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#14362E",
    marginTop: "5%",
    width: { xs: "90%", md: "70%" },
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.05em"
  },
  modalStyleTypoSecond: {
    fontSize: "14px",
    marginTop: "3%",
    color: "#0F172A",
    width: "100%",
    fontFamily: "'MyFont', 'Halyard Display'",
    textAlign: "center",
    letterSpacing: "0.08em"
  },
  checkCircleCss: {
    width: " 66.67px",
    height: "66.67px",
    color: "#73A58E"
  },

  contactDrawerSubmitBtn: {
    height: "45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    borderRadius: "8px",
    fontFamily: "'MyFont', 'Halyard Display'",
    letterSpacing: "1px",
    marginTop: "20px",
    width: "80%",
  },
  categoryCard : { maxWidth: 850, marginBottom: "25px", width: "100%", marginTop: "20px", fontFamily: "MyFont"},
  inputparent: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchinput: {
    width: "100%",
    border: "none",
    outline: "none"
  },
  tabStyle: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "#F8FAFC",
    margin: "auto",
    marginTop: "20px",
    borderRadius: "100px",
    width: "350px",
    paddingBottom: "12px",
    paddingTop: "12px",
    marginBottom: "20px"
  },
  openGridBox: {
    display: "grid", 
    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))", 
    gap: "15px", 
    alignItems: "center",
    padding: "20px"
  }, 
  trending: {
    // width: "50%",
    padding: "3px 6px",
    borderRadius: 25,
    textAlign: "center",
    cursor: "pointer",
    fontFamily: "MyFont",
    letterSpacing: "0.05em",
    color: "#64748B"
  },

  categoryDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px'
  },
  trendingselected: {
    border: "1px solid #73A58E",
    // width: "50%",
    padding: "6px 8px",
    borderRadius: 25,
    textAlign: "center",
    cursor: "pointer",
    fontFamily: "MyFont",
    letterSpacing: "0.05em",
    fontWeight: "600",
    color: "var(--900, #14362E)"
  },
  recommendationtab: {
    // border: "2px solid #73A58E",
    // width: "50%",
    borderRadius: "100px",
    cursor: "pointer",
    fontFamily: "MyFont",
    padding: "4px 6px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
    color: "#64748B"
  },
  recommendationtabselected: {
    borderRadius: "100px",
    cursor: "pointer",
    fontFamily: "MyFont",
    padding: "4px 6px",
    letterSpacing: "0.05em",
    fontWeight: "500",
    color: "black",
    border: "1px solid #73A58E",
  },
  titlename : { fontFamily: "MyFont", letterSpacing: "0.8px" },
  foryou: {
    // width: "100px"
    borderRadius: "100px",
    cursor: "pointer",
    fontFamily: "MyFont",
    padding: "2px 6px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
    color: "#64748B"
  },
  cardflexbox: { display: "flex", justifyContent: "space-between" },
  foryouselected: {
    borderRadius: "100px",
    cursor: "pointer",
    fontFamily: "MyFont",
    padding: "2px 6px",
    letterSpacing: "0.05em",
    fontWeight: "bold",
    color: "black",
    border: "1px solid #73A58E",
  }
  ,
  categoryDropwdown: {
    margin: "15px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "12px",
    fontFamily: "MyFont"
  },
  borderBottomstyle: {
    border: "1px solid #E2E8F0"
  },
  drawer: {
    width: "540px",
    display: "block", // Default value for 'xs'
    '@media (min-width: 600px)': {
      display: "none", // For 'sm'
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      width: "100%",
    },
  },
  // mainforyouparent
  mainforyouparent: {
    padding: "10px",
    marginBottom: "25px"
  },
  cityTYpeBox :{
    width: "41%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "10px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  selectedItems : { fontFamily: "MyFont", letterSpacing: "0.8px", color: "#475569" },
  mainHeader: {
    background: "#14362E",
    color: "white",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "6px",
  },
  innercontentParent: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px",
    marginTop: "10px",
    marginBottom: "10px",

  },
  
  innercontent: {
    borderRadius: "10px",
    border: "1px solid #73A58E",
    padding: "10px",
    width: "100%",
    maxWidth: "48%", 
    height: "auto", 
    display: "flex",
    flexDirection: "column",
    '@media (max-width: 768px)': {
      maxWidth: "100%",
    },
  },
  inercontentheader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headercontent: {
    fontFamily: "MyFont",
    fontWeight: "bold",
    letterSpacing: "0.8px",
    color: "#14362E",
    fontSize: "22px"
  },
  headersecondcontent: {
    color: "#73A58E",
    fontSize: "13px",
    fontWeight: '600',
    fontFamily: "MyFont !important",
    letterSpacing: "0.5px",
    cursor:'pointer'
  },
  innermaincontenttext: {
    color: "#0F172A",
    fontSize: "14px",
    fontFamily: "MyFont",
    letterSpacing: "0.7px",
  }
}
const BoxDetails = styled(Box)({
  "& .boxOuter": {
    "@media (max-width: 420px)": {
        overflowX:"auto",
        gap:"10px"
    },
  },
  "& .boxinner": {
    "@media (max-width: 420px)": {
        minWidth:"305px",
        height:"auto"
    },
  },
})

const MenuBox = styled(Box)({
  '& .menuicon': {
    width: "18px",
    height: "18px",
    top: "9px",
    left: "16px",
    padding: "2px"
  },
  '& .menuitemcss': {
    fontFamily: "myfont",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "22px",
    letterSpacing: "0.08em",
    textAlign: "left"
  },
  "@media(max-width: 600px)": {

  }
});


// Customizable Area End
