import React from "react";
// Customizable Area Start
import AllEventController, {
    Props
} from "./AllEventController";
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import {
    Container,
    Box,
    Card,
    styled,
    Drawer,
    Button,
    CardMedia,
    CardContent,
    Typography,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import { CardImg, locationimg, calender, watchimg, moneyImg, groupimg, filterImg, noevents, corssImg, SucessImg } from "../src/assets"
import DatePicker from "react-multi-date-picker"
import "react-multi-date-picker/styles/colors/green.css"
import Carousel from "react-multi-carousel";


export default class AllEvent extends AllEventController {
    constructor(props: Props) {
        super(props);
    }

    seperateEventResponse(images:any,videos:any,elementdata:any,responsive:any){
        return (
            <>
                 <Card style={{ maxWidth: 700, marginBottom: "20px", width: "100%", position: "relative" }}>
                                    <Carousel
                                    responsive={responsive}
                                    swipeable={true}
                                    autoPlay={false}
                                    draggable={true}
                                    showDots={false}
                                    partialVisible={false}
                                    infinite={false}
                                    dotListClass="custom-dot-list-style"
          >
                            {images.map((media:any, index:any) => (
                                <div key={`image-${index}`} style={{ display: "flex", height: "200px" , alignItems: "center"}}> 
                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
                                </div>
                                ))}
                            {videos.map((media:any, index:any) => (
                            <div key={`video-${index}`}>
                                <video style={{  height: "250px" ,width: "100%"}} controls>
                                    <source src={media.url} type="video/mp4" />
                                Your browser does not support the video tag.
                                </video>
                            </div>
                            ))}
                                </Carousel>
                                    <Typography style={{ position: "absolute", right: "20px", top: "10px", background: "#D4E5DE", padding: "1px 11px", color: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px", borderRadius: "15px" }}>{!elementdata.attributes.is_paid ? "Free" : "Paid"}</Typography>
                                    <CardContent style={{ display: "flex",justifyContent: "space-between" }}>
                                        <Box style={{ display: "flex",justifyContent: "start",  gap:"10px"}}>

                                        <Box style={{ padding: "11px" }}>
                                            <Typography style={{ fontFamily: "MyFont",letterSpacing:"0.8px" }}>{elementdata.attributes.formatted_date}</Typography>
                                        </Box>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography style={{fontFamily:"MyFont", letterSpacing:"0.8px"}}>{elementdata.attributes.title}</Typography>
                                            <Typography style={{fontSize: "12px", color: "#64748B",  fontFamily: "MyFont", letterSpacing: "0.8px" }}>
                                            {elementdata.attributes.notes.length > 120 ? `${elementdata.attributes.notes.slice(0, 120)}...`: elementdata.attributes.notes}</Typography>
                                            <Typography style={{ fontFamily: "MyFont",letterSpacing:"0.8px", fontSize: "12px" }}>{elementdata.attributes.time_duration}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "12px",letterSpacing:"0.8px" }}>{elementdata.attributes.going} Going - {elementdata.attributes.tickets_left} Tickets left</Typography>
                                        </Box>
                                        </Box>
                                        
                                        <Box>
                                            <Typography style={{ fontFamily: "MyFont", cursor: "pointer" ,  marginTop: "40px", width: "80px",fontSize: "15px", fontWeight: "bold"}} onClick={this.handleDetailsOpen.bind(this, elementdata)}>View details</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
            </>
        )
    }

    AlleventDisplay() {
        return (
            <>
                {
                    this.state.AllEventsresponse.length > 0 ? this.state.AllEventsresponse.map((elementdata: any, index: any) => {
                        const responsive = {
                            desktop: {
                                slidesToSlide: 1,
                              breakpoint: { max: 3000, min: 0 },
                              items: 1
                            }
                          };

                        function mapMediaItems(mediaItems: { content_type: string; url: string; }[]) {
                            return mediaItems.map((item: { content_type: string; url: string; }) => ({
                              type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
                              url: item?.url
                            }))

                          }
                        const imagesAndVideos = elementdata.attributes.images ? mapMediaItems(elementdata.attributes.images) : [];
                        const media = elementdata.attributes.videos ? mapMediaItems(elementdata.attributes.videos) : [];

                        const combinedMedia = [...imagesAndVideos, ...media]
                        const videos = combinedMedia.filter(media => media.type === 'video')
                        const images = combinedMedia.filter(media => media.type === 'image');
                        return (
                            <>
                               {this.seperateEventResponse(images,videos,elementdata,responsive)}
                            </>
                        )
                    }
                    ) : (
                        <>
                            <Box style={{width:"100%",height:"600px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <img src={noevents}  alt="" />
                            </Box>
                        </>
                    )
                }


            </>
        )
    }

    MyeventDisplay() {
        return (
            <>
                {
                    this.state.AllEventsresponse.length > 0 ? this.state.AllEventsresponse.map((element: any, index: any) => {
                        const responsive = {
                            desktop: {
                              breakpoint: { max: 3000, min: 0 },
                              items: 1,
                              slidesToSlide: 1
                            },
                          };

                        function mapMediaItems(mediaItems: { content_type: string; url: string; }[]) {
                            return mediaItems.map((item: { content_type: string; url: string; }) => ({
                              type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
                              url: item?.url,
                            }));
                          }

                        const imagesAndVideos = element.attributes.images ? mapMediaItems(element.attributes.images) : [];
                        const media = element.attributes.videos ? mapMediaItems(element.attributes.videos) : [];

                        const combinedMedia = [...imagesAndVideos, ...media];
                        const images = combinedMedia.filter(media => media.type === 'image');
                        const videos = combinedMedia.filter(media => media.type === 'video');


                        return (
                            <>
                                <Card style={{ maxWidth: 700, marginBottom: "20px", width: "100%",position:"relative" }}>
                                <Carousel
                                    responsive={responsive}
                                    swipeable={true}
                                    autoPlay={false}
                                    draggable={true}
                                    infinite={false}
                                    showDots={false}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style"
                                    >
                            {images.map((media, index) => (
                                <>
                                <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} /> 
                                </div>
                                </>
                        ))}
                            
                            {videos.map((media, index) => (
                            <div key={`video-${index}`}>
                                <>
                                <video style={{ width: "100%",height: "250px" }} controls>
                                    <source src={media.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                </>
                            </div>
                                 ))}
                </Carousel>
                                    <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Typography style={{ position: "absolute", right: "20px", top: "10px", background: "#D4E5DE", padding: "1px 11px", color: "#14362E", fontFamily: "MyFont", letterSpacing: "0.8px", borderRadius: "15px" }}>{!element.attributes.is_paid  ? "Free" : "Paid"}</Typography>
                                        <Box style={{ display: "flex",  gap:"10px",justifyContent: "start"}}>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography style={{ fontFamily: "MyFont" }}>{element.attributes.formatted_date}</Typography>
                                        </Box>
                                        <Box style={{ padding: "10px" }}>
                                            <Typography>{element.attributes.title}</Typography>
                                            <Typography style={{ color: "#64748B", fontSize: "12px", fontFamily: "MyFont", letterSpacing: "0.8px" }}>{element.attributes.notes}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{element.attributes.time_duration}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "12px" }}>{element.attributes.going} Going - {element.attributes.tickets_left} Tickets left</Typography>
                                        </Box>
                                        </Box>

                                        <Box>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "15px", marginTop: "40px", width: "80px", fontWeight: "bold", cursor: "pointer" }} onClick={this.handleDetailsOpenforMydata.bind(this, element)}>View details</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </>
                        )
                    }) : (
                        <>
                            <Box style={{width:"100%",height:"600px",display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <img style={{ marginTop: "50px" }} src={noevents} alt="" />
                            </Box>
                        </>
                    )
                }
            </>
        )
    }

    viewDetailsSidebar() {
        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 0 },
              items: 1,
              slidesToSlide: 1
            },
          };
        function mapMediaItemsData(mediaItemsData: { content_type: string; url: string; }[]) {
            return mediaItemsData.map((item: { content_type: string; url: string; }) => ({
              type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
              url: item?.url,
            }));
          }
        const imagesAndVideos = Object.keys(this.state.viewdetailsData).length > 0 && this.state.viewdetailsData.attributes?.images ? mapMediaItemsData(this.state.viewdetailsData.attributes.images) : [];
        const media =  Object.keys(this.state.viewdetailsData).length > 0 && this.state.viewdetailsData.attributes?.videos ? mapMediaItemsData(this.state.viewdetailsData.attributes.videos) : [];

        const combinedMedia = [...imagesAndVideos, ...media];
        const images = combinedMedia.filter(media => media.type === 'image');
        const videos = combinedMedia.filter(media => media.type === 'video');
        return (
            <>
                {
                    Object.keys(this.state.viewdetailsData).length > 0 && (
                      
                        <>
                            <BoxMain style={{borderTopLeftRadius:"15px"}}>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                                    <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px" }}>Event Details</Typography>
                                    <span onClick={this.drawerClose} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
                                </Box>
                                <Box style={webStyles.borderBottomstyle as any}></Box>
                                <Container>

                                    <Box style={{ display: 'flex', flexDirection: "column", alignItems: "center", padding: "10px" }}>
                                        <Box style={{ padding: "10px",width:"100%" }}>
                                            <Carousel
                                    responsive={responsive}
                                    autoPlay={false}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    infinite={false}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style"
          >
                            {images.map((media, index) => (
                                <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
                                </div>
                                ))}
                            {videos.map((media, index) => (
                            <div key={`video-${index}`}>
                                <video style={{ width: "100%", height: "250px" }} controls>
                                    <source src={media.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                                ))}
                                </Carousel>

                                        </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: "column", padding: "10px 20px" }}>
                                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold" }}>{this.state.viewdetailsData.attributes?.title}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "12px", color: "#64748B", marginBottom: "10px" }}>{this.state.viewdetailsData.attributes?.notes}</Typography>
                                        <Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={locationimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.city}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={calender} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.formatted_date}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={watchimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.time_duration}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={moneyImg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{!this.state.viewdetailsData.attributes?.is_paid ? "free" : `€ ${this.state.viewdetailsData.attributes?.amount}`}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <img src={groupimg} style={{ width: "18px" }} alt="" />
                                                {
                                                    this.state.viewdetailsData.attributes?.tickets_left == 0 ? (
                                                        <>
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>Sold Out</Typography>

                                                        </>
                                                    ):(
                                                        <>
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsData.attributes?.going} Going - {this.state.viewdetailsData.attributes?.tickets_left} Tickets left</Typography>
                                                        </>
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Container>

                                <Box style={{padding:"10px",marginTop:"170px",marginBottom:"18px"}}>
                                    {
                                        this.state.notgoing || this.state.viewdetailsData.attributes?.tickets_left == 0 ? (
                                            <>
                                    <Button variant="contained" style={{ width: "100%", background: "#E2E8F0", color: "white", textTransform: "capitalize", fontFamily: "MyFont",letterSpacing:"0.8px" }} >Going</Button>

                                            </>
                                        ):(
                                            <>
                                    <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont",letterSpacing:"0.8px" }} onClick={this.handleGoingPurchase.bind(this, this.state.viewdetailsData)}>Going</Button>
                                            </>
                                        )
                                    }
                                </Box>
                            </BoxMain>
                        </>
                    )
                }

            </>
        )
    }

    viewDetailsforMyEventSidebar() {

        const responsive = {
            desktop: {
              breakpoint: { max: 3000, min: 0 },
              slidesToSlide: 1,
              items: 1
            }
          }

        function mapMediaItemsValue(mediaItemsValue: { content_type: string; url: string; }[]) {
            return mediaItemsValue.map((item: { content_type: string; url: string; }) => ({
              type: item?.content_type?.startsWith('image/') ? 'image' : 'video',
              url: item?.url
            }));
        }

        const imagesAndVideos = Object.keys(this.state.viewdetailsforMyEventData).length > 0 && this.state.viewdetailsforMyEventData.attributes?.images ? mapMediaItemsValue(this.state.viewdetailsforMyEventData.attributes.images) : [];
        const media =  Object.keys(this.state.viewdetailsforMyEventData).length > 0 && this.state.viewdetailsforMyEventData.attributes?.videos ? mapMediaItemsValue(this.state.viewdetailsforMyEventData.attributes.videos) : [];

        const combinedMedia = [...imagesAndVideos, ...media]

        const videos = combinedMedia.filter(media => media.type === 'video');
        const images = combinedMedia.filter(media => media.type === 'image');
        return (
            
            <>
                {
                    Object.keys(this.state.viewdetailsforMyEventData).length > 0 && (
                        <>
                            <BoxMain style={{borderTopLeftRadius:"15px"}}>
                                <Box style={{ display: "flex",padding: "10px", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "21px",fontWeight: "bold" }}>My Event Details</Typography>
                                    <span onClick={this.drawerCloseMYEVENT} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
                                </Box>
                                <Box style={webStyles.borderBottomstyle as any}></Box>
                                <Container>

                                    <Box style={{ display: 'flex', flexDirection: "column", padding: "10px", alignItems: "center" }}>
                                        <Box style={{ width:"100%" ,padding: "10px"}}>
                                            <Carousel
                                    responsive={responsive}
                                    autoPlay={false}
                                    swipeable={true}
                                    draggable={true}
                                    showDots={false}
                                    infinite={false}
                                    partialVisible={false}
                                    dotListClass="custom-dot-list-style">
                            {images.map((media, index) => (
                                <div key={`image-${index}`} style={{ height: "200px", display: "flex", alignItems: "center" }}>
                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} /> 
                                </div>
                                ))}
                            {videos.map((media, index) => (

                            <div key={`video-${index}`}>
                                <video style={{ height: "250px" , width: "100%"}} controls>
                                    <source src={media.url} type="video/mp4" />
                                     Your browser does not support the video tag.
                                </video>

                            </div>
                                ))}
                                </Carousel>
                                        </Box>
                                    </Box>
                                    <Box style={{ display: 'flex', flexDirection: "column", padding: "10px 20px" }}>
                                        <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px" }}>{this.state.viewdetailsforMyEventData.attributes?.title}</Typography>
                                        <Typography style={{ fontFamily: "MyFont", letterSpacing: "0.8px",  color: "#64748B", fontSize: "12px", marginBottom: "10px" }}>{this.state.viewdetailsforMyEventData.attributes?.notes}</Typography>
                                        <Box>
                                            <Box style={{ display: "flex", marginBottom: "10px", alignItems: "center" }}>
                                                <img src={locationimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsforMyEventData.attributes?.city}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={calender} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsforMyEventData.attributes?.formatted_date}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={watchimg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsforMyEventData.attributes?.time_duration}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                                <img src={moneyImg} style={{ width: "18px" }} alt="" />
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{!this.state.viewdetailsforMyEventData.attributes?.is_paid ? "free" : `€ ${this.state.viewdetailsforMyEventData.attributes?.amount}`}</Typography>
                                            </Box>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <img src={groupimg} style={{ width: "18px" }} alt="" />
                                                {
                                                    this.state.viewdetailsforMyEventData.attributes?.tickets_left == 0 ? (
                                                        <>
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>Sold Out</Typography>

                                                        </>
                                                    ):(
                                                        <>
                                                <Typography style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "bold", paddingLeft: "4px", letterSpacing: "0.8px" }}>{this.state.viewdetailsforMyEventData.attributes?.going} Going - {this.state.viewdetailsforMyEventData.attributes?.tickets_left} Tickets left</Typography>
                                                        </>
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Container>
                                <Box style={{padding:"0 10px"}}>

                                <Box style={{padding:"10px",marginTop:"170px"}}>
                                    <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont",letterSpacing:"0.8px" }} onClick={this.handleZoommetopen.bind(this,this.state.viewdetailsforMyEventData.attributes?.zoom_meeting_url)}>Join Session</Button>
                                </Box>
                                <Box style={{padding:"10px"}}>
                                    <Button variant="outlined" style={{ width: "100%", color: "#14362E", textTransform: "capitalize", fontFamily: "MyFont",letterSpacing:"0.8px" }} onClick={this.handleNotgoing.bind(this,this.state.viewdetailsforMyEventData.id)}>Not Going</Button>
                                </Box>
                                </Box>
                            </BoxMain>
                        </>
                    )
                }

            </>
        )
    }

    DateAccordian() {
        const formattedRange = this.state.DateValue !== null && this.state.DateValue.length === 2
      ? `${this.formatDate(this.state?.DateValue[0])} - ${this.formatDate(this.state.DateValue[1])}`
      : '';
        return (
            <>
                <Accordion style={{ marginBottom: "10px",boxShadow:"none",border:"1px solid #E2E8F0" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Date Period </Typography> 
                            <Typography>{formattedRange}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                    <DatePicker
                                value={this.state.DateValue}
                                range
                                rangeHover
                                onChange={this.getDateRangeValue}
                                className="green"
                                style={{cursor:"pointer"}}
                            />
                            <Box onClick={this.cleardatefilter} style={{marginTop:"3px",marginLeft:"5px",cursor:"pointer"}}>
                            <i className="fa-solid fa-xmark"></i>
                            </Box>
                    </AccordionDetails>
                </Accordion>

            </>
        )
    }

    EventTypeAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px",boxShadow:"none",border:"1px solid #E2E8F0" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Event Type </Typography> 
                            <Typography>{this.state.eventMode == "in_person" ? "Inperson" : this.state.eventMode == "virtual"&&"Virtual" }</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>virtual</Typography>
                                <input type="radio" value="virtual" onChange={this.handleGetEventMode} style={{cursor:"pointer"}} name="eventmode" id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>In Person</Typography>
                                <input type="radio" value="in_person" onChange={this.handleGetEventMode} style={{cursor:"pointer"}} name="eventmode" id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    cityAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px",boxShadow:"none",border:"1px solid #E2E8F0" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>City </Typography> 
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    this.state.selectedcity && this.state.selectedcity.map((element: any, index: any) => {
                                        return (
                                            <>
                                                <Typography style={{fontFamily:"MyFont",letterSpacing:"0.8px"}}>{`${element.value},`}</Typography>
                                            </>
                                        )
                                    })
                                }

                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width:"100%" }}>
                            <Box style={{ display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "14px" }}>
                                <Box style={{ width: "100%",  position: "relative",marginBottom: "10px", border: "1px solid #CBD5E1", marginTop: "20px", display: "flex", alignItems: "center", borderRadius: "5px", padding: "0 5px", fontFamily: "MyFont" }}>
                                    <i className="fa-solid fa-magnifying-glass" style={{ color:"#94A3B8" }}></i>
                                    <input type="text" value={this.state.setSearchvalue} style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none", fontFamily: "MyFont",cursor:"pointer" }} onChange={this.handlesearchCity} name="" id="" />
                                    {
                                        this.state.setSearchvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer" }} data-test-id="removesearch" onClick={this.removesearchcitydata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8" }}></i>
                                    }
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
                                {
                                    this.state.selectedcity && this.state.selectedcity.map((element: any, index: any) => (
                                        <Box key={1} style={{ borderRadius: "20px", display: "flex", alignItems: "center", width: "auto", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                                            <Typography style={{ fontSize: "10px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
                                            <span onClick={this.handleselectmemberDelete.bind(this, index)} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
                                        </Box>
                                    ))
                                }
                            </Box>
                            {
                                this.state.cityDataList && this.state.cityDataList.map((element: any, index: any) => {
                                    const checkdata = this.state.selectedcity.map((ele: any) => ele.id == element.id)
                                    return (
                                        <>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <Typography>{element.name}</Typography>
                                                <input type="checkbox" checked={checkdata.some((eleme: any) => eleme)} onClick={(elementvalue) => this.getvaluemembercheck(elementvalue, element)} name="" id="" />
                                            </Box>
                                        </>
                                    )
                                })
                            }

                            <Box style={webStyles.borderBottomstyle as any}></Box>

                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    categoryAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px",boxShadow:"none",border:"1px solid #E2E8F0" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Category </Typography> 
                            <Box style={{ display: "flex", flexWrap: "wrap" }}>
                                {
                                    this.state.selectedcategory && this.state.selectedcategory.map((element: any, index: any) => {
                                        return (
                                            <>
                                                <Typography>{`${element.value},`}</Typography>
                                            </>
                                        )
                                    })
                                }
                            </Box>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "14px" }}>
                                <Box style={{ width: "100%", marginBottom: "10px", border: "1px solid #CBD5E1", position: "relative", marginTop: "20px", display: "flex", alignItems: "center", borderRadius: "5px", padding: "0 5px", fontFamily: "MyFont" }}>
                                    <i className="fa-solid fa-magnifying-glass" style={{ color: "#94A3B8" }}></i>
                                    <input type="text" style={{ width: "100%", padding: "10px 5px", borderRadius: "5px", border: "none", outline: "none", fontFamily: "MyFont",cursor:"pointer" }} value={this.state.setSearchCategoryvalue} onChange={this.handlesearchCategoryData} name="" id="" />
                                    {
                                        this.state.setSearchCategoryvalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer" }} data-test-id="removesearch" onClick={this.removesearchcategorydata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8" }}></i>
                                    }
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {
                                    this.state.selectedcategory && this.state.selectedcategory.map((element: any, index: any) => (
                                        <Box key={1} style={{ borderRadius: "20px", display: "flex", alignItems: "center", width: "auto", background: "#F1F5F9", flexWrap: "wrap", marginBottom: "15px" }}>
                                            <Typography style={{ fontSize: "10px", marginRight: "8px", color: "#475569", padding: "5px" }}>{element.value}</Typography>
                                            <span onClick={this.handleselectcategoryDelete.bind(this, index)} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark" style={{ fontSize: "10px" }}></i></span>
                                        </Box>
                                    ))
                                }
                            </Box>
                            {
                                this.state.categoryDataList && this.state.categoryDataList.map((element: any, index: any) => {
                                    const checkdata = this.state.selectedcategory.map((ele: any) => ele.id == element.id)
                                    return (
                                        <>
                                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                                <Typography>{element.attributes.name}</Typography>
                                                <input type="checkbox" name="" checked={checkdata.some((eleme: any) => eleme)} onClick={(elementvalue) => this.getvaluecategorycheck(elementvalue, element)} id="" />
                                            </Box>
                                        </>
                                    )
                                })
                            }
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    EventPriceAccordian() {
        return (
            <>
                <Accordion style={{ marginBottom: "10px",boxShadow:"none",border:"1px solid #E2E8F0" }}>
                    <AccordionSummary
                        expandIcon={<i className="fa-solid fa-caret-down"></i>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                            <Typography>Event Price</Typography> 
                            <Typography>{this.state.eventPrice}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ width: "100%" }}>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>All</Typography>
                                <input type="radio" name="price" value="All" onChange={this.handleGetEventPrice} id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>
                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>Paid</Typography>
                                <input type="radio" name="price" value="Paid" onChange={this.handleGetEventPrice} id="" />
                            </Box>
                            <Box style={webStyles.borderBottomstyle as any}></Box>

                            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                                <Typography>Free</Typography>
                                <input type="radio" name="price" value="Free" onChange={this.handleGetEventPrice} id="" />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </>
        )
    }

    filterDrawer() {
        return (
            <>
                <BoxMain style={{borderTopLeftRadius:"15px"}}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px" }}>
                        <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px" }}>Filter</Typography>
                        <span onClick={this.drawerClosefilter} style={{ cursor: "pointer" }}><i className="fa-solid fa-xmark"></i></span>
                    </Box>
                    <Box style={webStyles.borderBottomstyle as any}></Box>

                    <Container>
                        <Box style={{ padding: "10px" }}>

                            {this.DateAccordian()}

                            {this.EventTypeAccordian()}

                            {this.cityAccordian()}

                            {this.categoryAccordian()}

                            {this.EventPriceAccordian()}
                        </Box>

                    </Container>

                    <Box style={{padding:"0 10px",marginTop:"200px",marginBottom:"20px"}}>
                        <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleApplyfilter}>Apply Filter</Button>
                    </Box>
                </BoxMain>
            </>
        )
    }

    ErrorModel() {
        return (
            <>
                <Modal
                    open={this.state.errorTicket}
                    onClose={this.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                          }}
                      
                    >
                    <Box sx={{ width: { lg:"400px",xs: '90%', sm: 400 }, padding: 4, }}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center",background:"#fff" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={corssImg} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px", fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "18px" }}>Ticket Unavailable</Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px" }}>Ticket unavailable Please try again <br /> later.</Typography>
                            <Box style={{ width: "100%", marginTop: "10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleClose}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                    </Box>
                </Modal>
            </>
        )
    }

    EventSucessModel() {
        return (
            <>
                <Modal
                    open={this.state.TicketSucess}
                    onClose={this.handleCloseSucess}
                    aria-labelledby="m'odal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100vh',
                            alignItems: 'center',
                          }}
                      
                    >
                    <Box sx={{width: { sm: '400px',xs: '90%',lg:"400px" }, padding: 4}}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center" ,padding:"14px",background:"#fff" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={SucessImg} style={{width:"35px"}} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px", fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "18px" }}>You’re set for your next event!</Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px" }}>Your ticket will be available in the My Events section.</Typography>
                            <Box style={{ width: "100%", marginTop: "15px",marginBottom:"10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleCloseSucess}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                    </Box>
                </Modal>
            </>
        )
    }

    EventPaymentFaildModel(){
        return (
            <>
                 <Modal
                    open={this.state.paymentfailedalert}
                    onClose={this.handleClosePaymentAlert}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                        <Box sx={{
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                    >
                    <Box sx={{width:{ xs: '90%',lg:"400px" ,sm: '400px' }, padding: 5,}}>
                        <Box style={{ display: "flex", alignItems: "center",background:"#fff" ,padding:"12px",flexDirection: "column" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={corssImg} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px", fontFamily: "MyFont", letterSpacing: "0.8px", fontWeight: "bold", fontSize: "18px" }}>Payment Declined</Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "13px" }}>We couldn't process your payment. Please check your card details or try a different payment method.</Typography>
                            <Box style={{ width: "100%", marginTop: "15px",marginBottom:"11px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handleClosePaymentAlert}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                    </Box>
                </Modal>
            </>
        )
    }

    EventCancelSucessModel() {
        return (
            <>
                <Modal
                    open={this.state.NotGoingSucess}
                    onClose={this.handlecloseNotgoing}
                    aria-labelledby="m'odal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                     <Box 
                        sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh',
                          }}
                    >
                    <Box sx={{width: { xs: '90%', sm: '401px',lg:"401px" },padding: 4,}}>
                        <Box style={{ display: "flex",alignItems: "center",background:"#fff" ,padding:"13px",flexDirection: "column" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={SucessImg} style={{width:"36px"}} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "14px",fontWeight:"bold" }}>You have unregistered from this event</Typography>
                            <Typography style={{ marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "11px" }}>Your tickets will be removed from the My Events section</Typography>
                            <Box style={{ width: "100%", marginTop: "15px",marginBottom:"10px" }}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont" }} onClick={this.handlecloseNotgoing}>Understand</Button>
                            </Box>
                        </Box>

                    </Box>
                    </Box>
                </Modal>
            </>
        )
    }

    EventCancelSucessModelforPaid() {
        return (
            <>
                <Modal
                    open={this.state.NotGoingSucessforPaid}
                    onClose={this.handlecloseNotgoing}
                    aria-labelledby="m'odal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                      <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100vh',
                          }}
                      
                    >
                     <Box sx={{width: { lg:"400px",xs: '90%', sm: '400px' }, padding: 4,}}>
                        <Box style={{ display: "flex", flexDirection: "column", alignItems: "center",background:"#fff" ,padding:"15px" }}>
                            <Typography style={{ marginTop: "10px" }}><img src={SucessImg} style={{width:"34px"}} alt="" /></Typography>
                            <Typography style={{ marginTop: "10px",  fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "14px",fontWeight:"bold",textAlign: "center",}}>Your tickets are being refunded</Typography>
                            <Typography style={{marginTop: "10px", textAlign: "center", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "11px"}}>Your tickets will be refunded in accordance with our refund policy</Typography>
                            <Box style={{ marginTop: "15px" , marginBottom:"10px",width: "100%"}}>
                                <Button variant="contained" style={{ width: "100%", background: "black", color: "white", textTransform: "capitalize", fontFamily: "MyFont",letterSpacing:"0.8px" }} onClick={this.handlecloseNotgoingforpaid}>Understand</Button>
                            </Box>
                        </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        )
    }

    render() {
        return (
            <>
                <AppHeader component="Events" />

                <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "#F8FAFC", padding: "10px" }}>
                    <Card style={{ maxWidth: 750, marginBottom: "10px", minHeight: "100vh", width: "100%", fontFamily: "MyFont", borderRadius: "13px", padding: "10px", position: "relative" }}>
                        <Box sx={{display: { xs: "block", sm: "none"} , fontFamily: "MyFont",textAlign:"center",borderBottom:"1px solid lightgray",letterSpacing:"0.9px",fontWeight:"bold",fontSize:"18px"}}>Events</Box>
                        <Box style={webStyles.tabStyle as any}>
                        <Box style={{ background: "#F8FAFC",display:"flex",padding:"4px",borderRadius:"18px",width:"70%",justifyContent:"space-between"}}>
                            <Typography style={this.state.Tabview == "allevents" ? webStyles.posttab as any : { width:"40%" ,color: "#64748B", borderRadius: 25, textAlign: "center", cursor: "pointer",padding:"0px 10px", fontFamily: "MyFont",background: "#F8FAFC" }} onClick={this.handleTabChange.bind(this, "allevents")}>All Events</Typography>
                            <Typography style={this.state.Tabview == "myevents" ? webStyles.posttab as any : {width:"40%", background: "#F8FAFC", color: "#64748B", borderRadius: 25, fontWeight: "bold", fontFamily: "MyFont", cursor: "pointer",textAlign:"center",padding:"0px 10px" }} onClick={this.handleTabChange.bind(this, "myevents")}>My Events</Typography>
                            </Box>
                        </Box>
                        <Box sx={{display: { xs: "none", sm: "none",lg:"block"}, position: "absolute", top: "22px", right: "33px"}}>
                            <img src={filterImg} alt="" onClick={this.handleFilterDrawerOpen} style={{cursor:"pointer"}} />
                        </Box>
                        <Box sx={{display: { xs: "block", sm: "block",lg:"none"}, position: "absolute", top: "6px", right: "33px"}}>
                            <img src={filterImg} alt="" onClick={this.handleFilterDrawerOpen} style={{cursor:"pointer"}} />
                        </Box>
                        <Container>
                            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "10px" }}>
                                {
                                    this.state.Tabview == "allevents" ? (
                                        <>
                                            {this.AlleventDisplay()}
                                        </>
                                    ) : (
                                        <>
                                            {this.MyeventDisplay()}
                                        </>
                                    )
                                }
                            </Box>
                        </Container>
                    </Card>
                </Box>
                <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.open} onClose={this.toggleDrawer.bind(this, false)}>
                    {this.viewDetailsSidebar()}
                </Drawer>

                <Drawer style={webStyles.drawer as any} anchor="right" open={this.state.openformyevent} onClose={this.toggleMyEventDrawer.bind(this, false)}>
                {this.viewDetailsforMyEventSidebar()}
                </Drawer>

                <Drawer style={webStyles.drawer as any}  anchor="right" open={this.state.openFilter} onClose={this.toggleDrawerfilter}>
                    {this.filterDrawer()}
                </Drawer>
                {this.ErrorModel()}
                {this.EventSucessModel()}
                {this.EventCancelSucessModel()}
                {this.EventCancelSucessModelforPaid()}
                {this.EventPaymentFaildModel()}
                <AppFooter component="Events" />
            </>
        )
    }
}

const BoxMain = styled(Box)({

    width: "420px",
    "@media(max-width: 600px)": {
        width: '100vw',
    },
})

const webStyles = {
    tabStyle: {
        display: "flex",
        margin: "auto",
        marginTop: "0px",
        padding: "10px 8px",
        fontFamily: "MyFont",
        width: "300px",
        justifyContent:"space-around"
    },
    posttab: {
        border: "1px solid #73A58E",
        width: "40%",
        fontWeight: "bold",
        textAlign: "center",
        cursor: "pointer",
        fontFamily: "MyFont",
        borderRadius: 25,
        background: "#F8FAFC",
        padding:"0px 10px"
    },
    drawer: {
        width: "501px",
        display: "block",
        overflowY: "unset",
        '@media (min-width: 600px)': {
            display: "none",
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "240px",
        },
    },
    responsivetitle:{
        display:"none",
        '@media (max-width: 600px)': {
            display: "block !important",
        }
    },
    borderBottomstyle: {
        marginTop: "10px",
        border: "1px solid #E2E8F0",
    }

}
// Customizable Area End