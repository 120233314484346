import React from "react";

// Customizable Area Start
import { Box, Button, CircularProgress, Container, ThemeProvider, Typography, createTheme } from "@material-ui/core";
import { IoMdArrowBack } from "react-icons/io";
import { StyleSheet } from "react-native";
const theme = createTheme({
  palette: {
    primary: {
      main: "#14362E",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  htmlContent(htmlString: string) {
    return <Typography component="div" style={{color: "#0F172A",
    fontSize: "14px",
    fontFamily: "MyFont",
    lineHeight:'22px',}} dangerouslySetInnerHTML={{__html: htmlString}}/>
  }
  termsCondsWeb(){
    const data = [
      {
        heading: 'Terms & Conditions',
        details: this.getTermDetailsByName('Terms & Conditions')
      },
      {
        heading: 'General Data Protection Regulation (EU GDPR)',
        details: this.getTermDetailsByName('General Data Protection Regulation (EU GDPR)')
      },
      {
        heading: 'Privacy Policy',
        details: this.getTermDetailsByName('Privacy Policy')
      }
    ]
    return(
      <div style={{padding:0, borderTopRightRadius: "24px"}} >
                <Box>
                  <Typography style={{
                    color: "#14362E",
                    fontSize: "25px",
                    fontFamily:'MyFont',
                    fontWeight: 'bold',
                    margin: "0 0 16px 0",
                    lineHeight:'32px',
                    letterSpacing:'1.5px'
                  }}><Box data-test-id="headingWeb">{data[this.state.count].heading}</Box></Typography>
                  {!data[this.state.count].details &&<Box sx={{display:'flex', justifyContent:'center'}}><CircularProgress  /></Box> }
                  {data[this.state.count].details && <>
                      {this.htmlContent(data[this.state.count].details as string)}
                    <Box display={'flex'} justifyContent={'center'}>
                  <Button
                    data-test-id={"agreeButtonWeb"}
                    variant="contained"
                    fullWidth
                    style={{
                      color: "white",
                      backgroundColor: "#14362E",
                      borderRadius: "8px",
                      fontFamily: "MyFont",
                      height: "45px",
                      width:"100%",
                      margin:'10px auto',
                      fontSize: "16px",
                      fontWeight: 700,
                      textTransform: "capitalize",
                    }}

                    onClick={this.handleTermsCondsAcceptClick}
                  >
                    Agree
                  </Button>
                </Box>
                  </>}
                </Box>
        
              </div>
    )
                  }


  termsCondsMobile(){
    const data = [
      {
        heading: 'Terms & Conditions',
        details: this.getTermDetailsByName('Terms & Conditions')
      },
      {
        heading: 'General Data Protection Regulation (EU GDPR)',
        details: this.getTermDetailsByName('General Data Protection Regulation (EU GDPR)')
      },
      {
        heading: 'Privacy Policy',
        details: this.getTermDetailsByName('Privacy Policy')
      }
    ]
    return(
      <div>
          <Box padding={'0'} style={{ borderTopRightRadius: "24px"}}>
            <Typography variant="h3" data-test-id="headingMobile" style={{
              color: "#14362E",
              fontSize: "25px",
              fontWeight: 'bold',
              margin: "0 0 16px 0",
              fontFamily:'MyFont',
              lineHeight:'32px',
              letterSpacing:'1.5px'
            }}>
                {data[this.state.count].heading}
            </Typography>
            {
              !data[this.state.count].details && <Box sx={{display:'flex', justifyContent:'center'}}><CircularProgress/></Box>
            }
            {data[this.state.count].details && <>
              <Box data-test-id="detailsMobile">
                {this.htmlContent(data[this.state.count].details as string)}
              </Box>
              <Box display={'flex'} justifyContent={'center'}>
                <Button
                  data-test-id={"agreeButtonMobile"}
                  variant="contained"
                  fullWidth
                  style={{
                    color: "white",
                    backgroundColor: "#14362E",
                    borderRadius: "8px",
                    fontFamily: "MyFont",
                    height: "45px",
                    width:"100%",
                    margin:'10px auto',
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform: "capitalize",
                  }}
                  onClick={this.handleTermsCondsAcceptClick}
                >
                  Agree
                </Button>
              </Box>
            </>}
        </Box>
    </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
       <ThemeProvider theme={theme}>
        <Container maxWidth={false} style={{ display: "flex" }}>
          <Box display={{ xs: "none", sm: "none", md: "flex" }} style={{ width: "100%" }}>
          {this.termsCondsWeb()}
          </Box>
        </Container>
        <Box display={{ xs: "flex", sm: "flex", md: "none" }}>
        {this.termsCondsMobile()}
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 20,
    borderTopRightRadius: 24
  },
  spinnerContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    flex: 1,
    padding: 20,
    fontWeight: "600",
    fontSize: 22,
  },
  termsCondsTitle: {
    fontWeight: "600",
    marginBottom: 20,
    fontSize: 22,
  },
  scrollView: {
    height: "100%",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "auto",
    marginBottom: 40,
    marginRight: 20,
  },
  termsCondsContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomWidth: 1,
    padding: 20,
  },
  button: {
    backgroundColor: "blue",
    marginLeft: 10,
    width: 120,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4,
    alignSelf: "flex-end",
  },
  buttonLabel: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: 20,
  },
});
// Customizable Area End