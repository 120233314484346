import React from "react";
// Customizable Area Start
import UserProfileBasicController, {
    Props
} from "./UserProfileBasicController";
import ShareDrawer from "../../../components/src/ShareDrawer.web";
import { Container, Box, IconButton, Badge, Card, CardContent, Divider, Typography, Menu, InputAdornment, MenuItem, TextField, Drawer, List, ListItem, Collapse, Avatar, Button, Dialog, DialogTitle, DialogContent, Tabs, Tab, CardMedia, DialogContentText, DialogActions, CardHeader, Modal, Paper, styled, StyledComponentProps, CircularProgress } from "@material-ui/core";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { AppHeader } from "../../../components/src/AppHeader.web";
import { AppFooter } from "../../../components/src/AppFooter.web";
import { avatar, LinkImg, reviewsImg, bookmarkImg, other_profile } from "./assets";
import { Toaster } from 'react-hot-toast';
import { Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from "react-mentions";
import moment from "moment";
import {
    ArrowBack,
    MailOutline,
    MoreVert as MoreVertIcon,
} from "@material-ui/icons";
import Carousel from "react-multi-carousel";
import CloseIcon from "@material-ui/icons/Close";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import ReportIcon from '@material-ui/icons/Report';
import BlockIcon from '@material-ui/icons/Block';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Mail from '@material-ui/icons/Mail';
import EmojiPicker from "emoji-picker-react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom"

interface DrawerHeaderProps {
    title: string;
    onClose: () => void;
}

interface UserSuggestion extends SuggestionDataItem {
    profile_picture: string;
    email: string;
}

interface SearchInputProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
}

interface SelectedMembersProps {
    members: Array<{ value: string }>;
    onDelete: (index: number) => void;
}

interface ContinueButtonProps {
    onClick: () => void;
}
export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
    }

    likeCount=(element:any)=>{
      return element.attributes.like_counts === "0" ? "Like": element.attributes.like_counts;
    }
    
    Comment=(element:any)=>{
    return element.attributes.comment_counts == "0" ? "Comment" : element.attributes.comment_counts
    }
    
    repliesCount = (element: any) => {
      return element.attributes.replies_counts == "0" ? "Reply": element.attributes.replies_counts;
    }

    userData({ fullName, imgSrc = avatar, role, isOnline, id }: { fullName: string, imgSrc: string, role: string, isOnline: boolean, id: number }) {
        return (<Box style={{ borderBottom: '1px solid #CBD5E1', display: 'flex', padding: '5px 0', paddingLeft: '20px',  }} key={fullName} onClick={() => this.handleGoToUser(id)}>
            <StyledBadge
                overlap="circular"
                className={isOnline ? 'online' : 'offline'}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="dot"
                data-test-id='user-badge'
            >
                <Avatar src={imgSrc} style={{cursor: 'pointer'}} onClick={() => this.handleGoToUser(id)} />
            </StyledBadge>
            <Box style={{ paddingLeft: '16px' }}>
                <Typography style={{ color: '#0F172A', fontSize: '16px', fontWeight: 'bold' }}>{fullName}</Typography>
                <Typography style={{ color: '#64748B', fontSize: '11px' }} >{role}</Typography>
            </Box>
        </Box>)
    }
    acceptedInvitedDrawer({ users, title, onClose, open }: { users: any[], title: string, onClose: () => void, open: boolean }) {
        return (
            <StyledDrawer anchor="right" open={open}  onClose={onClose}>
                <Box style={webStyle.categoryDropwdown as any}>
                    <Typography style={webStyle.acceptedIinvitedTitle}>{title}</Typography>
                    <span style={{ cursor: "pointer" }} onClick={onClose}><i className="fa-solid fa-xmark"></i></span>
                </Box>
                <Divider />
                <Box style={{ padding: '25px 10px' }}>
                    {
                        users.map(user =>
                            this.userData({
                                fullName: `${user.first_name} ${user.last_name}`,
                                imgSrc: user.profile_picture.url,
                                role: user.role,
                                isOnline: user.online,
                                id: user.id
                            })
                        )
                    }
                </Box>
            </StyledDrawer>
        )
    }
    Blockuserdrawercode() {
        const data = [{
            title: "Unwanted Interactions",
            value: "Blocking helps prevent harassment, bullying, and unwanted attention from other users."
        }, {
            title: "Content Shielding",
            value: "Blocking shields users from offensive or inappropriate content posted by others."
        }, {
            title: "Privacy Control",
            value: "Users can block others to control who views their profile, posts, and personal information, addressing privacy concerns."
        }, {
            title: "Maintaining a Positive Environment",
            value: "Blocking aids in curating a positive online experience by preventing distractions, trolls, and disruptive behavior."
        }, {
            title: "Safety and Trust",
            value: "Blocking serves as a layer of protection against safety concerns, trust breaches, and unwanted solicitations, ensuring a safer and more trustworthy online presence."
        }]
        return (
            <>
                <BoxMain style={{borderTopLeftRadius: "25px",borderLeft:"1px solid #e3e3e3"}}>
                    <Box style={webStyle.categoryDropwdown as any}>
                        <Typography style={{ paddingLeft: "10px", fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "16px" }}>Block Cameron Rain</Typography>
                        <span style={{ cursor: "pointer" }} onClick={this.OpenBlockDrawerclose}><i className="fa-solid fa-xmark"></i></span>
                    </Box>

                    <Box style={webStyle.borderBottomstyle as any}></Box>

                    <Box style={{ padding: "10px" }}>
                        <Typography style={{ paddingLeft: "12px", fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "14px" }}>Why are you blocking this user?</Typography>
                    </Box>
                    <Box style={webStyle.borderBottomstyle as any}></Box>
                    {
                        data.length > 0 && data.map((element, index) => {
                            return (
                                <>
                                    <Box style={{ display: "flex", alignItems: "center", background: this.state.setBlockKey === index ? "#D4E5DE" : "", maxWidth: "500px" }}>
                                        <Box style={{ padding: "10px", cursor: "pointer", width: "100%" }} onClick={this.setblockcolor.bind(this, element, index)}>
                                            <Typography style={{ paddingLeft: "12px", letterSpacing: "0.8px", fontSize: "15px", fontFamily: "MyFont" }}>{element.title}</Typography>
                                            <Typography style={{ paddingLeft: "12px", letterSpacing: "0.8px", fontSize: "14px", fontFamily: "MyFont" }}>{element.value}</Typography>
                                        </Box>
                                        {
                                            this.state.setBlockKey === index ?
                                                <i style={{ marginRight: "10px", border: "1px solid #14362E", padding: "3px", borderRadius: "50%" }} className="fa-solid fa-check"></i> : ""
                                        }
                                    </Box>
                                    <Box style={webStyle.borderBottomstyle as any}></Box>
                                </>
                            )
                        })
                    }

                    <Box style={{ padding: "10px" }}>
                        {
                            this.state.setBlockKey !== undefined && this.state.setBlockKey !== null && this.state.setBlockKey !== ""  ? (
                                <>
                        <Button variant="contained" style={{ background: "#14362E", color: "#fff", width: "100%", textTransform: "capitalize",fontFamily:"MyFont",letterSpacing:"0.8px" }} onClick={this.OpenBlockmodel}>continue</Button>

                                </>
                            ):(
                                <>
                        <Button variant="contained" style={{ background: "#f2f2f2", color: "#fff", width: "100%", textTransform: "capitalize",fontFamily:"MyFont",letterSpacing:"0.8px" }} >continue</Button>

                                </>
                            )
                        }
                    </Box>

                    <Dialog
                        open={this.state.openblockModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ width: "100vw", margin: "auto" }}
                    >
                        <Box style={{borderTopLeftRadius: "10px",borderTopRightRadius: "10px",borderBottomLeftRadius: "10px",borderBottomRightRadius: "20px",maxWidth:"400px",width:"100%"}}>
                        <Typography id="alert-dialog-title" style={{ textAlign: "center",fontFamily:"MyFont",letterSpacing:"0.8px",fontSize:"18px",fontWeight:"bold",color:"#14362E" }}>
                            Block User
                        </Typography>
                        <DialogContent>
                            <Typography id="alert-dialog-description" style={{fontFamily:"MyFont",letterSpacing:"0.8px",color:"#0F172A",fontSize:"14px",fontWeight:"500"}}>
                                We understand that sometimes you need to remove certain people from your circle, that's ok! If it's the right thing for you, then it's the right thing to do! And don't worry, we will keep this between us, they won't know you have blocked them.
                            </Typography>
                        </DialogContent>
                        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button variant="contained" style={{ background: "#14362E", color: "#fff", width: "280px",fontFamily:"MyFont",letterSpacing:"0.8px",cursor:"pointer",textTransform:"capitalize" }} onClick={this.cancelButton}>Cancel</Button>
                            <Button variant="contained" style={{ width: "280px", border: "1px solid #73A58E",fontFamily:"MyFont",letterSpacing:"0.8px",cursor:"pointer",textTransform:"capitalize" }} onClick={this.confirmButton}>
                                Confirm
                            </Button>
                        </DialogActions>
                        </Box>
                    </Dialog>
                </BoxMain>
            </>
        )
    }

    actionUIData = (element: any, index: string) => {
        return (
            <Box key={element.id} style={{ cursor: "pointer", textAlign: "right" }}>
                
                {
                    element?.attributes?.account_id === +this.state.MyprofileData.id && <> 
                   
                    <IconButton
                        data-test-id="handleOpenMenu"
                        edge="start"
                        aria-haspopup="true"
                        aria-controls={`menu-${index}`}
                        aria-label="more"
                        onClick={(event) => this.handleOpenVerticalMenu(event, element.id)}
                    >
                    <MoreVertIcon />
                    </IconButton>
                    </>
                }
               
                <MenuBox style={{ position: "relative" }}>
                    <Menu
                        key={index}
                        anchorEl={this.state.openVerticalMenu}
                        id={`menu-${index}`}
                        open={this.state.openMenuId === element.id}
                        onClick={this.handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        style={{
                            marginTop: "34px"
                        }}
                    >
                        <MenuItem data-test-id="editMypost" style={{ color: "#D1584C", fontFamily: 'MyFont' }} onClick={() => this.gotoPostCreation(element.attributes?.id)}> Edit</MenuItem>
                        <MenuItem data-test-id="deleteMypost" style={{ color: "#D1584C" , fontFamily: 'MyFont'}} onClick={() => this.setdeleteItemClick(`${element.attributes.posted_user.first_name + " " + element.attributes.posted_user.last_name}`, element.attributes?.id)}>Delete</MenuItem>
                    </Menu>
                </MenuBox>
            </Box>
        );
    };

    otheruserpostexistData() {
        return (
            <>
                <Box key={1} style={{ display: "flex", alignItems: "center", flexDirection: "column", background: "white" }}>

                    {
                        this.state.OtheruserPostData.map((element: any, index: any) => {
                            const isFavorite = element.attributes.is_favorite;
                            const responsiveforotheruser = {
                                desktop: {
                                    breakpoint: { max: 3000, min: 0 },
                                    items: 1,
                                    slidesToSlide: 1
                                },
                            };
                            const combinedMediaforotheruser = [
                                ...(element.attributes.images_and_videos ? element.attributes.images_and_videos.map((item: { url: any; }) => ({ type: 'image', url: item?.url })) : []),
                                ...(element.attributes.media ? element.attributes.media.map((item: { content_type: string; url: any; }) => ({ type: item?.content_type?.startsWith('image/') ? 'image' : 'video', url: item?.url })) : []),
                            ];
                            const imagesforotheruser = combinedMediaforotheruser.filter(media => media.type === 'image');
                            const videosforotheruser = combinedMediaforotheruser.filter(media => media.type === 'video');

                            return (
                                <>

                                    <Card key={avatar} style={{ maxWidth: 750, width: "100%", fontFamily: "MyFont",  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>

                                        <CardHeader
                                            style={{ fontFamily: "MyFont" }}
                                            avatar={
                                                <Avatar src={element.attributes?.posted_user?.profile_picture?.url} aria-label="recipe">

                                                </Avatar>
                                            }
                                            action={
                                                <>
                                                {isFavorite && (
                                                    <IconButton
                                                    data-test-id="icon-button"
                                                    edge="start"
                                                    aria-haspopup="true"
                                                    aria-controls={`menu-${element.id}`}
                                                    aria-label="more"
                                                    onClick={() => this.getToFavriouteData(element.id)}
                                                    >
                                                    <BookmarkIcon style={{ color: "#14362E" }} />
                                                    </IconButton>
                                                    )}
                                                <i className="fa-solid fa-ellipsis-vertical" style={{ marginTop: "12px", marginRight: "5px" }} onClick={(e) => {
                                                    this.handleOpenMenuAction(e, element)}}></i>
                                                </>
                                            }

                                            title={<div style={{ fontFamily: "MyFont", fontWeight: "700", fontSize: "18px", letterSpacing: "1px" }}>{element.attributes.posted_user.first_name + element.attributes.posted_user.last_name} .  <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>CEO</span></div>}
                                            subheader={<div style={{ fontFamily: "MyFont", fontSize: "14px", letterSpacing: "1px", fontWeight: "400" }}>{moment(element.attributes.created_at).format('dddd, hh:mm A')}<span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes.category}</span></div>}
                                        />
                                        {this.renderOtherUserMenu(element)}
                                        <Typography style={{
                                            fontSize: "14px", paddingLeft: "17px", fontFamily: "MyFont", fontWeight: 500, letterSpacing: "1px", wordWrap: 'break-word',
                                            fontStyle: "normal"
                                        }}>{element.attributes.body}</Typography>

                                        {
                                            (element.attributes.link_title && element.attributes.external_link) && (
                                                <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex",  alignItems: "center",justifyContent: "space-between", height: "60px", marginTop: "10px", padding: "4px", width: "95%", margin: "15px auto", borderRadius: "10px", marginBottom: "10px" }}>
                                                    <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" ,fontFamily:"MyFont"}}>
                                                        <img src={LinkImg} alt="link img" />
                                                         
                                                        <Box style={{ marginLeft: "10px" }}>
                                                            <p>{element.attributes.link_title}</p>
                                                        </Box>
                                                    </Box>

                                                    <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
                                                        <span style={{ fontSize: "20px",fontFamily:"MyFont" }}><i className="fa-regular fa-copy"></i></span>
                                                    </Button>
                                                </Box>
                                            )
                                        }



                                        <Carousel
                                            responsive={responsiveforotheruser}
                                            autoPlay={false}
                                            swipeable={true}
                                            draggable={true}
                                            showDots={false}
                                            infinite={false}
                                            partialVisible={false}
                                            dotListClass="custom-dot-list-style"
                                        >
                                            {imagesforotheruser.map((media, index) => (
                                                <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
                                                </div>
                                            ))}
                                            {videosforotheruser.map((media, index) => (
                                                <div key={`video-${index}`}>
                                                    <video style={{ width: "100%", height: "250px" }} controls>
                                                        <source src={media.url} style={{ fontFamily: "MyFont" }} type="video/mp4" />
                                                        Your browser does not support the video tag. 
                                                    </video>
                                                </div>
                                            ))}
                                        </Carousel>

                                        <CardContent>

                                            <Box style={{ display: "flex", justifyContent: "space-evenly", fontFamily: "MyFont", alignItems: "center" }}>
                                                <Box style={{ fontFamily: "MyFont" }}>
                                                    {element.attributes.is_liked ? (
                                                        <>
                                                            <i className="fa-solid fa-heart" style={{ color: "red", cursor: "pointer", marginRight: "5px" }} onClick={this.likePost.bind(this, element)}></i>{this.likeCount(element)}
                                                        </>
                                                    ) : (<><i className="fa-regular fa-heart" style={{ cursor: "pointer", marginRight: "5px" }} onClick={this.likePost.bind(this, element)}></i>{element.attributes.like_counts == "0" ? "Like" : element.attributes.like_counts} </>)}&nbsp;&nbsp;
                                                </Box>
                                                <Box onClick={this.handleClick.bind(this, element.id)} style={{ fontFamily: "MyFont", letterSpacing: "0.8px",cursor:"pointer" }}>
                                                    <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                                                    {this.Comment(element)}
                                                </Box>
                                                <Box onClick={this.drawerSidebaar.bind(this, element.id)} style={{ fontFamily: "MyFont", letterSpacing: "0.9px",cursor:"pointer" }}>
                                                    <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                                    Share
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    {this.renderDrawerfunction(this.state.OtheruserPostData)}
                                    
                                </>
                            )
                        })
                    }



                </Box>
            </>
        )
    }

    renderLoader = () => {
        if (this.state.isMyprofilePostDataLoading) {
            return (
                <Box sx={{ ...webStyle.noPostWrapper }}>

                    <CircularProgress style={{ color: "#14362E" }} />
                </Box>
            )
        } else {
            return (
                <Box sx={{ ...webStyle.noPostWrapper }}>
                    <Box style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                        <i style={{ fontSize: "50px", color: "#64748B", border: "5px solid #64748B", borderRadius: "7px", padding: "10px", marginTop: "101px", }} className="fa-solid fa-user"></i>
                        <Typography style={{ fontFamily: "MyFont", fontSize: "24px", color: "#64748B", fontWeight: "bold", letterSpacing: "0.9px", textAlign: "center", marginTop: "21px" }}>
                            Nothing to show here
                        </Typography>
                        <Typography style={{ fontFamily: "MyFont", textAlign: "center", fontSize: "14px", color: "#64748B", letterSpacing: "0.8px", marginTop: "21px" }}>You haven't shared anyposts yet</Typography>
                        <Button variant="contained" style={{ background: "#14362E", color: "#fff", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "14px", fontWeight: "bold", cursor: "pointer", marginTop: "100px", textTransform: "none", marginBottom: "50px" }} onClick={this.redirectpostcreation}><i style={{ border: "1px solid #fff", borderRadius: "50%", padding: "3px" }} className="fa-solid fa-plus"></i>&nbsp; Add a post</Button>
                    </Box>
                </Box>
            )
        }
    }

    otheruserpost() {
        return (
            <>
                {
                    this.state.otheruserprofileData?.attributes?.is_block_by_me ? (
                        <>
                            <Box style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ height: "80%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                    <Typography style={{ fontFamily: "MyFont", fontSize: "30px", color: "#64748B", letterSpacing: "0.8px", textAlign: "center" }}>
                                        You've blocked <br /> this person

                                    </Typography>

                                    <i style={{ fontSize: "50px", color: "#64748B", marginTop: "10px" }} className="fa-solid fa-ban"></i>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            {
                                this.state.OtheruserPostData.length > 0 ? (
                                    <>
                                        {this.otheruserpostexistData()}
                                    </>
                                ) : (
                                    <>

                                        <Box style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", minHeight: "60vh" }}>

                                            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100%" }}>
                                                <i style={{ color: "#64748B", marginTop: "101px", border: "5px solid #64748B", fontSize: "50px", borderRadius: "7px", padding: "10px" }} className="fa-solid fa-user"></i>
                                                <Typography style={{ fontFamily: "MyFont", color: "#64748B", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "24px", textAlign: "center",marginTop:"20px" }}>
                                                    Nothing to show here
                                                </Typography>

                                                <Typography style={{ fontFamily: "MyFont", fontSize: "14px", color: "#64748B", textAlign: "center", letterSpacing: "0.8px",marginTop:"20px" }}>This user hasn't shared any <br /> posts yet</Typography>
                                            </Box>
                                        </Box>
                                    </>
                                )
                            }

                        </>
                    )
                }

            </>
        )
    }
    userSavedData() {
        return this.postsData(this.state.myRecomendationPosts);
    }
    otheruserSavedData() {
        return this.postsData(this.state.otherRecomendationPosts)
    }
    postsData(posts: any[]) {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 0 },
                items: 1,
                slidesToSlide: 1
            },
        };
        return posts.map((element: any, index: any) => {
            const combinedMedia = [
                ...(element.attributes.images_and_videos ? element.attributes.images_and_videos.map((item: { url: any; }) => ({ type: 'image', url: item?.url })) : []),
                ...(element.attributes.media ? element.attributes.media.map((item: { content_type: string; url: any; }) => ({ type: item?.content_type?.startsWith('image/') ? 'image' : 'video', url: item?.url })) : []),
            ];
            const images = combinedMedia.filter(media => media.type === 'image');
            const videos = combinedMedia.filter(media => media.type === 'video');
            return (
                <>
                    <Card key={element.id} style={{ maxWidth: 750, marginBottom: "25px", width: "100%", marginTop: "0px", fontFamily: "MyFont",  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
                        <CardHeader
                            style={{ fontFamily: "MyFont" }}
                            avatar={
                                <Avatar src={element.attributes.posted_user.profile_picture?.url} aria-label="recipe" />
                            }
                            action={this.actionUIData(element, index)}

                            title={<div style={{ fontFamily: "MyFont", fontSize: "16px", fontWeight: "700", letterSpacing: "1.5px" }}>{element.attributes.posted_user.first_name + element.attributes.posted_user.last_name} . <span style={{ fontFamily: "MyFont", fontSize: "12px", fontWeight: "700", letterSpacing: "1.5px", color: "#334155" }}>{element.attributes.posted_user?.role}</span></div>}
                            subheader={<div style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px" }}>{moment(element.attributes.updated_at).format('dddd, hh:mm A')} <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes?.category}</span></div>}
                        />
                        {element.attributes.is_recommendation && <Box style={{ paddingLeft: '17px', }}>
                            <Typography style={{ background: "#D4E5DE", padding: "3px 8px 3px 8px", color: "#14362E", fontFamily: "MyFont", fontWeight: "bold", fontSize: '12px', letterSpacing: "0.8px", width: "135px", borderRadius: "14px", display:'inline' }}>Recommendation</Typography>
                            <Typography style={{ fontWeight: '700', fontFamily: "MyFont", fontSize: '14px', marginTop: '20px' }}>{element.attributes.name}</Typography>
                            <Box display='flex' style={{ gap: '4px', alignItems: 'center' }}>
                                <Typography style={{ fontFamily: "MyFont", fontSize: '12px', fontWeight: '700', color: '#334155' }}>{element.attributes.recommendation_type?.name}</Typography>
                                <div style={{ width: '4px', height: '4px', background: '#334155' }} />
                                <Typography style={{ fontFamily: "MyFont" , color: '#64748B', fontSize: '11px'}}>{element.attributes?.city}</Typography>

                            </Box>

                        </Box>}
                        <Typography style={{
                            fontSize: "14px", paddingLeft: "17px", fontFamily: "MyFont", fontWeight: 500, letterSpacing: "1px", wordWrap: 'break-word',
                            fontStyle: "normal", color: '#0F172A', marginBottom: '24px'
                        }}>{element.attributes.body}</Typography>
                        {
                            (element.attributes.link_title && element.attributes.external_link) && (
                                <>
                                    <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                                        <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                            <img src={LinkImg} alt="" />
                                            <Box style={{ marginLeft: "10px" }}>
                                                <p>{element.attributes.link_title}</p>
                                            </Box>
                                        </Box>
                                        <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
                                            <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>
                                        </Button>
                                    </Box>
                                </>
                            )
                        }
                        <Carousel
                            responsive={responsive}
                            autoPlay={false}
                            swipeable={true}
                            draggable={true}
                            showDots={false}
                            infinite={false}
                            partialVisible={false}
                            dotListClass="custom-dot-list-style"
                        >
                            {images.map((media, index) => (
                                <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
                                </div>
                            ))}
                            {videos.map((media, index) => (
                                <div key={`video-${index}`}>
                                    <video style={{ width: "100%", height: "250px" }} controls>
                                        <source src={media.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            ))}
                        </Carousel>
                        <CardContent>
                            <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", color: '#64748B'}}>
                                <Box>
                                    {element.attributes.is_liked ? (
                                        <>
                                            <i className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{this.likeCount(element)}
                                        </>
                                    ) : (<>
                                        <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer"}} onClick={this.likePost.bind(this, element)} />
                                        {element.attributes.like_counts == "0" ? "Like" : element.attributes.like_counts} </>)}&nbsp;&nbsp;
                                </Box>
                                <Box onClick={this.handleClick.bind(this, element.id)}>
                                    <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                                    {this.Comment(element)}
                                </Box>
                                <Box onClick={this.drawerSidebaar.bind(this, element.id)} style={{cursor:"pointer"}}>
                                    <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                    Share
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                    {this.renderDrawerfunction(posts)}
                </>
            )
        })
    }
    otheruserfavouriteData() {
        return this.postsData(this.state.otherFavoritePosts);
    }
    userfavouriteData() {
        return this.postsData(this.state.myFavoritePosts)
    }
    myProfileInfoChunk({ label, value, type = 'text' }: { label: string, value: string, type?: string }) {
        return (
            <Box style={{ flex: '1' }}>
                <Typography style={{ color: '#0F172A', fontSize: '14px', fontFamily: 'MyFont', fontWeight: 'bold', letterSpacing: '0.8px' }}>{label}</Typography>
                <Typography style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'MyFont', letterSpacing: '0.8px' }}>
                    {type === 'link' ? <a data-test-id='click-to-visit' href={value}>Click here to visit</a> : value}
                </Typography>

            </Box>
        )
    }
    profileInfo(userData: any) {
        const stringifiedArray = (arr: string[] = []) => arr.join(', ');
        const date = new Date(userData.attributes?.created_at);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return (
            <Box style={{ paddingTop: '25px', display: 'flex', gap: '24px', flexDirection: 'column', paddingBottom: '25px' }}>
                <Box display='flex'>
                    {this.myProfileInfoChunk({ label: 'First Name', value: userData.attributes?.personal_information?.first_name })}
                    {this.myProfileInfoChunk({ label: 'Last Name', value: userData.attributes?.personal_information?.last_name })}
                </Box>
                <Box display='flex'>
                    {this.myProfileInfoChunk({ label: 'Role', value: userData.attributes?.account_details?.role?.name })}
                    {this.myProfileInfoChunk({ label: 'Industry', value: userData.attributes?.account_details?.industry?.name })}

                </Box>
                <Box display='flex'>
                    {this.myProfileInfoChunk({ label: 'Professional Level', value: userData.attributes?.professional_details?.professional_level })}
                    {this.myProfileInfoChunk({ label: 'Organization size', value: userData.attributes?.professional_details?.organization_size })}
                </Box>
                <Box display='flex'>
                    {this.myProfileInfoChunk({ label: 'LinkedIn Profile', value: userData.attributes?.account_details?.linked_in_profile })}
                    {this.myProfileInfoChunk({ label: 'Direct Reports', value: userData.attributes?.direct_reports })}
                </Box>
                <Box display='flex'>
                    {this.myProfileInfoChunk({ label: 'Country', value: this.state.countries[userData.attributes?.personal_information?.country]?.name })}
                    {this.myProfileInfoChunk({ label: 'Date Joined', value: `${day}/${month}/${year}` })}
                </Box>
                {this.myProfileInfoChunk({ label: 'Interests', value: stringifiedArray(userData.attributes?.personal_profile?.interests) })}
                {this.myProfileInfoChunk({ label: 'Hobbies', value: stringifiedArray(userData.attributes?.personal_profile?.hobbies) })}
                {this.myProfileInfoChunk({ label: 'Worries / Concerns', value: stringifiedArray(userData.attributes?.personal_profile?.wories) })}
                {this.myProfileInfoChunk({ label: 'Passions', value: stringifiedArray(userData.attributes?.personal_profile?.passions) })}
                {this.myProfileInfoChunk({ label: 'Expertise', value: stringifiedArray(userData.attributes?.personal_profile?.expertise) })}
            </Box>
        )
    }
    otheruserInfo() {
        return (
            this.profileInfo(this.state.otheruserprofileData)
        )
    }
    getSendIconStyle = (comment: string) => {
        return {
            borderRadius: '50px',
            background: comment.trim() ? '#73A58E' : '#E0E0E0',
            color: comment.trim() ? '#FFFFFF' : '#9E9E9E',
            padding: '6px 17px',
            margin: '6px',
        };
    };
    replyRender = (index: number, element: any) => {
        return (<Collapse data-test-id="collapse" in={this.state.commentReplyOpen[index] && this.state.openForElement === element?.id} timeout="auto" unmountOnExit>
            <Box style={{
                padding: "10px", margin: "auto", display: "flex", alignItems: "baseline",
                gap: "15px", justifyContent: "end"
            }}>
                <TextField
                    data-test-id="replytext"
                    fullWidth
                    maxRows={4}
                    variant="outlined"
                    placeholder="Write a Reply...."
                    value={this.state.replyComment}
                    onChange={this.handleReplyCommentChange}

                    style={{ marginBottom: "10px", width: this.state.isSmallScreen ? "80%" : "50%", height: "48px" }}
                    InputProps={{
                        style: {
                            fontSize: "16px",
                            fontWeight: "500",
                            fontFamily: "MyFont",
                            borderRadius: "50px"
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="fa-regular fa-face-smile custom-icon"
                                    style={{ cursor: "pointer", right: "auto" }}
                                    onClick={this.onClickReplyEmoji.bind(this)}>
                                </i>
                                {this.state.replyShowEmoji && <EmojiPicker
                                    onEmojiClick={this.ReplyEmojiClick.bind(this)}

                                    className="reply-emoji-picker"
                                />}
                                <IconButton
                                    aria-label="Submit"
                                    edge="end"
                                    onClick={this.handleReplycomment}
                                    style={{
                                        borderRadius: "8px",
                                        padding: "0",
                                    }}
                                    disabled={!this.state.replyComment.trim()}

                                >
                                    <ArrowUpwardIcon
                                        style={{
                                            borderRadius: "50px",
                                            background: this.state.replyComment.trim() ? '#73A58E' : '#E0E0E0',
                                            color: this.state.replyComment.trim() ? '#FFFFFF' : '#9E9E9E',
                                            padding: "6px 17px",
                                            margin: "6px"
                                        }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        </Collapse>)
    }

    renderReplycomment = (element: any, index: number) => {
        return (
            element.attributes.replies.slice().reverse().map((reply: any, replyIndex: number) => {
                const commentUser = reply.attributes.comment_user;
                if (!commentUser || !commentUser.first_name || !commentUser.last_name) {
                    return null;
                }
                return (
                    <Collapse data-test-id="collapse-reply" in={this.state.isReplyOpen && this.state.openForElement === element?.id} timeout="auto" unmountOnExit>
                        <Box key={replyIndex} sx={{  display: 'flex', flexDirection: 'column', padding: '0px 10px 0px 32px' }}>
                            <Box style={{ display: "flex", fontFamily: "MyFont", justifyContent: "end" }}>
                                <Avatar style={{ margin: "0px 4% 0% 0%" }} src={reply.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                                <Box style={{ fontSize: "14px", backgroundColor: "#F1F5F9", padding: "12px 16px", width: "60%", borderRadius: '0px 16px 16px 16px' }}>
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>
                                            {`${commentUser.first_name} ${commentUser.last_name}`}
                                        </Typography>
                                        <Typography style={{ fontSize: "12px", fontWeight: "500" }}>
                                            {this.calculateTimeGap(reply.attributes.created_at)}
                                        </Typography>
                                    </Box>
                                    {reply.attributes.comment}
                                </Box>
                            </Box>
                            <CardContent>
                                <Box style={{ display: "flex", justifyContent: "end", alignItems: "center", fontFamily: "MyFont", gap: this.state.isSmallScreen ? "25px" : "40px", color: '#64748B', fontWeight: 600, fontSize:'14px' }}>
                                    <Box>
                                        {reply.attributes.is_liked ? (
                                            <>
                                                <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, reply)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>
                                                {this.likeCount(element)}
                                            </>
                                        ) : (
                                            <>
                                                <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, reply)}></i>
                                                {reply.attributes.like_counts === 0 ? "Like" : reply.attributes.like_counts}
                                            </>
                                        )}
                                        &nbsp;&nbsp;
                                    </Box>
                                    <Box>
                                        <i data-test-id="likeReplyComment" className="fa-regular fa-message" style={{cursor: "pointer"}}
                                                onClick={ this.handleReplySection.bind(this, index, element.attributes.id, element?.id)
                                                }></i>&nbsp;&nbsp;
                                            Reply
                                    </Box>
                                    <div/>&nbsp;&nbsp;
                                </Box>
                            </CardContent>
                        </Box>
                    </Collapse>
                );
            })
        );
    }

    renderOtherUserMenu(element: any) {
        const isFavorite = element.attributes.is_favorite;
        return (
            <>
              <Box style={{ position: "relative", width: "100px" }}>

                <Menu
                    anchorEl={this.state.openMenuForOtherUser}
                    id="basic-menu"
                    open={this.state.openIdForOtherUser === element?.id}
                    onClose={this.handleCloseAction}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'top',

                    }}
                    transformOrigin={{

                        horizontal: 'right',
                        vertical: 'top',
                    }}
                    style={{margin:"34px 25px 0px 0px" }}
                >
                     <MenuItem
                    data-test-id="addtofavBtn"
                    onClick={() => this.getToFavriouteData(element.id)}
                    style={{
                      fontFamily: "myfont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      letterSpacing: "0.08em",
                      textAlign: "left",
                    }}
                  >
                    {isFavorite ? (
                      <>
                        <BookmarkBorderIcon
                          style={{
                            height: "20px",
                            fontWeight: "500",
                          }}
                        />
                        Remove from favourites
                      </>
                    ) : (
                      <>
                        <BookmarkBorderIcon
                          style={{
                            height: "20px",
                            fontWeight: "500",
                          }}
                        />
                        Add to favourites
                      </>
                    )}
                  </MenuItem>

                  {element?.attributes?.account_id != this.state.accountId && (<><MenuItem
                    data-test-id="addtoReportBtn"
                    onClick={(event) =>  this.handlePostReport(element.id)}
                    style={{
                      color: "#D1584C",
                      fontFamily: "myfont",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      letterSpacing: "0.08em",
                      textAlign: "left",
                    }}
                  >
                    <ReportIcon
                      style={{
                        height: "20px",
                        fontWeight: "500",
                      }} />
                    &nbsp; Report
                  </MenuItem>
                  </>)}
                  <MenuItem  data-testid="block-user-menu-item" style={{
                      fontFamily: "myfont",
                      color: "#D1584C",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "22px",
                      letterSpacing: "0.08em",
                      textAlign: "left",
                    }}
                    onClick={this.handleClose as any}
                    >
                        <BlockIcon
                        style={{
                          height: "20px",
                          fontWeight: "500",
                        }} />
                      &nbsp; Block user
                    </MenuItem>
                </Menu>
                </Box>
            </>
        )
    }
    commentDrawer(posts: any[], otheruserid: string){
        const element =  posts.find((element: any) => element.id === this.state.selectedPostId);
        if(!element) return null;

        const isFavorite = element && element?.attributes?.is_favorite;

        return  (
        <Card
            key={element.id}
            style={{
                maxWidth: 750,
                width: "100%",
                fontFamily: "MyFont",
                boxShadow: 'none',
            }}
        >
            <CardHeader
                data-test-id="commentsCard"
                style={{ fontFamily: "MyFont" }}
                avatar={
                    <Avatar src={element.attributes.posted_user.profile_picture.url} aria-label="recipe">
                    </Avatar>
                }
                action={
                    <>
                          {isFavorite && (
                <IconButton
                  data-test-id="icon-button"
                  edge="start"
                  aria-haspopup="true"
                  aria-controls={`menu-${element.id}`}
                  aria-label="more"
                  onClick={() => this.getToFavriouteData(element.id)}
                >
                  <BookmarkIcon style={{ color: "#14362E" }} />
                </IconButton>
              )}
                        <i data-test-id="actionId" className="fa-solid fa-ellipsis-vertical" style={{ marginTop: "12px", marginRight: "5px" }} onClick={(e) => otheruserid == undefined ? this.handleOpenMenu1(e, element) : this.handleOpenMenuAction(e, element)}></i>

                    </>
                }
                title={
                    <div style={{ fontFamily: "MyFont", fontSize: "16px", fontWeight: "700", letterSpacing: "1px" }}>
                        {element.attributes.posted_user.first_name + ' ' + element.attributes.posted_user.last_name} .
                        <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>
                            {element.attributes.posted_user.role}
                        </span>
                    </div>
                }
                subheader={
                    <div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>
                        {moment(element.attributes.updated_at).format('dddd, hh:mm A')}
                        <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>
                            {element.attributes.category}
                        </span>
                    </div>
                }
            />
            <Typography
                style={{
                    fontSize: "14px",
                    paddingLeft: "17px",
                    wordWrap: 'break-word',
                    fontFamily: "MyFont",
                    fontWeight: 500,
                    letterSpacing: "1px",
                    fontStyle: "normal"
                }}
            >
                {element.attributes.body}
            </Typography>
            {
                (element.attributes.link_title && element.attributes.external_link) && (
                    <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                        <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                            <img src={LinkImg} alt="" />
                            <Box style={{ marginLeft: "10px" }}>
                                <p>{element.attributes.link_title}</p>
                            </Box>
                        </Box>
                        <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
                            <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>
                        </Button>
                    </Box>
                )
            }
            <CardContent>
                <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont", color: '#64748B', fontWeight: 600, fontSize: '14px' }}>
                    <Box>
                        {element.attributes.is_liked ? (
                            <>
                                <i data-test-id="likecomment" className="fa-solid fa-heart" onClick={this.likePost.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                            </>
                        ) : (
                            <>
                                <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>
                                {element.attributes.like_counts === "0" ? "Like" : element.attributes.like_counts}
                            </>
                        )}
                        &nbsp;&nbsp;
                    </Box>
                    <Box>
                        <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                        {this.Comment(element)}
                    </Box>
                    <Box style={{cursor:"pointer"}}>
                        <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                        Share
                    </Box>
                </Box>
            </CardContent>
        </Card>
        )
    }

    sharedrawerfunction() {
        return (
                <ShareDrawer
                    open={this.state.sidebaarOpen}
                    handleClose={this.drawerSidebaarClose}
                    handleSave={this.handlePostshare} 
                    searchValue={this.state.sharemembervalue}
                    hadnleChangeSearchValue={this.handlesearchTag}
                    clearSerchValue={this.removesearchdata} 
                    selectedElements={this.state.selectedmember}
                    handleDeleteSelected={this.handleselectmemberDelete}
                    serachData={this.state.shareconnectiondata}
                    handleCheckItem={this.getvalueconnectioncheck}
                    messageValue={this.state.sharemessage}
                    handleChangeMessageValue={this.handlewritemessage}
                    disabled={this.state.sharePostProfileLoading}
                />
        )
    }
    renderDrawerfunction(posts: any[]) {
        const otheruserid = window.location.href.split("/")[4]
        return (
            <CustomDrawer
                anchor="right"
                open={this.state.isCommentDrawerOpen}
                onClose={this.handleClose2}
                id="postDrawer"
            >
                <Box

                    role="presentation"
                    style={{backgroundColor: '#F1F5F9', height: '100vh', width: '100%'}}
                >
                   { this.state.isSmallScreen ?
                   <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box  sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                            <IconButton data-test-id="smallscreenid" onClick={this.handleClose2}>
                                <ArrowBack />
                            </IconButton>
                        </Box>
                        <Box style={{display: 'flex', width: '60%', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography style={{ fontSize: "24px", fontWeight: "900", color: "#14362E", }}>
                                Post
                            </Typography>
                        </Box>
                    </Box>
                     : <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography style={{ fontSize: "24px", fontWeight: "900", color: "#14362E", paddingLeft: "25px" }}>
                            Post
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                            <IconButton onClick={this.handleClose2}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>}
                    <Divider />

                    {
                    this.state.loading ? ( 
                        <Box style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                            <CircularProgress style={{ color: '#14362E' }}/>
                        </Box>
                    ) : (
                    <Box>
                        {
                            this.state.selectedPostId !== null && (
                                <Box style={{padding: '10px 20px', backgroundColor: '#F1F5F9'}}>
                                    <Typography>{this.commentDrawer(posts, otheruserid)}</Typography>
                                </Box>
                            )
                        }

                        <Box style={{padding: '10px', backgroundColor: '#F1F5F9' }}>
                            <Box style={{ display: 'flex', alignItems: 'center', gap: "1", justifyContent: "center", padding: '10px 10px', backgroundColor: 'white' }}>
                                <Avatar
                                    src={otheruserid ? this.state.otheruserprofileData?.attributes?.personal_information?.profile_picture?.url : this.state.MyprofileData?.attributes?.personal_information?.profile_picture?.url}
                                    aria-label="recipe"
                                />
                                <StyledMentionsInput
                                    value={this.state.comment}
                                    onChange={this.handleCommentChange}
                                    placeholder="Write a comment..."
                                >
                                    <Mention
                                        trigger="@"
                                        data={this.state.users}
                                        renderSuggestion={(suggestion: SuggestionDataItem) => (<>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <Avatar style={{ margin: "8px" }} src={(suggestion as UserSuggestion).profile_picture} />
                                                <Box style={{ display: "flex", alignItems: "center" }}>{suggestion.display}</Box></Box>
                                        </>
                                        )}
                                    />

                                </StyledMentionsInput>
                                <InputAdornment position="end"
                                    style={{ position: "absolute", marginLeft: this.state.isSmallScreen ? "60%" :  "80%" }}
                                >
                                    <i className="fa-regular fa-face-smile custom-icon "
                                        style={{ paddingRight: "0px", cursor: "pointer", }}
                                        onClick={this.onClickEmoji.bind(this)}>
                                    </i>
                                    <EmojiPicker
                                        open={this.state.showEmoji}
                                        onEmojiClick={this.EmojiClick.bind(this)}
                                        className="emoji-picker"
                                    />
                                    <IconButton
                                        data-test-id="commentSubmit"
                                        aria-label="send comment"
                                        edge="end"
                                        onClick={this.handleCommentSubmit}
                                        className="button-icon custom-icon"
                                        disabled={!this.state.comment?.trim()}
                                    >
                                        <ArrowUpwardIcon
                                            style={this.getSendIconStyle(this.state.comment)}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            </Box>
                        </Box>
                        <List style={{padding: '5px 10px', backgroundColor: '#F1F5F9'}}>
                            <Box style={{backgroundColor: 'white'}}>
                                <ListItem button onClick={this.toggleComments} disableRipple
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "end",
                                        fontFamily: "MyFont",
                                    }}
                                >
                                    <Box style={{fontFamily:"MyFont", color:' #334155', fontWeight: "bold", marginRight: '6px'}}>All Comments</Box>
                                    {this.state.isCommentsOpen ? <ExpandMoreIcon /> : < ExpandLessIcon />}
                                </ListItem>
                                <Collapse in={this.state.isCommentsOpen} timeout="auto" unmountOnExit>
                                    <Box style={{ display: "flex", flexDirection: "column", marginTop: '10px' }}>
                                        {this.state.commentData.filter((comment: { attributes: { parent_comment_id: null; comment: string; }; }) => comment.attributes.parent_comment_id === null).map((element: {
                                            attributes: {
                                                comment_user: { first_name: string, last_name: string, profile_picture: { url: string } };
                                                created_at: string;
                                                like_counts: number;
                                                is_liked: boolean;
                                                replies: any;
                                                id: number; comment: string | null | undefined; parent_comment_id: number;
                                            };
                                            id: number;
                                        }, index: number) => (
                                            <Box key={index}>
                                                <Box style={{ display: "flex", fontFamily: "MyFont" }}>
                                                    <Avatar style={{ margin: "0px 16px" }} src={element.attributes.comment_user.profile_picture.url} aria-label="recipe" />
                                                    <Box style={{ fontSize: "14px", backgroundColor: "#F1F5F9", padding: "12px 16px", width: this.state.isSmallScreen ? "65%" : "75%", borderRadius: '0px 16px 16px 16px' }}>
                                                        <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
                                                            <Typography style={{ fontSize: "16px", fontWeight: "500", fontFamily: "MyFont" }}>{`${element.attributes.comment_user.first_name} ${element.attributes.comment_user.last_name}`}</Typography>
                                                            <Typography style={{ fontSize: "12px", fontWeight: "500" }}>{this.calculateTimeGap(element.attributes.created_at)}</Typography>
                                                        </Box>
                                                        {element.attributes.comment}
                                                    </Box>
                                                </Box>
                                                <CardContent style={{marginLeft: this.state.isSmallScreen ? '15%' : 'auto'}}>
                                                    <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont", color: '#64748B', fontWeight: 600, fontSize: '14px' }}>
                                                        <Box>
                                                            {element.attributes.is_liked ? (
                                                                <>
                                                                    <i className="fa-solid fa-heart" onClick={this.likeComment.bind(this, element)} style={{ color: "red", marginRight: "5px", cursor: "pointer" }}></i>{element.attributes.like_counts}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likeComment.bind(this, element)}></i>
                                                                    {element.attributes.like_counts === 0 ? "Like" : element.attributes.like_counts}
                                                                </>
                                                            )}
                                                            &nbsp;&nbsp;

                                                        </Box>
                                                        <Box>
                                                            <i data-test-id="likeReply" className="fa-regular fa-message" onClick={this.handleReplySection.bind(this, index, element.attributes.id, element?.id)}></i>&nbsp;&nbsp;
                                                            {this.repliesCount(element)}
                                                        </Box>
                                                        <Box style={{cursor:"pointer"}}>
                                                            <i className="fa-solid fa-arrow-up-from-bracket" onClick={this.handleShareCommentClick.bind(this, element.attributes.id)}></i>&nbsp;&nbsp;
                                                            Share
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                                <List>
                                                        <ListItem data-test-id="toggleList" button onClick={() => this.toggleReply(element)} disableRipple
                                                            style={{
                                                            fontSize: "14px",
                                                            fontWeight: "600",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "end",
                                                            fontFamily: "MyFont",
                                                            cursor: "pointer" 
                                                            }}
                                                        >
                                                            <Box  style={{color: '#334155',}}>All Replies</Box>
                                                            {this.state.isReplyOpen && this.state.openForElement === element?.id ? < ExpandLessIcon style={{cursor: "pointer"}} /> : <ExpandMoreIcon style={{cursor: "pointer"}}/> }
                                                        </ListItem>
                                                    </List>
                                                    {this.renderReplycomment(element, index)}
                                                    {this.replyRender(index, element)}
                                                    
                                                </Box>
                                        ))}
                                    </Box>
                                </Collapse>
                            </Box>
                        </List>
                    </Box>
                    )
                }

                </Box>
            </CustomDrawer>)
    }

    renderEditDeleteMenu(element: any) {
        return (
            <>
                <Box
                    style={{ width: "300px", position: "relative" }}
                >
                    <Menu
                        key={element.id}
                        style={{ padding: "200px", marginTop: "36px" }}
                        id="basic-menu1"
                        data-test-id="datahandleclose"
                        anchorEl={this.state.openMen1}

                        open={this.state.openMenpop}
                        onClose={this.handleCloseCus}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem data-test-id="editMypost" style={{ color: "#D1584C" , fontFamily:"MyFont" }} onClick={() => this.gotoPostCreation(element.attributes.id)}>Edit</MenuItem>
                        <MenuItem data-test-id="deleteMypost" style={{ color: "#D1584C" , fontFamily:"MyFont" }} onClick={(event) => this.setdeleteItemClick(`${element.attributes.posted_user.first_name + " " + element.attributes.posted_user.last_name}`, element.attributes.id)}>Delete</MenuItem>
                    </Menu>
                </Box>
            </>
        )
    }

    renderSharefunction = () => {
        const DrawerHeader: React.FC<DrawerHeaderProps> = ({ title, onClose }) => (
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                <Typography className="selecttype">{title}</Typography>
                <span onClick={onClose}>
                    <i className="fa-solid fa-xmark"></i>
                </span>
            </Box>
        );
        
        const SelectedMembers: React.FC<SelectedMembersProps> = ({ members, onDelete }) => (
            <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                {members.map((member, index) => (
                    <Box key={index} style={{ borderRadius: '19px', display: 'flex', alignItems: 'center', background: '#F1F5F9', margin: '6px', padding: '12px' }}>
                        <Typography style={{ fontSize: '11px', fontFamily: "myfont", marginRight: '9px', color: '#475569' }}>{member.value}</Typography>
                        <span onClick={() => onDelete(index)}>
                            <i className="fa-solid fa-xmark" style={{ fontSize: '11px' }}></i>
                        </span>
                    </Box>
                ))}
            </Box>
        );
        const ContinueButton: React.FC<ContinueButtonProps> = ({ onClick }) => (
            <Box style={{ display: "flex", justifyContent: "center", position: 'absolute', bottom: '0', width: '100%', padding: "10px 0px" }}>
                <Button variant="contained" style={{
                    width: "90%",
                    background: "#14362E",
                    textTransform: "none",
                    fontFamily: "myfont",
                    color: "white",
                    margin: "auto"
                }} onClick={onClick}>
                    Continue
                </Button></Box>
        );
        return (
            <>
                <CustomDrawer
                    anchor="right" open={this.state.open3} onClose={this.drawerSidebaarClose3}>
                    <BoxCustom2>
                        <DrawerHeader title="Share Comment" onClose={this.drawerSidebaarClose3} />
                        <Box style={{ borderBottom: '1px solid #CBD5E1' }}></Box>
                        <Box style={{
                            display: 'flex',
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "myfont",
                            padding: "15px"
                        }}>
                            <Box style={{
                                width: "100%",
                                marginBottom: "9px",
                                border: "1px solid #CBD5E1",
                                marginTop: "5px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "8px"
                            }}>
                                <i className="fa-solid fa-magnifying-glass" style={{ marginLeft: "9px", color:"#DADADA" }}></i>
                                <input type="text" style={{
                                width: "100%",
                                fontFamily: "MyFont",
                                padding: "11px 4px",
                                borderRadius: "6px",
                                border: "none",
                                outline: "none",
                                fontSize: '16px',
                                lineHeight: '24px',
                                color: "#0F172A"
                                }} onChange={this.handlesearchTag} placeholder="Search By Name" value={this.state.sharemembervalue}/>
                                {
                                this.state.sharemembervalue ? <i className="fa-solid fa-xmark" style={{ cursor: "pointer", marginRight: '15px' }} data-test-id="removesearchComment" onClick={this.removesearchdata}></i> : <i className="fa-solid fa-xmark" style={{ color: "#94A3B8", marginRight: '15px' }}></i>
                                }
                            </Box>
                            </Box>
                        <SelectedMembers members={this.state.selectedmember3} onDelete={this.handleselectmemberDelete3} />
                        <Box style={{padding: "5px 15px", maxHeight: this.state.selectedmember3.length > 3 ? "120px" : "180px", overflowY: "auto"}}>
                            {this.state.shareconnectiondata2.length > 0 && this.state.shareconnectiondata2.map((element1: any, index: any) => {
                                return (<Box className="box5" key={index}>
                                    <Box className="box6">
                                        <Avatar src={element1.profile_picture?.url} />
                                        <Box style={{ marginLeft: "11px" }}><span>{element1.name}</span><span>{element1.rol}</span></Box></Box>
                                    <span className="inputSelectedMember"><input type="checkbox" value={element1.e} onClick={(elementvalue) => this.getvalueconnectioncheck3(elementvalue, element1)} /></span>
                                </Box>)
                            })}
                        </Box>
                        <Box className="box7">
                            <Box className="box8"><input className="input2" type="text" onChange={this.handlewriteCommentmessage} name="exlink" placeholder="Write Message" id="" /></Box>
                        </Box>
                        <ContinueButton onClick={this.handleCommentshare} />
                    </BoxCustom2>
                </CustomDrawer>
            </>
        )
    }

    myuserPostexistData() {
        return (
            <>
                <Box key={1} style={{ display: "flex", flexDirection: "column", alignItems: "center", background: "white" }}>

                    {

                        this.state.MyprofilePostData.map((element: any, index: any) => {
                            const responsive = {
                                desktop: {
                                    breakpoint: { max: 3000, min: 0 },
                                    items: 1,
                                    slidesToSlide: 1
                                },
                            };
                            const combinedMediaformyuser = [
                                ...(element.attributes.images_and_videos ? element.attributes.images_and_videos.map((item: { url: any; }) => ({ type: 'image', url: item?.url })) : []),
                                ...(element.attributes.media ? element.attributes.media.map((item: { content_type: string; url: any; }) => ({ type: item?.content_type?.startsWith('image/') ? 'image' : 'video', url: item?.url })) : []),
                            ];
                            const imagesformyuser = combinedMediaformyuser.filter(media => media.type === 'image');
                            const videosformyuser = combinedMediaformyuser.filter(media => media.type === 'video');
                            return (
                                <>
                                    <Card key={element.id} style={webStyle.cardOne}>

                                        <HeaderContainer >

                                            <CardHeader
                                                style={{ fontFamily: "MyFont" }}
                                                avatar={
                                                    <Avatar src={element.attributes?.posted_user?.profile_picture?.url} aria-label="recipe">

                                                    </Avatar>
                                                }

                                                title={<div style={webStyle.titleBox}>{element.attributes.posted_user.first_name + element.attributes.posted_user.last_name} .  <span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#334155" }}>CEO</span></div>}
                                                subheader={<div style={{ fontFamily: "MyFont", fontSize: "14px", fontWeight: "400", letterSpacing: "1px" }}>{moment(element.attributes.created_at).format('dddd, hh:mm A')}<span style={{ fontFamily: "MyFont", fontSize: "11px", fontWeight: "500", letterSpacing: "1px", color: "#14362E" }}>{element.attributes.category}</span></div>}
                                            />
                                            <Box className="flexBoxCus">
                                                <IconButton
                                                    data-test-id="threeDots"
                                                    edge="start"
                                                    aria-haspopup="true"
                                                    aria-controls={`menu-${index}`}
                                                    aria-label="more"
                                                    onClick={(e) => this.handleOpenMenu1(e, element)} style={{ marginTop: "10px", marginRight: "10px" }}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                               {this.renderEditDeleteMenu(element)}
                                            </Box>
                                        </HeaderContainer>
                                        <Typography style={{
                                            fontSize: "14px", paddingLeft: "17px", fontFamily: "MyFont", fontWeight: 500, letterSpacing: "1px", wordWrap: 'break-word',
                                            fontStyle: "normal"
                                        }}>{element.attributes.body}</Typography>


                                        {
                                            (element.attributes.link_title && element.attributes.external_link) && (
                                                <Box style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", display: "flex", justifyContent: "space-between", padding: "4px", alignItems: "center", height: "60px", marginBottom: "10px", marginTop: "10px", width: "95%", margin: "15px auto", borderRadius: "10px" }}>
                                                    <Box style={{ display: "flex", borderRadius: "10px", alignItems: "center" }}>
                                                        <img src={LinkImg} alt="" />

                                                        <Box style={{ marginLeft: "10px", fontFamily: "MyFont" }}>
                                                            <p >{element.attributes.link_title}</p>
                                                        </Box>

                                                    </Box>
                                                    <Button data-test-id="handleCopyLink" onClick={() => this.handleCopyLink(element.attributes.external_link)}>
                                                        <span style={{ fontSize: "20px" }}><i className="fa-regular fa-copy"></i></span>
                                                    </Button>
                                                </Box>)
                                        }

                                        <Carousel
                                            responsive={responsive}
                                            autoPlay={false}
                                            swipeable={true}
                                            draggable={true}
                                            showDots={false}
                                            infinite={false}
                                            partialVisible={false}
                                            dotListClass="custom-dot-list-style"
                                        >
                                            {imagesformyuser.map((media, index) => (
                                                <div key={`image-${index}`} style={{ display: "flex", alignItems: "center", height: "200px" }}>
                                                    <img style={{ width: "100%" }} src={media?.url} alt={`media-${index}`} />
                                                </div>
                                            ))}
                                            {videosformyuser.map((media, index) => (
                                                <div key={`video-${index}`}>
                                                    <video style={{ width: "100%", height: "250px" }} controls>
                                                        <source src={media.url} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            ))}
                                        </Carousel>
                                        <CardContent>
                                            <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", fontFamily: "MyFont" }}>
                                                <Box>
                                                    {element.attributes.is_liked ? (
                                                        <>
                                                            <i className="fa-solid fa-heart" style={{ color: "red", marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>{element.attributes.like_counts}
                                                        </>
                                                    ) : (<><i className="fa-regular fa-heart" style={{ marginRight: "5px", cursor: "pointer" }} onClick={this.likePost.bind(this, element)}></i>{element.attributes.like_counts == "0" ? "Like" : element.attributes.like_counts} </>)}&nbsp;&nbsp;
                                                </Box>
                                                <Box onClick={this.handleClick.bind(this, element.id)}>
                                                    <i className="fa-regular fa-message"></i>&nbsp;&nbsp;
                                                    {this.Comment(element)}
                                                </Box>
                                                <Box onClick={this.drawerSidebaar.bind(this, element.id)} style={{cursor:"pointer"}}>
                                                    <i className="fa-solid fa-arrow-up-from-bracket"></i>&nbsp;&nbsp;
                                                    Share
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                    {this.renderDrawerfunction(this.state.MyprofilePostData)}
                                </>
                            )
                        })
                    }
                </Box>
            </>
        )
    }
    myuserpost() {
        return (
            <>
                {

                    this.state.MyprofilePostData.length > 0 ? (

                        <>
                            {this.myuserPostexistData()}
                        </>
                    ) : (
                        <>
                            {this.renderLoader()}
                        </>
                    )
                }



            </>
        )

    }

    renderContent() {
        switch (this.state.changeTabValue) {
            case 0:
                return this.otheruserpost();
            case 1:
                return this.otheruserfavouriteData();
            case 2:
                return this.otheruserSavedData();
            case 3:
                return this.otheruserInfo()
            default:
                return "";
        }
    }

    renderContentMyProfile() {
        switch (this.state.changeTabValue) {
            case 0:
                return this.myuserpost();
            case 1:
                return this.userfavouriteData();
            case 2:
                return this.userSavedData();
            case 3:
                return this.profileInfo(this.state.MyprofileData);
            default:

        }
    }

    getFriendStatus() {
        const friend = this.state.otheruserprofileData.attributes?.friend;

        switch (friend?.status && friend.connection_status) {
            case "pending":
                return (
                    <Typography 
                    onClick={this.removeRequest.bind(this, this.state.otheruserprofileData)}
                     style={{
                        background: "#7b0b6f",
                        padding: "5px 20px",
                        color: "#fff",
                        fontSize: "14px",
                        letterSpacing: "0.6px",
                        fontFamily: "MyFont",
                        fontWeight: "bold",
                        borderRadius: "5px",
                        width: "150px",
                        textAlign: "center",
                        cursor: "pointer",
                    }}>
                        <i className="fa-regular fa-clock"></i> &nbsp;Pending
                    </Typography>
                );



            default:
                if (friend === "" || friend == null) {
                    return (
                        <>
                        <Box style={{display:"flex",alignItems:"center"}}>
                        <Typography
                            onClick={this.inviteCircle.bind(this, this.state.otheruserprofileData.id)}
                            style={{
                                cursor: "pointer",
                                background: "#14362E",
                                padding: "5px 20px",
                                color: "#fff",
                                fontSize: "14px",
                                letterSpacing: "0.6px",
                                fontFamily: "MyFont",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                width: "150px",
                                textAlign: "center",
                                marginTop:"5px"
                            }}
                        >
                            <i className="fa-solid fa-check"></i> &nbsp;Invite to My Circle
                        </Typography>
                        <MyCircleMailBox
                        onClick={this.handleOpenEmail}
                        data-test-id="my-circle-mail"
                        style={{marginLeft:"4px"}}
                    >
                        <MailOutline style={{ fill: "#14362E", width: "30px", height: "22px", }} />
                    </MyCircleMailBox>
                    </Box>
                    </>
                    );
                } else {
                    return (
                        <Typography
                            style={{
                                background: "#D4E5DE",
                                padding: "5px 20px",
                                color: "#14362E",
                                fontSize: "14px",
                                letterSpacing: "0.6px",
                                fontFamily: "MyFont",
                                fontWeight: "bold",
                                borderRadius: "5px",
                                width: "110px",
                                textAlign: "center",
                                cursor: 'pointer'
                            }}
                        >
                            My Circle
                        </Typography>
                    );
                }
        }
    }

    otheruserRendercall(open: boolean) {
        return (
            <>
                <Container style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Card style={{ marginBottom: "25px", width: "100%", marginTop: "35px", maxWidth: 750, fontFamily: "MyFont", flexWrap: "wrap", borderRadius: "15px" }}>
                        <Box style={{ display: "flex", alignItems: "center", height: "25px", padding: "15px", }}>
                            <Box style={{ border: "1px solid #73A58E", borderRadius: "50%", padding: "5px", cursor: "pointer" }}>
                                <i className="fa-solid fa-arrow-left" onClick={this.handleGohomepage}></i>
                            </Box>
                            <Typography style={{ fontFamily: 'MyFont', fontWeight: "bold", fontSize: "22px", color: "#14362E", letterSpacing: "0.8px", textAlign: "center", width: "100%" }}>User Profile</Typography>

                        </Box>
                        <Container>
                            <Box style={{ borderBottom: "1px solid #CBD5E1" }}></Box>
                        </Container>

                        <CardContent>
                            <Box style={{ cursor: "pointer", textAlign: "right" }}>

                                <i className="fa-solid fa-ellipsis-vertical" onClick={this.handleOpenMenu}></i>
                                <Box style={{ position: "relative", width: "100px" }}>
                                <Menu
                                    anchorEl={this.state.openMenu}
                                    id="basic-menu"
                                    open={open}
                                    onClose={this.handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                    anchorOrigin={{
                                        horizontal: 'right',
                                        vertical: 'top',

                                    }}
                                    transformOrigin={{

                                        horizontal: 'right',
                                        vertical: 'top',
                                    }}
                                    style={{margin:"34px 25px 0px 0px" }}
                                >
                                    <MenuItem onClick={this.handleClose as any} data-testid="block-user-menu-item" style={{ color: "#D1584C",borderRadius:"5px",background:"#fff",boxShadow:"0px 2px 4px 0px #00000026",fontFamily:"MyFont",letterSpacing:"0.8px" }}>
                                        <i className="fa-solid fa-ban" /> &nbsp; Block User
                                    </MenuItem>
                                    </Menu>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap", padding: "15px" }}>

                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px" }}>
                                    <Box>
                                        <img src={this.state.otheruserprofileData?.attributes?.personal_information?.profile_picture?.url} style={{ width: "100px", height: "100px", borderRadius: "50%" }} alt="" />
                                    </Box>
                                    <Box style={{ width: "150px", marginLeft: "10px" }}>
                                        <Box style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontWeight: "bold", fontFamily: "MyFont", letterSpacing: "0.8px", fontSize: "21px", color: "#0F172A" }}>{this.state.otheruserprofileData.attributes?.personal_information?.first_name ? this.state.otheruserprofileData.attributes.personal_information.first_name : ""} {"  "}{this.state.otheruserprofileData?.attributes?.personal_information?.last_name ? this.state.otheruserprofileData.attributes.personal_information.last_name : ""}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontSize: "15px", color: "#0F172A", fontWeight: "500", letterSpacing: '0.8px' }}>{this.state.otheruserprofileData.attributes?.account_details?.company ? this.state.otheruserprofileData.attributes.account_details.company : ""}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontWeight: "500", letterSpacing: '0.8px', fontSize: "14px", width: "200px", marginTop: "15px" }}>{this.state.otheruserprofileData?.attributes?.personal_information?.bio ? this.state.otheruserprofileData.attributes.personal_information.bio : ""}</Typography>
                                        </Box>

                                    </Box>
                                </Box>

                                <Box style={webStyle.greenMyCircle as any}>
                                    {
                                        this.state.otheruserprofileData.attributes?.is_block_by_me ?
                                            (
                                                <>
                                                    <Box>
                                                        <Typography style={{ border: "1px solid #14362E", padding: "5px 20px", color: "#14362E", fontSize: "14px", letterSpacing: "0.6px", fontFamily: "MyFont", fontWeight: "bold", borderRadius: "5px", width: "110px", textAlign: "center", cursor: "pointer" }} onClick={this.unblockuser}><i className="fa-solid fa-ban"></i> &nbsp;Unblock</Typography>
                                                    </Box>
                                                </>
                                            )
                                            : (
                                                <>
                                                    <Box>
                                                        {this.getFriendStatus()}
                                                    </Box>
                                                </>
                                            )
                                    }

                                </Box>

                            </Box>

                            <Box style={{ padding: "10px", borderBottom: "3px solid #E2E8F0", paddingBottom: 0, marginBottom: "25px" }}>
                                <Container>
                                    <Tabs value={this.state.changeTabValue} onChange={this.changeTabvalue} TabIndicatorProps={{className: 'custom-tab-indicator'}}>
                                        <Tab icon={<i className="fa-solid fa-list"></i>} />
                                        <Tab icon={<img src={bookmarkImg} />} />
                                        <Tab icon={<img src={reviewsImg} />} />
                                        <Tab icon={<img src={other_profile} />} />
                                    </Tabs>
                                </Container>
                            </Box>
                            <Box>
                                {this.renderContent()}
                            </Box>
                        </CardContent>
                    </Card>
                    <Drawer anchor="right" open={this.state.openBlockDrawer} style={webStyle.drawer} onClose={this.OpenBlockDrawerclose}>
                        {this.Blockuserdrawercode()}
                    </Drawer>
                    <Drawer anchor="right" open={this.state.openReport} style={webStyle.drawer} onClose={this.OpenReportDrawerclose}>
                    <BoxCustom2>
                        <Box style={webStyle.categoryDropdown}>
                        <Typography className="selecttype">Report Content</Typography>
                        <span onClick={this.OpenReportDrawerclose}>
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                        </Box>
                        <Box style={webStyle.borderBottomStyle}></Box>
                        <Box className="flaggingcontent">Why are you flagging this content?</Box>
                        <Box style={webStyle.borderBottomStyle}></Box>
                        <Container className="content-container">
                        {
                            this.state.reportListData.map((item: { id: number, reason: string, description: string }, index: number) => {
                            return <Box
                                data-test-id="reportSelectedId"
                                className="typebox"
                                key={index}
                                onClick={() => this.handleSelect(item, index)}
                                style={{ backgroundColor: this.state.selected === `${index}` ? '#D4E5DE' : 'transparent' }}
                            >
                                <Box>
                                <Typography className="titlemyfont">{item.reason}</Typography>
                                <Typography className="titleReason">{item.description}</Typography>
                                </Box>
                                {this.state.selected === `${index}` && <CheckCircleOutlineOutlinedIcon className="checkedCircle" />}
                            </Box>
                            })
                        }
                        <Divider />

                        </Container>
                        <Container className="button-container">
                        <Button data-test-id="continueBtn" disabled={this.state.reportId == 0} variant="contained" className="selectbtn" onClick={this.addedReport} style={{fontFamily:"MyFont",letterSpacing:"0.8px"}}>Continue</Button>
                        </Container>
                    </BoxCustom2>

                    </Drawer>
                </Container>
            </>
        )
    }

    myProfileThreeDotPopupMenu = () => {
        const open = Boolean(this.state.threeDotanchorEl);

        return (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton
                    aria-controls={open ? 'popup-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => this.handleThreeDotClick(e)}
                >
                    <MoreVertIcon />
                </IconButton>

                <Box>
                    <Menu
                        id="popup-menu"
                        anchorEl={this.state.threeDotanchorEl}
                        open={open}
                        onClose={() => this.handleThreeDotClose()}
                        MenuListProps={{
                            'aria-labelledby': 'more-button',
                        }}
                        PaperProps={{
                            style: {
                                marginTop: "20px",
                                marginLeft: "-40px"
                            },
                        }}
                    >
                        <Link to="/ShareProfile" style={{ textDecoration: "none" }}>
                            <MenuItem style={{
                                color: "#14362E", borderRadius: "8px", background: "#fff", boxShadow: "0px 2px 4px 0px #00000026", fontFamily: "MyFont", letterSpacing: "0.8px",
                                border: "1px solid #EBEFF4"
                            }}>
                                <i className="fa fa-qrcode" /> &nbsp; My QR
                            </MenuItem>
                        </Link>
                    </Menu>
                </Box>
            </Box>
        );
    };

    myProfileRendercall() {
        return (
            <>
                <Container style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Card style={{ maxWidth: 750, marginBottom: '25px', width: "100%", marginTop: "35px", fontFamily: "MyFont", flexWrap: "wrap", borderRadius: "15px" }}>
                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "15px", height: "25px" }}>
                            <Box style={{ border: "1px solid #73A58E", padding: "5px", borderRadius: "50%", cursor: "pointer" }} onClick={this.redirectsettings}>
                                <i className="fa-solid fa-gear"></i>
                            </Box>
                            <Typography style={{ color: "#14362E", fontFamily: 'MyFont', fontWeight: "bold", fontSize: "22px", letterSpacing: "0.8px" }}>My Profile</Typography>
                            <Box style={{ border: "1px solid #73A58E", padding: "5px", borderRadius: "50%", cursor: "pointer" }} onClick={() => window.location.href = "EditUserProfile"}>
                                <i className="fa-solid fa-pen"></i>
                            </Box>
                        </Box>
                        <Container>
                            <Modal open={this.state.isModalOpen} onClose={this.handleCloseModal}>
                                <Paper
                                    style={{ ...webStyle.removeFromMyCircleModal }}
                                >
                                    <Box
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center" as 'center',
                                            alignItems: "center"
                                        }}
                                    >
                                        <Typography variant="h6" style={webStyle.modalTitle}>
                                            Remove from My Posts?
                                        </Typography>
                                        <Avatar
                                            src={this.state.MyprofileData?.attributes?.personal_information?.profile_picture?.url}
                                            aria-label="recipe"
                                        />
                                        <Typography style={webStyle.modalUserName}>
                                            {this.state.MyprofileData.attributes && this.state.MyprofileData.attributes.personal_information.first_name + "  " + this.state.MyprofileData.attributes.personal_information.last_name}
                                        </Typography>
                                        <Typography variant="body1" style={webStyle.modalContent}>
                                            Do you really want to remove  {this.state.MyprofileData.attributes && this.state.MyprofileData.attributes.personal_information.first_name + "  " + this.state.MyprofileData.attributes.personal_information.last_name} from your
                                            My posts?
                                        </Typography>
                                    </Box>

                                    <Box style={webStyle.modalActions}>
                                        <Button
                                            data-test-id='closeModal'
                                            variant="contained"
                                            style={{
                                                ...webStyle.cancelButton,
                                                textTransform: "capitalize",
                                            }}
                                            onClick={this.handleCloseModal}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            data-test-id="deleteMypostPop"
                                            variant="contained"
                                            style={{ ...webStyle.removeButton, textTransform: "capitalize", backgroundColor: "white" , border:"1px solid rgb(20, 54, 46)"}}
                                            onClick={() => this.handleMenuItemClick('Delete', this.state.deletePostId)}
                                        >
                                          Remove
                                        </Button>
                                    </Box>
                                </Paper>
                            </Modal>

                            <Box style={{ borderBottom: "1px solid #CBD5E1", margin: "0 15px" }}></Box>

                        </Container>

                        <CardContent>
                        {this.myProfileThreeDotPopupMenu()}

                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px", flexWrap: "wrap" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "auto" }}>
                                    <Box>
                                        <img src={this.state.MyprofileData.attributes ? this.state.MyprofileData.attributes.personal_information.profile_picture.url : ""} style={{ width: "100px", height: ' 100px', borderRadius: "50%", border: "3px solid #D4E5DE" }} alt="" />
                                    </Box>
                                    <Box style={{ marginLeft: "10px" }}> 
                                        <Box style={{ marginLeft: "10px" }}>
                                            <Typography style={{ fontFamily: "MyFont", fontWeight: "bold", letterSpacing: "0.8px", fontSize: "21px", color: "#0F172A" }}>{this.state.MyprofileData.attributes && this.state.MyprofileData.attributes.personal_information.first_name + "  " + this.state.MyprofileData.attributes.personal_information.last_name}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontWeight: "500", fontSize: "15px", color: "#0F172A", letterSpacing: '0.8px' }}>{this.state.MyprofileData.attributes && this.state.MyprofileData.attributes.account_details.company}</Typography>
                                            <Typography style={{ fontFamily: "MyFont", fontWeight: "500", fontSize: "14px", letterSpacing: '0.8px', width: "200px", marginTop: "15px" }}>{this.state.MyprofileData.attributes && this.state.MyprofileData.attributes.personal_information.bio}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box style={webStyle.greenMyCircle as any}>
                                    <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "100%", alignItems: "center" }}>
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "150px" }}>
                                            <Box onClick={() => this.handleOpenInvitedDrawer()}>
                                                <Typography style={{ fontSize: "14px", color: "#1E293B", fontWeight: 900, fontFamily: "MyFont", letterSpacing: '0.8px', textAlign:'center' }}>{this.state.MyprofileData.attributes?.invited}</Typography>
                                                <Typography style={{ fontSize: "12px", color: "#475569", fontWeight: "700", fontFamily: "MyFont", letterSpacing: '0.8px', cursor: 'pointer' }} >Invited</Typography>
                                            </Box>
                                            <Box style={{ border: "1px solid #CBD5E1", rotate: "180deg", height: "35px" }}></Box>
                                            <Box data-test-id='accept-drawer-button' onClick={() => this.handleOpenAcceptedDrawer()}>
                                                <Typography style={{ fontSize: "14px", color: "#1E293B", fontWeight: 900, fontFamily: "MyFont", letterSpacing: '0.8px',  textAlign:'center'}}>{this.state.MyprofileData.attributes?.accepted}</Typography>
                                                <Typography style={{ fontSize: "12px", color: "#475569", fontWeight: "700", fontFamily: "MyFont", letterSpacing: '0.8px', cursor: 'pointer' }} >Accepted</Typography>
                                            </Box>
                                        </Box>
                                        <Box style={{display: "flex", gap: "10px"}}>
                                            <MyCircleBox
                                                onClick={this.handleGoMyCircle}
                                                data-test-id="my-circle-box"
                                            >
                                                My Circle
                                            </MyCircleBox>
                                            <MyCircleMailBox onClick={this.handleOpenEmail}
                                                data-test-id="my-circle-mail"
                                            >
                                                <MailOutline style={{ fill: "#14362E", width: "30px", height: "22px" }} />
                                            </MyCircleMailBox>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <Box style={{ borderBottom: "3px solid #E2E8F0", padding: "10px", paddingBottom: 0, marginBottom: "25px" }}>

                                <Container>
                                    <Tabs value={this.state.changeTabValue} onChange={this.changeTabvalue} TabIndicatorProps={{className: 'custom-tab-indicator'}}>
                                        <Tab icon={<i className="fa-solid fa-list"></i>} />
                                        <Tab icon={<img src={bookmarkImg} />} />
                                        <Tab icon={<img src={reviewsImg} />} />
                                        <Tab icon={<img src={other_profile} />} />
                                    </Tabs>
                                </Container>

                            </Box>
                            <Box data-test-id="MYprofile">
                                {this.renderContentMyProfile()}
                            </Box>
                        </CardContent>
                    </Card>
                    <Drawer anchor="right" open={this.state.openBlockDrawer} style={webStyle.drawer}>
                        {this.Blockuserdrawercode()}
                    </Drawer>
                    {this.acceptedInvitedDrawer({
                        title: "Invited",
                        users: this.state.invitedUsers,
                        onClose: this.handleCloseIntivedDrawer,
                        open: this.state.openInvitedDrawer
                    })}
                    {this.acceptedInvitedDrawer({
                        title: "Accepted",
                        users: this.state.acceptedUsers,
                        onClose: this.handleCloseAcceptedDrawer,
                        open: this.state.openAcceptedDrawer
                    })}
                </Container>
            </>
        )
    }
    render() {
        const open = this.state.openMenu

        return (

            <Box style={{background: '#F8FAFC', opacity: this.state.isCommentDrawerOpen && !this.state.isSmallScreen ? '30%' : '100%',}}>
                <AppHeader component={"profile"} />
                {
                    this.state.otheruserparamsid ? (<>
                        {this.otheruserRendercall(open)}

                    </>) : (
                        <>
                            {this.myProfileRendercall()}
                        </>
                    )
                }
                    {this.sharedrawerfunction()}
                    {this.renderSharefunction()}
                <AppFooter component={"profile"} />
                <Toaster />
            </Box>

        );

    }
}

const StyledMentionsInput = styled(MentionsInput)<MentionsInputProps & StyledComponentProps>(() => ({
    marginLeft: "8px",
    width: '440px',
    height: '48px',
    backgroundColor: '#F1F5F9',
    border: '1px solid #E2E8F0',
    borderRadius: "50px",
    "& textarea": {
        padding: '12px 16px',
        width: '100%',
        height: '100%',
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "MyFont",
        border: "none",
        borderRadius: "50px",
        "&:focus": {
            outline: 'none',
            border: 'none',
        },
        "&:hover": {
            border: 'none',
        },
    },
    "&:focus": {
        outline: 'none',
        border: '1px solid #E2E8F0',
    },
    "&:hover": {
        border: '1px solid #E2E8F0',
    },
    '& [class*="__suggestions"]': {
        top: "25px !important",
        minWidth: "333px",
        fontFamily: "myfont",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "16px",
        border: "1px solid #E2E8F0",
        padding: "8px 12px",
    },
    '& [class*="__suggestions__list"]': {
        border: "none",
    },
    '& [class*="__suggestions__item"]': {
        border: "none",
        borderBottom: "1px solid #E2E8F0",
        borderRadius: "0",
        padding: "8px"
    },
    "@media(max-width: 600px)": {
        "& textarea": {
            fontSize: "13px !important"
        },
        '& [class*="__suggestions"]': {
            minWidth: "100%",
            fontSize: "14px",
        },
    }

}));

const MenuBox = styled(Box)({
    '& .menuicon': {
      width: "18px",
      height: "18px",
      top: "9px",
      left: "16px",
      padding: "2px"
    },
    '& .menuitemcss': {
      fontFamily: "myfont",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "22px",
      letterSpacing: "0.08em",
      textAlign: "left"
    },
    "@media(max-width: 600px)": {
  
    }
  });
const HeaderContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-between",

    '& .flexBoxCus': {
        position: "relative",
        textAlign: "right" as 'right',
        cursor: "pointer",
        flexWrap: "wrap",
        width: "30px",
    },
});
const BoxMain = styled(Box)({

    width: "450px",
    "@media(max-width: 600px)": {
        width: '100vw',
    },
})

const MyCircleBox = styled(Box)({

    padding: "5px 20px",
    color: "#14362E",
    fontSize: "14px",
    marginTop: "10px",
    letterSpacing: "0.6px",
    fontFamily: "MyFont",
    border: "1px solid #73A58A",
    fontWeight: "bold",
    borderRadius: "5px",
    width: "110px",
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "5px",
    "@media (max-width: 600px)": {
        width: '250px',
    },
    "@media (max-width: 400px)": {
        width: '200px',
    }
})

const MyCircleMailBox = styled(Box)({
    padding: "5px",
    color: "#14362E",
    fontSize: "14px",
    marginTop: "10px",
    letterSpacing: "0.6px",
    fontFamily: "MyFont",
    border: "1px solid #73A58A",
    fontWeight: "bold",
    borderRadius: "5px",
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "5px",
})

const BoxCustom2 = styled(Box)({
    width: '540px',
    fontFamily: "myfont",
    "& .mainBox": {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '4px',
        height: '30px',
        marginBottom: '5px',
        paddingLeft: "10px",
        fontFamily: "Halyard Display",
        fontSize: "12px",
        fontWeight: "400"
    },
    "& .mainBoxDivider": {
        marginLeft: "10px",
        width: "95%"
    },
    "& .mediatitle": {
        margin: "15px",
        width: "370px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "12px",
        fontFamily: "MyFont"
    },
    "@media (max-width: 600px)": {
        "& .mediatitle": {
            width: "150px",
        },
    },
    "& .content-container": {
        height: "calc(100vh - 200px)",
        overflowY: "auto",
        paddingBottom: "80px",
    },
    "& .button-container": {
        position: "absolute",
        display: 'flex',
        justifyContent: 'center',
        bottom: 0,
        width: "100%",
        "@media (max-width: 600px)": {
            width: '200px',
        },
        background: "white",
        padding: "10px 0",
        boxShadow: "0 -2px 5px rgba(0,0,0,0.1)",
    },
    '& .selectbtn': {
        width: "90%",
        background: "#14362E",
        textTransform: "none",
        color: "white",
    },
    '@media (max-width: 601px)': {
        '& .selectbtn': {
            marginTop: "0",
        },
    },
    '& .selecttype': {
        color: '#14362E',
        fontSize: "24px",
        fontWeight: "bold",
        paddingLeft: '5px',
        fontFamily: "Myfont"
    },
    '& .sideBarlabel': {
        fontFamily: "Sans-serif",
        color: "#475569",
        letterSpacing: "8%"
    },
    '& .flaggingcontent': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'start',
        padding: '14px',
        fontWeight: "700",
        fontSize: "16px",
        letterSpacing: "0.08em",
        color: "#0F172A"
    },
    "& .typebox": {
        display: "flex",
        paddingLeft: "18px",
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    "& .typebox:hover": {
        backgroundColor: "#D4E5DE"
    },
    "& .checkedCircle": {
        width: "27.7px",
        height: "27.7px",
        color: "#14362E",
        paddingTop: "10px"
    }
});

const CustomDrawer = styled(Drawer)({
    '& .inputSelectedMember': {
        '& input[type="checkbox"]': {
          appearance: "none",
          width: "20px",
          height: "20px",
          border: "2px solid #555",
          borderRadius: "5px",
          cursor: "pointer",
          marginLeft: "auto",
        },
      
        '& input[type="checkbox"]:checked': {
          backgroundColor: "#73A58E",
          borderColor: "#73A58E",
        },
      
        '& input[type="checkbox"]:checked::before': {
          content: '"✔"',
          color: "white",
          fontSize: "16px",
          display: "block",
          textAlign: "center",
          lineHeight: "18px",
        }
      },
    '& .MuiDrawer-paper': {
        width: '540px',
        height: '100vh',
        borderRadius: '24px 0px 0px 0px',
    },
    '& .MuiPaper-elevation16': {
        boxShadow: "rgba(168, 164, 164, 0.24) 0px 4px 3px !important",
    },
    '& .button-icon': {
        borderRadius: "8px",
        padding: "0",
        position: "absolute",
        right: "17%"
    },
    '& .custom-icon': {
        borderRadius: "8px",
        padding: "0",
        position: "relative",
        right: "44%",
        display: 'block'
    },
    '& .emoji-picker': {
        height: "400px !important",
        width: "370px !important",
        position: "absolute",
        right: "32% ",
        top: "26px",
        zIndex: 1000
    },
    '& .reply-emoji-picker': {
        height: "400px !important",
        width: "450px !important",
        position: "absolute",
        top: '103% ',
        right: '0%',
        zIndex: 1000
    },
    '& .display_arrow': {
        display: 'none'
    },
    '@media (max-width: 601px)': {
        '& .MuiDrawer-paper': {
            width: '100%',
            height: '92%',
            overflowX: "hidden",
        },
        '& .button-icon': {
            right: "7%"
        },
        '& .custom-icon': {
            right: "10%"
        },
        '& .emoji-picker': {
            right: '5%',
            width: "auto !important",
            height: '500px !important',
            maxHeight: '500px !important',
        },
        '& .reply-emoji-picker': {
            top: "103%",
            right: '0%',
            height: '390px !important',
            width: 'auto !important',
            maxHeight: '390px !important',
        },
        '& .display_arrow': {
            display: 'flex'
        }
    },
    '& .box7': {
        display: 'flex',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "14px"
    },
    '& .box8': {
        width: "99%",
        border: "1px solid #CBD5E1",
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px"
    },
    '& .input2': {
        width: "99%",
        padding: "11px 4px",
        borderRadius: "7px",
        border: "none",
        outline: "none",
        lineHeight: '24px',
        fontSize: '16px',
        fontFamily: 'MyFont',
    },
    '& .box5': {
        
        display: "flex",
        justifyContent: "space-between",
        padding: "5px",
        alignItems: "center",
        height: "55px",
        marginBottom: "9px",
        marginTop: "11px"
    },
    '& .box6': {
        display: "flex",
        borderRadius: "9px",
        alignItems: "center"
    },
})

const webStyle = {
    categoryDropdown: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px'
    },
    borderBottomStyle: {
        borderBottom: '1px solid #CBD5E1'
    },
    greenMyCircle: {
        margin: "auto"
    },
    cardOne: {
        maxWidth: 750,
        marginBottom: "25px",
        width: "100%",

        fontFamily: "MyFont"
    },
    removeFromMyCircleModal: {
        position: "absolute" as 'absolute',
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "400px",
        backgroundColor: "white",
        padding: 20,
        borderRadius: 8,
        height: "310px",
        overflow: "auto"
    },
    modalTitle: {
        marginBottom: 15,
        fontFamily: "'MyFont', 'Halyard Display'",
        color: "#14362E",
        fontSize: "24px",
        fontWeight: 500,
        letterSpacing: "0.05em",
    },
    modalContent: {
        marginTop: 20,
        marginBottom: 20,
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 500,
        letterSpacing: "0.05em",
        fontFamily: "'MyFont', 'Halyard Display'",
    },

    modalActions: {
        display: "flex",
        justifyContent: "center",
        gap: 8,
    },
    titleBox: { fontFamily: "MyFont", fontSize: "18px", fontWeight: "700", letterSpacing: "1px" },

    cancelButton: {
        backgroundColor: "#14362E",
        color: "white",
        borderRadius: "7px",
        letterSpacing: "0.05em",
        fontFamily: "'MyFont', 'Halyard Display'",
    },
    removeButton: {
        backgroundColor: "white",
        borderRadius: "7px",
        color: "#14362E",
        borderColor: "1px solid #14362E",
        letterSpacing: "0.05em",
        fontFamily: "'MyFont', 'Halyard Display'",
    },
    modalUserName: {
        color: "#0F172A",
        letterSpacing: "0.05em",
        fontSize: "16px",
        fontWeight: "bolder",
        marginTop: "5px",
        fontFamily: "'MyFont', 'Halyard Display'",
    },
    modalPosition: {
        color: "#64748B",
        fontSize: "12px",
        letterSpacing: "0.08em",
        fontFamily: "'MyFont', 'Halyard Display'",
    },
    categoryDropwdown: {
        padding: "15px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: 'borderBox',
        "@media (max-width: 600px)": {
            width: '100vw',

        }
    },
    borderBottomstyle: {
        border: "1px solid #E2E8F0"
    },
    drawer: {
        width: '540px',
        display: "block",
        '@media (min-width: 600px)': {
            display: "none", 
        },
        "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "240px",
        },
    },

    acceptedIinvitedTitle: {
        paddingLeft: "10px",
        color: "#14362E",
        fontWeight: 'bold',
        fontSize: '24px',
        fontFamily: 'MyFont',
        letterSpacing: '0.8px'
    },

    noPostWrapper: {
        width: "100%", minHeight: "60vh", display: "flex", justifyContent: "center", alignItems: "center",
        "@media (max-width: 600px)": {
            minHeight: "45vh"
        }
    }
}

const StyledDrawer = styled(Drawer)({
    '& .MuiPaper-root': {
        width: '540px',
        "@media (max-width: 600px)": {
            width: '100vw',
    
        }
    }

})
const StyledBadge = styled(Badge)({
    '&.online': {
        '& .MuiBadge-badge': {
            background: '#73A58E'
        }
    },
    '&.offline': {
        '& .MuiBadge-badge': {
            background: '#D1584C'
        }
    }

});
// Customizable Area End