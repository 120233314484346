import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyBkukiU8pkswDqMR8nKUFN21PblQDWfyiQ",
  authDomain: "kella-leadership.firebaseapp.com",
  projectId: "kella-leadership",
  storageBucket: "kella-leadership.appspot.com",
  messagingSenderId: "785665949778",
  appId: "1:785665949778:web:609e29dc2dc4777a7ddf8b",
  measurementId: "G-E47VSS9WZQ"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Check if messaging is supported in the browser
const isMessagingSupported = firebase.messaging.isSupported();

// Initialize messaging only if it's supported
let messaging = null;
if (isMessagingSupported) {
  messaging = firebase.messaging();
} else {
  console.warn('Firebase Messaging is not supported in this browser.');
}

export { messaging, isMessagingSupported };
