import React from "react";

//Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid,
 Checkbox,InputLabel,MenuItem,TextField,
 Drawer,
 Divider,
 FormControl,
 RadioGroup,
 FormControlLabel,
 Radio,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { logo, women,personalprofile,interests,hobbies,worries,passion,expertise, all_done, kella_logo,mobile_bg } from "./assets";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OnboardingScreenController, { Props } from "../../../blocks/user-profile-basic/src/OnboardingScreenController";
import { Close } from "@material-ui/icons";

type TCheckboxItem = {
  id: number;
  testIdKey: string;
  title: string;
  isChecked: boolean;
  handleChange: (title: string) => void;
}
type TCheckboxList = {
  title: string;
  subtitle: string;
  items: { id: number, title: string}[]
  navigationBlockKey: string;
  testIdKey: string;
  chekedList: string[];
  handleChange: (title: string) => void;
  stepImg: string;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const webStyle = {
    contactUsDrawerTxt: {
      fontFamily: "MyFont",
      letterSpacing: "1.4",
      color: "#1E1E1E",
      fontSize: "14px",
      marginBottom: "20px",
    },
    contactUsDrawerInputLabel: {
      fontSize: "16px",
      fontWeight: 700,
      color: "#334155",
    },
}
export default class OnboardingScreen extends OnboardingScreenController {
  constructor(props: Props) {
    super(props);
   
  }
  selectMenuDawer () {
    return (    <StyledDrawer anchor="right" open={!!this.state.selectDrawerType} onClose={this.handleCloseSelectDrawer}>
    <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"} style={{padding:'28px 20px', flexShrink: 0}}>
      <Typography style={{fontFamily:'MyFont', color: '#14362E', fontWeight:'bold', fontSize:'24px'}}>{this.state.drawerData.title}</Typography>
      <Close onClick={this.handleCloseSelectDrawer}/>
    </Box>
    <Divider/>
    <Box style={{padding: '28px', flexGrow:1,height :'100%', overflowY:'auto', marginBottom:'15px'}}>
    <FormControl style={{width:'100%'}}>

      <RadioGroup value={this.state.drawerSelectedOption} onChange={this.handleChangeDrawerSelect}>
      {
       this.state.drawerData.options.map(identity => (
        <FormControlLabel
          style={{borderBottom:'1px solid #CBD5E1', justifyContent:'space-between'}}
          key={identity.title}
          value={identity.title}
          control={<StyledRadio size="small" />}
          label={identity.title}
          labelPlacement="start"
        />
        ))
      }
        </RadioGroup>
    </FormControl>
    </Box>
    <Box style={{padding:'0 28px', paddingBottom:'40px', flexGrow:0}}>
    <Button size='large' fullWidth onClick={() =>this.handleSelectDrawer()} variant="contained" style={{ fontFamily: "'MyFont', 'Halyard Display'",textTransform:'unset', background: '#14362E', color: 'white', fontSize:'16px', fontWeight:'bold'}}>Select</Button>
    </Box>

  </StyledDrawer>)
  }
  navigationBlock(buttonTestId: string) {
    return (
      <Box sx={{justifyContent: 'center' , marginTop: { sm:"160px",xs:"44px"}, paddingBottom: {xs: '40px', sm:'80px'}, display: 'flex', flexDirection: 'row', padding: '10px', width:'100%', boxSizing:'border-box'}}>
          <Button
            variant="contained"
            style={{
                height: "56px",
                fontWeight: 590,
                fontFamily: "'MyFont', 'MyFont'",
                textTransform: "none",
                borderRadius: "8px",
                backgroundColor: "white",
                color: "black",
                border: '1px solid #73A58E',
                fontSize: "16px",
                letterSpacing: "0.08em",
                maxWidth: "210px",
                marginRight: '10px',
                display: buttonTestId ==='screenhome' ? 'none': 'block',
            }}
            onClick={() => this.handleBackButtonClick()}
            fullWidth
          >
            Back
          </Button>

        <Button
            data-test-id={buttonTestId}
            style={{
                textTransform: "none",
                fontWeight: 590,
                letterSpacing: "0.08em",
                borderRadius: "8px",
                height: "56px",
                fontFamily: "'MyFont', 'MyFont'",
                backgroundColor: "#14362E",
                color: "white",
                fontSize: "16px",
                maxWidth: buttonTestId ==='screenhome' ? '100%': '210px'
            }}
          variant="contained"
          disabled={!!this.state.helperText}
          onClick={() => this.state.currentScreen === 7? this.handleSave() : this.handleStartButtonClick()}
          fullWidth
        >
          Next
        </Button>

    </Box>
    )
  }
  checkboxListItem({id, testIdKey, isChecked, handleChange, title}: TCheckboxItem){
    return (
      <Grid item sm={6} xs={12}  key={id} style={{padding:'5px',paddingBottom:'0px',}}>
        <div style={{ paddingLeft:'30px' ,display: 'flex', alignItems: 'center', boxSizing:'border-box', gap:'10px', width:'100%'}}>
          <StyledCheckbox
            data-test-id={`${testIdKey}-${id}`}
            checked={isChecked}
            onChange={() => handleChange(title)}
            style={{color: isChecked ? '#73A58E' : '', }}
          />
          <Typography variant="h6" style={{ marginTop: 0, fontSize:'16px', textOverflow: 'ellipsis',fontFamily: "MyFont", }}>{title}</Typography>
        </div>
      </Grid>
    )
  }
  checkboxList({stepImg, title, subtitle, navigationBlockKey, items, handleChange, chekedList, testIdKey}:TCheckboxList) {
    return (
      <Grid
       alignItems="center"
       container
       justifyContent="space-between"
      > 
        <StyledGrid
          item
          xs={12}
          md={8}
        >
          <Box sx={{marginTop:{xs:"3rem"}}}>
            <img
              src={logo}
              style={{ width: "249px", height: "44px"}}
              alt="kella_logo_txt"
            />
          </Box>
          <Box style={{marginTop:'2rem'}}>
            <img
              src={stepImg}
              style={{  width: "300.88px",height: "24.59px" }}
            />
          </Box>
          <Box style={{ marginTop: '1rem', textAlign:'center',  }}>
            <Typography variant="h6" style={{ fontFamily: "MyFont",color: '#14362E',fontWeight: 'bold', fontSize: '18px',  }}>
              {title}
            </Typography>
          </Box>
          <Box sx={{ marginTop:'5rem', textAlign: 'left', marginLeft:'45px', alignSelf: 'flex-start'}}>
            <Typography variant="body1" style={{ fontWeight:'bold',fontFamily: "MyFont", color: '#0F172A', fontSize:'18px' }}>
            {subtitle}
            </Typography>
          </Box>
          <Box
            sx={{
                flexDirection: "column",
                marginTop: "3rem",
                justifyContent: 'center',
                height: "100%",
                display: "flex",
            }}
          > 
            <div style={{  justifyContent: 'center',display: 'flex', }}>
              <Grid container spacing={6} style={{width:'100%'}}>
                {items.map((item) => this.checkboxListItem({
                  id: item.id,
                  testIdKey,
                  title: item.title,
                  isChecked: chekedList.includes(item.title),
                  handleChange
                  }))}
              </Grid>
            </div>  
          </Box>
          {this.navigationBlock(navigationBlockKey)} 
        </StyledGrid>
        <StyledHiddenGrid item xs={12} md={4} >
          <Box sx={{ display: { xs: "none", md: "flex" },}}>
            <img
              src={women}
              style={{ maxWidth: "100%", width: "100%", height: "100vh" }}
              alt="forgot-password-screen"
            />
          </Box>
        </StyledHiddenGrid>
      </Grid> 
    )
  }
  render() {
     
    return(
      <ThemeProvider theme={theme}>
        {this.state.currentScreen === 0 && 
      <Grid
      alignItems="center"
      justifyContent="space-between"
      container
      >
        <StyledGrid
          item
          md={8}
          xs={12}
          className="firstStep"
        >
          <Box sx={{marginTop: {sm:"3rem", xs:'26rem',md: '3rem'}}}>
            <img
              src={kella_logo}
              style={{ maxWidth: "100%", height: "auto"}} 
              alt="kella_logo_txt"
            />
          </Box>
          
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: {xs: "94%", md: "55%"},
              marginTop:{ xs: '2rem', md:'9rem'},
              flexDirection: "column",
            }}
          >
            <Typography
              component="div"
              variant="subtitle1"
              
              style={{
                  textAlign: "center",
                  lineHeight: "28px",
                  fontSize:"1.875rem" ,
                  fontFamily: "MyFont",
                  color: "#14362E",
                fontWeight: 'bold',
                letterSpacing: "0.08em",
                
              }}
            >
              {this.welcomeText}
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              style={{
                marginTop: "0.5rem",
                fontFamily: "'MyFont', 'MyFont'",
                letterSpacing: "0.05em",
                fontSize: "1.125rem", 
                fontWeight: "normal",
                color: "#0F172A",
                textAlign:'center',
              }}
            >
              {this.welcomeBelowText}
            </Typography>
           
           
          </Box>
          <Box sx={{ width: "310px", marginTop: {xs:"30%", md:"20%"}, marginBottom:'40px' }}>
            <Button
             data-test-id="home"
              variant="contained"
              style={{
                borderRadius: "8px",
                textTransform: "none",
                fontWeight: 590,
                letterSpacing: "0.08em",
                height: "56px",
                fontFamily: "'MyFont', 'MyFont'",
                backgroundColor: "#14362E",
                color: "white",
                fontSize: "16px",
              }}
              fullWidth
              onClick={() => this.handleStartButtonClick()}
            >
              Start
            </Button>
          </Box>
        
        </StyledGrid>
        <StyledHiddenGrid item xs={12} md={4}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <img
              src={women}
              style={{ maxWidth: "100%", width: "100%", height: "100vh" }}
              alt="forgot-password-screen"
            />
          </Box>
        </StyledHiddenGrid>
      </Grid>}
      {this.state.currentScreen === 1 && 
       <Grid  justifyContent="space-between" container alignItems="center">
       <StyledGrid item xs={12} md={8}>
        <Box sx={{marginTop:{ md:"-2rem",xs:"3rem"}}}>
          <img
            style={{ width: "249px",marginTop:'-2rem', height: "44px"}}
            
            src={logo}
            alt="kella_logo_txt"
          />
        </Box>
        <Box style={{ marginTop: '2rem' }}>
          <img
              style={{  width: "300.88px",height: "24.59px" }}
              src={personalprofile}
          />
        </Box>
        <Box style={{ marginTop: '1rem' }}>
          <Typography variant="h6" style={{ fontFamily: "MyFont",color: '#14362E',fontWeight: 'bold', fontSize: '18px',  }}>
            Personal Profile
          </Typography>
        </Box>
        <Box sx={{ flexDirection: 'row',marginTop:'2rem', width: { xs: '290px', sm: '390px' }}}>
        <Grid item xs={10} sm={12} style={{margin:'auto',marginTop:'2rem',marginBottom:'2rem',}}>
          <InputLabel htmlFor="subject" style={webStyle.contactUsDrawerInputLabel}>Birthday</InputLabel>
          <TextField
            variant="outlined"
            value={this.state.birthday}
            fullWidth
            id="date"
            placeholder="dd/mm/yyyy"
            helperText={this.state.helperText}
            onChange={this.handleBirthdayChange}
            FormHelperTextProps={{
              style: { color: 'red' }, // Set the color of the helper text to red
            }}
            InputLabelProps={{
              shrink: true,
              style: {
                fontWeight: 700,
                fontFamily: "'MyFont',  'Halyard Display'",
                color: "#94A3B8",
                letterSpacing: "0.05em",
                fontSize: "16px"
              }
            }}
            InputProps={{
              style: {
                marginTop: '5px',
                borderRadius: '8px',
                fontFamily: "'MyFont',  'Halyard Display'",
              },
            }}
          />
        </Grid>
        <Grid item xs={10} sm={12} style={{margin:'auto'}}>
         <InputLabel style={webStyle.contactUsDrawerInputLabel} htmlFor="subject">Sexual identity</InputLabel>
         <Button
         data-test-id="Sexual identity"
          style={{
            textTransform: 'none',
            height:'56px',
            borderRadius:'8px',
            justifyContent:'space-between',
            marginTop:'5px',
            padding:'18px 14px',
            fontSize:'1rem',
            fontFamily: "'MyFont',  'Halyard Display'",
          }}
          onClick={() => this.handleOpenSelectDrawer('identity')}
          variant='outlined'
          endIcon={<ArrowDropDownIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
          fullWidth
        >
          {this.state.subject || 'Select an option'}
        </Button>  
        </Grid>
        <Grid item xs={10} sm={12} style={{margin:'auto',marginTop:'2rem'}}>
          <InputLabel htmlFor="Ethnicity" style={webStyle.contactUsDrawerInputLabel}>Ethnicity</InputLabel>
          <Button
         data-test-id="Ethnicity"
          style={{
            textTransform: 'none',
            height:'56px',
            borderRadius:'8px',
            justifyContent:'space-between',
            marginTop:'5px',
            padding:'18px 14px',
            fontSize:'1rem',
            fontFamily: "'MyFont',  'Halyard Display'",
          }}
          onClick={() => this.handleOpenSelectDrawer('ethnicity')}
          variant='outlined'
          endIcon={<ArrowDropDownIcon fontSize="small" style={{color: 'rgba(0, 0, 0, 0.54)'}}/>}
          fullWidth
        >
          {this.state.ethnicity || 'Select an option'}
        </Button> 
        </Grid>
          {this.navigationBlock('screenhome')}
    </Box>
    {this.selectMenuDawer()}
  </StyledGrid> 
  <StyledHiddenGrid item xs={12} md={4}>
    <Box sx={{ display: { xs: "none", md: "flex" } }}>
      <img
        src={women}
        style={{ maxWidth: "100%", width: "100%", height: "100vh" }}
        alt="forgot-password-screen"
      />
    </Box>
  </StyledHiddenGrid>
     </Grid>
  }
      {
        this.state.currentScreen === 2 &&
        this.checkboxList({
          title: 'Interests',
          subtitle: 'What are your top 5 professional interests?',
          chekedList: this.state.checkedItems,
          testIdKey: 'interest',
          handleChange:  this.handleCheckboxChange,
          items: this.state.interests,
          navigationBlockKey: 'interests',
          stepImg: interests
        })
      }
       {
        this.state.currentScreen === 3 && 
        this.checkboxList({
            title: 'Hobbies',
            subtitle: 'What do you like to do in your spare time?',
            chekedList: this.state.checkedHobbies,
            testIdKey: 'hobby',
            handleChange:  this.handleCheckboxChangeHobbies,
            items: this.state.hobbies,
            navigationBlockKey: 'hobbies',
            stepImg: hobbies
          })
       }
        {
          this.state.currentScreen === 4 && 
          this.checkboxList({
            title: 'Worries/Concerns',
            subtitle: 'What are your worries and concerns?',
            chekedList: this.state.checkedWorries,
            testIdKey: 'worry',
            handleChange:  this.handleCheckboxChangeWorries,
            items: this.state.worries,
            navigationBlockKey: 'worries',
            stepImg: worries
          })   
      }
      {
        this.state.currentScreen === 5 && 
        this.checkboxList({
          title: 'Passions',
          subtitle: 'Please let us know what makes you happy?',
          chekedList: this.state.checkedPassions,
          testIdKey: 'passion',
          handleChange:  this.handleCheckboxChangePassions,
          items: this.state.passions,
          navigationBlockKey: 'passions',
          stepImg: passion
        })
      }
      {
        this.state.currentScreen === 6 && 
        this.checkboxList({
            title: 'Expertise',
            subtitle: 'Choose your area of expertise(max 5)',
            chekedList: this.state.checkedExpertise,
            testIdKey: 'expertiseItem',
            handleChange:  this.handleCheckboxChangeExpertise,
            items: this.state.expertise,
            navigationBlockKey: 'expertise',
            stepImg: expertise
          })
      }
      {this.state.currentScreen === 7 && 
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledGrid
          item
          xs={12}
          md={8}
         
        >
          <Box sx={{marginTop:{xs:"3rem"}}}>
            <img
              src={logo}
              style={{ width: "249px", height: "44px"}}
              alt="kella_logo_txt"
            />
            </Box>
          <Box style={{marginTop:'2rem'}}>
            <img
              src={all_done}
              style={{  width: "300.88px",height: "24.59px" }}
            />
          </Box>
          <Typography variant="h6" style={{ marginTop:'16px',display:'flex',justifyContent:'space-around',fontWeight:'bolder', color: "#14362E", fontSize: '20px',fontFamily: "MyFont"  }}>
            All Done!
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: {xs: "94%", md: "85%"},
              marginTop:"4rem"
            }}
          >
            <Typography
              variant="subtitle1"
              component="div"
              style={{
                marginTop: "0.5rem",
                fontSize: "1.125rem", 
                fontWeight: "normal",
                color: "#0F172A",
                fontFamily: "'MyFont', 'MyFont'",
                letterSpacing: "0.05em",
                width:"100%",
                textAlign:'center'
              }}
            >
                 Thank you for your time, your answers will be used to ensure that you are seeing content that matches your chosen interests, concerns, expertise and passions. Please enjoy and make the most of your new community. Lean on us and let us support you to go even further! Let's get started...
         
            </Typography>
            <Typography
                  variant="subtitle1"
                  component="div"
                  
                  style={{
                    fontWeight: 'bold',
                    letterSpacing: "0.08em",
                    textAlign: "center",
                    lineHeight: "28px",
                    fontSize:"1.6rem" ,
                    fontFamily: "MyFont",
                    color: "#14362E",
                    marginTop:"6rem",
                    
                  }}
                >
                   Let's get started...
                 </Typography>
           
          </Box>
          {this.navigationBlock('homefinish')}
        </StyledGrid>
        <StyledHiddenGrid item xs={12} md={4}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <img
              src={women}
              alt="forgot-password-screen"
              style={{ maxWidth: "100%", width: "100%", height: "100vh" }}
            />
          </Box>
        </StyledHiddenGrid>
      </Grid>}
      </ThemeProvider>
    );
  }
}
const StyledRadio = styled(Radio)({
  '&.Mui-checked': {
    color: '#73A58E'
  },
});
const StyledGrid = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "0%",
  overflow:'auto',
  height:'100vh',
  "@media (max-width: 900px)": {
    height: '100%',
},
'&.firstStep': {
  "@media (max-width: 900px)": {
    backgroundImage: `url(${mobile_bg})`,
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover' 
},
}
});
const StyledHiddenGrid = styled(Grid)({
  "@media (max-width: 900px)": {
    display:'none'
},
})
const StyledDrawer = styled(Drawer)({
  "& .MuiDrawer-paper ": {
    width:'484px',
    display:'flex',
    flexDirection:'column',
    height: '100vh',
    "@media (max-width: 600px)": {
        width: '100vw',
        height: '80vh',

    },
  },
 
  "& .MuiFormControlLabel-label": {
    color: '#475569',
    fontSize: '16px',
    fontFamily:'MyFont'
  }
})
const StyledCheckbox = styled(Checkbox)({
  display:"flex",
  alignSelf:'flex-start',
  '&.MuiCheckbox-colorSecondary:hover': {
    background: 'rgba(115, 165, 142, 0.1)',
  }
});

//Customizable Area End

