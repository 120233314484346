import React from "react";

//Customizable Area Start
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { logo, women } from "./assets";

import ForgotPasswordController from "./ForgotPasswordController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPasswordCheckEmail extends ForgotPasswordController {
  render() {
    return (
      <ThemeProvider theme={theme}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid
          item
          xs={12}
          md={8}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0%",
          }}
        >
          <Box sx={{ marginTop: { xs: "3rem", md: "-3rem" } }}>
            <img
              src={logo}
              style={{ maxWidth: "100%", height: "auto" }}
              alt="kella_logo_txt"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "94%", md: "40%" },
              height: "100%",
            }}
          >
            <Typography
              variant="subtitle1"
              component="div"
              style={{
                letterSpacing: "0.05em",
                textAlign: "left",
                fontWeight: 700,
                fontSize: "20px",
                fontFamily: "'MyFont', 'Halyard Display'",
                lineHeight: "28px",
                color: "#14362E",
                marginTop: "3rem",
              }}
            >
              {this.state.labelCheckEmail}

            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              line-height="24px"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#0F172A",
                letterSpacing: "0.05em",
                textAlign: "left",
                marginTop: "0.5rem",
                fontFamily: "'MyFont', 'Halyard Display'",            
              }}
            >
              We have sent a password reset link to {this.state.storedEmail}
            </Typography>
          </Box>
         
          <Box sx={{ width: "310px" , marginTop: {xs:"65%", md:"35%"}}}>
            <Button
              data-test-id="recoveryLink"
              id="web-login-button"
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#14362E",
                textTransform: "none",
                letterSpacing: "0.08em",
                color: "white",
                borderRadius: "8px",
                height: "56px",
                fontWeight: 590,
                fontSize: "16px",
                fontFamily: "'MyFont', 'Halyard Display'",
              }}
            >
              Send recovery link
            </Button>
          </Box>
          <Box
            style={webStyle.forgotPasswordStyle}
          >
            Don't have an account? &nbsp;
            <span style={{ cursor: "pointer" }}>
              <a
                style={{
                  color: "#14362E",
                  fontWeight: "bold",     
                  textDecoration: "none",
                }}
                onClick={this.handlesignupNavigation}
              >
                Sign up
              </a>
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}>
            <img
              alt="forgot-password-screen"
              src={women}
              style={{
                width: "100%",
                height: "100%",
                maxWidth: "100%", 
              }}
            />
          </Box>
        </Grid>
      </Grid>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  forgotPasswordStyle: {
    display: "flex",
    letterSpacing: "0.08em",
    padding: "8px 0px",
    marginTop: "0%",
    fontFamily: "'MyFont', 'Halyard Display'",
  }
}
// Customizable Area End
