Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodForGet = "GET";
exports.apiMethodTypeGet = "POST";
exports.productAPiEndPoint = "/qr_codes/qr_codes";
exports.qrCodeApiEndPoint = "/bx_block_qr_codes/qr_code";
// Customizable Area End
