Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'bx_block_chat/messages/room_messages';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'bx_block_chat/messages/friends';
exports.getGroupChatsApiEndpoint = 'bx_block_chat/rooms'

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.blockReasons = [
  {
    id: 1,
    title: "Unwanted Interactions",
    description: "Blocking helps prevent harassment, bullying, and unwanted attention from other users.",
    backgroundColor: "#F1E3E4",
  },
  {
    id: 2,
    title: "Content Shielding",
    description: "Blocking shields users from offensive or inappropriate content posted by others.",
    backgroundColor: "#E7E3F1",
  },
  {
    id: 3,
    title: "Privacy Control",
    description: "Users can block others to control who views their profile, posts, and personal information, addressing privacy concerns.",
    backgroundColor: "#D4E5DE",
  },
  {
    id: 4,
    title: "Maintaining a Positive Environment",
    description: "Blocking aids in creating a positive online experience by preventing distractions, trolls, and disruptive behavior.",
    backgroundColor: "#E5E3E3",
  },
  {
    id: 5,
    title: "Safety and Trust",
    description: "Blocking serves as a layer of protection against safety concerns, trust breaches, and unwanted solicitations, ensuring a safer and more trustworthy online presence.",
    backgroundColor: "#E3F1E5",
  },
]; 
exports.blockUserEndPoint = "/bx_block_block_users/blocked_user";
exports.removeFromMyCircleEndPoint = "bx_block_addfriends/connections/";

  // Customizable Area End