// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordWebBlock from "../../blocks/forgot-password/src/ForgotPasswordBlock.web"
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import ForgotPasswordBlockOTP from "../../blocks/forgot-password/src/ForgotPasswordBlockOTP.web"
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import NewPasswordWebBlock from "../../blocks/forgot-password/src/NewPasswordBlock.web";
import ForgotPasswordCheckEmail from "../../blocks/forgot-password/src/ForgotPasswordCheckEmail.web"
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
// import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import OnboardingScreen from "../../blocks/user-profile-basic/src/OnboardingScreen.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Videos from "../../blocks/videos/src/Videos";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import EditUserProfile from "../../blocks/user-profile-basic/src/EditUserProfile.web";
// import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
// import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import CreateComment from "../../blocks/comments/src/CreateComment";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
// import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import QrCodesWeb from "../../blocks/qrcodes/src/QrCodes.web";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPageBlock from "../../blocks/landingpage/src/LandingPageBlock";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Trending from "../../blocks/trending/src/Trending.web";
import Categorydetails from "../../blocks/trending/src/TrendingProductDetails.web"
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Chat from "../../blocks/chat/src/Chat.web";
import ViewChat from "../../blocks/chat/src/ViewChat.web";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OTPInputAuthBlock from "../../blocks/otp-input-confirmation/src/OTPInputAuthBlock.web";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import NewsFeed from "../../blocks/newsfeed/src/NewFeedBlock.web"
import AccountDetails from "../../blocks/user-profile-basic/src/AccountDetails.web"
import ProfessionalDetails from "../../blocks/user-profile-basic/src/ProfessionalDetails.web"
import ApprovalStates from "../../blocks/user-profile-basic/src/ApprovalStates.web"
import Settings from "../../blocks/settings2/src/Settings.web";
import Payment from "../../blocks/settings2/src/Payment.web";
import HelpCentre from "../../blocks/settings2/src/HelpCentre.web";
import NotificationSettings from "../../blocks/settings2/src/NotificationSettings.web";
import PrivacySettings from "../../blocks/settings2/src/PrivacySettings.web";
import FAQ from "../../blocks/settings2/src/FAQ.web";
import AddFriends from "../../blocks/addfriends/src/AddFriends.web";
import AllEvents from "../../blocks/events/src/AllEventBlock.web";
import purchaseEvent from "../../blocks/events/src/AllEventDetailBlock.web"
import Billing from "../../blocks/settings2/src/Billing.web"; 
import EditPayment from "../../blocks/settings2/src/EditPayment.web";
import SubscriptionSuccessScreen from "../../components/src/SubscriptionSuccessScreen.web";
import SubscriptionFailedScreen from "../../components/src/SubscriptionFailedScreen.web";
import "firebase/messaging";
import { messaging, isMessagingSupported } from "./firebase";

const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
ForgotPasswordWebBlock:{
 component:ForgotPasswordWebBlock,
path:"/ForgotPassword"},
ForgotPasswordBlockOTP:{
 component:ForgotPasswordBlockOTP,
path:"/ForgotPasswordOTP"},
NewPasswordWebBlock:{
 component:NewPasswordWebBlock,
path:"/NewPassword"},
ForgotPasswordCheckEmail:{
  component:ForgotPasswordCheckEmail,
path:"/ForgotPasswordCheckEmail"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Videos:{
 component:Videos,
path:"/Videos"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EditUserProfile:{
 component:EditUserProfile,
path:"/EditUserProfile"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Settings:{
  component:Settings,
 path:"/Settings",},
// Notificationsettings:{
//  component:Notificationsettings,
// path:"/Notificationsettings"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
OnboardingScreen:{
  component:OnboardingScreen,
 path:"/OnboardingScreen"},
QrCodes:{
 component:QrCodes,
path:"/QrCodes"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
component:LandingPageBlock,
path:"/LandingPageBlock"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Trending:{
 component:Trending,
path:"/ExploreBlock"},
Categorydetails:{
  component:Categorydetails,
 path:"/Categorydetails"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OTPInputAuthBlock:{
 component:OTPInputAuthBlock,
path:"/OTPInputAuthBlock"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},

  Home: {
    component: LandingPageBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  NewsFeed:{
    component:NewsFeed,
    path:'/NewsFeed'
  },
  AllEvents:{
    component:AllEvents,
    path:'/AllEvents'
  },
  purchaseEvent:{
    component:purchaseEvent,
    path:'/purchaseEvent'
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // },
  AccountDetails: {
    component: AccountDetails,
    path: '/AccountDetails'
  },
  ProfessionalDetails: {
    component: ProfessionalDetails,
    path: "/ProfessionalDetails",
  },
  ApprovalStates:{
    component:ApprovalStates,
    path:"/ApprovalStates",
  },
  Payment:{
    component:Payment,
    path:"/Payment",
  },
  NotificationSettings:{
    component:NotificationSettings,
    path:"/NotificationSettings",
  },
  PrivacySettings:{
    component:PrivacySettings,
    path:"/PrivacySettings",
  },
  FAQ:{
    component:FAQ,
    path:"/FAQ",
  },
  AddFriends:{
    component:AddFriends,
    path:"/MyCircle",
  },
  Billing:{
    component: Billing,
    path: '/Billing' 
  },
  EditPayment:{
    component: EditPayment,
    path: '/EditPayment' 
  },
  SubscriptionSuccessScreen:{
    component: SubscriptionSuccessScreen,
    path: '/SubscriptionBillingSuccess' 
  },
  SubscriptionFailedScreen:{
    component: SubscriptionFailedScreen,
    path: '/SubscriptionBillingFailed' 
  },
  QrCodesWeb:{
    component: QrCodesWeb,
    path: '/ShareProfile' 
  }
};


class App extends Component {

  async componentDidMount() {
    const isToken = localStorage.getItem('accessToken');
    const isAnyPlanActive =localStorage.getItem('isAnyPlanActive');
    const currentPath = window.location.pathname;

    // Paths where user can be redirect without active plan
    const exceptionPaths = ['/Subscriptionbilling', '/Settings',
       '/SubscriptionBillingSuccess', '/SubscriptionBillingFailed'];
    const isValidPage = !exceptionPaths.includes(currentPath)
    const unprotedctedPages = ['/','/EmailAccountRegistration', '/EmailAccountLoginBlock', '/OTPInputAuthBlock', '/AccountDetails', '/ProfessionalDetails', '/ApprovalStates', '/ForgotPassword', '/ForgotPasswordCheckEmail', '/NewPassword', '/EmailAccountRegistration/?signup=true']

    if(!isToken && !unprotedctedPages.includes(currentPath)){
      window.location.href = '/EmailAccountLoginBlock'
    }
    // Redirect to subscription plan page if user has no active plan 
    if (isToken && !isAnyPlanActive && isValidPage) {
        window.location.href = '/Subscriptionbilling';
      }

      function isAppleDevice() {
        return (/iPad|iPhone|iPod/.test(navigator.userAgent) || /MacIntel/.test(navigator.platform)) && !window.MSStream;
      }
      
      try {
        // Check if Firebase Messaging is supported in the browser
        console.log("isMessagingSupported", isMessagingSupported);
      
        if (!isMessagingSupported) {
          const browser = this.detectBrowser();
          console.error(`Firebase Messaging is not supported in ${browser}.`);
          return; // Stop further execution
        }
      
        // If iOS or macOS, request notification permission
        if (isAppleDevice()) {
          console.log('Apple device (iOS or macOS) detected.');
      
          // Request Notification permission for iOS and macOS
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              // Continue with the service worker registration and token retrieval process
              proceedWithMessaging();
            } else if (permission === 'denied') {
              console.error('Notification permission denied.');
            } else {
              console.warn('Notification permission dismissed.');
            }
          }).catch((error) => {
            console.error('An error occurred while requesting notification permission:', error);
          });
        } else {
          console.log('Non-Apple device detected (e.g., Android), proceeding without permission request.');
          // Proceed directly for Android or other non-Apple devices
          proceedWithMessaging();
        }
      
      } catch (error) {
        console.error('An error occurred during Firebase Messaging initialization:', error);
      }
      
      // Function to proceed with the Firebase messaging setup (for both Apple devices after permission and Android)
      function proceedWithMessaging() {
        // Check if the browser supports service workers
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then(async (registration) => {
              // Retrieve token for push notifications
              messaging.getToken({ serviceWorkerRegistration: registration })
                .then((currentToken) => {
                  if (currentToken) {
                    // Store the token in localStorage
                    localStorage.setItem('device_id', currentToken);
                  } else {
                    console.error('No registration token available.');
                  }
                })
                .catch((err) => {
                  console.error('An error occurred while retrieving token:', err);
                });
      
              // Handle incoming messages
              messaging.onMessage((payload) => {
                try {
                  if (payload && payload.notification) {
                    const { title, body } = payload.notification;
                    console.log(`Notification: ${title} - ${body}`);
                  } else {
                    console.error('No notification payload available.');
                  }
                } catch (error) {
                  console.error('Error while handling push notification:', error);
                }
              });
            })
            .catch((error) => {
              console.error('Service Worker registration failed:', error);
            });
        } else {
          console.error('Service Worker is not supported in this browser.');
        }
      }            
  }
  
  // Utility function to detect the browser
  detectBrowser() {
    const userAgent = navigator.userAgent;
    if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      return 'Safari';
    } else if (/Chrome/.test(userAgent)) {
      return 'Chrome';
    } else if (/Firefox/.test(userAgent)) {
      return 'Firefox';
    } else {
      return 'Other';
    }
  }
  

  render() {
  const isStopScroll = localStorage?.getItem("stopBgScroll");
  
    return (
      <View style={{overflow: isStopScroll==="true" ? "hidden" : "auto"}}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;