import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import toast from 'react-hot-toast';
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
const navigation = require("react-navigation");

type accounts = {
  id: number;
  display: string;
  profile_picture: string;
};

interface Item {
  attributes: {
    availability: number;
    breadth: number;
    description: string;
    discount: string;
    height: number;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
    length: number;
    manufacture_date: string;
    name: string;
    on_sale: true;
    price: number;
    recommended: false;
    sale_price: string;
    sku: string;
    stock_qty: number;
    weight: string;
  };
  id: string;
  type: string;
}

type ReportCategory = {
  id: number;
  reason: string;
  description: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  route: string;
  navigation: typeof navigation;
  id: string;
  history: object
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  list: string[];
  citilist: SS["CityItem"][];
  recommendationType: SS["CityItem"][];
  categoryData:SS["CityItem"][];
  TabsView:string;
  Content: string;
  open:boolean;
  selectedType: SS["SelectedItem"][];
  selectedCity: SS["SelectedItem"][];
  savetype: string;
  savecity: string;
  openCity: boolean;
  openMenuId: any;
  openMenu: boolean;
  reportListData: ReportCategory[],
  selectedpostId: any,
  reportId: number,
  selected: string,
  successModalOpen: boolean,
  showCopiedMessage: boolean,
  opentest: any,
  deletedResponseData: any,
  deletePostTitle: string,
  sidebaarOpen: boolean,
  blockUserSideBar: boolean,
  setSearchvalue: string,
  openBlockedModal: boolean,
  openForElement: number,
  postId: string,
  sharemessage: string,
  shareconnectiondata: any,
  shareconnectiondata2:any,
  selectedmember: any,
  selectedPostId: number,
  isSmallScreen: boolean,
  isCommentDrawerOpen: boolean;
  comment: string;
  commentString: string;
  commentData: any;
  mentions_ids: any;
  isCommentsOpen: boolean;
  commentID: number;
  replyCommentString: string;
  reasonTitle: string;
  reasonDescription: string,
  indexBlockList: number,
  errorMsg: string;
  isReplyOpen: boolean;
  selectedComment:number;
  open3: boolean,
  commentReplyOpen:any;
  selectedblockpostId: number,
  profile: any;
  users:any;
  replyComment:string;
  selectedmember3:any;
  sharemessage3:string;
  showEmoji : boolean,
  emoji : any,
  postShareTrendingLoading: boolean
  replyShowEmoji: boolean,
  accountId: string
  searchCityQuery: string
  filteredCities: SS["CityItem"][]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  CheckboxEvent: {
    target: {
      checked: boolean;
      name?: string;  
      value?: string; 
    };
  };
  SelectedItem: {
    id: string;
    value: string;
  };
  error?: string;
  data?: string[];
  CitiesListResponse: {
    error: boolean;
    data:{
      id:string,
      name:string,
      label: string;
    value: string;
    }[];
  };
  TrendingListResponse:{
    error: boolean;
    data:string[];
  };
  CityItem :{
    label: string;
    value: string;
    id: string;
  },
  RecommendationResponse: {
    error: boolean;
    data:{
      attributes: {
        id: string;
        name: string;
        label: string;
        value: string;
      };
    }[];
  Recommendation :{
      label: string;
      value: string;
      id: string;
    }
  };
  CategoryListresponse:{
    error: boolean;
    data:{
      id:string,
      name:string,
      label: string;
    value: string;
    }[];
  }
  
  // Customizable Area End
}

export default class TrendingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  trendingList: string | Message = "";
  citiesList: string | Message = "";
  recommendationtypeid: string | Message = "";
  categoryData:string | Message = "";
  categoryListingId:string | Message = "";
  addFavDataCallId: string | Message = "";
  addBlockUserId: string = "";
  likePostCallId: string | Message = "";
  getReportDataCallId: string | Message = "";
  addReportPostId: string | Message = "";
  getShareconnectionCallId: string = ""
  sharePostId: string = "";
  notificationshowID: string = "";
  apiCommentItemCallId: string = "";
  tagandMentionapiId: string = "";
  createCommentId: string = "";
  replycommentID: string = "";
  likeCommentCallID: string ="";
  shareCommentId: string = "";
  getuserdataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      list: [],
      citilist: [],
      recommendationType: [],
      categoryData:[],
      TabsView: "Trending",
      Content: "",
      isSmallScreen: false,
      open: false,
      openForElement: null as unknown as number,
      deletedResponseData: [],
      selectedType: [],
      selectedCity: [],
      isReplyOpen: false,
      savetype: "false",
      savecity: "false",
      openBlockedModal: false,
      openCity: false,
      openMenuId: "",
      selectedblockpostId: 0,
      openMenu: false,
      reportListData: [],
      selectedpostId: "",
      reportId: 0,
      selected: "",
      successModalOpen: false,
      showCopiedMessage: false,
      opentest: false,
      deletePostTitle: "",
      sidebaarOpen: false,
      blockUserSideBar: false,
      setSearchvalue: '',
      postId: "",
      sharemessage: "",
      shareconnectiondata: [],
      selectedmember: [],
      selectedPostId: 0,
      isCommentDrawerOpen: false,
      comment: "",
      commentString: "",
      commentData: [],
      mentions_ids: [],
      reasonTitle: "",
      reasonDescription: "",
      indexBlockList: null as unknown as number,
      isCommentsOpen: false,
      commentID: null as unknown as number,
      replyCommentString: "",
      errorMsg: "",
      selectedComment: null as unknown as number,
      open3: false,
      commentReplyOpen: [],
      profile:[],
      users: [],
      replyComment: "",
      selectedmember3: [],
      shareconnectiondata2:[],
      sharemessage3:"",
      showEmoji:false,
      emoji:null,
      replyShowEmoji: false,
      accountId: "",
      searchCityQuery: '',
      filteredCities: [],
      postShareTrendingLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson1 = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse1 = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
      if (errorResponse1) {
        this.parseApiCatchErrorResponse(errorResponse1);
      } else {
        this.handleApiResponse(apiRequestCallId, responseJson1);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({ isSmallScreen: window.innerWidth < 600 ?  true : false });
    let fromWhere =  await getStorageData("exploreRedirection")
    let accountId = await getStorageData("account_id")
    this.setState({
       accountId: accountId
    })
    if(fromWhere == 'fromViewGroup') {
      this.setState({ TabsView: "foryou" })
    }
    this.getTrendingListApi("");
    this.getCategoryAPi()
    this.getViewProfileDara();
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {

    if (prevState.open !== this.state.open) {
      this.getTrendingListApi("");
    }

    if (prevState.openCity !== this.state.openCity) {
      this.getTrendingListApi(""); 
    }
  
    if (prevState.TabsView !== this.state.TabsView) {
      this.setState({
        list: []
      })
      this.getTrendingListApi("");
      this.getcitiesListApi();
      this.getrecommendationTypeApi();
    }

    if (prevState.commentData !== this.state.commentData) {
      this.initializeCommentReplyOpen();
    }

    const postDrawer = document.getElementById('postDrawer');
    if(postDrawer) {
      if (this.state.showEmoji && !prevState.showEmoji) {
        postDrawer.addEventListener('click', this.handleClickOutside)
      } else if (!this.state.showEmoji && prevState.showEmoji) {
        postDrawer.removeEventListener('click', this.handleClickOutside);
      }
      if (this.state.replyShowEmoji && !prevState.replyShowEmoji) {
        postDrawer.addEventListener('click', this.handleClickOutside);
      } else if (!this.state.replyShowEmoji && prevState.replyShowEmoji) {
        postDrawer.removeEventListener('click', this.handleClickOutside);
      }
    }

  
  }

  handleApiResponse(apiRequestCallId:string | number, responseJson1:SS["TrendingListResponse"] | SS["CitiesListResponse"] | SS["RecommendationResponse"] | SS["CategoryListresponse"] ) {
    switch (apiRequestCallId) {
      case this.getuserdataCallId:
        localStorage.setItem("userdata", JSON.stringify(responseJson1.data));
        this.setState({ profile: responseJson1.data });
        break;
      case this.trendingList:
        this.handleTrendingListResponse(responseJson1 as SS["TrendingListResponse"]);
        break;
      case this.citiesList:
        this.handleCitiesListResponse(responseJson1 as SS["CitiesListResponse"]);
        break;
      case this.recommendationtypeid:
        this.handleRecommendationTypeResponse(responseJson1 as SS["RecommendationResponse"]);
        break;
      case this.categoryListingId:
        this.handleCategoryLisingIdResponse(responseJson1 as SS["CategoryListresponse"] );
        break;
       /*istanbul ignore next */
      case this.addFavDataCallId:
        this.AddToFavData();
        break;
               /*istanbul ignore next */

      case this.likePostCallId:
        this.getTrendingListApi("")
        break;
               /*istanbul ignore next */

      case this.getReportDataCallId:
        this.getReportCatData(responseJson1 as any);
        break;
               /*istanbul ignore next */

      case this.addReportPostId:
        this.postReportData(responseJson1 as any)
        break;
               /*istanbul ignore next */
              case this.getShareconnectionCallId:
                this.getShareconnection(responseJson1)
                break;
              case this.sharePostId:
                this.sharePostData(responseJson1)
                break;
              case this.notificationshowID:
                this.getNotificationdata(responseJson1)
                break;
              case this.addBlockUserId:
                this.postBlockData();
                break;
              case this.apiCommentItemCallId:
                this.setState({ commentData: responseJson1.data, errorMsg: "", loading: false, });
                this.getEnablenotification()
                break;
              case this.likeCommentCallID:
                this.getCommentData(this.state.selectedPostId)
                break;
              case this.createCommentId:
                this.getCommentData(this.state.selectedPostId)
                this.getTrendingListApi("");
                break;
              case this.replycommentID:
                this.getCommentData(this.state.selectedPostId)
                break;
              case this.tagandMentionapiId:
                this.fetchUsers(responseJson1 as any)
                break;
              case this.shareCommentId:
                this.sharCommentData(responseJson1)
                break;
      default:
        this.setState({ loading: false });
        this.parseApiErrorResponse(responseJson1);
        break;
    }
  }

  drawerSidebaar = (elementid: string) => {
    this.setState({ postId: elementid })
    this.setState({ sidebaarOpen: true })
  }
  drawerSidebaarClose = () => {
    this.setState({ shareconnectiondata: [], sharemessage: "", setSearchvalue:'', sidebaarOpen: false, postShareTrendingLoading: false, selectedmember: [] })
  }

  drawerBlockUser = (element: string) => {
    this.setState({ blockUserSideBar: true })
  }

  drawerBlockUserClose = () => {
    this.setState({ blockUserSideBar: false })
  }

  handlewritemessage = (event: any) => {
    this.setState({ sharemessage: event.target.value })
  }
  handlesearchTag = (event: any) => {
    this.handleSearchconnections(event);
    this.setState({setSearchvalue:event.target.value})
  }

  removesearchdata = () => {
    this.setState({setSearchvalue: '', shareconnectiondata:[]})
  }

  postBlockData = () => {
    this.setState({
      openBlockedModal: true,
      blockUserSideBar: false,
      successModalOpen: false,
    })
  }


  handleSearchconnections = (data: any) => {
    const token = localStorage.getItem("accessToken")

    const getShareconnectionMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getShareconnectionCallId = getShareconnectionMessage.messageId;

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_share/share?search=${data.target.value}`
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getShareconnectionMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    runEngine.sendMessage(getShareconnectionMessage.id, getShareconnectionMessage);
  }

  getShareconnection = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ shareconnectiondata: responseJson.data,shareconnectiondata2:responseJson.data })
    }
  }

  getvalueconnectioncheck = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember, { id: data.id, value: data.name }];
        this.setState({ selectedmember: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember: deleteSelectedType });
    }
  }

  handleselectmemberDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember: deleteSelectedType });
  }

  handleCloseSuccessModal = () => {
    this.setState({
      successModalOpen: false,
      openBlockedModal: false
    })
  }

  toggleReply = (element: any) => {
    this.setState((prevState) => ({
      isReplyOpen: element?.id === prevState.openForElement ? !prevState.isReplyOpen : true,
      openForElement: element?.id
    }));
  };

  closeOkayModal = () => {
    this.setState({
      successModalOpen: false,
      openBlockedModal: false
    })
  }


  addedToBlock = () => {
    const token = localStorage.getItem("accessToken")
    let data = {
      blocker_id: this.state.selectedblockpostId,
      reason_title: this.state.reasonTitle,
      reason_description: this.state.reasonDescription
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addBlockUserId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.addToBlockApiPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethodPost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePostshare = () => {
    this.setState({postShareTrendingLoading: true})
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember.map((element: any) => element.id);
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };


    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sharePostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `posts/${this.state.postId}/share`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  sharePostData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.drawerSidebaarClose()
    }
  }

  getEnablenotification = () => {
    const token = localStorage.getItem("accessToken");

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationshowID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles/unreads`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getNotificationdata = (responseJson: any) => {
    if (responseJson.data) {
      localStorage.setItem("unreadnotification", responseJson.data.unread_notification)
    }
  }

  handleCommentfetch = (postId: number) => {
    this.getCommentData(postId)
    this.getUserData()
  }
  handleClick = (postId: number, event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(true, postId);
    this.setState({ selectedPostId: postId });
    this.handleCommentfetch(postId)
  }
  toggleCommentDrawer = (isOpen: boolean, postId: number) => {
    this.setState({ isCommentDrawerOpen: isOpen, selectedPostId: postId });
  }

  handleClose2 = (event: React.MouseEvent<HTMLElement>) => {
    this.toggleCommentDrawer(false, 0);
  }
 

  handleCommentChange = (event: any) => {
    this.setState({ comment: event.target.value });
  };

  handleCommentSubmit = () => {
    const { comment, commentData } = this.state;
    const mentionRegex = /@\[(.+?)\]\((\d+)\)/g;
    let mentionIds: number[] = [];
    let formattedComment = comment.replace(mentionRegex, (_, display, id) => {
      mentionIds.push(id);
      return `@${display}`;
    });
    if (comment.trim()) {
      const newComment = {
        attributes: {
          comment: formattedComment,
          post_id: this.state.selectedPostId,
          mentions_ids: mentionIds
        }
      };

      this.setState({
        commentData: [newComment, ...commentData],
        commentString: formattedComment,
        mentions_ids: mentionIds,
        comment: '',
        isCommentsOpen: true,
      }, () => {
        this.createComment();
        this.getCommentData(this.state.selectedPostId)
      });
    }
  };
  
  toggleComments = () => {
    this.setState((prevState) => ({ isCommentsOpen: !prevState.isCommentsOpen }));
  };

  initializeCommentReplyOpen = () => {
    const commentReplyOpen = this.state.commentData.map(() => false);
    this.setState({ commentReplyOpen });
  };

  handleReplycomment = () => {
    const { replyComment, commentData } = this.state;
    if (replyComment.trim()) {
      const newReply = {
        attributes: {
          comment: replyComment,
          post_id: this.state.selectedPostId,
          parent_comment_id: this.state.commentID,
        }
      };

      const updatedComments = commentData.map((comment: any) => {
        if (comment.attributes.id === this.state.commentID) {
          return {
            ...comment,
            attributes: {
              ...comment.attributes,
              replies: [newReply, ...comment.attributes.replies]
            }
          };
        }
        return comment;
      });

      this.setState({
        commentData: updatedComments,
        replyCommentString: this.state.replyComment,
        replyComment: '',
        commentReplyOpen: []
      }, () => {
        this.createReplyComment();
      });
    }
    this.getCommentData(this.state.selectedPostId)
  }

  likeComment = (e: any) => {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };

    const attrs = {
      "post_id": e.attributes.post_id,
      "likeable_id": e.id,
      "likeable_type": "BxBlockMentionsAndTagging::Comment"
    };
    const data = {
      "type": e.attributes.is_liked ? "unlike" : "like",
      "attributes": attrs
    };

    const finaldata = {
      data
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeCommentCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_like/likes'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShareCommentClick = (id: number) => {
    this.setState({ selectedComment: id, open3: true });
  };

  getvalueconnectioncheck3 = (elementdata: any, data: any) => {

    if (elementdata.target.checked) {
      const IteamIndex = this.state.selectedmember3.findIndex((iteam: any) => iteam.id === data.id);


      if (IteamIndex < 0) {
        const storetype = [...this.state.selectedmember3, { id: data.id, value: data.name }];
        this.setState({ selectedmember3: storetype });
      }
    } else {
      const deleteSelectedType = this.state.selectedmember3.filter((element: any) => {
        return element.id !== data.id
      })

      this.setState({ selectedmember3: deleteSelectedType });
    }
  }
  handleselectmemberDelete3 = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedmember3.filter((element: any, index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedmember3: deleteSelectedType });
  }

  drawerSidebaarClose3 = () => {
    this.setState({ open3: false, selectedmember3: [], shareconnectiondata2: [], isCommentDrawerOpen: false })
  }

  fetchUsers = (data: { accounts: { id: number; first_name: string; last_name: string; profile_picture: { url: string } }[] }) => {
    const mappedUsers: accounts[] = data.accounts.map((user) => ({
      id: user.id,
      display: `${user.first_name} ${user.last_name}`,
      profile_picture: user.profile_picture.url,
    }));
    this.setState({ users: mappedUsers });
  };

  sharCommentData = (responseJson: any) => {
    if (responseJson.message) {
      this.getEnablenotification()
      this.setState({ shareconnectiondata2: [], sharemessage3: "", open3: false })
    }
  }

  handlewriteCommentmessage = (event: any) => {
    this.setState({ sharemessage3: event.target.value })
  }

  handleCommentshare = () => {
    const token = localStorage.getItem("accessToken");

    const memberid = this.state.selectedmember3.map((element: any) => element.id);
    const header = {
      token,
    };

    let formdata = new FormData()
    for (let iteams of memberid) {
      formdata.append("room_ids[]", iteams)
    }
    formdata.append("content", this.state.sharemessage3)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.shareCommentId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `comments/${this.state.selectedComment}/share`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `POST`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  createComment = () => {
    const header = {
      "Content-Type":'application/json',
      token: localStorage.getItem("accessToken")
    };

    const httpBody = {
      post_id: this.state.selectedPostId,
      comment: this.state.commentString,
      mentions_ids: this.state.mentions_ids
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCommentId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_mentions_and_tagging/comments'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createReplyComment() {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };

    const replyData = {
      parent_comment_id: this.state.commentID,
      comment: this.state.replyCommentString,
      mentions_ids: []
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.replycommentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_mentions_and_tagging/comments/create_reply'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(replyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getCommentData(postId: number): any {
    const header = {
      "Content-Type":'application/json',
      token: localStorage.getItem("accessToken")
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiCommentItemCallId = requestMessage.messageId;

    const endpoint = `bx_block_mentions_and_tagging/comments?post_id=${postId}`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserData(): any {
    const header = {
      "Content-Type": 'application/json',
      token: localStorage.getItem("accessToken")
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.tagandMentionapiId = requestMessage.messageId;

    const endpoint = `bx_block_addfriends/connections`;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  calculateTimeGap = (createdAt: string): string => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const diffInMillis = currentDate.getTime() - createdDate.getTime();
    const diffInSeconds = diffInMillis / 1000;
    const diffInMinutes = diffInSeconds / 60;
    const diffInHours = diffInMinutes / 60;
    const diffInDays = diffInHours / 24;
    const diffInWeeks = diffInDays / 7;

    if (diffInMinutes < 1) {
      return 'few mins ago';
    } else if (diffInHours < 1) {
      return `${Math.floor(diffInMinutes)}m`;
    } else if (diffInHours < 24) {
      if (diffInHours < 1.5) {
        return 'few hrs ago';
      }
      return `${Math.floor(diffInHours)}h`;
    } else if (diffInDays < 7) {
      return `${Math.floor(diffInDays)}d`;
    } else {
      return `${Math.floor(diffInWeeks)}w`;
    }
  };

  handleReplySection = (index: number, commentId: number, elementId: number) => {
    const updatedCommentReplyOpen = this.state.commentReplyOpen.map((isOpen: boolean, i: number) => (i === index ? !isOpen : isOpen));
    this.setState({ commentReplyOpen: updatedCommentReplyOpen, commentID: commentId, openForElement: elementId });
  };
  handleReplyCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ replyComment: event.target.value });
  };
       /*istanbul ignore next */
  postReportData = (responseJson: ReportCategory) => {
    if (responseJson) {
      this.setState({
        successModalOpen: true,
        reportId: 0,
        selected: "",
        open: false
      })
    }
  }
       /*istanbul ignore next */
  getReportCatData = (responseJson: { categories: ReportCategory[] }) => {
    this.setState({
      reportListData: responseJson.categories,
    })
  }
       /*istanbul ignore next */

  AddToFavData = () => {
  this.getTrendingListApi("")
  this.getViewProfileDara();
  }
  
  handleTrendingListResponse(responseJson1:SS["TrendingListResponse"]) {
    if (responseJson1.error) {
      this.setState({ selectedCity: [], selectedType: [] });
    } else {
      this.setState({
        list: responseJson1.data || [],
        loading: false,
      });
    }
  }
  handleCitiesListResponse(responseJson1:SS["CitiesListResponse"]) {
    if (responseJson1.error) {
      toast.error("not data found");
    } else {
      const testdata = responseJson1.data.map((element:SS["CitiesListResponse"]["data"][0])=> ({
        label: element.name,
        value: element.name,
        id: element.id
      }));
      this.setState({ citilist: testdata  || [], filteredCities: testdata});
    }
  }
  handleSearch = (value: string) => {
    const query = value.toLowerCase();
    this.setState({searchCityQuery: query})

    const results = this.state.citilist.filter(item =>
      item.value.toLowerCase().includes(query)
    );
    
    this.setState({ filteredCities: results.length ? results: this.state.citilist});
  };
  handleRecommendationTypeResponse(responseJson1:SS["RecommendationResponse"] ) {
    if (responseJson1.error) {
      toast.error("not data found");
    } else {
      const testdata2 = responseJson1.data.map((element:SS["RecommendationResponse"]["data"][0] ) => ({
        label: element.attributes.name,
        value: element.attributes.name,
        id: element.attributes.id
      }));
      this.setState({ recommendationType: testdata2 || [] });
    }
  }

  handleCategoryLisingIdResponse(responseJson1:SS["CategoryListresponse"] ){
    if(responseJson1.error){
      toast.error("not data found");
    }else{
      this.setState({categoryData:responseJson1.data})
    }
  }
  

  toggleDrawer1 = (content: string) => {
      this.setState({ openCity: true });
  }
    

  toggleDrawer2 = (content: string) => {
      this.setState({ opentest: true });
  }   

  handleTabChange = async (element: string) => {
    if(element == "Trending"){
      this.setState({selectedCity:[]})
      this.setState({selectedType:[]})
    }
  
    this.setState({ TabsView: element })
  }

  handleselectTypeDelete = (elementdata: number) => {
    const deleteSelectedType = this.state.selectedType.filter((element: SS["SelectedItem"], index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedType: deleteSelectedType });
  }

  handleselectCityDelete = (elementdata: number) => {
    const deleteSelectedCity = this.state.selectedCity.filter((element: SS["SelectedItem"], index: number) => {
      return index !== elementdata
    })

    this.setState({ selectedCity: deleteSelectedCity });
  }

  settypevalueintype = () => {
    this.setState({ savetype: "true" })
    this.getTrendingListApi("")
    this.drawerClose()
  }

  settypevalueincity = () => {
    this.setState({ savecity: "true" })
    this.getTrendingListApi("")
    this.drawerClose1()
  }

  getvaluetyepcheck = (data: SS["SelectedItem"]) => {

      const isExist = this.state.selectedType.find((item: SS["SelectedItem"]) => item.id === data.id);
      if (isExist) {
        const filteredArr = this.state.selectedType.filter(city => city.id !== data.id);
        this.setState({selectedType: filteredArr})
      } else {
        const storetype = [...this.state.selectedType, { id: data.id, value: data.value }];
        this.setState({ selectedType: storetype }); 
      }

  }

  getvaluecitycheck = (citydata: SS["SelectedItem"]) => {
      const isExist = this.state.selectedCity.find((iteam: SS["SelectedItem"]) => iteam.id === citydata.id);
      if (isExist) {
        const filteredArr = this.state.selectedCity.filter(city => city.id !== citydata.id);
        this.setState({selectedCity: filteredArr})
      }else {
        const storetype = [...this.state.selectedCity, { id: citydata.id, value: citydata.value }];
        this.setState({ selectedCity: storetype });
      }
    
  }

  isCitySelected = (city: { value: string }) => {
    return this.state.selectedCity.some(selected => selected.value === city.value);
  }

  isTypeSelected = (type:{ value: string} ) => {
     return this.state.selectedType.some(selected => selected.value === type.value);
  }

  handleviewgroup=(categoryid:string,subcategoryid:string,subcategoryname:string)=>{
     setStorageData( "categoriesId" , categoryid )
     setStorageData( "subcategoryId" , subcategoryid )
     setStorageData( "subcategoryName" , subcategoryname )
     setStorageData("categoryid" , categoryid)

     const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Categorydetails");
    categoryid && message.addData(getName(MessageEnum.NavigationScreenNameMessage), categoryid);
    runEngine.sendMessage(message.id, message);
    }

    removeStorageNavigate = async (elementId: string) => {
      await removeStorageData( "categoriesId"  )
      await removeStorageData( "subcategoryId"  )
      await removeStorageData( "subcategoryName" )
      setStorageData("categoriesId" , elementId)

      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), "Categorydetails");
      elementId && message.addData(getName(MessageEnum.NavigationScreenNameMessage), elementId);
      runEngine.sendMessage(message.id, message);
    }

  drawerClose = () => {
    this.setState({ opentest: false, openCity: false , open: false })
  }

  drawerClose1 = () => {
    this.setState({ open: false, openCity: false })
  }

  searchdata = (element: SS["CheckboxEvent"]) => {
    this.getTrendingListApi(element.target.value)
  }

  getTrendingListApi = async (search:SS["CheckboxEvent"]["target"]["value"]) => {
    this.setState({ loading: true });
    const token = localStorage.getItem("accessToken")
    const header = {
      "token": token
    };

    const recommendationid = []
    for (const selected of this.state.selectedType) {
      recommendationid.push(selected.id);
    }

    const city = this.state.selectedCity.map((cityObj: SS["SelectedItem"]) => cityObj.value);

    let urlweb = `bx_block_advanced_search/filter?search_type=${this.state.TabsView}`;
    if (recommendationid.length > 0) {
      const queryStringParams = encodeURIComponent(JSON.stringify(recommendationid))
      urlweb += `&recommendation_type_id=${queryStringParams}`
    }
    if (city.length > 0) {
      const cityParam = encodeURIComponent(JSON.stringify(city));
      urlweb += `&city=${cityParam}`;
    }

    if (search && search.length > 0) {
      urlweb += `&search=${search}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.trendingList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlweb}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategoryAPi = ()=>{
    const token = localStorage.getItem("accessToken")
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    let urlweb = `bx_block_categories/categories`;
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.categoryListingId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${urlweb}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getcitiesListApi = async () => {
    const token = localStorage.getItem("accessToken")
    const header = {
      // "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.citiesList = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/list_cities`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getrecommendationTypeApi = async () => {
    const token = localStorage.getItem("accessToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.recommendationtypeid = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/list_recommendation_type`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

     /*istanbul ignore next */

  getToFavriouteData(addFavId: string) {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addFavDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddToFavIdPoint + addFavId + '/mark_as_favorite'
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethodPost
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

   /*istanbul ignore next */
  likePost = (e: any) => {
    const token = localStorage.getItem("accessToken")
    const account_id = localStorage.getItem("account_id")

    const attrs = {
      "post_id": e.id,
      "account_id": account_id
    };

    const data = {
      type: e.attributes.is_liked ? "unlike" : "like",
      attributes: attrs
    };

    const finaldata = {
      data
    }


    const LikePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.likePostCallId = LikePostMessage.messageId;

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_like_a_post/like_posts`
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: `${token}`,
      })
    );


    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(finaldata)
    );

    LikePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );


    runEngine.sendMessage(LikePostMessage.id, LikePostMessage);
  }

   /*istanbul ignore next */
  handlePostReport = (postId: number) => {
    this.setState({ open: true })
    this.getReportData();
    this.setState({
      selectedpostId: postId
    })
  }

     /*istanbul ignore next */
  getReportData() {
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportDataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReportApiPoint
    );



    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

       /*istanbul ignore next */
  addedReport = () => {
    const token = localStorage.getItem("accessToken")
    let data = {
      post_id: this.state.selectedpostId,
      flag_category_id: this.state.reportId
    }

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addReportPostId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReportApiPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethodPost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
       /*istanbul ignore next */
  handleSelect = (item: { id: number }, index: number) => {
    this.setState({
      selected: `${index}`,
      reportId: item.id
    })
  }
         /*istanbul ignore next */
  handleOpenMenu = (event: any, id: string) => {
    this.setState({
      openMenuId: id,
      openMenu: event?.currentTarget,
    });
  };
       /*istanbul ignore next */
  handleClose = () => {
    this.setState({
      openMenuId: null,
      openMenu: false,
    });
  };
       /*istanbul ignore next */
  toggleDrawer = (e: boolean, content: string) => {
    this.setState({ opentest: false , open : false })
  }


    handleBlockUser = (elementId: number) => {
      this.setState({
        blockUserSideBar: true,
        deletedResponseData: configJSON.blockReasons,
        selectedblockpostId: elementId
      })
      this.handleClose();
    }

    handleSelect1 = (item: { title: string, description: string }, index: number) => {
      this.setState({
        reasonTitle: item.title,
        reasonDescription: item.description,
        indexBlockList: index
      });
    }

       /*istanbul ignore next */
  /*istanbul ignore next */

  transformMediaItems = (items: { content_type: string; url: string; }[]) => {
    return items.map((media: { content_type: string; url: string; }) => {
      let mediaType = 'video';
      if (media.content_type && media.content_type.startsWith('image/')) {
        mediaType = 'image';
      }
      return {
        type: mediaType,
        url: media.url,
      };
    });
  }
  /*istanbul ignore next */
  getMedia = (element: any) => {
    const imagesAndVideoss = element.attributes.images_and_videos ? this.transformMediaItems(element.attributes.images_and_videos) : [];
    const media = element.attributes.media ? this.transformMediaItems(element.attributes.media) : [];
    return [...imagesAndVideoss, ...media];
  };

  onClickEmoji(){
    this.setState({showEmoji: !this.state.showEmoji})
  }
  EmojiClick(emojiObject:any){
  const { comment } = this.state;
    const newText = comment.slice(0) + emojiObject.emoji 
    this.setState({ comment: newText, showEmoji: false });
  }

  onClickReplyEmoji(){
    this.setState({replyShowEmoji: !this.state.replyShowEmoji});
  }
  ReplyEmojiClick(emojiObject:any){
  const { replyComment } = this.state;
    const newText = replyComment.slice(0) + emojiObject.emoji 
    this.setState({ replyComment: newText, replyShowEmoji: false });
  }

  drawerCloses = () => {
    this.setState({isCommentDrawerOpen : false})
  }

  getViewProfileDara() {    
    const account_id = localStorage.getItem("account_id");
    const token = localStorage.getItem("accessToken")

    const getNewsFeedMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getuserdataCallId = getNewsFeedMessage.messageId;

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${account_id}`
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: `${token}`,
      })
    );

    getNewsFeedMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNewsFeedMessage.id, getNewsFeedMessage);
  }

  handleClickOutside=(event: any)=>{
    this.setState({showEmoji: false, replyShowEmoji: false,});
    document.removeEventListener('click', this.handleClickOutside);
  }

  // Customizable Area End
}
