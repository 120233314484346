import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Popover
} from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroll-component';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AppHeader } from "../../../components/src/AppHeader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { imgBell,green,no_notification, new_kella_logo } from "./assets";
import { styled } from "@material-ui/styles";
export interface Account {
  id: number;
  first_name: string;
  last_name: string;
  online: boolean;
  role: string;
  profile_picture: {url: string};
  friend: boolean;
  mutual_friends: {
    count: number;
    profile_icons: string[];
  };
  created_at: string;
  connection_id: number;
}
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  emptyNotifications(){
    return (
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <Box style={{ width: '155px', height: '155px', gap: '0px', opacity: '0px' }}>
          <img src={no_notification} alt="No notifications" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        </Box>
        <Typography
          style={{
            fontFamily: 'Halyard Display',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            letterSpacing: '0.08em',
            textAlign: 'left',
            marginTop: '20px',
            padding: '10px 20px',
            color: '#64748B',
          }}
        >
          No new notifications
        </Typography>
      </Box>
    )
  }
  notificationList() {
    return (
      <InfiniteScroll
      scrollableTarget="scrollableDiv"
      scrollThreshold='80%'
      dataLength={this.state.notificationsResponse?.data.length}
      next={this.getNotificationsData}
      hasMore={this.state.isHasNextPage}
      loader={<h4>Loading...</h4>}
    > {
      this.state.notificationsResponse?.data.map((notification, index) => {
      const { created_at, remarks, account, is_read, title } = notification.attributes;
      const { id } = notification;
      return (
        <Box
          key={index}
          style={{
            width: '90%',
            margin: 'auto',
            borderBottom: '1px solid #CBD5E1',
            padding: '10px',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            cursor: "pointer"
          }}
        >
          <Box style={{ marginRight: '10px' }}>
            {this.notificationImg(account)}
          </Box>
          <Box style={{ flex: 1, textAlign: 'center' }}
              data-test-id="handlePostRedirectionToNewsfeed"
              onClick={()=> this.handlePostRedirectionToNewsfeed(notification.attributes)}
          >
            <Typography
              style={{
                fontFamily: 'Halyard Display',
                fontWeight: 400,
                fontSize: '11px',
                lineHeight: '16px',
                color: '#475569',
                textAlign: 'left',
                margin: 0,
                marginTop: '8px',
              }}
            >
              {this.formatDate(created_at)}
            </Typography>
            {this.notificationTitle({account, is_read, title })}
            <Typography style={{ fontFamily: "'MyFont', 'Halyard Display'", fontSize: '12px', fontWeight: 'normal', textAlign: 'left', margin: 0, marginTop: '8px' }}>
              {remarks}
            </Typography>
          </Box>
          <CustomBox  onClick={(event) => this.handleClick(event, id)}  >
            <i  className="fa-solid fa-ellipsis-vertical" ></i>
          </CustomBox>
          {this.popover()}
        </Box>
      );
    })
  }
  </InfiniteScroll>
)
  }
  notificationImg(account?: Account) {
    if(account) return (
      <img
      src={account.profile_picture.url}
      alt={`${account.first_name} ${account.last_name}`}
      style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover', cursor: 'pointer' }}
      onClick={() => this.handleGoToAccount(account.id)}
    />
    )
    return  <img
    src={ new_kella_logo}
    alt={'kella_logo'}
    style={{ width: '50px', height: '50px', borderRadius: '50%', objectFit: 'cover' }}
  />
  }
  notificationTitle({account, title, is_read }:{account: Account, title: string, is_read: boolean }) {
    return (
      <Typography
              style={{
                fontSize: '14px',
                fontWeight: is_read ? '500' : 'bold',
                textAlign: 'left',
                margin: 0,
                marginTop: '8px',
                lineHeight: '22px',
                letterSpacing: '0.08em',
                fontFamily:"'MyFont', 'Halyard Display'"
              }}
            >
              {account ? <>
                {account.first_name}{" "}
              <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                {account.last_name} {account.online && <img src={green} alt="Online" style={{ marginLeft: '4px', width: '10px', height: '10px' }} />}
              </span>
              </>: title}
            </Typography>
    )
  }
  popover(){
    return (
    <Popover
      open={Boolean(this.state.anchorEl)}
      anchorEl={this.state.anchorEl}
      onClose={this.handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box style={{ width: '100px', height: '30px', padding: '7px 98px 7px 16px', gap: '8px', borderRadius: '8px', opacity: '0px', backgroundColor: 'white',border: '1px solid rgb(203, 213, 225)'  }}>
        <Typography
        data-test-id="delete-option"
        onClick={() => this.deleteNotifications(this.state.currentNotificationId)}
        style={{
        cursor: 'pointer',
        color: '#D1584C',
        fontFamily: 'Halyard Display',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.08em',
        textAlign: 'left',
        padding: '5px',
        margin: 0,
        }}
        >
        <i className="fa-regular fa-trash-can" style={{ color: '#D1584C' }}></i> Delete
        </Typography>
      </Box>
    </Popover>)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start 
      <ThemeProvider theme={theme}>

        <AppHeader component={"Notifications"} />
        <Box style={{ backgroundColor: '#F8FAFC', width: '100%'}} sx={{ paddingTop: {xs: '0', md:'20px'} }}>
          <Container style={{ marginBottom:"2rem", backgroundColor: '#fff', maxWidth: '700px' ,borderRadius:'11px',}}>
            <Box sx={webStyle.mainWrapper} >
              <Box sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'space-between', padding: '10px 16px', width:'100%', boxSizing: 'border-box'}}>
                <ArrowBackIcon data-test-id='arrowIcon' onClick={this.handleBack}/>
                <Typography style={{alignSelf: 'center', fontSize: '20px',fontWeight: 'bold', color: '#14362E', fontFamily: 'MyFont', transform: 'translateX(-13px)'  }}>Notifications</Typography>
                <div/>
              </Box>
            {this.state.notificationsResponse?.data && this.state.notificationsResponse.data.length > 0 && (
  <Box data-test-id="allreadmsg" onClick={() => this.markAsAllRead()} style={{ cursor: 'pointer', width: "93%", borderBottom: '1px solid #CBD5E1', marginBottom: '0px' }}>
    <Typography
      style={{
        textAlign: 'end',
        paddingTop: '2rem',
        paddingBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: '#64748B',
        fontFamily: 'Halyard Display',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '22px',
      }}
    >
      <i className="fa-solid fa-check" style={{ color: '#64748B', fontSize: '14px', marginRight: '5px' }}></i>
      <span style={{ fontWeight: 'bold' }}>
        {configJSON.notifications}
      </span>
    </Typography>
  </Box>
)}

              <Dialog
                maxWidth={"md"}
                open={!!this.state.selectedData}
                onClose={() => this.setState({ selectedData: null })}
              >
                <DialogContent>
                  <Typography
                    variant="h6"
                    style={
                      this.state.selectedData?.attributes.is_read
                        ? webStyle.itemHeading
                        : webStyle.itemHeadingRead
                    }
                  >
                    {this.state.selectedData?.attributes.headings}
                  </Typography>
                  <Typography variant="h6" style={webStyle.contents}>
                    {this.convertDate(
                      this.state.selectedData?.attributes.created_at
                    )} 
                   </Typography> 
                  <Typography variant="h6" style={webStyle.contents}> 
                    {this.state.selectedData?.attributes.contents}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => this.setState({ selectedData: null })}
                    color="primary"
                    style={webStyle.okButton}
                  >
                    {configJSON.okText}
                  </Button>
                  <Button  data-test-id="deletemsg"
                  onClick={() =>
                      this.deleteNotifications(
                        this.state.selectedData?.attributes.id
                      )
                    }
                    variant="contained"
                    color="primary"
                    style={webStyle.deleteButton}
                  > 
                    {configJSON.deleteText}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
              <div id="scrollableDiv" style={{ height: '80vh', overflow: 'auto' }} data-test-id='scorable-div'>
                {this.notificationList()}
              </div>
              {!this.state.notificationsResponse?.data.length && !this.state.isNotificationLoading && this.emptyNotifications()}
          </Container>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const CustomBox = styled(Box)({
  width: "25px",
  height: "25px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  transition: "background-color 0.3s",
  "&:hover":{
    cursor: "pointer",
    backgroundColor: "grey"
  }
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "15px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
